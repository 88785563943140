import { ButtonV2, Component, themeV2, useSafeCallback, useSafeState, useUnmountRef } from '@atomica.co/components';
import {
  BaseDto,
  FETCH_SPACE_RESERVATION,
  FetchSpaceReservationRequest,
  FetchSpaceReservationResponse,
  SAVE_SPACE_PARTICIPANT,
  SHARED_SPACE_RESERVATION_END_AT,
  SHARED_SPACE_RESERVATION_ID,
  SHARED_SPACE_RESERVATION_START_AT,
  SaveSpaceParticipantRequest,
  SaveSpaceParticipantResponse,
  SpaceParticipantDiv,
  User
} from '@atomica.co/irori';
import { builder, embedIdInPath } from '@atomica.co/utils';
import { CSSProperties } from '@material-ui/styles';
import React, { useEffect, useLayoutEffect } from 'react';
import styled from 'styled-components';
import mojacoGreeting from '../../../assets/mojaco/mojaco_greeting.png';
import mojacoSorry from '../../../assets/mojaco/mojaco_sorry.png';
import usePath from '../../../redux/hooks/usePath';
import CommonRequest from '../../../requests/common-request';
import { PATH_IDS, Path } from '../../../router/Routes';

interface P {
  base: BaseDto;
  user: User;
}

const AccountShare: React.FC<P> = React.memo(props => {
  const { base, user } = props;
  const { openPath, queryParams } = usePath();
  const unmountRef = useUnmountRef();
  const [loaded, setLoaded] = useSafeState<boolean>(unmountRef, false);
  const [isShared, setIsShared] = useSafeState<boolean>(unmountRef);

  const sharedSpaceReservationId = queryParams[SHARED_SPACE_RESERVATION_ID];

  const openSpaceReservationDetail = useSafeCallback(() => {
    if (!sharedSpaceReservationId) return;
    openPath(embedIdInPath(Path.ACCOUNT_RESERVATION_DETAIL, PATH_IDS, [base.baseCode, sharedSpaceReservationId]));
  }, [base, openPath, sharedSpaceReservationId]);

  const redirectIfAlreadyParticipated = useSafeCallback(async (): Promise<void> => {
    if (!sharedSpaceReservationId) return undefined;
    const request = builder<FetchSpaceReservationRequest>().spaceReservationId(sharedSpaceReservationId).build();
    const response = await CommonRequest.call<FetchSpaceReservationRequest, FetchSpaceReservationResponse>(
      FETCH_SPACE_RESERVATION,
      request
    );
    if (!response.spaceReservation?.participants?.find(participant => participant.user?.userId === user.userId)) return;
    openSpaceReservationDetail();
  }, [openSpaceReservationDetail, sharedSpaceReservationId, user]);

  const initialize = useSafeCallback(async (): Promise<void> => {
    const sharedSpaceReservationStartAt = new Date(decodeURIComponent(queryParams[SHARED_SPACE_RESERVATION_START_AT]!));
    const sharedSpaceReservationEndAt = new Date(decodeURIComponent(queryParams[SHARED_SPACE_RESERVATION_END_AT]!));
    if (
      !user ||
      !sharedSpaceReservationId ||
      !sharedSpaceReservationStartAt ||
      !sharedSpaceReservationEndAt ||
      new Date() > sharedSpaceReservationEndAt
    ) {
      setLoaded(true);
      setIsShared(false);
      return;
    }
    const request = builder<SaveSpaceParticipantRequest>()
      .baseId(base.baseId)
      .userId(user.userId)
      .spaceReservationId(sharedSpaceReservationId)
      .participantDiv(SpaceParticipantDiv.MEMBER)
      .startAt(sharedSpaceReservationStartAt)
      .endAt(sharedSpaceReservationEndAt)
      .build();

    const response = await CommonRequest.call<SaveSpaceParticipantRequest, SaveSpaceParticipantResponse>(
      SAVE_SPACE_PARTICIPANT,
      request
    );

    if (!response.spaceParticipantId) {
      setLoaded(true);
      setIsShared(false);
      return;
    }
    setLoaded(true);
    setIsShared(true);
  }, [base.baseId, queryParams, setIsShared, setLoaded, sharedSpaceReservationId, user]);

  useEffect(() => {
    initialize();
  }, [initialize]);

  useLayoutEffect(() => {
    redirectIfAlreadyParticipated();
  }, [redirectIfAlreadyParticipated]);

  return (
    <Component loading={!loaded} style={styleForComponent} className='account-share'>
      <Container>
        {isShared === false && (
          <>
            <Label>このリンクは無効です</Label>
            <Mojaco src={mojacoSorry} />
          </>
        )}
        {isShared === true && (
          <>
            <Label>招待への参加が完了しました！</Label>
            <ButtonV2 type='primary' label='参加した予約を確認する' onClick={openSpaceReservationDetail} />
            <Mojaco src={mojacoGreeting} />
          </>
        )}
      </Container>
    </Component>
  );
});

AccountShare.displayName = 'AccountShare';
export default AccountShare;

const styleForComponent: CSSProperties = {
  width: '100%',
  height: '100%'
};

const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const Label = styled.label`
  ${themeV2.mixins.v2.typography.label.large};
`;

const Mojaco = styled.img`
  width: 100%;
  max-width: 350px;
  height: auto;
  object-fit: cover;
  user-select: none;
  padding: ${themeV2.mixins.v2.spacing}px;
`;
