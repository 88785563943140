import { ButtonV2, DialogV2, themeV2, useSafeCallback, useSafeState, useUnmountRef } from '@atomica.co/components';
import { SearchField } from '@atomica.co/components/build/v2/textfield/SearchField';
import {
  BaseDto,
  MessageTemplate,
  SEARCH_MESSAGE_TEMPLATES_FOR_ADMIN,
  SearchMessageTemplatesForAdminRequest,
  SearchMessageTemplatesForAdminResponse
} from '@atomica.co/irori';
import { Index, Name, Text, Word } from '@atomica.co/types';
import { EMPTY, builder } from '@atomica.co/utils';
import { RadioGroup } from '@material-ui/core';
import React, { useEffect, useMemo } from 'react';
import styled from 'styled-components';
import useCommonRequest from '../../../redux/hooks/useCommonRequest';
import SelectTemplateCard from '../card/SelectTemplateCard';

interface P {
  base: BaseDto;
  isOpen: boolean;
  name?: Name;
  onChange: (text: Text) => void;
  onClose: () => void;
}

const SelectTemplateModal: React.FC<P> = React.memo(props => {
  const { base, isOpen, name, onChange, onClose } = props;

  const { commonRequest } = useCommonRequest();

  const unmountRef = useUnmountRef();
  const [searchingWord, setSearchingWord] = useSafeState<Word>(unmountRef, EMPTY);
  const [selectedIndex, setSelectedIndex] = useSafeState<Index>(unmountRef, -1);
  const [messageTemplates, setMessageTemplates] = useSafeState<MessageTemplate[]>(unmountRef, []);

  const handleTemplateSelected = useSafeCallback((): void => {
    onChange(messageTemplates[selectedIndex].content);
    onClose();
  }, [messageTemplates, onChange, onClose, selectedIndex]);

  const handleRadioChanged = useSafeCallback(
    (e: React.ChangeEvent<HTMLInputElement>): void => {
      setSelectedIndex(Number(e.target.value));
    },
    [setSelectedIndex]
  );

  const initialize = useSafeCallback(async (): Promise<void> => {
    const request = builder<SearchMessageTemplatesForAdminRequest>().baseId(base.baseId).word(searchingWord).build();
    const { messageTemplates } = await commonRequest<
      SearchMessageTemplatesForAdminRequest,
      SearchMessageTemplatesForAdminResponse
    >(SEARCH_MESSAGE_TEMPLATES_FOR_ADMIN, request);
    setMessageTemplates(messageTemplates);
    setSelectedIndex(-1);
  }, [base, commonRequest, searchingWord, setMessageTemplates, setSelectedIndex]);

  useEffect(() => {
    initialize();
  }, [initialize]);

  const footerButtons = useMemo<React.ReactNode[]>(() => {
    return [
      <ButtonV2 key='cancelButton' type='default' label='キャンセル' onClick={() => onClose()} />,
      <ButtonV2
        key='reflectButton'
        width={105}
        disabled={selectedIndex === -1}
        type='primary'
        label='反映'
        onClick={handleTemplateSelected}
      />
    ];
  }, [handleTemplateSelected, onClose, selectedIndex]);

  return (
    <DialogV2
      isOpen={isOpen}
      headerLabel={'テンプレートを選択' + (name ? `(${name})` : EMPTY)}
      buttonsOnTheRight={footerButtons}
      onClose={onClose}
    >
      <Container>
        <SearchFieldWrapper>
          <SearchField
            disableUnderline
            placeholder='タイトル・本文で検索'
            value={searchingWord}
            onChange={setSearchingWord}
          />
        </SearchFieldWrapper>
        <StyledRadioGroup value={selectedIndex} onChange={handleRadioChanged}>
          {messageTemplates.map((template: MessageTemplate, index: Index) => (
            <SelectTemplateCard
              key={`template-card-${index}`}
              template={template}
              index={index}
              isSelected={index === selectedIndex}
            />
          ))}
        </StyledRadioGroup>
      </Container>
    </DialogV2>
  );
});

SelectTemplateModal.displayName = 'SelectTemplateModal';
export default SelectTemplateModal;

const Container = styled.div`
  max-height: 640px;
  display: flex;
  flex-direction: column;
  gap: ${themeV2.mixins.v2.spacing * 2}px;
  padding: ${themeV2.mixins.v2.spacing * 2}px ${themeV2.mixins.v2.spacing * 3}px;
  overflow: scroll;
  ${themeV2.mixins.v2.scrollbarInvisible};
`;

const SearchFieldWrapper = styled.div`
  padding: ${themeV2.mixins.v2.spacing / 2}px ${themeV2.mixins.v2.spacing}px;
  border: 1px solid ${themeV2.mixins.v2.color.border.gray};
  border-radius: 8px;
`;

const StyledRadioGroup = styled(RadioGroup)`
  display: flex;
  flex-direction: column;
  gap: ${themeV2.mixins.v2.spacing * 2}px;
`;
