import {
  ButtonV2,
  ColWidth,
  DialogV2,
  Header,
  TableV3,
  themeV2,
  useSafeCallback,
  useSafeState,
  useUnmountRef
} from '@atomica.co/components';
import { AuthorityId, BaseAuthority } from '@atomica.co/irori';
import { Name } from '@atomica.co/types';
import { EMPTY, builder, hasLength } from '@atomica.co/utils';
import React, { useEffect, useMemo } from 'react';
import styled from 'styled-components';

const COLUMN_WIDTH: ColWidth = { name: 'auto' };

const HEADER: Header = { name: { label: '会員種別' } };

interface MembershipRow {
  id: AuthorityId;
  name: Name;
}

interface P {
  isOpen: boolean;
  baseMemberships: BaseAuthority[];
  membershipsToSave: BaseAuthority[];
  onClick: (baseMemberships: BaseAuthority[]) => void;
  onClose(isUpdated: boolean): void;
}

const MembershipModal: React.FC<P> = React.memo(props => {
  const { isOpen, baseMemberships, membershipsToSave, onClick, onClose } = props;

  const unmountRef = useUnmountRef();
  const [selectedMembershipIds, setSelectedMembershipIds] = useSafeState<AuthorityId[]>(unmountRef, []);

  const handleModalClosed = useSafeCallback((): void => {
    onClose(false);
  }, [onClose]);

  const selectMemberships = useSafeCallback(async (): Promise<void> => {
    const selectedMemberships = baseMemberships.filter(membership =>
      selectedMembershipIds.includes(membership.authorityId)
    );
    onClick(selectedMemberships);
    onClose(false);
  }, [baseMemberships, selectedMembershipIds, onClick, onClose]);

  useEffect(() => {
    const selectedMembershipIds = membershipsToSave.map(membership => membership.authorityId);
    setSelectedMembershipIds(selectedMembershipIds);
  }, [membershipsToSave, setSelectedMembershipIds]);

  const footerButtons = useMemo<React.ReactNode[]>(() => {
    return [
      <ButtonV2 key='cancelButton' label='キャンセル' onClick={handleModalClosed} />,
      <ButtonV2
        key='saveButton'
        width={105}
        type='primary'
        label={`追加${hasLength(selectedMembershipIds) ? `(${selectedMembershipIds.length})` : EMPTY}`}
        disabled={!hasLength(selectedMembershipIds)}
        onClick={selectMemberships}
      />
    ];
  }, [selectedMembershipIds, handleModalClosed, selectMemberships]);

  const rows = useMemo<MembershipRow[]>(
    () =>
      baseMemberships.map(membership =>
        builder<MembershipRow>().id(membership.authorityId).name(membership.authorityName).build()
      ),
    [baseMemberships]
  );

  return (
    <DialogV2
      isOpen={isOpen}
      headerLabel='会員種別を選択'
      buttonsOnTheRight={footerButtons}
      onClose={() => onClose(false)}
    >
      <TableWrapper>
        <TableV3
          colWidth={COLUMN_WIDTH}
          header={HEADER}
          rows={rows}
          selectedIds={selectedMembershipIds}
          setSelectedIds={setSelectedMembershipIds}
        />
      </TableWrapper>
    </DialogV2>
  );
});

MembershipModal.displayName = 'MembershipModal';
export default MembershipModal;

const TableWrapper = styled.div`
  height: auto;
  max-height: 532px;
  overflow-y: scroll;
  position: relative;
  ${themeV2.mixins.v2.scrollbarInvisible};
`;
