import { AccessV2, Direction, toFullName } from '@atomica.co/irori';
import { Key, Name, Text } from '@atomica.co/types';
import { EMPTY, Language, ONE, ZERO, calcTimeDiff, formatTimeFromMsec, isEmpty, isSameDay } from '@atomica.co/utils';
import { format } from 'date-fns';

interface AccessesGroupedBySpaceAndUser {
  [key: Key]: AccessV2[];
}

export interface AccessForBillingDetail {
  inDatetime: Text;
  outDatetime: Text;
  usageTime: Text;
  userName: Name;
  spaceName: Name;
}

export const toAccessesForBillingDetails = (accesses: AccessV2[]): AccessForBillingDetail[] => {
  const results: AccessForBillingDetail[] = [];
  const grouped = accesses.reduce((grouped: AccessesGroupedBySpaceAndUser, access: AccessV2) => {
    if (!access.user) return grouped;
    const key = `${access.space?.spaceId}-${access.user.userId}`;
    (grouped[key] = grouped[key] || []).push(access);
    return grouped;
  }, {});

  for (const key in grouped) {
    const init: AccessForBillingDetail = {
      inDatetime: EMPTY,
      outDatetime: EMPTY,
      usageTime: EMPTY,
      userName: EMPTY,
      spaceName: EMPTY
    };

    let result: AccessForBillingDetail = JSON.parse(JSON.stringify(init));

    const pushAndInitailize = () => {
      results.push(JSON.parse(JSON.stringify(result)));
      result = JSON.parse(JSON.stringify(init));
    };

    for (const access of grouped[key]) {
      const { direction } = access;
      if (!direction) continue;

      if (!isEmpty(result.outDatetime) && !isSameDay(access.operationDatetime, new Date(result.outDatetime))) {
        result = JSON.parse(JSON.stringify(init));
      }

      switch (direction) {
        case Direction.ENTER:
          result.inDatetime = format(access.operationDatetime, 'yyyy/MM/dd HH:mm:ss');
          break;

        case Direction.EXIT:
          result.outDatetime = format(access.operationDatetime, 'yyyy/MM/dd HH:mm:ss');
          break;

        default:
          continue;
      }

      if (!access.user) continue;
      result.userName = access.user ? toFullName(access.user) : EMPTY;

      if (!access.space) continue;
      result.spaceName = access.space.spaceName;

      if (!isEmpty(result.inDatetime) && !isEmpty(result.outDatetime)) {
        result.usageTime = formatTimeFromMsec(
          calcTimeDiff(new Date(result.inDatetime), new Date(result.outDatetime)),
          Language.JAPANESE
        );
      }

      if (direction === Direction.ENTER && !!result.outDatetime) {
        results.pop();
        pushAndInitailize();
        continue;
      }
      if (direction === Direction.ENTER && !result.outDatetime) {
        pushAndInitailize();
        continue;
      }

      results.push(JSON.parse(JSON.stringify(result)));
    }
  }
  return results.sort((a, b) => {
    if (a.inDatetime === EMPTY) {
      return a.outDatetime > b.outDatetime ? ZERO : ONE;
    }
    return a.inDatetime > b.inDatetime ? ZERO : ONE;
  });
};
