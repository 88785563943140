import { SelectBoxV2, StatusV2, themeV2, useSafeCallback, useSafeState, useUnmountRef } from '@atomica.co/components';
import { BaseDto, Sort, User, toFullName } from '@atomica.co/irori';
import { USER_ID, UserId, Word } from '@atomica.co/types';
import { EMPTY, embedIdInPath, hasLength } from '@atomica.co/utils';
import SearchIcon from '@material-ui/icons/Search';
import { isAfter } from 'date-fns';
import React, { useMemo, useRef } from 'react';
import styled from 'styled-components';
import defaultUserIcon from '../../../../assets/default_user_icon.png';
import { Labels } from '../../../../models/common-model';
import useCachedUserList, { CachedUserList } from '../../../../redux/hooks/useCachedUserList';
import usePath from '../../../../redux/hooks/usePath';
import { PATH_IDS } from '../../../../router/Routes';

interface P {
  base: BaseDto;
  selectedUsers: User[];
}

const CHECKIN_SORT_LABEL: Labels = {
  [Sort.ASC]: '最終チェックイン: 昇順',
  [Sort.DESC]: '最終チェックイン: 降順'
};

const MultiUserUpdate: React.FC<P> = props => {
  const { base, selectedUsers } = props;
  const unmountRef = useUnmountRef();
  const { cachedUserList } = useCachedUserList();
  const userList = useRef<CachedUserList>(cachedUserList);

  const { path, openPath, params } = usePath();
  const selectedUserId = useMemo<UserId>(() => params[USER_ID], [params]);
  const [searchingWord, setSearchingWord] = useSafeState<Word>(unmountRef, EMPTY);
  const [sortOfLastCheckIn, setSortOfLastCheckIn] = useSafeState<Sort>(unmountRef, Sort.ASC);

  const handleClickUser = useSafeCallback(
    (userId: UserId) => {
      openPath(embedIdInPath(path, PATH_IDS, [base.baseCode, userId]));
    },
    [base, openPath]
  );

  const filteredDisplayUsers = useMemo<User[]>(() => {
    return selectedUsers
      .filter((user: User) => {
        const fullName = toFullName(user);
        return fullName.includes(searchingWord) || searchingWord === EMPTY;
      })
      .sort((a: User, b: User) => {
        const asc = isAfter(b.lastCheckInDate!, a.lastCheckInDate!) ? -1 : 1;
        return sortOfLastCheckIn === Sort.DESC ? -asc : asc;
      });
  }, [selectedUsers, searchingWord, sortOfLastCheckIn]);

  return (
    <>
      <Sidebar>
        <Heading>ユーザー情報の一括更新 </Heading>
        <Subheading>{userList.current.selectedUserIds.length}名選択中</Subheading>
        <SearchRow>
          <Search>
            <SearchIcon color='inherit' />
          </Search>
          <Input
            placeholder='テキスト検索'
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => setSearchingWord(e.target.value)}
          />
        </SearchRow>

        <SelectBoxV2
          labels={CHECKIN_SORT_LABEL}
          options={Object.keys(CHECKIN_SORT_LABEL)}
          value={sortOfLastCheckIn}
          onChange={setSortOfLastCheckIn}
        />

        {hasLength(selectedUsers) &&
          filteredDisplayUsers.map((user, index) => (
            <FlexHolder
              onClick={() => handleClickUser(user.userId)}
              key={index}
              active={selectedUserId === user.userId ? true : false}
            >
              <SmallImage src={user.photoURL || defaultUserIcon} />
              <TxtHolder>
                <RowUpperText>{toFullName(user)}</RowUpperText>
                <RowLowerText>{user.companyName}</RowLowerText>
                <StatusV2 label='未更新' variant='normal' status='default' width={80} />
              </TxtHolder>
            </FlexHolder>
          ))}
      </Sidebar>
    </>
  );
};

export default MultiUserUpdate;

const Sidebar = styled.div`
  padding: 32px 24px;
  background-color: ${themeV2.mixins.v2.color.font.white};
  border-right: 1px solid ${themeV2.mixins.v2.color.font.lightGray};
  width: 330px;
  height: 100%;
  overflow-y: auto;
`;

const Heading = styled.div`
  ${themeV2.mixins.v2.typography.title.xLarge};
  color: ${themeV2.mixins.v2.color.font.black};
  font-size: 22px;
`;

const Subheading = styled.div`
  margin-top: 24px;
  color: ${themeV2.mixins.v2.color.font.black};
  font-weight: 700;
  font-size: 19px;
`;

const SearchRow = styled.div`
  display: flex;
  align-items: center;
  margin: 10px 0px;
  color: ${themeV2.mixins.v2.color.font.gray};
  position: relative;
`;

const Search = styled.div`
  position: absolute;
  left: 12px;
`;

const Input = styled.input`
  padding-top: 7.5px;
  padding-bottom: 7.5px;
  padding-left: 42px;
  border-radius: 8px;
  border: 1px solid ${themeV2.mixins.v2.color.font.gray};
  width: 100%;
  height: 36px;
  flex: 1;
`;

const SmallImage = styled.img`
  max-width: 35px;
  max-height: 35px;
  border-radius: 20px;
  overflow: hidden;
`;
const FlexHolder = styled.div<{ active: boolean }>`
  display: flex;
  margin-top: 16px;
  gap: 8px;
  padding: 14px;
  border-radius: 8px;
  cursor: pointer;
  ${({ active }) => active && `border: 1px solid #EF796B;`}
  ${({ active }) => !active && `background: #FAF9F7;`}
`;

const TxtHolder = styled.div`
  display: flex;
  flex-direction: column;
`;

const RowUpperText = styled.p`
  margin: 0px;
  padding: 1px 0px;
  font-size: 14px;
  color: ${themeV2.mixins.v2.color.font.black};
  font-weight: 700;
`;

const RowLowerText = styled.p`
  margin: 0px;
  padding: 2px 0px;
  ${themeV2.mixins.v2.typography.body.small};
  color: ${themeV2.mixins.v2.color.font.black};
`;
