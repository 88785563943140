import { ButtonV2, Component, Modal, themeV2 } from '@atomica.co/components';
import { Typography } from '@material-ui/core';
import React from 'react';
import styled from 'styled-components';
import media from 'styled-media-query';

interface P {
  isOpen: boolean;
  onClick(): void;
  onClose(): void;
}

const ConfirmationModal: React.FC<P> = React.memo(props => {
  const { isOpen, onClick, onClose } = props;

  return (
    <Component className='confirmation-modal'>
      <Modal isOpen={isOpen} onClose={onClose}>
        <Container onClick={onClose}>
          <Frame onClick={e => e.stopPropagation()}>
            <Content>
              <div>
                <Message>予約を取り消してもよろしいですか？</Message>
              </div>

              <ButtonV2Wrapper>
                <LeftButtonV2Wrapper>
                  <ButtonV2 label='取り消す' type='primary' onClick={onClick} />
                </LeftButtonV2Wrapper>

                <ButtonV2 disabled={true} label='キャンセル' type='secondary' onClick={onClose} />
              </ButtonV2Wrapper>
            </Content>
          </Frame>
        </Container>
      </Modal>
    </Component>
  );
});

ConfirmationModal.displayName = 'ConfirmationModal';
export default ConfirmationModal;

const Container = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  justify-content: center;
`;

const Frame = styled.div`
  width: 480px;
  height: auto;
  background: ${themeV2.mixins.v2.color.background.lightGray};
  border-radius: 12px;
  padding: ${themeV2.mixins.v2.spacing * 3}px ${themeV2.mixins.v2.spacing * 4}px;
  display: flex;
  flex-direction: column;

  ${media.lessThan('small')`
    width: 95vw;
    max-height: 80vh;
    overflow-y: auto;

    @supports (height: 1dvh) {
      max-height: 80dvh;
    }
  `}
`;
const Content = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: ${themeV2.mixins.v2.spacing * 2}px;
`;
const Message = styled(Typography)`
  ${themeV2.mixins.v2.typography.body.large};
  text-align: center;
`;

const ButtonV2Wrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;

const LeftButtonV2Wrapper = styled.div`
  margin-right: ${themeV2.mixins.v2.spacing}px;
`;
