import {
  CheckBoxV2,
  InputWithLabelV2,
  LabelV2,
  RadioBoxV2,
  TextFieldV2,
  themeV2,
  useSafeCallback
} from '@atomica.co/components';
import { ContractV2, PaymentMethod, PaymentOption } from '@atomica.co/irori';
import { Address, Count, Post } from '@atomica.co/types';
import { EMPTY } from '@atomica.co/utils';
import { Typography } from '@material-ui/core';
import React, { useRef } from 'react';
import styled from 'styled-components';
import { PAYMENT_COUNT_LABELS, PAYMENT_METHOD_LABELS } from '../../../texts/contract-v2-text';

export interface PaymentInputValues {
  billingPost: Post;
  billingAddress: Address;
  paymentMethod: PaymentMethod;
  isSameBillingAddressAndContractorAddress: boolean;
  paymentOption: PaymentOption;
  paymentCount: Count;
  bankAccount: string;
}

export interface PaymentInputOnChanges {
  setPaymentMethod: React.Dispatch<React.SetStateAction<PaymentMethod>>;
  setPaymentOption: React.Dispatch<React.SetStateAction<PaymentOption>>;
  setIsSameBillingAddressAndContractorAddress: React.Dispatch<React.SetStateAction<boolean>>;
  setPaymentCount: React.Dispatch<React.SetStateAction<Count>>;
  setBankAccount: React.Dispatch<React.SetStateAction<string>>;
  setBillingPost: React.Dispatch<React.SetStateAction<Post>>;
  setBillingAddress: React.Dispatch<React.SetStateAction<Address>>;
}

interface P {
  contract: ContractV2 | undefined;
  contractorPost: Post;
  contractorAddress: Address;
  values: PaymentInputValues;
  onChanges: PaymentInputOnChanges;
}

const PlanInput: React.FC<P> = React.memo(props => {
  const { contract, contractorPost, contractorAddress, values, onChanges } = props;
  const {
    billingPost,
    billingAddress,
    paymentMethod,
    paymentCount,
    isSameBillingAddressAndContractorAddress,
    paymentOption,
    bankAccount
  } = values;
  const {
    setPaymentMethod,
    setPaymentOption,
    setIsSameBillingAddressAndContractorAddress,
    setPaymentCount,
    setBillingPost,
    setBillingAddress,
    setBankAccount
  } = onChanges;

  const currentBillingPost = useRef<Post>(contract?.billingPost ?? EMPTY);
  const currentBillingAddress = useRef<Address>(contract?.billingAddress ?? EMPTY);

  const handleCheckboxChanged = useSafeCallback(() => {
    setIsSameBillingAddressAndContractorAddress(checked => {
      if (checked) {
        setBillingPost(currentBillingPost.current);
        setBillingAddress(currentBillingAddress.current);
      } else {
        setBillingPost(contractorPost);
        setBillingAddress(contractorAddress);
      }
      return !checked;
    });
  }, [
    contractorAddress,
    contractorAddress,
    setBillingAddress,
    setBillingPost,
    setIsSameBillingAddressAndContractorAddress
  ]);

  return (
    <PaymentContent>
      <ContentTitle>支払い方法</ContentTitle>
      <Rows>
        <Row direction='column'>
          <LabelV2 required text='支払い方法' />
          <RadioWrapper>
            <RadioBoxV2
              options={[
                PaymentMethod.CREDIT_CARD,
                PaymentMethod.BANK_TRANSFER,
                PaymentMethod.INVOICE,
                PaymentMethod.CASH
              ]}
              labels={PAYMENT_METHOD_LABELS}
              onChange={setPaymentMethod}
              value={paymentMethod}
              gap={40}
            />
          </RadioWrapper>
        </Row>

        {paymentMethod === PaymentMethod.CREDIT_CARD && (
          <Row direction='column'>
            <LabelV2 required text='支払い回数' />
            <CustomRow direction='row'>
              <RadioWrapper>
                <RadioBoxV2
                  options={[PaymentOption.SINGLE, PaymentOption.INSTALLMENT]}
                  labels={PAYMENT_COUNT_LABELS}
                  onChange={setPaymentOption}
                  value={paymentOption}
                  direction='column'
                  rightComponents={{
                    [PaymentOption.INSTALLMENT]: (
                      <TextFieldWrapper>
                        <TextFieldV2
                          inputWidth={100}
                          align='right'
                          value={paymentCount}
                          onChange={value => setPaymentCount(value)}
                          readonly={paymentOption === PaymentOption.SINGLE ? true : false}
                        />
                        回
                      </TextFieldWrapper>
                    )
                  }}
                />
              </RadioWrapper>
            </CustomRow>
          </Row>
        )}

        {paymentMethod === PaymentMethod.BANK_TRANSFER && (
          <Row direction='column'>
            <Cell>
              <InputWithLabelV2 text='口座情報' multiline value={bankAccount} onChange={setBankAccount} />
            </Cell>
          </Row>
        )}
        {paymentMethod === PaymentMethod.INVOICE && (
          <>
            <Row>
              <Cell widthRate={100}>
                <LabelV2 text='請求先住所' />
              </Cell>
            </Row>
            <Row direction='column'>
              <CheckBoxV2 checked={isSameBillingAddressAndContractorAddress} onChange={handleCheckboxChanged}>
                契約者住所と同じ
              </CheckBoxV2>
              <TextFieldV2
                placeholder='郵便番号'
                value={billingPost}
                disabled={isSameBillingAddressAndContractorAddress}
                onChange={value => {
                  setBillingPost(value);
                  currentBillingPost.current = value;
                }}
              />
              <TextFieldV2
                placeholder='住所'
                value={billingAddress}
                disabled={isSameBillingAddressAndContractorAddress}
                onChange={value => {
                  setBillingAddress(value);
                  currentBillingAddress.current = value;
                }}
              />
            </Row>
          </>
        )}
      </Rows>
    </PaymentContent>
  );
});

PlanInput.displayName = 'PlanInput';
export default PlanInput;

const Content = styled.div`
  background-color: ${themeV2.mixins.v2.color.background.white};
  border-radius: 6px;
  padding: ${`${themeV2.mixins.v2.spacing * 2}px ${themeV2.mixins.v2.spacing * 3}px`};
`;
const Rows = styled.div`
  padding: ${themeV2.mixins.v2.spacing * 3}px 0;
  display: flex;
  flex-direction: column;
  gap: ${themeV2.mixins.v2.spacing * 3}px;
  width: 100%;
`;
const Row = styled.div<{ direction?: 'row' | 'column'; gap?: number }>`
  display: flex;
  min-height: 24px;
  width: 100%;
  flex-direction: ${({ direction = 'row' }) => direction};
  gap: ${({ direction, gap = direction === 'row' ? themeV2.mixins.v2.spacing * 3 : themeV2.mixins.v2.spacing / 2 }) =>
    gap}px;
`;
const Cell = styled.div<{ widthRate?: number }>`
  width: ${({ widthRate = 100 }) => widthRate}%;
  display: flex;
`;

const CustomRow = styled(Row)`
  align-items: flex-end;
`;

const PaymentContent = styled(Content)``;

const ContentTitle = styled(Typography)`
  ${themeV2.mixins.v2.typography.title.xLarge};
  color: ${themeV2.mixins.v2.color.font.black};
  padding: ${themeV2.mixins.v2.spacing}px 0;
  width: 100%;
`;

const RadioWrapper = styled.div`
  margin-left: ${themeV2.mixins.v2.spacing * 2}px;
`;

const TextFieldWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: ${themeV2.mixins.v2.spacing * 3}px;
`;
