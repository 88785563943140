import { useSafeCallback } from '@atomica.co/components';
import {
  BaseDto,
  FETCH_BASE,
  FetchBaseRequest,
  FetchBaseResponse,
  UPDATE_USER_LINE_ID,
  UpdateUserLineIdRequest,
  UpdateUserLineIdResponse,
  User
} from '@atomica.co/irori';
import { Id } from '@atomica.co/types';
import { LIFF_STATE, REDIRECT_PATH, builder, getQueryParams } from '@atomica.co/utils';
import { Liff } from '@line/liff';
import React, { useEffect } from 'react';
import { RouteComponentProps } from 'react-router';
import Screen from '../../components/screen/Screen';
import { initLiff } from '../../line';
import { toLiffId } from '../../line/config';
import { QueryParams } from '../../models/path-model';
import useCommonRequest from '../../redux/hooks/useCommonRequest';
import usePath from '../../redux/hooks/usePath';
import { Path } from '../../router/Routes';

interface P extends RouteComponentProps {
  user: User | undefined;
}

const LiffScreen: React.FC<P> = React.memo(() => {
  const { queryParams, openPath } = usePath();
  const { commonRequest } = useCommonRequest();

  const getLiffLocalStorageKeys = useSafeCallback((prefix): string[] => {
    const keys: string[] = [];
    for (let i = 0; i < localStorage.length; i++) {
      const key = localStorage.key(i);
      if (key && key.startsWith(prefix)) {
        keys.push(key);
      }
    }
    return keys;
  }, []);

  const clearExpiredIdToken = useSafeCallback(
    (liffId: Id): void => {
      const keyPrefix = `LIFF_STORE:${liffId}:`;
      const key = keyPrefix + 'decodedIDToken';
      const decodedIDTokenString = localStorage.getItem(key);
      if (!decodedIDTokenString) {
        return;
      }
      const decodedIDToken = JSON.parse(decodedIDTokenString);

      if (decodedIDToken && new Date().getTime() > decodedIDToken.exp * 1000) {
        const keys = getLiffLocalStorageKeys(keyPrefix);
        keys.forEach(key => localStorage.removeItem(key));
      }
    },
    [getLiffLocalStorageKeys]
  );

  const getRedirectPath = useSafeCallback((): Path => {
    const liffState = queryParams[LIFF_STATE];
    const redirectPath = liffState ? getQueryParams<QueryParams>(liffState)[REDIRECT_PATH] : queryParams[REDIRECT_PATH];
    return redirectPath;
  }, [queryParams]);

  const fetchBase = useSafeCallback(async (): Promise<BaseDto | undefined> => {
    const redirectPath = getRedirectPath();
    const baseMatch = redirectPath.match(/base\/([^/]+)/);
    if (!baseMatch) return;

    const baseCode = baseMatch[1];
    const request = builder<FetchBaseRequest>().baseCode(baseCode).build();
    const response = await commonRequest<FetchBaseRequest, FetchBaseResponse>(FETCH_BASE, request);
    return response.base;
  }, [getRedirectPath]);

  const updateUserLineId = useSafeCallback(
    async (base?: BaseDto, liff?: Liff): Promise<void> => {
      if (!base || !liff) return;

      const idToken = liff.getIDToken();
      if (!idToken) return;

      const request = builder<UpdateUserLineIdRequest>().baseCode(base.baseCode).idToken(idToken).build();
      await commonRequest<UpdateUserLineIdRequest, UpdateUserLineIdResponse>(UPDATE_USER_LINE_ID, request);
    },
    [commonRequest]
  );

  const initialize = useSafeCallback(async (): Promise<void> => {
    const base = await fetchBase();
    const liffId = toLiffId(base?.lineLiffId);

    clearExpiredIdToken(liffId);
    const liff = await initLiff(liffId);
    await updateUserLineId(base, liff);
    openPath(getRedirectPath());
  }, [fetchBase, clearExpiredIdToken, updateUserLineId, openPath, getRedirectPath]);

  useEffect((): void => {
    initialize();
  }, [initialize]);

  return <Screen loading={true} className='liff-screen' />;
});

LiffScreen.displayName = 'LiftScreen';
export default LiffScreen;
