import { EditorTabEnum, Hour, NumberOfPeople } from '../enums/common-enum';
import { Labels } from '../models/common-model';

export const NO_DATA_TEXT = 'データがありません';

export const NO_SEARCH_RESULTS_TEXT = '検索結果が見つかりません';

export const CONFERENCE_USAGE_HOURS_LABEL = '利用時間';

export const HOURS_LABELS: Labels = {
  [Hour.ONE]: '１時間',
  [Hour.ONE_AND_A_HALF]: '１時間半',
  [Hour.TWO]: '２時間',
  [Hour.TWO_AND_A_HALF]: '２時間半',
  [Hour.THREE]: '３時間',
  [Hour.THREE_AND_A_HALF]: '３時間半',
  [Hour.FOUR]: '４時間',
  [Hour.FOUR_AND_A_HALF]: '４時間半',
  [Hour.FIVE]: '５時間',
  [Hour.FIVE_AND_A_HALF]: '５時間半',
  [Hour.SIX]: '６時間',
  [Hour.SIX_AND_A_HALF]: '６時間半',
  [Hour.SEVEN]: '７時間',
  [Hour.SEVEN_AND_A_HALF]: '７時間半',
  [Hour.EIGHT]: '８時間',
  [Hour.EIGHT_AND_A_HALF]: '８時間半',
  [Hour.NINE]: '９時間',
  [Hour.NINE_AND_A_HALF]: '９時間半',
  [Hour.TEN]: '１０時間',
  [Hour.TEN_AND_A_HALF]: '１０時間半',
  [Hour.ELEVEN]: '１１時間',
  [Hour.ELEVEN_AND_A_HALF]: '１１時間半',
  [Hour.TWELVE]: '１２時間'
};

export const NUMBER_OF_PEOPLE_LABELS: Labels = {
  [NumberOfPeople.ONE]: '１人',
  [NumberOfPeople.TWO]: '２人',
  [NumberOfPeople.THREE]: '３人',
  [NumberOfPeople.FOUR]: '４人',
  [NumberOfPeople.FIVE]: '５人',
  [NumberOfPeople.SIX]: '６人',
  [NumberOfPeople.SEVEN]: '７人',
  [NumberOfPeople.EIGHT]: '８人',
  [NumberOfPeople.NINE]: '９人',
  [NumberOfPeople.TEN]: '１０人',
  [NumberOfPeople.ELEVEN]: '１１人',
  [NumberOfPeople.TWELVE]: '１２人',
  [NumberOfPeople.THIRTEEN]: '１３人',
  [NumberOfPeople.FOURTEEN]: '１４人',
  [NumberOfPeople.FIFTEEN]: '１５人',
  [NumberOfPeople.SIXTEEN]: '１６人',
  [NumberOfPeople.SEVENTEEN]: '１７人',
  [NumberOfPeople.EIGHTEEN]: '１８人',
  [NumberOfPeople.NINETEEN]: '１９人',
  [NumberOfPeople.TWENTY]: '２０人',
  [NumberOfPeople.TWENTY_ONE]: '２１人',
  [NumberOfPeople.TWENTY_TWO]: '２２人',
  [NumberOfPeople.TWENTY_THREE]: '２３人',
  [NumberOfPeople.TWENTY_FOUR]: '２４人',
  [NumberOfPeople.TWENTY_FIVE]: '２５人',
  [NumberOfPeople.TWENTY_SIX]: '２６人',
  [NumberOfPeople.TWENTY_SEVEN]: '２７人',
  [NumberOfPeople.TWENTY_EIGHT]: '２８人',
  [NumberOfPeople.TWENTY_NINE]: '２９人',
  [NumberOfPeople.THIRTY]: '３０人'
};

export const EDITER_TYPE_LABELS: Labels = {
  [EditorTabEnum.EDIT]: '編集',
  [EditorTabEnum.PREVIEW]: 'プレビュー'
};
