import { TaxDiv, TaxId, TaxV2 } from '@atomica.co/irori';
import { Code, Id, Key, Label, Option, Rate } from '@atomica.co/types';
import { ZERO, builder, isZero } from '@atomica.co/utils';
import { Labels } from '../models/common-model';
import { TAX_DIV_LABELS } from '../texts/tax-text';
import { getTaxKey } from '../utils/tax-util';

export interface DisplayTax {
  label: Label;
  taxCode: Code;
  taxDiv: TaxDiv;
  taxId: TaxId;
  taxRate: Rate;
}

export interface DisplayTaxes {
  [key: Key]: DisplayTax;
}

export type ObjectForTaxLabel = {
  taxId: TaxId;
  taxDiv: TaxDiv;
  taxRate: Rate;
};

export type TaxMapping = Record<Id, ObjectForTaxLabel>;

export const toTaxMapping = (taxes: TaxV2[]): TaxMapping => {
  return taxes.reduce((labels: TaxMapping, tax: TaxV2) => {
    if (isZero(tax.taxRate)) {
      labels[getTaxKey(tax.taxId, TaxDiv.EXEMPT, tax.taxRate)] = builder<ObjectForTaxLabel>()
        .taxId(tax.taxId)
        .taxDiv(TaxDiv.EXEMPT)
        .taxRate(0)
        .build();
      return labels;
    }
    labels[getTaxKey(tax.taxId, TaxDiv.INCLUDED, tax.taxRate)] = builder<ObjectForTaxLabel>()
      .taxId(tax.taxId)
      .taxDiv(TaxDiv.INCLUDED)
      .taxRate(tax.taxRate)
      .build();
    labels[getTaxKey(tax.taxId, TaxDiv.EXCLUDED, tax.taxRate)] = builder<ObjectForTaxLabel>()
      .taxId(tax.taxId)
      .taxDiv(TaxDiv.EXCLUDED)
      .taxRate(tax.taxRate)
      .build();
    return labels;
  }, {});
};

export const toTaxLabel = (obj: ObjectForTaxLabel): Label => {
  const taxDivLabel = TAX_DIV_LABELS[obj.taxDiv];
  if (isZero(obj.taxRate)) return taxDivLabel;
  return `${taxDivLabel} ${obj.taxRate}%`;
};

export const toDisplayTaxes = (taxes: TaxV2[]): DisplayTaxes => {
  return taxes.reduce((displayTaxes: DisplayTaxes, tax: TaxV2) => {
    if (tax.taxRate === ZERO) {
      displayTaxes[getTaxKey(tax.taxId, TaxDiv.EXEMPT, tax.taxRate)] = {
        label: TAX_DIV_LABELS[TaxDiv.EXEMPT],
        taxCode: tax.taxCode,
        taxDiv: TaxDiv.EXEMPT,
        taxId: tax.taxId,
        taxRate: tax.taxRate
      };
      return displayTaxes;
    }
    displayTaxes[getTaxKey(tax.taxId, TaxDiv.INCLUDED, tax.taxRate)] = {
      label: `${TAX_DIV_LABELS[TaxDiv.INCLUDED]} ${tax.taxRate}%`,
      taxCode: tax.taxCode,
      taxDiv: TaxDiv.INCLUDED,
      taxId: tax.taxId,
      taxRate: tax.taxRate
    };
    displayTaxes[getTaxKey(tax.taxId, TaxDiv.EXCLUDED, tax.taxRate)] = {
      label: `${TAX_DIV_LABELS[TaxDiv.EXCLUDED]} ${tax.taxRate}%`,
      taxCode: tax.taxCode,
      taxDiv: TaxDiv.EXCLUDED,
      taxId: tax.taxId,
      taxRate: tax.taxRate
    };
    return displayTaxes;
  }, {});
};

export const toTaxOptions = (taxes: DisplayTaxes | undefined): Option[] => {
  if (!taxes) return [];
  return Object.keys(taxes).map(key => key);
};

export const toTaxLabels = (taxes: DisplayTaxes | undefined): Labels => {
  if (!taxes) return {};
  const labels: Labels = {};
  for (const key in taxes) {
    labels[key] = taxes[key].label;
  }
  return labels;
};
