import React, { memo, useEffect, useMemo } from 'react';
import {
  DialogV2,
  ButtonV2,
  TableV3,
  useSafeState,
  useUnmountRef,
  useSafeCallback,
  Header,
  Row,
  SearchField,
  Pagenate,
  themeV2
} from '@atomica.co/components';
import {
  BaseId,
  ContractSortColumn,
  ContractV2,
  ContractorType,
  SEARCH_CONTRACTS_V2_FOR_ADMIN,
  SearchContractsV2ForAdminRequest,
  SearchContractsV2ForAdminResponse,
  Sort
} from '@atomica.co/irori';
import { EMPTY, builder, isGreaterThanZero } from '@atomica.co/utils';
import useCommonRequest from '../../redux/hooks/useCommonRequest';
import { format } from 'date-fns';
import styled from 'styled-components';
import { Count, Index, Offset, Word } from '@atomica.co/types';

interface P {
  baseId: BaseId;
  isOpen: boolean;
  onClose: () => void;
  onRowClick?: (contract: ContractV2) => void;
}

const LIMIT = 10;

const SearchContractModal = memo<P>(props => {
  const { baseId, isOpen, onClose, onRowClick } = props;
  const { commonRequest } = useCommonRequest();
  const unmountRef = useUnmountRef();
  const [loading, setLoading] = useSafeState<boolean>(unmountRef, false);
  const [contracts, setContracts] = useSafeState<ContractV2[]>(unmountRef, []);
  const [word, setWord] = useSafeState<Word>(unmountRef, EMPTY);
  const [count, setCount] = useSafeState<Count>(unmountRef, 0);
  const [offset, setOffset] = useSafeState<Offset>(unmountRef, 0);
  const [rows, setRows] = useSafeState<Row[]>(unmountRef, []);

  const convertContractsToRows = useSafeCallback((contracts: ContractV2[]): Row[] => {
    return contracts.map(
      ({
        contractId: id,
        contractNo,
        contractorType,
        entityName,
        contractorName,
        startDate,
        endDate,
        contractPlan
      }) => ({
        id,
        contractNo,
        contractor: contractorType === ContractorType.ENTITY ? entityName : contractorName,
        contractTerm: `${format(startDate, 'yyyy/MM/dd')} ～ 
        ${endDate ? format(new Date(endDate), 'yyyy/MM/dd') : '未定'}`,
        contractPlan: contractPlan?.contractPlanName ?? EMPTY
      })
    );
  }, []);

  const searchContracts = useSafeCallback(
    async ({ offset = 0, word = EMPTY }: { offset?: Offset; word?: Word }): Promise<void> => {
      setRows([]);
      setLoading(true);
      const request = builder<SearchContractsV2ForAdminRequest>()
        .baseId(baseId)
        .word(word)
        .limit(LIMIT)
        .offset(offset)
        .sortCol(ContractSortColumn.CONTRACT_NO)
        .sort(Sort.ASC)
        .build();
      const response = await commonRequest<SearchContractsV2ForAdminRequest, SearchContractsV2ForAdminResponse>(
        SEARCH_CONTRACTS_V2_FOR_ADMIN,
        request
      );
      setContracts(response.contracts);
      setCount(response.count);
      setOffset(offset);
      setRows(convertContractsToRows(response.contracts));
      setLoading(false);
    },
    [setLoading, baseId, commonRequest, setContracts, setCount, setRows, convertContractsToRows, setOffset]
  );

  const header = useMemo<Header>(
    () => ({
      contractNo: { label: '契約番号' },
      contractPlan: { label: '契約プラン' },
      contractor: { label: '契約者' },
      contractTerm: { label: '契約期間' }
    }),
    []
  );
  const handleClickRow = useSafeCallback(
    (index: Index): void => {
      const target = contracts.at(index);
      target && onRowClick?.(target);
      onClose();
    },
    [contracts, onRowClick, onClose]
  );
  const handleClose = useSafeCallback((): void => {
    setWord(EMPTY);
    onClose();
  }, [onClose, setWord]);

  useEffect(() => {
    if (!isOpen) return;
    searchContracts({ word });
  }, [isOpen, searchContracts, word]);

  return (
    <DialogV2
      width={800}
      height={600}
      isOpen={isOpen}
      headerLabel={'契約の選択'}
      onClose={onClose}
      buttonsOnTheRight={[<ButtonV2 key='dialog_close' label='閉じる' onClick={handleClose} />]}
    >
      <Content>
        <SearchWrapper>
          <SearchField disableUnderline placeholder='契約番号・契約者' value={word} onChange={setWord} />
        </SearchWrapper>
        <Body>
          <TableWrapper loading={loading}>
            <TableV3 header={header} rows={rows} onClickRow={handleClickRow} isLoaderShown={loading} />
          </TableWrapper>
        </Body>
        {!loading && isGreaterThanZero(count) && (
          <Pagenate
            offset={offset}
            limit={LIMIT}
            count={count}
            onClickBack={() => {
              searchContracts({ offset: offset - 1, word });
            }}
            onClickForward={() => {
              searchContracts({ offset: offset + 1, word });
            }}
          />
        )}
      </Content>
    </DialogV2>
  );
});
SearchContractModal.displayName = 'SearchContractModal';
export default SearchContractModal;
const Content = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
`;
const SearchWrapper = styled.div`
  padding: ${themeV2.mixins.v2.spacing / 2}px ${themeV2.mixins.v2.spacing * 2.5}px;
  border-bottom: 1px solid ${themeV2.mixins.v2.color.border.gray};
`;
const Body = styled.div`
  flex: 1;
  overflow-y: auto;
`;
const TableWrapper = styled.div<{ loading: boolean }>`
  height: ${({ loading }) => (loading ? '100%' : 'max-content')};
`;
