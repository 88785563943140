import { Component, LabelV2, PageHeaderV2, themeV3, useSafeState, useUnmountRef } from '@atomica.co/components';
import { BaseDto } from '@atomica.co/irori';
import { format } from 'date-fns';
import { ja } from 'date-fns/locale';
import React, { useEffect } from 'react';
import styled from 'styled-components';
import usePath from '../../redux/hooks/usePath';
import { Path } from '../../router/Routes';
import { DropInItemForHistoryState } from './DropInEntryScreen';

interface P {
  base: BaseDto;
}

const DropInConfirmScreen: React.FC<P> = React.memo(props => {
  const { base } = props;
  base;

  const { getHistoryState, openBasePath } = usePath();

  const unmountRef = useUnmountRef();
  const [dropInItem, setDropInItem] = useSafeState<DropInItemForHistoryState>(unmountRef);

  useEffect(() => {
    const dropInItem = getHistoryState<DropInItemForHistoryState>();
    if (!dropInItem) {
      return openBasePath(Path.DROP_IN);
    }
    setDropInItem(dropInItem);
  }, [getHistoryState, openBasePath, setDropInItem]);

  return (
    <Component className='drop-in-confirm-screen' loading={!dropInItem}>
      <Container data-testid='drop-in-confirm-screen'>
        <PageHeaderV2 title='ご利用内容の確認' />
        <Content data-testid='summary'>
          <Row>
            <LabelV2 text='利用スペース' />
            <Card>
              <BodyLarge>{dropInItem?.spaceName}</BodyLarge>
              <TextWrapper>
                <BodyMedium>{dropInItem?.planName}</BodyMedium>
                <BodyMedium>¥{dropInItem?.unitPrice}/時間</BodyMedium>
              </TextWrapper>
            </Card>
          </Row>
          <Row>
            <LabelV2 text='利用日時' />
            <TextWrapper>
              <BodyLarge>{format(new Date(), 'MM/dd(E) HH:mm～', { locale: ja })}</BodyLarge>
              <BodyMedium>※正確な時間は決済後にホーム画面でご確認ください。</BodyMedium>
            </TextWrapper>
          </Row>
          <Row>
            <LabelV2 text='支払い方法' />
            <BodyLarge>クレジットカード</BodyLarge>
          </Row>
        </Content>
        <Content data-testid='price'>
          <Title>料金</Title>
          <Row>
            <TextWrapper>
              <BodyLarge>利用料金</BodyLarge>
              <BodyMedium>{dropInItem?.spaceName}</BodyMedium>
            </TextWrapper>
            <PriceWrapper>
              <BodyLarge>{dropInItem?.planName}</BodyLarge>
              <BodyLarge>¥{dropInItem?.unitPrice?.toLocaleString()}</BodyLarge>
            </PriceWrapper>
          </Row>
          <Line />
          <Row>
            <PriceWrapper>
              <BodyLarge>小計</BodyLarge>
              <BodyLarge>¥{dropInItem?.unitPrice?.toLocaleString()}</BodyLarge>
            </PriceWrapper>
            <PriceWrapper>
              <BodyMedium>うち消費税（{dropInItem?.taxRate}%）</BodyMedium>
              <BodyMedium>
                ¥{((dropInItem?.unitPrice ?? 0) * ((dropInItem?.taxRate ?? 0) / 100)).toLocaleString()}
              </BodyMedium>
            </PriceWrapper>
          </Row>
          <Card>
            <PriceWrapper>
              <BodyLargeBold>合計</BodyLargeBold>
              <BodyLargeBold>¥{dropInItem?.unitPrice?.toLocaleString()}</BodyLargeBold>
            </PriceWrapper>
          </Card>
        </Content>
      </Container>
    </Component>
  );
});

DropInConfirmScreen.displayName = 'DropInConfirmScreen';
export default DropInConfirmScreen;

const Container = styled.div`
  max-width: 640px;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: ${themeV3.mixins.v3.spacing * 2}px;
  padding: ${themeV3.mixins.v3.spacing * 2}px;
  margin-inline: auto;
`;

const Content = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: ${themeV3.mixins.v3.spacing * 2}px;
  padding: ${themeV3.mixins.v3.spacing * 2}px;
  background: ${themeV3.mixins.v3.color.object.white};
  border-radius: 12px;
`;

const Row = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${themeV3.mixins.v3.spacing}px;
`;

const Card = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${themeV3.mixins.v3.spacing / 2}px;
  padding: ${themeV3.mixins.v3.spacing * 2}px;
  background: ${themeV3.mixins.v3.color.container.neutral.row};
  border-radius: 8px;
`;

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const BodyLarge = styled.span`
  ${themeV3.mixins.v3.typography.body.large};
  color: ${themeV3.mixins.v3.color.object.black};
`;

const BodyMedium = styled.span`
  ${themeV3.mixins.v3.typography.body.medium};
  color: ${themeV3.mixins.v3.color.object.gray};
`;

const Title = styled.span`
  ${themeV3.mixins.v3.typography.title.large};
  color: ${themeV3.mixins.v3.color.object.black};
`;

const PriceWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const Line = styled.hr`
  width: 100%;
  color: ${themeV3.mixins.v3.color.border.gray};
  margin: 0;
`;

const BodyLargeBold = styled.span`
  ${themeV3.mixins.v3.typography.body.largeBold};
  color: ${themeV3.mixins.v3.color.object.black};
`;
