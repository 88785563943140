import { RecurrenceTypeEnum } from '@atomica.co/irori';
import { DateStr, Label, Milliseconds, Time, TimeZone } from '@atomica.co/types';
import {
  COLON,
  Language,
  MILLI_SECONDS_OF_ONE_SECOND,
  ONE,
  SECONDS_OF_ONE_HOUR,
  SECONDS_OF_ONE_MINUTE,
  TWO,
  paddingZero,
  toZonedTimeFromUtc
} from '@atomica.co/utils';
import { format, parse, set } from 'date-fns';
import { format as formatTz } from 'date-fns-tz';

export const toTimeStr = (msec: Milliseconds, lang?: Language): string => {
  const hours = Math.floor(msec / (SECONDS_OF_ONE_HOUR * MILLI_SECONDS_OF_ONE_SECOND));
  const minutes = Math.round(
    (msec % (SECONDS_OF_ONE_HOUR * MILLI_SECONDS_OF_ONE_SECOND)) / (SECONDS_OF_ONE_MINUTE * MILLI_SECONDS_OF_ONE_SECOND)
  );

  if (!lang) {
    return `${hours < 10 ? paddingZero(hours, ONE) : hours}:${minutes < 10 ? paddingZero(minutes, ONE) : minutes}`;
  }

  switch (lang) {
    case Language.JAPANESE:
      return (
        `${hours < 10 ? paddingZero(hours, ONE) : hours}時間` +
        `${minutes < 10 ? paddingZero(minutes, ONE) : minutes}分`
      );

    default:
      throw new Error(`${lang} is out of target.`);
  }
};

const regex = new RegExp('^([01][0-9]|2[0-3]):[0-5][0-9](:[0-5][0-9])?$');

export const toZonedDate = (time: Time, timezone: TimeZone): Date => {
  return toZonedTimeFromUtc(`${formatTz(new Date(), 'yyyy-MM-dd')}T${time}Z`, timezone);
};

export const toZonedTimeStr = (time: Time, timezone: TimeZone, timeFormat = 'HH:mm:ss'): Time => {
  if (!time || !timezone || !regex.test(time)) return time;

  return formatTz(toZonedDate(time, timezone), timeFormat);
};

export const toUtcTimeStr = (time: Time): Time => {
  if (!time || !regex.test(time)) return time;

  const [hours, minutes] = time.split(COLON).map(t => parseInt(t));
  const date: Date = set(new Date(), { hours, minutes });
  const utcHours = paddingZero(date.getUTCHours(), TWO).slice(-TWO);
  const utcMinutes = paddingZero(date.getUTCMinutes(), TWO).slice(-TWO);

  return [utcHours, utcMinutes].join(COLON);
};

export const toRecurrenceLabel = (date: Date, recurrenceType: RecurrenceTypeEnum, locale: Locale): Label => {
  switch (recurrenceType) {
    case RecurrenceTypeEnum.EVERY_WEEK: {
      const week = formatTz(date, 'EEEE', { locale });
      return `毎週${week}`;
    }
    default:
      return '繰り返しなし';
  }
};

export const isValidDateString = (str: DateStr, dateFormat: string): boolean => {
  try {
    const formatString = format(parse(str, dateFormat, new Date()), dateFormat);
    return formatString === str;
  } catch {
    return false;
  }
};

export const toDateWithNewTime = (date: Date, time: Time | undefined): Date => {
  // NOTE: 24:00を00:00として扱う特別な処理
  const normalizedTime = time === '24:00' ? '00:00' : time;

  try {
    const currentDate = new Date(date);

    if (!normalizedTime) {
      return currentDate;
    }

    const [hours, minutes] = normalizedTime.split(':').map(Number);

    // HACK: 多分杞憂: 時間と分のバリデーション
    if (isNaN(hours) || isNaN(minutes) || hours < 0 || minutes < 0 || minutes >= 60) {
      throw new Error('Invalid time format');
    }

    // NOTE: 24:00の場合は次の日の00:00として処理
    if (time === '24:00') {
      currentDate.setDate(currentDate.getDate() + 1);
    }

    currentDate.setHours(hours);
    currentDate.setMinutes(minutes);

    if (isNaN(currentDate.getTime())) {
      throw new Error('Invalid date or time');
    }
    return currentDate;
  } catch (error) {
    console.error('Date parsing failed:', error);
    throw error;
  }
};
