import { Component, Pagenate, themeV2, useSafeCallback, useSafeState, useUnmountRef } from '@atomica.co/components';
import {
  BaseDto,
  FETCH_USER_FOR_ADMIN,
  FetchUserForAdminRequest,
  FetchUserForAdminResponse,
  User
} from '@atomica.co/irori';
import { Index, USER_ID, UserId } from '@atomica.co/types';
import { NOT_FOUND_INDEX, ONE, builder, embedIdInPath, hasLength } from '@atomica.co/utils';
import { CSSProperties } from '@material-ui/core/styles/withStyles';
import CloseIcon from '@material-ui/icons/Close';
import React, { useEffect, useMemo, useRef } from 'react';
import styled from 'styled-components';
import { CONSOLE_FOOTER_HEIGHT, HEADER_HEIGHT } from '../../constants/common-const';
import useCachedUserList, { CachedUserList } from '../../redux/hooks/useCachedUserList';
import useCommonRequest from '../../redux/hooks/useCommonRequest';
import usePath from '../../redux/hooks/usePath';
import { PATH_IDS, Path } from '../../router/Routes';
import MultiUserUpdate from './user-overview/section/MultiUserUpdate';
import UserOverviewV2 from './user-overview/UserOverviewV2';

const LIMIT = 1;

interface P {
  base: BaseDto;
  user: User;
}

const UserV2BulkUpdateScreen: React.FC<P> = props => {
  const { base, user } = props;
  const unmountRef = useUnmountRef();
  const { params, path, openPath } = usePath();
  const { commonRequest } = useCommonRequest();

  const [signInUser, setSignInUser] = useSafeState<User>(unmountRef);
  const [selectedUser, setSelectedUser] = useSafeState<User>(unmountRef);

  const selectedUserId = useMemo<UserId>(() => params[USER_ID], [params]);
  const { cachedUserList, clearCachedUserList } = useCachedUserList();
  const userList = useRef<CachedUserList>(cachedUserList);
  const [displayUserIdx, setDisplayUserIdx] = useSafeState<Index>(
    unmountRef,
    userList.current.selectedUserIds.indexOf(selectedUserId)
  );
  const [isLoaderShown, setIsLoaderShown] = useSafeState<boolean>(unmountRef, false);

  const displayUsers = useMemo<User[]>(() => {
    if (!hasLength(cachedUserList.selectedUserIds) && selectedUser) {
      return [selectedUser];
    }
    return cachedUserList.users;
  }, [cachedUserList, selectedUser]);

  const initialize = useSafeCallback(async (): Promise<void> => {
    setIsLoaderShown(true);
    const request = builder<FetchUserForAdminRequest>().baseId(base.baseId).userId(selectedUserId).build();
    const response = await commonRequest<FetchUserForAdminRequest, FetchUserForAdminResponse>(
      FETCH_USER_FOR_ADMIN,
      request
    );
    if (user) setSignInUser(user);
    if (response.user) setSelectedUser(response.user);
    setIsLoaderShown(false);
  }, [base, commonRequest, selectedUserId, setIsLoaderShown, setSelectedUser, setSignInUser, user]);

  useEffect(() => {
    initialize();
  }, [initialize]);

  useEffect(() => {
    return () => {
      clearCachedUserList();
    };
  }, [clearCachedUserList]);

  useEffect(() => {
    const index = userList.current.selectedUserIds.indexOf(selectedUserId);
    if (index >= 0) {
      setDisplayUserIdx(index);
      return;
    }
    setDisplayUserIdx(selectedUser ? 0 : NOT_FOUND_INDEX);
  }, [selectedUser, selectedUserId, setDisplayUserIdx]);

  const openUserDetailsScreen = useSafeCallback(
    (selectedId?: UserId): void => {
      const userIdToOpen = selectedId ? selectedId : selectedUserId;
      openPath(embedIdInPath(path, PATH_IDS, [base.baseCode, userIdToOpen]));
    },
    [openPath, base, path, selectedUserId]
  );

  const toNextPage = useSafeCallback((): void => {
    setDisplayUserIdx(displayIdx => {
      const nextDisplayIdx = displayIdx + LIMIT;
      const selectedUserId = userList.current.selectedUserIds[nextDisplayIdx];
      openUserDetailsScreen(selectedUserId);
      return nextDisplayIdx;
    });
  }, [setDisplayUserIdx, openUserDetailsScreen]);

  const toPreviousPage = useSafeCallback((): void => {
    setDisplayUserIdx(displayIdx => {
      const nextDisplayIdx = displayIdx - LIMIT;
      const selectedUserId = userList.current.selectedUserIds[nextDisplayIdx];
      openUserDetailsScreen(selectedUserId);
      return nextDisplayIdx;
    });
  }, [setDisplayUserIdx, openUserDetailsScreen]);

  const BackButton = useSafeCallback((): void => {
    openPath(embedIdInPath(Path.USER_SEARCH_V2, PATH_IDS, [base.baseCode]));
  }, [base, openPath]);

  return (
    <Component className='user-v2-bulk-update-screen' loading={false} style={styleForComponent}>
      <BackComponent>
        <BackText onClick={BackButton}>
          <CloseIcon style={{ fontSize: '18px', color: '#666666' }} /> 一括更新モードを終了
        </BackText>
      </BackComponent>
      <Container>
        <MultiUserUpdate base={base} selectedUsers={displayUsers} />
        <UserOverviewWrapper className='new-hed'>
          <UserOverviewV2
            base={base}
            selectedUser={selectedUser}
            signInUser={signInUser}
            isLoaderShown={isLoaderShown}
          />
        </UserOverviewWrapper>
        <Footer>
          <Pagenate
            shape='rect'
            limit={LIMIT}
            offset={displayUserIdx}
            count={displayUsers.length}
            clickable={displayUsers.length > ONE}
            onClickBack={toPreviousPage}
            onClickForward={toNextPage}
          />
        </Footer>
      </Container>
    </Component>
  );
};

export default UserV2BulkUpdateScreen;

const styleForComponent: CSSProperties = {
  height: `calc(100dvh - ${HEADER_HEIGHT}px)`
};

const Container = styled.div`
  width: 100%;
  display: flex;
  height: calc(100vh - ${HEADER_HEIGHT + CONSOLE_FOOTER_HEIGHT + 32}px);
  overflow-y: auto;

  @supports (height: 1dvh) {
    height: calc(100dvh - ${HEADER_HEIGHT + CONSOLE_FOOTER_HEIGHT + 32}px);
  }
`;

const UserOverviewWrapper = styled.div`
  flex: 1;
  padding: ${themeV2.mixins.v2.spacing * 3}px;
  height: 100%;
  overflow-y: auto;
  ::-webkit-scrollbar {
    display: none;
  }
`;

const Footer = styled.div`
  position: fixed;
  right: 0;
  transition: ${themeV2.transitions.create(['width', 'right'], {
    easing: themeV2.transitions.easing.easeOut,
    duration: themeV2.transitions.duration.enteringScreen
  })};
  width: 100%;
  bottom: 0;
`;

const BackComponent = styled.div`
  width: 100%;
  height: 32px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 0 ${themeV2.mixins.v2.spacing * 2}px;
  background: ${themeV2.mixins.v2.color.background.offWhite};
  border-bottom: 1px solid ${themeV2.mixins.v2.color.border.gray};
`;

const BackText = styled.div`
  ${themeV2.mixins.v2.typography.label.medium};
  display: flex;
  align-items: center;
  gap: ${themeV2.mixins.v2.spacing / 2}px;
  color: ${themeV2.mixins.v2.color.font.gray};
  cursor: pointer;
`;
