import {
  ButtonV2,
  DialogV2,
  LabelV2,
  MasterSearchOption,
  MasterSearchV2,
  ScreenLoaderV2,
  themeV2,
  useSafeCallback,
  useSafeState,
  useUnmountRef
} from '@atomica.co/components';
import {
  BaseId,
  EventScheduleGroupV2,
  EventScheduleParticipantV2,
  EventScheduleV2,
  SAVE_EVENT_SCHEDULE_PARTICIPANTS_V2_FOR_ADMIN,
  SEARCH_EVENT_SCHEDULE_GROUPS_V2_FOR_ADMIN,
  SaveEventScheduleParticipantsV2ForAdminRequest,
  SaveEventScheduleParticipantsV2ForAdminResponse,
  SearchEventScheduleGroupsV2ForAdminRequest,
  SearchEventScheduleGroupsV2ForAdminResponse
} from '@atomica.co/irori';
import { Name, Word } from '@atomica.co/types';
import { EMPTY, builder, isEmpty } from '@atomica.co/utils';
import React, { useEffect, useMemo } from 'react';
import styled from 'styled-components';
import { toEventScheduleParticipantV2 } from '../../../converters/event-schedule-participant-v2-converter';
import useCommonRequest from '../../../redux/hooks/useCommonRequest';

const LIMIT = 5;

interface P {
  baseId: BaseId;
  isOpen: boolean;
  eventScheduleV2: EventScheduleV2;
  eventScheduleParticipants: EventScheduleParticipantV2[];
  savedGroupName?: Name;
  onChange?: (groupName: Name) => void;
  onClose(isUpdated: boolean): void;
  openRegisterGroupModal(): void;
}

const AssignGroupModal: React.FC<P> = React.memo(props => {
  const {
    baseId,
    isOpen,
    eventScheduleV2,
    eventScheduleParticipants,
    savedGroupName = EMPTY,
    onChange,
    onClose,
    openRegisterGroupModal
  } = props;
  const { commonRequest } = useCommonRequest();
  const unmountRef = useUnmountRef();

  const [eventScheduleGroups, setEventScheduleGroups] = useSafeState<EventScheduleGroupV2[]>(unmountRef, []);
  const [selectedGroup, setSelectedGroup] = useSafeState<EventScheduleGroupV2 | undefined>(unmountRef);
  const [isScreenLoaderShown, setIsScreenLoaderShown] = useSafeState<boolean>(unmountRef, false);

  const selectedWord = useMemo<Word>(() => {
    if (!isEmpty(savedGroupName)) return savedGroupName;
    return selectedGroup?.name || EMPTY;
  }, [savedGroupName, selectedGroup]);

  const assignParticipantsToGroup = useSafeCallback(async (): Promise<void> => {
    if (!selectedWord) return;
    const groupToAssing = selectedGroup || eventScheduleGroups.find(group => group.name === selectedWord);
    if (!groupToAssing) return;
    setIsScreenLoaderShown(true);
    const participantsToUpdate = eventScheduleParticipants.map(eventScheduleParticipant => {
      return toEventScheduleParticipantV2(
        eventScheduleParticipant.eventScheduleParticipantId,
        eventScheduleV2,
        eventScheduleParticipant.user!,
        groupToAssing
      );
    });

    const request = builder<SaveEventScheduleParticipantsV2ForAdminRequest>()
      .baseId(baseId)
      .eventScheduleParticipantsV2(participantsToUpdate)
      .build();
    await commonRequest<
      SaveEventScheduleParticipantsV2ForAdminRequest,
      SaveEventScheduleParticipantsV2ForAdminResponse
    >(SAVE_EVENT_SCHEDULE_PARTICIPANTS_V2_FOR_ADMIN, request);
    setIsScreenLoaderShown(false);
    onClose(true);
  }, [
    baseId,
    commonRequest,
    eventScheduleV2,
    eventScheduleGroups,
    selectedGroup,
    eventScheduleParticipants,
    selectedWord,
    onClose,
    setIsScreenLoaderShown
  ]);

  const optionsForMasterSearch = useMemo<MasterSearchOption[]>(() => {
    const groupOptions = eventScheduleGroups.map(group => ({
      value: group.eventScheduleGroupId,
      label: group.name
    }));
    return groupOptions;
  }, [eventScheduleGroups]);

  const handleSearchEventScheduleGroups = useSafeCallback(
    async (word: Word): Promise<void> => {
      const request = builder<SearchEventScheduleGroupsV2ForAdminRequest>()
        .baseId(baseId)
        .eventScheduleIdV2(eventScheduleV2.eventScheduleId)
        .word(word)
        .build();
      const response = await commonRequest<
        SearchEventScheduleGroupsV2ForAdminRequest,
        SearchEventScheduleGroupsV2ForAdminResponse
      >(SEARCH_EVENT_SCHEDULE_GROUPS_V2_FOR_ADMIN, request);
      setEventScheduleGroups(response.eventScheduleGroupsV2);
    },
    [baseId, commonRequest, eventScheduleV2, setEventScheduleGroups]
  );

  const handleModalClosed = useSafeCallback((): void => {
    onClose(false);
  }, [onClose]);

  const footerButtons = useMemo<React.ReactNode[]>(() => {
    return [
      <ButtonV2 key='cancelButton' type='default' label='キャンセル' onClick={handleModalClosed} />,
      <ButtonV2
        key='createButton'
        width={105}
        disabled={!selectedWord}
        type='primary'
        label='追加'
        onClick={assignParticipantsToGroup}
      />
    ];
  }, [assignParticipantsToGroup, handleModalClosed, selectedWord]);

  const handleOptionSelected = useSafeCallback(
    (option: MasterSearchOption): void => {
      const selectedGroup = eventScheduleGroups.find(group => group.eventScheduleGroupId === option.value);
      setSelectedGroup(selectedGroup);
    },
    [eventScheduleGroups, setSelectedGroup]
  );

  const handleOptionCleared = useSafeCallback((): void => {
    if (onChange) onChange(EMPTY);
    setSelectedGroup(undefined);
  }, [onChange, setSelectedGroup]);

  useEffect(() => {
    handleSearchEventScheduleGroups(selectedWord);
  }, [selectedWord, handleSearchEventScheduleGroups]);

  return (
    <DialogV2
      isOpen={isOpen}
      headerLabel='割り当て先のグループを選択'
      buttonsOnTheRight={footerButtons}
      onClose={() => onClose(false)}
    >
      <FormWrapper>
        <NoGroupDesc>指定するグループを選択するか、新しく作成してください。</NoGroupDesc>
        <SearchWrapper>
          <LabelV2 text='グループ' required />
          <MasterSearchV2
            labelForNoData='一致するグループはありません'
            limit={LIMIT}
            selectedWord={selectedWord}
            actions={[{ label: 'グループを作成', onClick: openRegisterGroupModal }]}
            options={optionsForMasterSearch}
            onChange={handleSearchEventScheduleGroups}
            handleOptionSelected={handleOptionSelected}
            handleOptionCleared={handleOptionCleared}
          />
        </SearchWrapper>
      </FormWrapper>
      <ScreenLoaderV2 loading={isScreenLoaderShown} />
    </DialogV2>
  );
});

AssignGroupModal.displayName = 'AssignGroupModal';
export default AssignGroupModal;

const FormWrapper = styled.div`
  height: 361px;
  display: flex;
  flex-direction: column;
  gap: ${themeV2.mixins.v2.spacing}px;
  padding: ${themeV2.mixins.v2.spacing * 2}px ${themeV2.mixins.v2.spacing * 3}px;
  background-color: #faf9f7;
`;
const SearchWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;
const NoGroupDesc = styled.div`
  ${themeV2.mixins.v2.typography.body.medium};
  color: ${themeV2.mixins.v2.color.font.gray};
`;
