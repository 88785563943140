import {
  BackButtonV2,
  TextFieldV2,
  themeV2,
  useSafeCallback,
  useSafeState,
  useUnmountRef
} from '@atomica.co/components';
import { BASE_CODE, BaseDto, DOMAINS, ErrorCode } from '@atomica.co/irori';
import { Email, Message } from '@atomica.co/types';
import { EMPTY, MINUS_ONE, ONE, ZERO, embedIdInPath, isEmail } from '@atomica.co/utils';
import MailIcon from '@material-ui/icons/Mail';
import React, { useEffect } from 'react';
import styled from 'styled-components';
import CapsuleButton from '../../../../../components/button/CapsuleButton';
import env from '../../../../../env/env';
import firebase, { auth } from '../../../../../firebase';
import { Path } from '../../../../../router/Routes';
import { ERROR_MESSAGES } from '../../../../../texts/error-text';

interface P {
  base: BaseDto;

  onSendEmail(email: Email): void;
  goBack(): void;
}

const InputEmail: React.FC<P> = React.memo(props => {
  const { base, onSendEmail, goBack } = props;
  const unmountRef = useUnmountRef();
  const [disabledSendButton, setDisabledSendButton] = useSafeState<boolean>(unmountRef, true);
  const [email, setEmail] = useSafeState<Email>(unmountRef, EMPTY);
  const [errorMessage, setErrorMessage] = useSafeState<Message>(unmountRef, EMPTY);

  useEffect(() => {
    setDisabledSendButton(!email);
  }, [setDisabledSendButton, email]);

  const constructErrorMessage = useSafeCallback(
    (code: ErrorCode): void => {
      if (!code) return;

      Object.entries(ERROR_MESSAGES)
        .filter(message => code.toString().indexOf(message[ZERO]) !== MINUS_ONE)
        .forEach(message => setErrorMessage(message[ONE]));
    },
    [setErrorMessage]
  );

  const signInWithEmailAndPassword = useSafeCallback((): void => {
    if (!email || !isEmail(email)) {
      constructErrorMessage(ErrorCode.INVALID_EMAIL);
      return;
    }

    const redirectUrl = `https://${DOMAINS[env]}${embedIdInPath(Path.SIGN_IN, [BASE_CODE], [base.baseCode])}`;
    setDisabledSendButton(true);
    const settings: firebase.auth.ActionCodeSettings = {
      handleCodeInApp: true,
      url: redirectUrl
    };

    auth
      .sendPasswordResetEmail(email, settings)
      .then(() => {
        onSendEmail(email);
      })
      .catch(err => {
        setDisabledSendButton(false);
        constructErrorMessage(err.code);
      });
  }, [base, setDisabledSendButton, email, onSendEmail, constructErrorMessage]);

  return (
    <Container>
      <TextFieldWrapper>
        <StyledTextField
          inputMode='email'
          errorMessage={errorMessage}
          height={56}
          placeholder='Email'
          value={email}
          onChange={setEmail}
          onEnter={signInWithEmailAndPassword}
        />
      </TextFieldWrapper>

      <BackButtonWrapper>
        <BackButtonV2 label='戻る' onClick={goBack} />
      </BackButtonWrapper>

      <ButtonWrapper>
        <CapsuleButton
          type='primary'
          disabled={disabledSendButton}
          icon={<MailIcon fontSize='small' />}
          label='再設定のURLを送る'
          onClick={signInWithEmailAndPassword}
        />
      </ButtonWrapper>
    </Container>
  );
});

InputEmail.displayName = 'InputEmail';
export default InputEmail;

const Container = styled.div`
  width: 100%;
`;

const TextFieldWrapper = styled.div`
  padding: 0 ${themeV2.mixins.v2.spacing * 2}px;
`;

const StyledTextField = styled(TextFieldV2)`
  && {
    border: none !important;
    border-radius: 16px !important;
  }
`;

const BackButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  padding-right: ${themeV2.mixins.v2.spacing * 2}px;
`;

const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: ${themeV2.mixins.v2.spacing * 1.5}px;
`;
