import { ShotCategory, ShotQuantityInputType, ShotSubcategory } from '@atomica.co/irori';
import { Count, Label, Quantity } from '@atomica.co/types';
import { EMPTY, ZERO } from '@atomica.co/utils';
import { Hour } from '../enums/common-enum';

const CONFERENCE_USAGE_HOURS_LABEL = '利用時間';

const NUMBER_OF_PEOPLE_LABEL_ONE_DAY = '1DAY利用人数';

export default class ShotQuantityService {
  public static calculateBaseQuantity = (
    category: ShotCategory | undefined,
    subcategory: ShotSubcategory | undefined,
    conferenceUsageHours: Hour | undefined
  ): Quantity => {
    if (!category) return ZERO;

    switch (category.quantityInputType) {
      case ShotQuantityInputType.FIXED:
        return category.defaultQuantity;

      case ShotQuantityInputType.MANUAL:
        if (!subcategory) return ZERO;

        switch (subcategory.quantityInputType) {
          case ShotQuantityInputType.FIXED:
            return subcategory.defaultQuantity;

          case ShotQuantityInputType.MANUAL:
            return conferenceUsageHours ? parseFloat(conferenceUsageHours) : ZERO;

          default:
            throw new Error(`${subcategory.quantityInputType} is out of target.`);
        }

      default:
        throw new Error(`${category.quantityInputType} is out of target.`);
    }
  };

  public static getBaseQuantityLabel = (category: ShotCategory | undefined): Label => {
    if (!category) return EMPTY;

    switch (category.quantityInputType) {
      case ShotQuantityInputType.FIXED:
        return EMPTY;

      case ShotQuantityInputType.MANUAL:
        return CONFERENCE_USAGE_HOURS_LABEL;

      default:
        throw new Error(`${category.quantityInputType} is out of target.`);
    }
  };

  public static calculateOptionQuantity = (
    category: ShotCategory | undefined,
    subcategory: ShotSubcategory | undefined,
    conferenceOneDayUsage: boolean | undefined,
    conferenceOneDayNumberOfPeople: Count | undefined
  ): Quantity => {
    if (!category) return ZERO;

    switch (category.quantityInputType) {
      case ShotQuantityInputType.FIXED:
        return subcategory ? subcategory.defaultQuantity : ZERO;

      case ShotQuantityInputType.MANUAL:
        return conferenceOneDayUsage === true && !!conferenceOneDayNumberOfPeople
          ? conferenceOneDayNumberOfPeople
          : ZERO;

      default:
        throw new Error(`${category.quantityInputType} is out of target.`);
    }
  };

  public static getOptionQuantityLabel = (
    category: ShotCategory | undefined,
    conferenceOneDayUsage: boolean | undefined
  ): Label => {
    if (!category) return EMPTY;

    switch (category.quantityInputType) {
      case ShotQuantityInputType.FIXED:
        return EMPTY;

      case ShotQuantityInputType.MANUAL:
        return conferenceOneDayUsage === true ? NUMBER_OF_PEOPLE_LABEL_ONE_DAY : EMPTY;

      default:
        throw new Error(`${category.quantityInputType} is out of target.`);
    }
  };
}
