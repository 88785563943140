import { theme } from '@atomica.co/components';
import { ErrorCode } from '@atomica.co/irori';
import { HttpResponseStatus } from '@atomica.co/utils';
import { ThemeProvider as MuiThemeProvider, StylesProvider } from '@material-ui/styles';
import { MaterialDesignContent, SnackbarOrigin, SnackbarProvider } from 'notistack';
import React from 'react';
import ReactDOM from 'react-dom';
import { ErrorBoundary } from 'react-error-boundary';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import styled, { ThemeProvider as StyledThemeProvider } from 'styled-components';
import BadRequest from './components/errors/BadRequest';
import NoPrivilege from './components/errors/NoPrivilege';
import NotFound from './components/errors/NotFound';
import UnknownError from './components/errors/UnknownError';
import './index.css';
import SnackbarProviderV2 from './provider/SnackbarProviderV2';
import store, { persistor } from './redux/store';
import Root from './screens/root/Root';
import * as serviceWorker from './serviceWorker';

const snackbarOrigin: SnackbarOrigin = {
  vertical: 'top',
  horizontal: 'right'
};

const StyledMaterialDesignContent = styled(MaterialDesignContent)(() => ({
  '&.notistack-MuiContent-success': {
    fontSize: theme.mixins.typography.fontSize.sixteen,
    fontWeight: theme.mixins.typography.fontWeight.sevenHundreds as number,
    fontFamily: theme.mixins.typography.fontFamily,
    backgroundColor: `${theme.mixins.background.green} !important`,
    borderRadius: 16
  },
  '&.notistack-MuiContent-error': {
    backgroundColor: `${theme.mixins.background.pink} !important`,
    borderRadius: 16
  }
}));

const App: React.FC = () => {
  return (
    <Provider store={store}>
      <PersistGate persistor={persistor}>
        <StylesProvider injectFirst>
          <MuiThemeProvider theme={theme}>
            <StyledThemeProvider theme={theme}>
              <SnackbarProvider
                maxSnack={1}
                autoHideDuration={1000}
                Components={{ success: StyledMaterialDesignContent, error: StyledMaterialDesignContent }}
                anchorOrigin={snackbarOrigin}
              >
                <SnackbarProviderV2>
                  <ErrorBoundary
                    fallbackRender={e => {
                      if (e.error.message === ErrorCode.PERMISSION_DENIED) return <NoPrivilege {...e} />;
                      switch (e.error.details.errorCode) {
                        case HttpResponseStatus.PERMISSION_DENIED:
                          return <NoPrivilege {...e} />;
                        case HttpResponseStatus.BAD_REQUEST:
                          return <BadRequest {...e} />;
                        case HttpResponseStatus.NOT_FOUND:
                          return <NotFound {...e} />;
                        default:
                          return <UnknownError {...e} />;
                      }
                    }}
                  >
                    <Root />
                  </ErrorBoundary>
                </SnackbarProviderV2>
              </SnackbarProvider>
            </StyledThemeProvider>
          </MuiThemeProvider>
        </StylesProvider>
      </PersistGate>
    </Provider>
  );
};

ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
