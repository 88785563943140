export const CONTRACT_UPDATED = '契約内容を更新しました';

export const CONTRACT_DELETED = '契約を削除しました';

export const EMAIL_SENT = 'メールを送信しました';

export const ACCESS_RECORDED = '入退室を記録しました';

export const SHOT_USAGE_SAVED = '入力内容を登録しました';

export const SHOT_USAGE_UPDATED = '入力内容を更新しました';

export const SHOT_USAGE_DELETED = '入力内容を取り消しました';

export const REGISTERED_AS_EVENT_WAITING = '開催通知をONにしました';

export const EVENT_JOINED = '参加申し込みが完了しました';

export const USER_NO_SELECTED = '対象のユーザーを選択してください';

export const USER_AUTH_SAVED = '権限を変更しました';

export const USER_AUTH_ERROR = '権限の変更に失敗しました';

export const SPACE_RESERVE_ERROR = '予約に失敗しました';

export const SPACE_RESERVE_SAVED = '予約が完了しました';

export const SPACE_RESERVE_UPDATED = '予約内容を更新しました';

export const SPACE_RESERVE_DELETED = '予約を取り消しました';

export const SPACE_RESERVE_EDITED = '予約日時を変更しました';

export const SPACE_RESERVE_EXTENDED = '利用時間を延長しました';

export const QFACE_SET_ERROR = '写真の更新に失敗しました';

export const QFACE_SET_SAVED = '写真の更新が完了しました';

export const INVITATION_LINK_COPIED = '招待リンクをコピーしました';

export const ACCOUNT_EVENT_DETAIL_LINK_COPIED = '詳細画面へのリンクをコピーしました';

export const EMAIL_COPIED = 'メールをコピーしました';

export const INTRODUCTION_MESSAGE_COPIED = '他己紹介をコピーしました';

export const SEND_INVITATION_SUCCESS = '招待が完了しました';

export const SEND_INVITATION_ERROR = '招待メールの送信に失敗したユーザが存在します';

export const CANCEL_INVITATION_SUCCESS = '取り消しが完了しました';

export const CANCEL_INVITATION_ERROR = '取り消しに失敗しました';

export const FAILED_TO_OPEN_KEY = '解錠に失敗しました';

export const OPEN_KEY_SUCCESSFULLY = '解錠しました';

export const ENTRY_FORM_SAVED = '申し込みが完了しました';

export const SUCCESS_CHECKOUT = 'ご利用ありがとうございました';

export const CANCEL_PARTICIPANT_SUCCESS = '参加キャンセルしました';

export const CANCEL_PARTICIPANT_ERROR = '参加キャンセルに失敗しました';

export const SEND_STRIPE_REGISTER_CREDIT_CARD_FROM_SUCCESS = 'クレジットカード登録メールを送信しました';

export const FAILED_PAYMENT = '請求金額の確定に失敗した請求があります。';

export const SUCCEEDED_PAYMENT = '請求金額の確定に成功しました。';
