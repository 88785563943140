import {
  Contract,
  ContractId,
  ContractPaymentMethod,
  ContractStatus,
  InflowSource,
  PaymentType,
  UseAddress
} from '@atomica.co/irori';
import { Amount, Code, Count, Email, Month, Name, Remarks } from '@atomica.co/types';
import { builder, toBeginningOfDay, toEndOfDay, toTaxIncluded, ZERO } from '@atomica.co/utils';

/** @deprecated */
export const TAX_RATE = 10;

export const toContract = (
  contractId: ContractId,
  contractName: Name,
  contractStatus: ContractStatus,
  startDate: Date,
  endDate: Date,
  representativeName: Name,
  representativeEmail: Email,
  contractPlan: Name,
  paymentMethod: ContractPaymentMethod,
  paymentType: PaymentType,
  useAddress: UseAddress,
  numberOfKeys: Count,
  numberOfLockers: Count,
  taxExcludedPlanPrice: Amount,
  taxExcludedKeyIssuePrice: Amount,
  taxExcludedLockerPrice: Amount,
  taxExcludedAddressPrice: Amount,
  serviceRetainer: Amount,
  initDiscountAmount: Amount,
  discountAmount: Amount,
  discountMonths: Month,
  taxIncludedTotalAmount: Amount,
  billingAmount: Amount,
  remarks: Remarks,
  inflowSource: InflowSource,
  baseCode: Code
): Contract => {
  return builder<Contract>()
    .contractId(contractId)
    .contractName(contractName)
    .contractStatus(contractStatus)
    .startDate(toBeginningOfDay(startDate) as Date)
    .endDate(toEndOfDay(endDate) as Date)
    .representativeName(representativeName)
    .representativeEmail(representativeEmail)
    .contractPlan(contractPlan)
    .paymentMethod(paymentMethod)
    .paymentType(paymentType)
    .useAddress(useAddress === UseAddress.YES)
    .numberOfKeys(numberOfKeys ? numberOfKeys : ZERO)
    .numberOfLockers(numberOfLockers ? numberOfLockers : ZERO)
    .taxExcludedPlanPrice(taxExcludedPlanPrice)
    .taxIncludedPlanPrice(toTaxIncluded(taxExcludedPlanPrice, TAX_RATE))
    .taxExcludedKeyIssuePrice(taxExcludedKeyIssuePrice)
    .taxIncludedKeyIssuePrice(toTaxIncluded(taxExcludedKeyIssuePrice, TAX_RATE))
    .taxExcludedLockerPrice(taxExcludedLockerPrice)
    .taxIncludedLockerPrice(toTaxIncluded(taxExcludedLockerPrice, TAX_RATE))
    .taxExcludedAddressPrice(taxExcludedAddressPrice)
    .taxIncludedAddressPrice(toTaxIncluded(taxExcludedAddressPrice, TAX_RATE))
    .serviceRetainer(serviceRetainer)
    .initDiscountAmount(initDiscountAmount)
    .discountAmount(discountAmount)
    .discountMonths(discountMonths)
    .taxIncludedTotalAmount(taxIncludedTotalAmount)
    .billingAmount(billingAmount ? billingAmount : ZERO)
    .remarks(remarks)
    .inflowSource(inflowSource)
    .base(baseCode)
    .build();
};
