import { BoxFrame, ButtonV2, themeV2, useSafeCallback } from '@atomica.co/components';
import { Email, Message } from '@atomica.co/types';
import { Typography } from '@material-ui/core';
import CheckIcon from '@material-ui/icons/Check';
import React, { useMemo } from 'react';
import styled from 'styled-components';
import { SignUpInSendEmailStatus } from '../../../enums/account-enum';
import usePath from '../../../redux/hooks/usePath';
import { Path } from '../../../router/Routes';

interface P {
  email: Email;
  status: SignUpInSendEmailStatus;
  onClick?: () => void;
}

const EmailSent: React.FC<P> = React.memo(props => {
  const { email, status, onClick } = props;
  const { openBasePath } = usePath();

  const message = useMemo<Message>(() => {
    switch (status) {
      case SignUpInSendEmailStatus.SIGN_UP:
        return '仮登録が完了しました';
      case SignUpInSendEmailStatus.RESET_PASSWORD:
        return 'メールを送信しました';
    }
  }, [status]);

  const openSignInScreen = useSafeCallback((): void => {
    switch (status) {
      case SignUpInSendEmailStatus.SIGN_UP:
        openBasePath(Path.SIGN_IN);
        break;
      case SignUpInSendEmailStatus.RESET_PASSWORD:
        if (onClick) onClick();
        break;
    }
  }, [onClick, openBasePath, status]);

  return (
    <Container>
      <BoxWrapper>
        <BoxFrame
          content={
            <BoxContent>
              <Icon>
                <CheckIcon />
              </Icon>
              <MessageText>{message}</MessageText>
            </BoxContent>
          }
        />
      </BoxWrapper>

      <Remarks>
        {status === SignUpInSendEmailStatus.SIGN_UP && <Row>仮登録ありがとうございます。</Row>}
        <Row>{email}</Row>
        <Row>宛にメールを送信しました。</Row>
      </Remarks>

      <Remarks>
        <Row>本文内のURLにアクセスし、</Row>
        <Row>パスワードを{status === SignUpInSendEmailStatus.SIGN_UP ? '設定' : '再設定'}してください。</Row>
      </Remarks>

      <ButtonV2 label='ログイン画面に戻る' onClick={openSignInScreen} />
    </Container>
  );
});

EmailSent.displayName = 'EmailSent';
export default EmailSent;

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: ${themeV2.mixins.v2.spacing * 2}px;
`;

const BoxWrapper = styled.div`
  margin: ${themeV2.mixins.v2.spacing}px;
`;

const BoxContent = styled.div`
  width: 280px;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Icon = styled(Typography)`
  width: 40px;
  height: 40px;
  color: ${themeV2.mixins.v2.color.font.pink};
  display: flex;
  align-items: center;
  justify-content: center;
`;

const MessageText = styled(Typography)`
  ${themeV2.mixins.v2.typography.title.large};
  width: calc(100% - 40px);
  height: auto;
  color: ${themeV2.mixins.v2.color.font.gray};
`;

const Remarks = styled.div`
  width: 100%;
`;

const Row = styled(Typography)`
  ${themeV2.mixins.v2.typography.body.small};
  color: ${themeV2.mixins.v2.color.font.gray};
  text-align: center;
`;
