import { Component, themeV2, useSafeCallback, useSafeState, useUnmountRef } from '@atomica.co/components';
import {
  AccessV2,
  BaseDto,
  ContractVersion,
  FETCH_ACCESSES,
  FetchAccessesRequest,
  FetchAccessesResponse,
  User
} from '@atomica.co/irori';
import { Index } from '@atomica.co/types';
import { Language, builder, hasLength, toDateTimeStr } from '@atomica.co/utils';
import { Divider, List, ListItem, Typography } from '@material-ui/core';
import React, { memo, useEffect } from 'react';
import styled from 'styled-components';
import CommonRequest from '../../../../requests/common-request';
import { DIRECTION_LABELS } from '../../../../texts/access-v2-text';

const LIMIT = 10;

interface Props {
  base: BaseDto;
  user: User;
}

const AccountEnterExitHistoryCard: React.FC<Props> = memo(props => {
  const { base, user } = props;
  const unmountRef = useUnmountRef();
  // const [offset, setOffset] = useSafeState<Offset>(unmountRef, ZERO);
  const [accesses, setAccesses] = useSafeState<AccessV2[]>(unmountRef, []);
  const [isInitialized, setIsInitialized] = useSafeState<boolean>(unmountRef, false);
  // const [totalCount, setTotalCount] = useSafeState<Count>(unmountRef, ZERO);

  // const isInitialized = useRef<boolean>(false);

  const initialize = useSafeCallback(async (): Promise<void> => {
    const request = builder<FetchAccessesRequest>()
      .baseCode(base.baseCode)
      .userId(user.userId)
      .limit(LIMIT)
      // .offset(offset * LIMIT)
      .version(ContractVersion.V2)
      .build();
    const response = await CommonRequest.call<FetchAccessesRequest, FetchAccessesResponse>(FETCH_ACCESSES, request);

    setAccesses(response.accesses);
    // setTotalCount(response.totalCount);
    setIsInitialized(true);
  }, [base, user, setAccesses, setIsInitialized]);

  useEffect(() => {
    if (isInitialized) return;
    initialize();
  }, [isInitialized, initialize]);

  return (
    <Component
      style={{ width: '100%', height: '100%' }}
      loading={!isInitialized}
      className='account-usage-situation-card'
    >
      <Card data-testid='enter-exit-history'>
        <ContentWrapper>
          {!hasLength(accesses) && <NoItemMessage>エントランス入退履歴はありません</NoItemMessage>}
          <StyledList>
            {accesses.map((access: AccessV2, index: Index) => {
              return (
                <div key={`access-row-${index}`}>
                  <ListItem
                    style={{ height: 56, display: 'flex', justifyContent: 'space-between' }}
                    key={access.accessId}
                  >
                    <Text>{toDateTimeStr(access.operationDatetime, Language.JAPANESE)}</Text>
                    <Text>{access.direction && DIRECTION_LABELS[access.direction]}</Text>
                  </ListItem>
                  {index !== accesses.length - 1 && <StyledDivider variant='fullWidth' flexItem />}
                </div>
              );
            })}
          </StyledList>
        </ContentWrapper>
      </Card>
    </Component>
  );
});

AccountEnterExitHistoryCard.displayName = 'AccountEnterExitHistoryCard';
export default AccountEnterExitHistoryCard;

const Card = styled.div`
  display: flex;
  width: 100%;
  padding: ${themeV2.mixins.v2.spacing * 2}px ${themeV2.mixins.v2.spacing * 3}px ${themeV2.mixins.v2.spacing}px
    ${themeV2.mixins.v2.spacing * 3}px;
  flex-direction: column;
  align-items: flex-start;
  background: ${themeV2.mixins.v2.color.background.white};
  border-radius: ${themeV2.mixins.v2.spacing}px;
`;

const StyledDivider = styled(Divider)`
  border: 1px solid ${themeV2.mixins.v2.color.border.gray};
`;

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const StyledList = styled(List)`
  border-radius: 12px;
  padding: 0;
`;

const NoItemMessage = styled.div`
  width: 100%;
  text-align: center;
  ${themeV2.mixins.v2.typography.body.medium};
  color: ${themeV2.mixins.v2.color.font.gray};
  padding: ${themeV2.mixins.v2.spacing * 2}px;
`;

const Text = styled(Typography)`
  ${themeV2.mixins.v2.typography.body.large};
  color: ${themeV2.mixins.v2.color.font.black};
`;
