import {
  ButtonV2,
  DialogV2,
  RadioBoxV2,
  ScreenLoaderV2,
  themeV2,
  useSafeCallback,
  useSafeState,
  useUnmountRef
} from '@atomica.co/components';
import React, { useMemo } from 'react';
import styled from 'styled-components';
import { EVENT_SCHEDULE_RECURRENCE_TARGET_LABELS } from '../../../texts/event-text';
import { RecurrenceTarget } from '@atomica.co/irori';
import { Labels } from '../../../models/common-model';

const LABEL: Labels = {
  edit: '編集',
  delete: '削除'
};

interface P {
  isOpen: boolean;
  editOrDelete: 'edit' | 'delete';
  onClick: (recurrenceTarget: RecurrenceTarget) => Promise<void>;
  onClose(isUpdated: boolean): void;
}

const EditOrDeleteEventScheduleModal: React.FC<P> = React.memo(props => {
  const { isOpen, editOrDelete, onClick, onClose } = props;

  const unmountRef = useUnmountRef();
  const [recurrenceTarget, setReccurentTarget] = useSafeState<RecurrenceTarget>(unmountRef, RecurrenceTarget.THIS_ONE);
  const [isScreenLoaderShown, setIsScreenLoaderShown] = useSafeState<boolean>(unmountRef, false);

  const handleButtonClicked = useSafeCallback(async () => {
    setIsScreenLoaderShown(true);
    await onClick(recurrenceTarget);
    setIsScreenLoaderShown(false);
    onClose(true);
  }, [recurrenceTarget, onClick, onClose, setIsScreenLoaderShown]);

  const footerButtons = useMemo<React.ReactNode[]>(() => {
    return [
      <ButtonV2 key='cancel' label='キャンセル' onClick={() => onClose(false)} />,
      <ButtonV2 key='delete' width={105} type='primary' label={LABEL[editOrDelete]} onClick={handleButtonClicked} />
    ];
  }, [editOrDelete, onClose, handleButtonClicked]);

  return (
    <DialogV2
      isOpen={isOpen}
      headerLabel={`定期的なスケジュールの${LABEL[editOrDelete]}`}
      buttonsOnTheRight={footerButtons}
      onClose={() => onClose(false)}
    >
      <FormWrapper>
        <RadioBoxV2
          direction='column'
          options={Object.values(RecurrenceTarget)}
          labels={EVENT_SCHEDULE_RECURRENCE_TARGET_LABELS}
          value={recurrenceTarget}
          onChange={setReccurentTarget}
        />
      </FormWrapper>
      <ScreenLoaderV2 loading={isScreenLoaderShown} />
    </DialogV2>
  );
});

EditOrDeleteEventScheduleModal.displayName = 'EditOrDeleteEventScheduleModal';
export default EditOrDeleteEventScheduleModal;

const FormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${themeV2.mixins.v2.spacing * 2}px;
  padding: ${themeV2.mixins.v2.spacing * 2}px ${themeV2.mixins.v2.spacing * 3}px;
`;
