import { Id, Name, URL } from '@atomica.co/types';
import { EMPTY, urlToFile } from '@atomica.co/utils';
import compress from 'browser-image-compression';
import crypto from 'crypto';
import { ImageCategory } from '../enums/common-enum';
import { storage } from '../firebase';

const IMAGE_PATH_PREFIX = 'knotPLACE';

const IMAGE_UPLOAD_OPTIONS = {
  maxSizeMB: 0.2,
  maxWidthOrHeight: 1080
};

export class ImageService {
  private static constructImagePath(category: ImageCategory, imageId: Id): URL {
    return `${IMAGE_PATH_PREFIX}/${category}/${imageId}`;
  }

  private static changeFileName(originalFile: File, newName: Name): File {
    const extension = originalFile.name.split('.').pop();
    const newFileName = `${newName}.${extension}`;
    return new File([originalFile], newFileName, {
      type: originalFile.type,
      lastModified: originalFile.lastModified
    });
  }

  public static async getCompressedImage(image: File | undefined, newName?: Name): Promise<File | undefined> {
    if (!image) return;

    try {
      const compressedImage = await compress(image, IMAGE_UPLOAD_OPTIONS);
      return newName ? ImageService.changeFileName(compressedImage, newName) : compressedImage;
    } catch (e) {
      console.error(JSON.stringify(e));
    }
  }

  public static async getImageDataUrl(image?: File): Promise<URL> {
    if (!image) return EMPTY;

    try {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(image);
      await new Promise<void>(resolve => (fileReader.onload = () => resolve()));
      return fileReader.result as URL;
    } catch (e) {
      console.error(JSON.stringify(e));
      return EMPTY;
    }
  }

  public static async urlToFile(url: URL | null | undefined): Promise<File | undefined> {
    return await urlToFile(url);
  }

  public static async uploadImageToFirebase(
    image: File | undefined,
    category: ImageCategory,
    imageId: Id
  ): Promise<URL> {
    if (!image) return EMPTY;

    try {
      const compressedImage = await compress(image, IMAGE_UPLOAD_OPTIONS);
      const ref = storage.ref(ImageService.constructImagePath(category, imageId));
      const photo = await ref.put(compressedImage);
      return await photo.ref.getDownloadURL();
    } catch (e) {
      console.error(JSON.stringify(e));
      return EMPTY;
    }
  }

  public static async uploadImageUrlToFirebase(
    url: URL | null | undefined,
    category: ImageCategory,
    imageId: Id
  ): Promise<URL | undefined> {
    const image = await ImageService.urlToFile(url);
    return await ImageService.uploadImageToFirebase(image, category, imageId);
  }

  public static async putImageFileToStorage(file: File): Promise<string> {
    const STORAGE_FACE_RECOGNITION_PHOTOS_PATH = 'faceRecognitionPhotos';
    const today = new Date();
    const folder = `${STORAGE_FACE_RECOGNITION_PHOTOS_PATH}/${today.getFullYear()}_${
      today.getMonth() + 1
    }_${today.getDate()}`;
    const fileName =
      crypto.createHash('md5').update(`${file.name}_${today.getTime()}`).digest('hex') +
      '.' +
      file.name.split('.').pop();
    const storageRef = storage.ref();
    const faceRecognitionPhotosRef = storageRef.child(`${folder}/${fileName}`);
    await faceRecognitionPhotosRef.put(file);
    return `${folder}/${fileName}`;
  }
}
