import { ButtonV2, DialogV2 } from '@atomica.co/components';
import { Label } from '@atomica.co/types';
import React, { useMemo } from 'react';
import styled from 'styled-components';
import {
  USER_DETAIL_ROOM_ENTRY_EXIT_HISTORY_FEATURES,
  UserDetailRoomEntryExitHistoryFeatures
} from '../../../../enums/users-enum';
import { USER_DETAIL_ROOM_ENTRY_EXIT_HISTORY_FEATURE_LABELS } from '../../../../texts/user-text';

import { EntryExitErrors, EntryExitHook } from '../../../../redux/hooks/useRoomEntryExit';
import DeleteContent from './content/DeleteContent';
import UpsertContent from './content/UpsertContent';

interface P {
  isOpen: boolean;
  isDisabledAction: boolean;
  onAction: () => void;
  onClose: () => void;
  actionKey?: UserDetailRoomEntryExitHistoryFeatures;
  entryExitHook: EntryExitHook;
  errors?: EntryExitErrors;
}

type ContentComponent = React.FC<{ entryExitHook: EntryExitHook; errors?: EntryExitErrors }>;

const CONTENT_COMPONENT_MAP: Record<UserDetailRoomEntryExitHistoryFeatures, ContentComponent> = {
  [USER_DETAIL_ROOM_ENTRY_EXIT_HISTORY_FEATURES.REGISTER]: UpsertContent,
  [USER_DETAIL_ROOM_ENTRY_EXIT_HISTORY_FEATURES.UPDATE]: UpsertContent,
  [USER_DETAIL_ROOM_ENTRY_EXIT_HISTORY_FEATURES.DELETE]: DeleteContent
};

const RoomEntryExitHistoryModal: React.FC<P> = React.memo(props => {
  const { isOpen, isDisabledAction, onAction, onClose, actionKey, entryExitHook, errors } = props;

  const buttons = useMemo<React.ReactNode[] | undefined>(() => {
    if (!actionKey) return undefined;
    return [
      <ButtonV2 key='cancel' label='キャンセル' onClick={onClose} />,
      <StyledButton
        key='save'
        label={USER_DETAIL_ROOM_ENTRY_EXIT_HISTORY_FEATURE_LABELS[actionKey]}
        onClick={onAction}
        disabled={isDisabledAction}
      />
    ];
  }, [actionKey, isDisabledAction, onClose, onAction]);

  const headerLabel = useMemo<Label>(() => {
    if (!actionKey) return '-------';
    return '入退室履歴の'.concat(USER_DETAIL_ROOM_ENTRY_EXIT_HISTORY_FEATURE_LABELS[actionKey]);
  }, [actionKey]);

  const Content = useMemo(() => {
    if (!actionKey) return undefined;
    const ContentComponent = CONTENT_COMPONENT_MAP[actionKey];
    return <ContentComponent entryExitHook={entryExitHook} errors={errors} />;
  }, [actionKey, entryExitHook, errors]);

  return (
    <DialogV2 width={500} isOpen={isOpen} headerLabel={headerLabel} buttonsOnTheRight={buttons} onClose={onClose}>
      {Content}
    </DialogV2>
  );
});

RoomEntryExitHistoryModal.displayName = 'RoomEntryExitHistoryModal';
export default RoomEntryExitHistoryModal;

const StyledButton = styled(ButtonV2).attrs(() => ({ type: 'primary', width: 105 }))``;
