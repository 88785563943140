import { CardWithLabelV2, LabelV2, StatusV2, themeV2 } from '@atomica.co/components';
import { Questionnaire, QuestionnaireStatus, toFullName } from '@atomica.co/irori';
import { DateStr } from '@atomica.co/types';
import { format } from 'date-fns';
import React from 'react';
import styled from 'styled-components';
import { getQuestionnaireStatusLabel, toStatus } from '../../../utils/questionnaire-util';
import QuestionnaireForm from '../form/QuestionnaireForm';

interface P {
  questionnaire: Questionnaire;
  thumbnail: File | undefined;
}

const toDateStr = (date: Date | undefined): DateStr => (date ? format(date, 'yyyy/MM/dd') : 'なし');

const QuestionnaireDetail: React.FC<P> = React.memo(props => {
  const { questionnaire, thumbnail } = props;

  return (
    <Container>
      <QuestionnaireForm questionnaire={questionnaire} thumbnail={thumbnail} />
      <div>
        <Content>
          <Title>その他の情報</Title>
          <CardWrapper>
            <LabelV2 text='公開状態' />
            <Card>
              <StatusV2 status={toStatus(questionnaire)} label={getQuestionnaireStatusLabel(questionnaire)} />
            </Card>
          </CardWrapper>
          <CardWithLabelV2 label='回答期限' text={toDateStr(questionnaire.answerDueAt)} />
          <CardWithLabelV2
            label='作成日'
            text={toDateStr(questionnaire.createdAt)}
            subText={toFullName(questionnaire.createdUser)}
          />
          <CardWithLabelV2
            label='最終更新日'
            text={toDateStr(questionnaire.updatedAt)}
            subText={toFullName(questionnaire.updatedUser)}
          />
          {questionnaire.status === QuestionnaireStatus.RELEASED && (
            <CardWithLabelV2
              label='公開日'
              text={toDateStr(questionnaire.releasedAt)}
              subText={toFullName(questionnaire.releasedUser)}
            />
          )}
        </Content>
      </div>
    </Container>
  );
});

QuestionnaireDetail.displayName = 'QuestionnaireDetail';
export default QuestionnaireDetail;

const Container = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 2fr 1fr;
  gap: ${themeV2.mixins.v2.spacing * 3}px;
  padding-bottom: ${themeV2.mixins.v2.spacing * 4}px;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${themeV2.mixins.v2.spacing * 3}px;
  padding: ${themeV2.mixins.v2.spacing * 2}px ${themeV2.mixins.v2.spacing * 3}px;
  border-radius: 8px;
  background: ${themeV2.mixins.v2.color.background.white};
`;

const Title = styled.div`
  ${themeV2.mixins.v2.typography.title.xLarge};
  color: ${themeV2.mixins.v2.color.font.black};
  border-bottom: 1px solid ${themeV2.mixins.v2.color.border.gray};
  padding-bottom: ${themeV2.mixins.v2.spacing}px;
`;

const CardWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${themeV2.mixins.v2.spacing}px;
`;

const Card = styled.div`
  background: ${themeV2.mixins.v2.color.background.offWhite};
  border-radius: 8px;
  padding: ${themeV2.mixins.v2.spacing * 2}px;
`;
