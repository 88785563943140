import { BaseDto, User } from '@atomica.co/irori';
import { Json } from '@atomica.co/types';
import { embedIdInPath, EMPTY, toDateTimeStr } from '@atomica.co/utils';
import { LoggingAction } from '../enums/logging-enum';
import firebase from '../firebase';
import { getLineProfile } from '../line';
import LogRequest from '../requests/log-request';
import { Path, PATH_IDS } from '../router/Routes';

export default class LoggerService {
  private _base: BaseDto;
  private _firebase: firebase.User | undefined;
  private _user: User | undefined;
  private _lineUser;

  constructor(base: BaseDto, firebase: firebase.User | undefined, user: User | undefined) {
    this._base = base;
    this._firebase = firebase;
    this._user = user;
  }

  private async _initialize(): Promise<void> {
    this._lineUser = await getLineProfile(this._base.lineLiffId);
  }

  private _toSignInEvent(): Json {
    return {
      action: LoggingAction.TRANSITION_TO_SIGN_IN_PAGE,
      userId: this._user ? this._user.userId : EMPTY,
      liffUserId: this._lineUser ? this._lineUser.userId : EMPTY,
      externalId: this._firebase ? this._firebase.uid : EMPTY,
      isActivated: this._user ? this._user.isActivated : EMPTY,
      currentURL: window.location.href,
      nextURL: window.location.origin + embedIdInPath(Path.SIGN_IN, PATH_IDS, [this._base.baseCode]),
      operationAt: toDateTimeStr(new Date())
    };
  }

  public async saveSignInEvent(): Promise<void> {
    await this._initialize();
    const event = this._toSignInEvent();
    await LogRequest.saveInfoLog(event);
  }

  private _toSignOutEvent(): Json {
    return {
      action: LoggingAction.TRANSITION_TO_SIGN_OUT_PAGE,
      userId: this._user ? this._user.userId : EMPTY,
      liffUserId: this._lineUser ? this._lineUser.userId : EMPTY,
      externalId: this._firebase ? this._firebase.uid : EMPTY,
      isActivated: this._user ? this._user.isActivated : EMPTY,
      currentURL: window.location.href,
      nextURL: window.location.origin + embedIdInPath(Path.SIGN_OUT, PATH_IDS, [this._base.baseCode]),
      operationAt: toDateTimeStr(new Date())
    };
  }

  public async saveSignOutEvent(): Promise<void> {
    await this._initialize();
    const event = this._toSignOutEvent();
    await LogRequest.saveInfoLog(event);
  }

  private _toRegisterAccountEvent(): Json {
    return {
      action: LoggingAction.TRANSITION_TO_REGISTER_ACCOUNT_PAGE,
      userId: this._user ? this._user.userId : EMPTY,
      liffUserId: this._lineUser ? this._lineUser.userId : EMPTY,
      externalId: this._firebase ? this._firebase.uid : EMPTY,
      isActivated: this._user ? this._user.isActivated : EMPTY,
      currentURL: window.location.href,
      nextURL: window.location.origin + embedIdInPath(Path.REGISTER_ACCOUNT, PATH_IDS, [this._base.baseCode]),
      operationAt: toDateTimeStr(new Date())
    };
  }

  public async saveRegisterAccountEvent(): Promise<void> {
    await this._initialize();
    const event = this._toRegisterAccountEvent();
    await LogRequest.saveInfoLog(event);
  }
}
