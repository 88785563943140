import { Component, Icon, LinkButton, themeV2, themeV3 } from '@atomica.co/components';
import { LoginServiceType, ValidContractV2Invitation } from '@atomica.co/irori';
import { Message } from '@atomica.co/types';
import { EMPTY } from '@atomica.co/utils';
import MailIcon from '@material-ui/icons/Mail';
import React from 'react';
import { RouteComponentProps } from 'react-router';
import styled from 'styled-components';
import google from '../../../assets/icon/icon_google.png';
import line from '../../../assets/icon/icon_line.png';
import sakura from '../../../assets/icon/sakuraId.svg';
import CapsuleButton from '../../../components/button/CapsuleButton';

interface P extends RouteComponentProps {
  invitation: ValidContractV2Invitation | undefined;
  messageText: Message;
  messageSubText?: Message;
  usableLoginServiceTypes: LoginServiceType[];
  openRegisterAccountScreenWithEmail: () => void;
  openGoogleLoginPage: () => void;
  openLineLoginPage: () => void;
  openSakuraLoginPage: () => void;
  openSignIn: () => void;
  errorMessage: Message;
}
const ValidInvitation: React.FC<P> = React.memo(props => {
  const {
    invitation,
    messageText,
    messageSubText,
    usableLoginServiceTypes,
    openRegisterAccountScreenWithEmail,
    openGoogleLoginPage,
    openLineLoginPage,
    openSakuraLoginPage,
    openSignIn,
    errorMessage
  } = props;

  return (
    <Component className='valid-invitation'>
      <Card>
        <MessageSection>
          <MessageText>{messageText}</MessageText>
          {messageSubText && <MessageSubText>{MessageSubText}</MessageSubText>}
        </MessageSection>
        <EmailSection>
          招待メールアドレス
          <EmailText>{invitation?.email ?? EMPTY}</EmailText>
          <EmailSubText>
            <span>※</span>
            <span>
              招待メールアドレス以外のサービスで登録を行う場合は、同一メールアドレスのアカウントをご利用ください。
            </span>
          </EmailSubText>
        </EmailSection>
        <ButtonSection>
          {usableLoginServiceTypes.map((loginServiceType, idx) => {
            switch (loginServiceType) {
              case LoginServiceType.MAIL:
                return <RegisterByEmail key={idx} onClick={openRegisterAccountScreenWithEmail} />;
              case LoginServiceType.GOOGLE:
                return <RegisterByGoogle key={idx} onClick={openGoogleLoginPage} />;
              case LoginServiceType.LINE:
                return <RegisterByLine key={idx} onClick={openLineLoginPage} />;
              case LoginServiceType.SAKURA:
                return <RegisterBySakura key={idx} onClick={openSakuraLoginPage} />;
              default: {
                const _: never = loginServiceType;
                throw new Error(_);
              }
            }
          })}
        </ButtonSection>
        {errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}

        <LinkSection>
          <LinkButton size='small' label='すでにアカウントをお持ちの方はログイン' onClick={openSignIn} />
        </LinkSection>
      </Card>
    </Component>
  );
});

const RegisterByEmail: React.FC<{ onClick: () => void }> = ({ onClick }) => (
  <CapsuleButton icon={<MailIcon fontSize='small' />} label='招待メールアドレスで登録' onClick={onClick} />
);
const RegisterByLine: React.FC<{ onClick: () => void }> = ({ onClick }) => (
  <CapsuleButton icon={<Icon size='xsmall' src={line} />} label='LINEで登録' onClick={onClick} />
);
const RegisterBySakura: React.FC<{ onClick: () => void }> = ({ onClick }) => (
  <CapsuleButton icon={<Icon size='xsmall' src={sakura} />} label='さくらIDで登録' onClick={onClick} />
);
const RegisterByGoogle: React.FC<{ onClick: () => void }> = ({ onClick }) => (
  <CapsuleButton icon={<Icon size='xsmall' src={google} />} label='Googleで登録' onClick={onClick} />
);

ValidInvitation.displayName = 'ValidInvitation';
export default ValidInvitation;

const Card = styled.div`
  width: 100%;
  height: auto;
  background: ${themeV2.mixins.v2.color.background.white};
  border-radius: 16px;
  padding: ${themeV2.mixins.v2.spacing * 4}px ${themeV2.mixins.v2.spacing * 2}px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: ${themeV2.mixins.v2.spacing * 3}px;
`;
const ButtonSection = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 8px;
  align-items: center;
`;

const MessageSection = styled.div`
  padding: 0 ${themeV2.mixins.v2.spacing * 3}px;
  text-align: center;
`;
const MessageText = styled.p`
  ${themeV2.mixins.v2.typography.label.large};
  white-space: pre-line;
`;
const MessageSubText = styled.p`
  ${themeV2.mixins.v2.typography.body.medium};
  white-space: pre-line;
`;
const EmailSection = styled.div`
  ${themeV2.mixins.v2.typography.body.small};
  width: 100%;
  text-align: center;
`;

const EmailText = styled.div`
  border-top: 1px solid ${themeV3.mixins.v3.color.border.gray};
  border-bottom: 1px solid ${themeV3.mixins.v3.color.border.gray};
  margin: ${themeV2.mixins.v2.spacing * 0.5}px ${themeV2.mixins.v2.spacing * 2}px;
  padding: ${themeV2.mixins.v2.spacing * 1.5}px;
  white-space: pre-wrap;
  word-break: break-all;
`;
const EmailSubText = styled.p`
  display: flex;
  gap: ${themeV2.mixins.v2.spacing * 0.5}px;
  ${themeV2.mixins.v2.typography.body.small};
  color: ${themeV2.mixins.v2.color.font.gray};
  padding: ${themeV2.mixins.v2.spacing}px ${themeV2.mixins.v2.spacing * 1.5}px;
  text-align: left;
`;
const LinkSection = styled.div`
  margin-top: ${themeV2.mixins.v2.spacing * 5}px;
  && {
    color: ${themeV2.mixins.v2.color.font.blue} !important;
  }
`;

const ErrorMessage = styled.div`
  ${themeV2.mixins.v2.typography.body.small};
  color: ${themeV2.mixins.v2.color.font.red};
`;
