import {
  CircularLoader,
  ColgroupV2,
  Component,
  TableV2,
  TbodyV2,
  TdV2,
  TheadV2,
  themeV2,
  ThV2,
  TrV2,
  useSafeCallback,
  useSafeState,
  useUnmountRef
} from '@atomica.co/components';
import {
  BaseDto,
  BillingIdV2,
  BillingLogSortColumn,
  BillingLogV2,
  FETCH_BILLING_LOGS_V2_FOR_ADMIN,
  FetchBillingLogsV2ForAdminRequest,
  FetchBillingLogsV2ForAdminResponse,
  Sort
} from '@atomica.co/irori';
import { Index, Label } from '@atomica.co/types';
import { builder, EMPTY, hasLength } from '@atomica.co/utils';
import { CSSProperties } from '@material-ui/core/styles/withStyles';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import { format } from 'date-fns';
import React, { useEffect } from 'react';
import styled from 'styled-components';
import useCommonRequest from '../../../redux/hooks/useCommonRequest';
import { NO_DATA_TEXT } from '../../../texts/common-text';
import { toFullName } from '../../../utils/user-util';

const TABLE_COLUMNS: TableColumn[] = [
  { label: '更新日時', sortCol: BillingLogSortColumn.CREATED_AT },
  { label: '更新内容' },
  { label: '更新者' }
];

interface TableColumn {
  label: Label;
  sortCol?: BillingLogSortColumn;
}

interface P {
  base: BaseDto;
  sort: Sort;
  sortKey: BillingLogSortColumn;
  billingId: BillingIdV2;
  onClick: (value: BillingLogSortColumn | undefined) => void;
}

const BillingLogs: React.FC<P> = React.memo(props => {
  const { base, sort, sortKey, billingId, onClick } = props;

  const { commonRequest } = useCommonRequest();

  const unmountRef = useUnmountRef();
  const [billingLogs, setBillingLogs] = useSafeState<BillingLogV2[]>(unmountRef, []);

  const [isLoaderShown, setIsLoaderShown] = useSafeState<boolean>(unmountRef, true);

  const initialize = useSafeCallback(async (): Promise<void> => {
    setIsLoaderShown(true);
    const request = builder<FetchBillingLogsV2ForAdminRequest>()
      .baseId(base.baseId)
      .billingId(billingId)
      .sortCol(sortKey ? sortKey : BillingLogSortColumn.CREATED_AT)
      .sort(sort)
      .build();
    const response = await commonRequest<FetchBillingLogsV2ForAdminRequest, FetchBillingLogsV2ForAdminResponse>(
      FETCH_BILLING_LOGS_V2_FOR_ADMIN,
      request
    );
    setBillingLogs(response.billingLogs);
    setIsLoaderShown(false);
  }, [base, billingId, commonRequest, setBillingLogs, setIsLoaderShown, sortKey, sort]);

  useEffect(() => {
    initialize();
  }, [initialize]);

  return (
    <Component className='billing-logs' style={styleForComponent}>
      <Container>
        <TableWrapper>
          <TableV2 shape='circle'>
            <ColgroupV2 width={256} />
            <ColgroupV2 />
            <ColgroupV2 width={160} />
            <TheadV2>
              <TrV2>
                {TABLE_COLUMNS.map((column: TableColumn, idx: number) => (
                  <ThV2 key={idx} onClick={() => onClick(column.sortCol)}>
                    {column.sortCol ? (
                      <SortColumn>
                        {column.label}
                        {column.sortCol === sortKey &&
                          (sort === Sort.ASC ? <CustomArrowDownwardIcon /> : <CustomArrowUpwardIcon />)}
                      </SortColumn>
                    ) : (
                      column.label
                    )}
                  </ThV2>
                ))}
              </TrV2>
            </TheadV2>

            <TbodyV2>
              {hasLength(billingLogs) &&
                billingLogs.map((row: BillingLogV2, index: Index) => (
                  <TrV2 key={`tr${index}`}>
                    <TdV2>{format(row.createdAt!, 'yyyy/MM/dd HH:mm:ss')}</TdV2>
                    <TdV2>{row.operation}</TdV2>
                    <TdV2>{row.createdUser ? toFullName(row.createdUser) : EMPTY}</TdV2>
                  </TrV2>
                ))}
            </TbodyV2>
          </TableV2>

          {!isLoaderShown && !hasLength(billingLogs) && <NoSearchResult>{NO_DATA_TEXT}</NoSearchResult>}

          {isLoaderShown && (
            <LoaderWrapper>
              <CircularLoader />
            </LoaderWrapper>
          )}
        </TableWrapper>
      </Container>
    </Component>
  );
});

BillingLogs.displayName = 'BillingLogs';
export default BillingLogs;

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding-bottom: ${themeV2.mixins.v2.spacing * 10}px;
`;

const TableWrapper = styled.div`
  min-height: 92px;
  display: flex;
  flex-direction: column;
  border-radius: 6px;
  background: ${themeV2.mixins.v2.color.background.white};
  margin: ${themeV2.mixins.v2.spacing * 2}px 0;
`;

const SortColumn = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  cursor: pointer;
  gap: 8px;
`;

const CustomArrowDownwardIcon = styled(ArrowDownwardIcon)`
  ${themeV2.mixins.v2.typography.body.large};
`;

const CustomArrowUpwardIcon = styled(ArrowUpwardIcon)`
  ${themeV2.mixins.v2.typography.body.large};
`;

const LoaderWrapper = styled.div`
  flex: 1;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;
const NoSearchResult = styled.div`
  width: 100%;
  height: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
  ${themeV2.mixins.v2.typography.body.medium};
  color: ${themeV2.mixins.v2.color.font.gray};
  padding: ${themeV2.mixins.v2.spacing * 3}px;
`;

const styleForComponent: CSSProperties = {
  width: '100%',
  height: '100%'
};
