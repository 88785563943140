import {
  ButtonV2,
  DialogV2,
  InputWithLabelV2,
  ScreenLoaderV2,
  themeV2,
  useSafeCallback,
  useSafeState,
  useUnmountRef
} from '@atomica.co/components';
import {
  BaseId,
  EventScheduleGroupV2,
  EventScheduleV2,
  SAVE_EVENT_SCHEDULE_GROUPS_V2_FOR_ADMIN,
  SaveEventScheduleGroupsV2ForAdminRequest,
  SaveEventScheduleGroupsV2ForAdminResponse
} from '@atomica.co/irori';
import { Name, Sequence, Size } from '@atomica.co/types';
import { ONE, ZERO, builder, hasLength, isGreaterThanZero, uuid } from '@atomica.co/utils';
import React, { useMemo } from 'react';
import styled from 'styled-components';
import { toEventScheduleGroupV2 } from '../../../converters/event-schedule-group-v2-converter';
import useCommonRequest from '../../../redux/hooks/useCommonRequest';

interface P {
  baseId: BaseId;
  isOpen: boolean;
  eventScheduleV2: EventScheduleV2;
  groups: EventScheduleGroupV2[];
  onClose(isUpdated: boolean): void;
}

const RegisterIncrementalGroupsModal: React.FC<P> = React.memo(props => {
  const { baseId, isOpen, eventScheduleV2, groups, onClose } = props;
  const unmountRef = useUnmountRef();
  const { commonRequest } = useCommonRequest();
  const [numberOfCreateGroups, setNumberOfCreateGroups] = useSafeState<Size>(unmountRef, ONE);
  const [isScreenLoaderShown, setIsScreenLoaderShown] = useSafeState<boolean>(unmountRef, false);

  const exisitingGroupNames = useMemo<Name[]>(() => {
    if (!hasLength(groups)) return [];
    return groups.map(group => group.name);
  }, [groups]);

  const createUniqueGroupNumber = useSafeCallback(
    (groupNumber: Sequence): Sequence => {
      if (exisitingGroupNames.includes('グループ' + groupNumber)) return createUniqueGroupNumber(groupNumber + ONE);
      return groupNumber;
    },
    [exisitingGroupNames]
  );

  const handleSaveClicked = useSafeCallback(async (): Promise<void> => {
    setIsScreenLoaderShown(true);
    const groupsToSave: EventScheduleGroupV2[] = [];
    for (let i = ZERO; i < numberOfCreateGroups; i += ONE) {
      const groupName = `グループ${createUniqueGroupNumber(i)}`;
      exisitingGroupNames.push(groupName);
      const group = toEventScheduleGroupV2(uuid(), groupName, eventScheduleV2);
      groupsToSave.push(group);
    }

    const request = builder<SaveEventScheduleGroupsV2ForAdminRequest>()
      .baseId(baseId)
      .eventScheduleGroupsV2(groupsToSave)
      .build();
    await commonRequest<SaveEventScheduleGroupsV2ForAdminRequest, SaveEventScheduleGroupsV2ForAdminResponse>(
      SAVE_EVENT_SCHEDULE_GROUPS_V2_FOR_ADMIN,
      request
    );

    onClose(true);
    setIsScreenLoaderShown(false);
  }, [
    baseId,
    eventScheduleV2,
    exisitingGroupNames,
    numberOfCreateGroups,
    commonRequest,
    createUniqueGroupNumber,
    onClose,
    setIsScreenLoaderShown
  ]);

  const handleModalClosed = useSafeCallback((): void => {
    onClose(false);
  }, [onClose]);

  const footerButtons = useMemo<React.ReactNode[]>(() => {
    return [
      <ButtonV2 key='cancelButton' type='default' label='キャンセル' onClick={handleModalClosed} />,
      <ButtonV2
        key='createButton'
        width={105}
        disabled={!isGreaterThanZero(numberOfCreateGroups)}
        type='primary'
        label='作成'
        onClick={handleSaveClicked}
      />
    ];
  }, [handleSaveClicked, handleModalClosed, numberOfCreateGroups]);

  return (
    <DialogV2
      isOpen={isOpen}
      headerLabel='グループを作成'
      buttonsOnTheRight={footerButtons}
      onClose={() => onClose(false)}
      width={480}
    >
      <FormWrapper>
        <InputWithLabelV2
          required={true}
          unit='個'
          align='right'
          text='グループ数'
          value={numberOfCreateGroups}
          inputWidth={84}
          onChange={setNumberOfCreateGroups}
        />
      </FormWrapper>
      <ScreenLoaderV2 loading={isScreenLoaderShown} />
    </DialogV2>
  );
});

RegisterIncrementalGroupsModal.displayName = 'RegisterIncrementalGroupsModal';
export default RegisterIncrementalGroupsModal;

const FormWrapper = styled.div`
  padding: ${themeV2.mixins.v2.spacing * 2}px ${themeV2.mixins.v2.spacing * 3}px;
  background-color: #faf9f7;
`;
