import {
  ContractorType,
  InflowSource,
  InvitationStatus,
  InvoiceDeliveryMethod,
  PaymentMethod,
  PaymentOption,
  SpacePaymentFrequency
} from '@atomica.co/irori';
import { Name, Text } from '@atomica.co/types';
import { EMPTY, isDefAndNotNull } from '@atomica.co/utils';
import { ContractAction } from '../enums/contract-v2-enum';
import { Labels } from '../models/common-model';

export const CONTRACT_CREATED = '新規作成しました';

export const CONTRACT_DELETED = '契約を削除しました';

export const CONTRACT_MODIFIED_DEFAULT = (columnName: Name, itemName?: Name): Text =>
  `${itemName ? `${itemName}の` : EMPTY}${columnName}を変更しました。`;

export const CONTRACT_MODIFIED_DETAIL = (
  columnName: Name,
  prev: string | number | boolean,
  curr: string | number | boolean,
  itemName?: Name
): Text =>
  `${itemName ? `${itemName}の` : EMPTY}${columnName}を${
    isDefAndNotNull(prev) ? `${prev}から` : EMPTY
  }${curr}に変更しました。`;
export const CONTRACT_DETAIL_ADDED = (columnName: Name): Text => `${columnName}を追加しました。`;

export const CONTRACT_DETAIL_DELETED = (columnName: Name): Text => `${columnName}を削除しました。`;

export const CONTRACT_DETAIL_MODIFIED_DEFAULT = (columnName: Name): Text => `${columnName}を追加しました。`;

export const CONTRACT_DETAIL_MODIFIED_DETAIL = (
  columnName: Name,
  prev: string | number | boolean,
  curr: string | number | boolean
): Text => `${columnName}を${prev != null && `${prev}から`}${curr}に変更しました。`;

export const CONTRACTOR_TYPE_LABELS: Labels = {
  [ContractorType.PERSON]: '個人',
  [ContractorType.ENTITY]: '法人'
};

export const PAYMENT_METHOD_LABELS: Labels = {
  [PaymentMethod.CREDIT_CARD]: 'クレジットカード',
  [PaymentMethod.BANK_TRANSFER]: '口座振替',
  [PaymentMethod.INVOICE]: '請求書払い',
  [PaymentMethod.CASH]: '現金'
};

export const SPACE_PAYMENT_FREQUENCY_LABELS: Labels = {
  [SpacePaymentFrequency.MONTHLY_PAYMENT]: 'その他の請求と合算して決済',
  [SpacePaymentFrequency.PAY_PER_USE]: '利用時都度払い'
};

export const PAYMENT_COUNT_LABELS: Labels = {
  [PaymentOption.SINGLE]: '一括払い',
  [PaymentOption.INSTALLMENT]: '分割払い'
};

export const CONTRACT_USER_STATUS_LABELS: Labels = {
  [InvitationStatus.INVITED]: '招待中',
  [InvitationStatus.REGISTERED]: '登録済み',
  [InvitationStatus.DELETED]: '削除済み',
};

export const CONTRACT_INFLOW_SOURCE_LABELS: Labels = {
  [InflowSource.ORGANIC]: '自然流入',
  [InflowSource.DIRECT]: '直営業',
  [InflowSource.SHOT]: 'ショット会員'
};

export const CONTRACT_ACTION_LABEL: Labels = {
  [ContractAction.OPERATE_ALL]: '一括操作',
  [ContractAction.OPEN_SELECTED]: '詳細を確認する'
};

export const INVOICE_DELIVERY_METHOD_LABELS: Labels = {
  [InvoiceDeliveryMethod.NONE]: 'なし',
  [InvoiceDeliveryMethod.EMAIL]: 'メール',
  [InvoiceDeliveryMethod.POST]: '郵送'
};
