import { ButtonV2, CircularLoader, themeV2, useMobile, useSafeCallback } from '@atomica.co/components';
import { BaseDto, UserWishData } from '@atomica.co/irori';
import { Id } from '@atomica.co/types';
import { embedIdInPath, EMPTY, hasLength } from '@atomica.co/utils';
import { Divider, Typography } from '@material-ui/core';
import { Add } from '@material-ui/icons';
import React, { memo, useMemo } from 'react';
import styled from 'styled-components';
import { createCustomization } from '../../../../customization/customizationFactory';
import usePath from '../../../../redux/hooks/usePath';
import { Path, PATH_IDS } from '../../../../router/Routes';

interface Props {
  base: BaseDto;
  userWishes: UserWishData[];
  initialized: boolean;
  userId: Id;
  isSelf?: boolean;
}

const renderUserWish = (userWish: UserWishData, idx: number): JSX.Element => {
  const wishNumber = idx + 1;

  return (
    <Frame>
      <WishNumberWrapper>
        <WishNumber>{wishNumber}</WishNumber>
      </WishNumberWrapper>
      <WishContentWrapper>
        <WishContent>{userWish.content}</WishContent>
      </WishContentWrapper>
    </Frame>
  );
};

const AccountWishCardViewV3: React.FC<Props> = memo(props => {
  const { base, userWishes, initialized, userId, isSelf = true } = props;
  const { openBasePath, openPath } = usePath();
  const isMobile = useMobile();

  const customization = createCustomization(base.baseCode);
  const replacedWishWord = useMemo(() => {
    return customization.replaceWithMapping('Wish');
  }, [customization]);

  const hasWishes = useMemo<boolean>(() => hasLength(userWishes), [userWishes]);
  const isRegisterButtonShown = useMemo<boolean>(() => isSelf && userWishes.length < 3, [isSelf, userWishes.length]);

  const translationList = useSafeCallback(() => {
    openPath(embedIdInPath(Path.ACCOUNT_WISH_LIST, PATH_IDS, [base.baseCode, userId]));
  }, [openBasePath, openPath, base.baseCode, userId]);

  return (
    <Card>
      <SectionTitle data-testid='account-wish-title'>
        <TitleWrapper>
          <Title>
            {isSelf && 'あなたの'}
            {`${replacedWishWord}リスト(β版)`}
          </Title>
          <ButtonV2 width={40} type='secondary' label='一覧' onClick={translationList} />
        </TitleWrapper>
        <StyledDivider variant='fullWidth' flexItem />
      </SectionTitle>

      {!initialized && (
        <LoaderWrapper>
          <CircularLoader />
        </LoaderWrapper>
      )}
      {initialized && (
        <ContentWrapper isMobile={isMobile}>
          {!hasWishes && <Message> {`${replacedWishWord}はまだありません。`}</Message>}
          {hasWishes && userWishes.map(renderUserWish)}
          {isRegisterButtonShown && (
            <ButtonV2
              type='secondary'
              isFullWidth
              size='large'
              startIcon={<Add />}
              label='新規作成'
              onClick={() => openBasePath(Path.REGISTER_ACCOUNT_WISH)}
            />
          )}
        </ContentWrapper>
      )}
    </Card>
  );
});
AccountWishCardViewV3.displayName = 'AccountWishCardViewV3';
export default AccountWishCardViewV3;

const Card = styled.div`
  display: flex;
  width: 100%;
  padding: ${themeV2.mixins.v2.spacing * 2}px;
  flex-direction: column;
  align-items: flex-start;
  gap: ${themeV2.mixins.v2.spacing * 2}px;

  background: ${themeV2.mixins.v2.color.background.white};
  border-radius: ${themeV2.mixins.v2.spacing}px;
`;

const SectionTitle = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: flex-start;
  gap: ${themeV2.mixins.v2.spacing}px;
  flex-shrink: 0;
`;

const TitleWrapper = styled.div`
  width: 100%;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const ContentWrapper = styled.div<{ isMobile: boolean }>`
  display: flex;
  align-items: start;
  gap: 16px;
  flex-direction: column;
  width: 100%;
  ${({ isMobile }) => (isMobile ? EMPTY : `padding: 0 ${themeV2.mixins.v2.spacing * 4}px`)};
`;

const Title = styled(Typography)`
  ${themeV2.mixins.v2.typography.title.large};
  color: ${themeV2.mixins.v2.color.font.black};
`;

const StyledDivider = styled(Divider)`
  border: 1px solid ${themeV2.mixins.v2.color.border.gray};
`;

const Message = styled.span`
  ${themeV2.mixins.v2.typography.body.medium};
`;

const Frame = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  gap: 8px;
  align-self: stretch;
`;

const WishNumberWrapper = styled.div`
  display: flex;
  width: 24px;
  height: 24px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 4px;
  background: ${themeV2.mixins.v2.color.background.yellow};
`;

const WishNumber = styled(Typography)`
  ${themeV2.mixins.v2.typography.title.small};
  color: ${themeV2.mixins.v2.color.font.black};
  text-align: center;
`;

const WishContentWrapper = styled.div`
  width: 100%;
  min-height: 74px;
  padding: 16px;
  gap: 8px;
  align-content: center;
  border-radius: 8px;
  background: ${themeV2.mixins.v2.color.background.offWhite};
`;

const WishContent = styled(Typography)`
  ${themeV2.mixins.v2.typography.body.medium};
  color: ${themeV2.mixins.v2.color.font.black};

  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  word-break: break-all;
`;

const LoaderWrapper = styled.div`
  width: 100%;
  height: 120px;
  display: flex;
  justify-content: center;
  align-items: center;
`;
