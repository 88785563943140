import {
  ButtonV2,
  DialogV2,
  PullDownOption,
  PullDownV2,
  RadioBoxV2,
  ScreenLoaderV2,
  themeV2,
  useSafeCallback,
  useSafeState,
  useUnmountRef
} from '@atomica.co/components';
import {
  BaseId,
  DELETE_EVENT_SCHEDULE_GROUP_V2_FOR_ADMIN,
  DeleteEventScheduleGroupV2ForAdminRequest,
  DeleteEventScheduleGroupV2ForAdminResponse,
  EventScheduleGroupV2,
  EventScheduleParticipantV2,
  EventScheduleV2,
  SAVE_EVENT_SCHEDULE_PARTICIPANTS_V2_FOR_ADMIN,
  SEARCH_EVENT_SCHEDULE_GROUPS_V2_FOR_ADMIN,
  SaveEventScheduleParticipantsV2ForAdminRequest,
  SaveEventScheduleParticipantsV2ForAdminResponse,
  SearchEventScheduleGroupsV2ForAdminRequest,
  SearchEventScheduleGroupsV2ForAdminResponse
} from '@atomica.co/irori';
import { Count, Id } from '@atomica.co/types';
import { EMPTY, ZERO, builder, hasLength, isEmpty, isGreaterThanZero } from '@atomica.co/utils';
import React, { useEffect, useMemo } from 'react';
import styled from 'styled-components';
import { AssignMethod } from '../../../enums/event-v2-enum';
import useCommonRequest from '../../../redux/hooks/useCommonRequest';
import { HOW_TO_ASSIGN_PARTICIPANTS } from '../../../texts/messages-text';

interface P {
  baseId: BaseId;
  isOpen: boolean;
  eventScheduleV2: EventScheduleV2;
  selectedGroup?: EventScheduleGroupV2;
  onClose(isUpdated: boolean): void;
}

const DeleteGroupModal: React.FC<P> = React.memo(props => {
  const { isOpen, baseId, eventScheduleV2, selectedGroup, onClose } = props;
  const unmountRef = useUnmountRef();
  const [assignMethod, setAssignMethod] = useSafeState<AssignMethod>(unmountRef, AssignMethod.REVERT);
  const [eventScheduleGroups, setEventScheduleGroups] = useSafeState<EventScheduleGroupV2[]>(unmountRef, []);
  const [selectedId, setSelectedId] = useSafeState<Id>(unmountRef, EMPTY);
  const [isScreenLoaderShown, setIsScreenLoaderShown] = useSafeState<boolean>(unmountRef, false);
  const { commonRequest } = useCommonRequest();

  const isDisabled = useMemo<boolean>(
    () => assignMethod === AssignMethod.CHANGE && isEmpty(selectedId),
    [assignMethod, selectedId]
  );

  const participants = useMemo<EventScheduleParticipantV2[]>(() => selectedGroup?.participants || [], [selectedGroup]);

  const pullDownOptions = useMemo<PullDownOption[]>(() => {
    if (!selectedGroup) return [];
    return eventScheduleGroups
      .filter(group => group.eventScheduleGroupId !== selectedGroup.eventScheduleGroupId)
      .map(group => builder<PullDownOption>().id(group.eventScheduleGroupId).label(group.name).build());
  }, [eventScheduleGroups, selectedGroup]);

  const fetchGroups = useSafeCallback(async (): Promise<void> => {
    const request = builder<SearchEventScheduleGroupsV2ForAdminRequest>()
      .baseId(baseId)
      .eventScheduleIdV2(eventScheduleV2.eventScheduleId)
      .word(EMPTY)
      .build();

    const response = await commonRequest<
      SearchEventScheduleGroupsV2ForAdminRequest,
      SearchEventScheduleGroupsV2ForAdminResponse
    >(SEARCH_EVENT_SCHEDULE_GROUPS_V2_FOR_ADMIN, request);
    setEventScheduleGroups(response.eventScheduleGroupsV2);
  }, [baseId, commonRequest, eventScheduleV2, setEventScheduleGroups]);

  const deleteGroup = useSafeCallback(async (): Promise<void> => {
    if (!selectedGroup) return;
    setIsScreenLoaderShown(true);
    const requestToDeleteGroup = builder<DeleteEventScheduleGroupV2ForAdminRequest>()
      .baseId(baseId)
      .groupToDelete(selectedGroup)
      .build();
    await commonRequest<DeleteEventScheduleGroupV2ForAdminRequest, DeleteEventScheduleGroupV2ForAdminResponse>(
      DELETE_EVENT_SCHEDULE_GROUP_V2_FOR_ADMIN,
      requestToDeleteGroup
    );

    if (assignMethod === AssignMethod.CHANGE) {
      const participantsToSave = participants.map(participant =>
        builder<EventScheduleParticipantV2>()
          .eventScheduleParticipantId(participant.eventScheduleParticipantId)
          .eventSchedule(selectedGroup.eventSchedule)
          .user(participant.user)
          .eventGroup(eventScheduleGroups.find(group => group.eventScheduleGroupId === selectedId)!)
          .build()
      );
      const requestToSaveParticipant = builder<SaveEventScheduleParticipantsV2ForAdminRequest>()
        .baseId(baseId)
        .eventScheduleParticipantsV2(participantsToSave)
        .build();
      await commonRequest<
        SaveEventScheduleParticipantsV2ForAdminRequest,
        SaveEventScheduleParticipantsV2ForAdminResponse
      >(SAVE_EVENT_SCHEDULE_PARTICIPANTS_V2_FOR_ADMIN, requestToSaveParticipant);
    }
    setIsScreenLoaderShown(false);
    onClose(true);
  }, [
    assignMethod,
    baseId,
    commonRequest,
    eventScheduleGroups,
    onClose,
    participants,
    selectedGroup,
    selectedId,
    setIsScreenLoaderShown
  ]);

  const handleModalClosed = useSafeCallback((): void => {
    onClose(false);
  }, [onClose]);

  const footerButtons = useMemo<React.ReactNode[]>(() => {
    return [
      <ButtonV2 key='cancelButton' label='キャンセル' onClick={handleModalClosed} />,
      <ButtonV2 key='saveButton' width={105} type='primary' disabled={isDisabled} label='保存' onClick={deleteGroup} />
    ];
  }, [isDisabled, handleModalClosed, deleteGroup]);

  const countForParticipants = useMemo<Count>(() => {
    return hasLength(participants) ? participants.length : ZERO;
  }, [participants]);

  const initialize = useSafeCallback(async (): Promise<void> => {
    await fetchGroups();
  }, [fetchGroups]);

  useEffect(() => {
    initialize();
  }, [initialize]);

  return (
    <DialogV2
      isOpen={isOpen}
      headerLabel='参加者の割り当て先を選択してください'
      buttonsOnTheRight={footerButtons}
      onClose={() => onClose(false)}
    >
      <FormWrapper>
        {isGreaterThanZero(countForParticipants) && (
          <MessageWrapper>
            {countForParticipants}名の参加者が含まれています。割当先を選択して、グループを削除してください。
          </MessageWrapper>
        )}

        <RadioBoxV2
          direction='column'
          placeholder='参加者の割り当て方法'
          labels={HOW_TO_ASSIGN_PARTICIPANTS}
          options={Object.values(AssignMethod)}
          disabledOptions={!hasLength(pullDownOptions) ? [AssignMethod.CHANGE] : undefined}
          value={assignMethod}
          onChange={setAssignMethod}
        />

        {assignMethod === AssignMethod.CHANGE && (
          <PullDownWrapper>
            <PullDownV2 nullable id={selectedId} placeholder='選択' options={pullDownOptions} onClick={setSelectedId} />
          </PullDownWrapper>
        )}
      </FormWrapper>
      <ScreenLoaderV2 loading={isScreenLoaderShown} />
    </DialogV2>
  );
});

DeleteGroupModal.displayName = 'DeleteGroupModal';
export default DeleteGroupModal;

const FormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${themeV2.mixins.v2.spacing * 2}px;
  padding: ${themeV2.mixins.v2.spacing * 2}px ${themeV2.mixins.v2.spacing * 3}px;
  background-color: #faf9f7;
`;
const MessageWrapper = styled.div`
  ${themeV2.mixins.v2.typography.body.large}
`;

const PullDownWrapper = styled.div`
  width: 500px;
  height: auto;
`;
