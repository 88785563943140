import {
  ButtonV2,
  TabProperty,
  TabsV3,
  TextFieldV2,
  themeV2,
  useSafeCallback,
  useSafeState,
  useUnmountRef
} from '@atomica.co/components';
import {
  BaseDto,
  EventScheduleV2,
  SEARCH_EVENT_REGULAR_PARTICIPANTS_V2_FOR_ADMIN,
  SearchEventRegularParticipantsV2ForAdminRequest,
  SearchEventRegularParticipantsV2ForAdminResponse
} from '@atomica.co/irori';
import { Index, UserId, Word } from '@atomica.co/types';
import { EMPTY, ONE, ZERO, builder, hasLength } from '@atomica.co/utils';
import { Add } from '@material-ui/icons';
import React, { useEffect } from 'react';
import styled from 'styled-components';
import useCommonRequest from '../../../redux/hooks/useCommonRequest';
import { EVENT_SCHEDULE_PARTICIPANT_TAB_LABELS } from '../../../texts/event-text';
import RegisterGroupModal from '../modal/RegisterGroupModal';
import GroupedParticipantList from './grouped-participant-list/GroupedParticipantList';
import ParticipantList from './participant-list/ParticipantList';

interface P {
  base: BaseDto;
  eventSchedule: EventScheduleV2;
}

const tabProperties = Object.values(EVENT_SCHEDULE_PARTICIPANT_TAB_LABELS).map<TabProperty>(label => ({
  label,
  disabled: false
}));

const ParticipantsV2Menu: React.FC<P> = React.memo(props => {
  const { base, eventSchedule } = props;
  const { commonRequest } = useCommonRequest();

  const unmountRef = useUnmountRef();
  const [selectedTabIdx, setSelectedTabIdx] = useSafeState<Index>(unmountRef, ZERO);
  const [searchingWord, setSearchingWord] = useSafeState<Word>(unmountRef, EMPTY);
  const [isRegisterGroupModalOpen, setIsRegisterGroupModalOpen] = useSafeState<boolean>(unmountRef, false);
  const [reloadQueue, setReloadQueue] = useSafeState<boolean>(unmountRef, false);
  const [regularUserIds, setRegularUserIds] = useSafeState<UserId[]>(unmountRef, []);
  const [isGroupExisted, setIsGroupExisted] = useSafeState<boolean>(unmountRef, false);

  const reload = useSafeCallback(() => {
    setReloadQueue(reloadQueue => !reloadQueue);
  }, [setReloadQueue]);

  const initialize = useSafeCallback(async (): Promise<void> => {
    const participantRequest = builder<SearchEventRegularParticipantsV2ForAdminRequest>()
      .baseId(base.baseId)
      .eventIdV2(eventSchedule.eventV2!.eventId)
      .offset(ZERO)
      .word(EMPTY)
      .build();

    const participantResponse = await commonRequest<
      SearchEventRegularParticipantsV2ForAdminRequest,
      SearchEventRegularParticipantsV2ForAdminResponse
    >(SEARCH_EVENT_REGULAR_PARTICIPANTS_V2_FOR_ADMIN, participantRequest);

    const { eventRegularParticipantsV2 } = participantResponse;
    const userIds = eventRegularParticipantsV2.map(participant => participant.user!.userId);

    setRegularUserIds(userIds);
  }, [base.baseId, commonRequest, eventSchedule, setRegularUserIds]);

  useEffect(() => {
    initialize();
  }, [initialize]);

  return (
    <Container>
      <Content>
        <ContentHeader>
          <TabWrapper>
            <TabsV3
              tabs={tabProperties}
              variant='outline'
              selectedTabIdx={selectedTabIdx}
              onChange={setSelectedTabIdx}
            />
          </TabWrapper>
          {selectedTabIdx === ONE && isGroupExisted && (
            <ComponentWrapper>
              <InputWrapper>
                <TextFieldV2 placeholder='グループ名で検索' value={searchingWord} onChange={setSearchingWord} />
              </InputWrapper>
              <ButtonV2
                type='secondary'
                startIcon={<Add />}
                label='グループを追加'
                onClick={() => setIsRegisterGroupModalOpen(true)}
              />
            </ComponentWrapper>
          )}
        </ContentHeader>
        <RegisterGroupModal
          baseId={base.baseId}
          isOpen={isRegisterGroupModalOpen}
          eventScheduleV2={eventSchedule}
          mode='register'
          onChange={reload}
          onClose={isUpdated => setIsRegisterGroupModalOpen(isUpdated)}
        />
        {selectedTabIdx === ZERO && (
          <ParticipantList
            baseCode={base.baseCode}
            baseId={base.baseId}
            eventSchedule={eventSchedule}
            regularUserIds={regularUserIds}
          />
        )}
        {selectedTabIdx === ONE && (
          <GroupedParticipantList
            baseCode={base.baseCode}
            baseId={base.baseId}
            regularUserIds={regularUserIds}
            searchingWord={searchingWord}
            reloadQueue={reloadQueue}
            handleGroupsLoaded={groups => setIsGroupExisted(hasLength(groups))}
          />
        )}
      </Content>
    </Container>
  );
});

ParticipantsV2Menu.displayName = 'ParticipantsV2Menu';
export default ParticipantsV2Menu;

const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
`;

const Content = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  scrollbar-width: none;
  -ms-overflow-style: none;
  ::-webkit-scrollbar {
    display: none;
  }
  background-color: ${themeV2.mixins.v2.color.background.yellow};
`;
const ContentHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: sticky;
  top: 0;
  background-color: ${themeV2.mixins.v2.color.background.yellow};
  z-index: 11;
  padding-top: ${themeV2.mixins.v2.spacing * 2}px;
`;
const TabWrapper = styled.div`
  flex-grow: 1;
`;

const ComponentWrapper = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  gap: 16px;
`;

const InputWrapper = styled.div`
  width: 296px;
`;
