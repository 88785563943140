import { ButtonV2, themeV2, useSafeCallback } from '@atomica.co/components';
import liff from '@line/liff';
import React from 'react';
import styled from 'styled-components';
import { isInLineClient } from '../../../line';
import { PRODUCTION_WORKHUB_PASS_URL, STAGING_WORKHUB_PASS_URL } from '../../../constants/account-const';
import usePath from '../../../redux/hooks/usePath';
import { Path } from '../../../router/Routes';
import { EnvDef } from '@atomica.co/utils';
import env from '../../../env/env';

const openWorkhubPass = (): void => {
  switch (env) {
    case EnvDef.LOCAL:
    case EnvDef.TEST:
    case EnvDef.DEVELOPMENT:
    case EnvDef.STAGING:
      window.open(STAGING_WORKHUB_PASS_URL);
      break;
    case EnvDef.PRODUCTION:
      window.open(PRODUCTION_WORKHUB_PASS_URL);
  }
};

const closeLiff = (): void => {
  liff.closeWindow();
};

const RegisterAccountCompletion: React.FC = React.memo(() => {
  const { openBasePath } = usePath();

  const openAccountProfileScreen = useSafeCallback((): void => {
    openBasePath(Path.ACCOUNT_PROFILE);
  }, [openBasePath]);

  return (
    <Container>
      <Content>
        <Heading>登録が完了しました。</Heading>
        <Message>
          スペースを予約する場合は「続けて予約に進む」
          <br />
          からworkhub Pass に遷移します。
        </Message>
        <StyledButton type='primary' label='続けて予約に進む（workhub Pass）' onClick={openWorkhubPass} />
        <StyledButton type='primary' label='プロフィール画面に進む（knotPLACE）' onClick={openAccountProfileScreen} />
        {isInLineClient() && <StyledButton label='閉じる' onClick={closeLiff} />}
      </Content>
    </Container>
  );
});

RegisterAccountCompletion.displayName = 'RegisterAccountCompletion';
export default RegisterAccountCompletion;

const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: ${themeV2.mixins.v2.spacing * 2}px;
`;

const Heading = styled.div`
  ${themeV2.mixins.v2.typography.title.large};
  color: ${themeV2.mixins.v2.color.font.black};
`;

const Message = styled.div`
  ${themeV2.mixins.v2.typography.body.large};
  color: ${themeV2.mixins.v2.color.font.black};
  padding-bottom: ${themeV2.mixins.v2.spacing}px;
  text-align: center;
`;

const StyledButton = styled(ButtonV2).attrs(() => ({ isFullWidth: true, size: 'large' }))``;
