import {
  ButtonV2,
  MOBILE_MAX_WIDTH,
  MOBILE_MIN_WIDTH,
  themeV2,
  useSafeCallback,
  useSafeState,
  useUnmountRef
} from '@atomica.co/components';
import {
  BaseFunctionToggleCode,
  FETCH_BASE,
  FetchBaseRequest,
  FetchBaseResponse,
  User,
  isBaseFunctionToggleEnabled,
  toLiffUrl
} from '@atomica.co/irori';
import { Code, URL as URLStr } from '@atomica.co/types';
import { EMPTY, ONE, REDIRECT_PATH, builder } from '@atomica.co/utils';
import { Typography } from '@material-ui/core';
import React, { useEffect, useMemo } from 'react';
import { RouteComponentProps } from 'react-router';
import styled from 'styled-components';
import Screen from '../../components/screen/Screen';
import env from '../../env/env';
import CommonRequest from '../../requests/common-request';
import { convertToBrowserUrlFromLiffUrl } from '../../utils/url-util';
import mojaco from './../../assets/mojaco/mojaco_bow.png';

const BASE_CODE_INDEX = 2;
const redirectURL = window.location.search.substring(1).replace(`${REDIRECT_PATH}=`, EMPTY);
const match = redirectURL.match(/app\/([0-9A-Za-z-]+)\?/);
const lineLiffId = match ? match[ONE] : undefined;

interface P extends RouteComponentProps {
  user: User | undefined;
}

const RedirectToLineScreen: React.FC<P> = React.memo(() => {
  const unmountRef = useUnmountRef();
  const [loaded, setLoaded] = useSafeState<boolean>(unmountRef, false);

  const isToLine = useMemo<boolean>(() => redirectURL.includes(toLiffUrl(env, lineLiffId)), []);
  const browserUrlToOpen = useMemo<URLStr>(() => convertToBrowserUrlFromLiffUrl(redirectURL, lineLiffId), []);
  const baseCode = useMemo<Code>(() => {
    return new URL(browserUrlToOpen).pathname.split('/')[BASE_CODE_INDEX];
  }, [browserUrlToOpen]);

  const openLine = useSafeCallback((): void => {
    window.location.href = redirectURL;
  }, []);

  const openBrowser = useSafeCallback((): void => {
    window.location.href = browserUrlToOpen;
  }, [browserUrlToOpen]);

  const initialize = useSafeCallback(async (): Promise<void> => {
    if (!baseCode) return;
    const request = builder<FetchBaseRequest>().baseCode(baseCode).build();
    const { base } = await CommonRequest.call<FetchBaseRequest, FetchBaseResponse>(FETCH_BASE, request);
    const forceBrowser = isBaseFunctionToggleEnabled(base, BaseFunctionToggleCode.FUNCTION_FORCE_BROWSER);

    if (forceBrowser) {
      openBrowser();
      return;
    }

    openLine();
    setLoaded(true);
  }, [baseCode, openBrowser, openLine, setLoaded]);

  useEffect(() => {
    initialize();
  }, [initialize]);

  return (
    <Screen className='redirect-to-line-screen' loading={!loaded}>
      <Container>
        <Content>
          <MessageWrapper>
            <MainMessage>{`${isToLine ? 'LINEを' : 'ブラウザで'}開こうとしています...`}</MainMessage>
            {isToLine && (
              <>
                <br />
                <SubMessage>画面が切り替わらない場合は</SubMessage>
                <SubMessage>LINEがインストールされているか</SubMessage>
                <SubMessage>ご確認ください</SubMessage>
              </>
            )}
          </MessageWrapper>

          <Mojaco src={mojaco} />
          {isToLine && (
            <ButtonV2
              isFullWidth
              type='tertiary'
              label='LINEがインストールされていない場合はこちら'
              onClick={openBrowser}
            />
          )}
        </Content>
      </Container>
    </Screen>
  );
});

RedirectToLineScreen.displayName = 'RedirectToLineScreen';
export default RedirectToLineScreen;

const Container = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;

  @supports (height: 1dvh) {
    height: 100dvh;
  }
`;

const Content = styled.div`
  width: 100vw;
  min-width: ${MOBILE_MIN_WIDTH}px;
  max-width: ${MOBILE_MAX_WIDTH}px;
  height: 100%;
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
  gap: ${themeV2.mixins.v2.spacing * 2}px;
  padding: ${themeV2.mixins.v2.spacing * 3}px ${themeV2.mixins.v2.spacing * 4}px;
`;

const MessageWrapper = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  flex-flow: column;
  justify-content: center;
  padding: ${themeV2.mixins.v2.spacing * 3}px ${themeV2.mixins.v2.spacing}px;
`;

const MainMessage = styled(Typography)`
  ${themeV2.mixins.v2.typography.title.large};
  text-align: center;
  user-select: none;
`;

const SubMessage = styled(Typography)`
  ${themeV2.mixins.v2.typography.body.large};
  text-align: center;
  user-select: none;
`;

const Mojaco = styled.img`
  width: 100%;
  height: auto;
  object-fit: cover;
  user-select: none;
  padding: ${themeV2.mixins.v2.spacing}px;
`;
