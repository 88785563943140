import { LabelV2, PageHeaderV2, themeV2 } from '@atomica.co/components';
import { EventScheduleParticipantV2, EventScheduleV2, EventV2, toFullName } from '@atomica.co/irori';
import { Label } from '@atomica.co/types';
import { ZERO, builder, hasLength, isNull, isUndefined } from '@atomica.co/utils';
import React from 'react';
import styled from 'styled-components';
import mojaco from '../../../../assets/mojaco/mojaco_break.png';
import ListV2, { ListRow } from '../../../../components/list/List';

interface P {
  event: EventV2;
  eventSchedule: EventScheduleV2;
  appliedParticipants: EventScheduleParticipantV2[];
  interestedParticipants: EventScheduleParticipantV2[];
}

const participantList = (participants: EventScheduleParticipantV2[], label: Label): JSX.Element => {
  if (!hasLength(participants)) return <></>;
  return (
    <>
      <StyledTitle>
        {participants.length || ZERO}名の{label}
      </StyledTitle>
      <ListV2
        rows={participants.map(participant =>
          builder<ListRow>()
            .icon(participant.user?.photoURL || mojaco)
            .text(toFullName(participant.user))
            .subText(participant.messageForParticipants)
            .build()
        )}
      />
    </>
  );
};

const AccountEventParticipants: React.FC<P> = React.memo(props => {
  const { event, eventSchedule, appliedParticipants, interestedParticipants } = props;

  return (
    <Container>
      <Content>
        <HeaderWrapper>
          <PageHeaderV2 title={`${event.name} ${eventSchedule.name}の参加者`} />
        </HeaderWrapper>
        <CardWrapper>
          <CardRow>
            <LabelWrapper>
              <LabelV2 text='定員' fontSize='large' />
            </LabelWrapper>
            <Text>
              {isNull(eventSchedule.capacity) || isUndefined(eventSchedule.capacity)
                ? '定員なし'
                : `${eventSchedule.capacity}名`}
            </Text>
          </CardRow>
          <Border />
          <CardRow>
            <LabelWrapper>
              <LabelV2 text='参加者' fontSize='large' />
            </LabelWrapper>
            <Text>{appliedParticipants.length || ZERO}名</Text>
          </CardRow>
          <Border />
          <CardRow>
            <LabelWrapper>
              <LabelV2 text='興味あり' fontSize='large' />
            </LabelWrapper>
            <Text>{interestedParticipants.length || ZERO}名</Text>
          </CardRow>
        </CardWrapper>
        {participantList(appliedParticipants, '参加者')}
        {participantList(interestedParticipants, '「興味あり」ユーザー')}
      </Content>
    </Container>
  );
});

AccountEventParticipants.displayName = 'AccountEventParticipants';
export default AccountEventParticipants;

const Container = styled.div`
  width: 100%;
  height: 100%;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: start;
  padding: 0 ${themeV2.mixins.v2.spacing}px;
  gap: 16px;
`;

const HeaderWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: ${themeV2.mixins.v2.spacing * 2}px ${themeV2.mixins.v2.spacing}px ${themeV2.mixins.v2.spacing}px;
`;

const CardWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: ${themeV2.mixins.v2.spacing * 2}px;
  background: ${themeV2.mixins.v2.color.background.white};
  border-radius: 12px;
`;

const CardRow = styled.div`
  display: flex;
  gap: 8px;
`;

const LabelWrapper = styled.div`
  width: 72px;
`;

const Text = styled.div`
  ${themeV2.mixins.v2.typography.body.large};
`;

const Border = styled.div`
  border-top: 1px dashed ${themeV2.mixins.v2.color.border.gray};
  margin: ${themeV2.mixins.v2.spacing * 2}px 0;
`;

const StyledTitle = styled.div`
  ${themeV2.mixins.v2.typography.title.large};
  margin: ${themeV2.mixins.v2.spacing}px ${themeV2.mixins.v2.spacing}px 0px;
`;
