import { ErrorCode } from '@atomica.co/irori';
import { Labels } from '../models/common-model';

export const ERROR_MESSAGES: Labels = {
  /** common */
  [ErrorCode.INVALID_EMAIL]: 'このメールアドレスは利用できません',

  /** createUserWithEmailAndPassword */
  [ErrorCode.EMAIL_ALREADY_IN_USE]: 'このメールアドレスは登録されています',
  [ErrorCode.OPERATION_NOT_ALLOWED]: 'サポートチームに問い合わせください',
  [ErrorCode.WEAK_PASSWORD]: '6文字以上のパスワードを入力してください',

  /** signInWithEmailAndPassword */
  [ErrorCode.USER_DISABLED]: 'サポートチームに問い合わせください',
  [ErrorCode.USER_NOT_FOUND]: 'アカウントが見つかりませんでした',
  [ErrorCode.WRONG_PASSWORD]: 'パスワードが正しくありません',

  /** LINE */
  [ErrorCode.EMAIL_NOT_FOUND]: 'LINEにメールアドレスが登録されていません',
  [ErrorCode.EMAIL_IMPROPERLY_FORMATTED]: 'LINEに登録のメールアドレスはご利用いただけません',
  [ErrorCode.LINE_API_AGUMENT_ERROR]: 'システム管理者にお問い合わせください',
  [ErrorCode.LINE_API_NETWORK_ERROR]:
    'ネットワーク接続に問題が発生しました\\nインターネット接続を確認し、しばらく待ってから再度お試しください',
  [ErrorCode.LINE_API_TIMEOUT_EXCCEEDE]:
    'ネットワーク接続に問題が発生しました\\nインターネット接続を確認し、しばらく待ってから再度お試しください',

  /** Others */
  [ErrorCode.SYSTEM_ERROR_ONE]: 'システム管理者にお問い合わせください[エラーコード01]',
  [ErrorCode.SYSTEM_ERROR_TWO]: 'システム管理者にお問い合わせください[エラーコード02]',
  [ErrorCode.SYSTEM_ERROR_THREE]: 'システム管理者にお問い合わせください[エラーコード03]',
  [ErrorCode.SYSTEM_ERROR_FOUR]: 'システム管理者にお問い合わせください[エラーコード04]',
  [ErrorCode.SYSTEM_ERROR_FIVE]: 'システム管理者にお問い合わせください[エラーコード05]',
  [ErrorCode.SYSTEM_ERROR_SIX]: 'システム管理者にお問い合わせください[エラーコード06]',
  [ErrorCode.SYSTEM_ERROR_SEVEN]: 'システム管理者にお問い合わせください[エラーコード07]',
  [ErrorCode.SYSTEM_ERROR_EIGHT]: 'システム管理者にお問い合わせください[エラーコード08]',
  [ErrorCode.SYSTEM_ERROR_NINE]: 'システム管理者にお問い合わせください[エラーコード09]',
  [ErrorCode.SYSTEM_ERROR_TEN]: 'システム管理者にお問い合わせください[エラーコード10]',
  [ErrorCode.SYSTEM_ERROR]: 'システム管理者にお問い合わせください',

  /** Others Error Detail */
  [ErrorCode.INVALID_GRANT]: 'システム管理者にお問い合わせください',
  [ErrorCode.FUNCTION_INTERNAL]: 'システム管理者にお問い合わせください',
  [ErrorCode.INTERNAL]: 'システム管理者にお問い合わせください',
  [ErrorCode.AUTH_ARGUMENT_ERROR]:
    'ネットワーク接続に問題が発生しました\\nインターネット接続を確認し、しばらく待ってから再度お試しください',
  [ErrorCode.AUTH_USER_CANCELLED]:
    'ネットワーク接続に問題が発生しました\\nインターネット接続を確認し、しばらく待ってから再度お試しください',
  [ErrorCode.AUTH_TIME_OUT]:
    'ネットワーク接続に問題が発生しました\\nインターネット接続を確認し、しばらく待ってから再度お試しください',
  [ErrorCode.AUTH_NETWORK_REQUEST_FAILED]:
    'ネットワーク接続に問題が発生しました\\nインターネット接続を確認し、しばらく待ってから再度お試しください',
  [ErrorCode.AUTH_INVALID_CREDENTIAL]:
    'ネットワーク接続に問題が発生しました\\nインターネット接続を確認し、しばらく待ってから再度お試しください',
  [ErrorCode.AUTH_ACCOUNT_EXISTS_WITH_DIFFERENT_CREDENTIAL]: 'システム管理者にお問い合わせください',
  [ErrorCode.AUTH_INVALID_SESSION_ID]:
    'ネットワーク接続に問題が発生しました\\nインターネット接続を確認し、しばらく待ってから再度お試しください',

  /** Re-authentication Required */
  [ErrorCode.LOGIN_ACCESS_EXPIRED]: 'ログインに失敗しました\\nお手数ですが、もう一度ご確認の上お試しください'
};
