import React, { useMemo } from 'react';
import Screen from '../../../components/screen/Screen';
import useBase from '../../../redux/hooks/useBase';
import usePath from '../../../redux/hooks/usePath';
import useUser from '../../../redux/hooks/useUser';
import { Path } from '../../../router/Routes';
import RegisterAccountScreenV2 from '../../account/RegisterAccountScreenV2';
import RegisterAccountScreenV3 from '../../account/RegisterAccountScreenV3';
import ConsumerScreen from '../../consumer/ConsumerScreen';
import JoinScreen from '../../join/JoinScreen';
import LiffScreen from '../../liff/LiffScreen';
import RedirectToLineScreen from '../../redirect/RedirectToLineScreen';
import SignOutScreen from '../../sign-out/SignOutScreen';
import SignUpInScreen from '../../sign-up-in/SignUpInScreen';
import StepVerificationScreen from '../../verification/StepVerificationScreen';

const NoGuardedScreens: React.FC = React.memo(() => {
  const { path } = usePath();
  const { loaded: baseLoaded, base } = useBase();
  const { loaded: userLoaded, user } = useUser();

  const screen = useMemo<React.ReactElement | undefined>(() => {
    if (!baseLoaded || !userLoaded) return;

    switch (path) {
      case Path.REDIRECT:
        return <RedirectToLineScreen user={user} />;

      case Path.LIFF:
        return <LiffScreen user={user} />;

      case Path.JOIN:
        return base ? <JoinScreen base={base} user={user} /> : undefined;

      case Path.SIGN_UP:
      case Path.SIGN_IN:
        return base ? <SignUpInScreen base={base} user={user} /> : undefined;

      case Path.REGISTER_ACCOUNT:
        return base ? <RegisterAccountScreenV3 base={base} user={user} /> : undefined;

      case Path.REGISTER_ACCOUNT_V2:
        return base ? <RegisterAccountScreenV2 base={base} user={user} /> : undefined;

      case Path.ACCOUNT_EVENTS_NO_GUARDED:
      case Path.RESERVE_SPACE_NO_GUARDED:
        return base ? <ConsumerScreen isDrawerOpen={false} base={base} user={user} /> : undefined;

      case Path.ACCOUNT_EVENT_DEATIL:
      case Path.ACCOUNT_EVENT_PARTICIPANTS:
        return base ? <ConsumerScreen isDrawerOpen={false} base={base} user={user} /> : undefined;

      case Path.COMPLETED_REGISTER_CREDIT_CARD:
        return base ? <ConsumerScreen isDrawerOpen={false} base={base} user={user} /> : undefined;

      case Path.STEP_VERIFICATION:
        return base ? <StepVerificationScreen base={base} user={user} /> : undefined;

      case Path.SIGN_OUT:
        return base ? <SignOutScreen base={base} user={user} /> : undefined;
    }
  }, [path, baseLoaded, base, userLoaded, user]);

  return (
    <Screen className='no-guarded-screens' loading={!baseLoaded && !userLoaded}>
      {screen}
    </Screen>
  );
});

NoGuardedScreens.displayName = 'NoGuardedScreens';
export default NoGuardedScreens;
