import {
  CardData,
  CardDeckRef,
  DirectionEnum,
  PositionEnum,
  ReactUseGestureEventHandlers,
  useSafeCallback,
  useSafeState,
  useUnmountRef,
  YSwipableDeck
} from '@atomica.co/components';
import { AccessControl, BaseDto, User } from '@atomica.co/irori';
import { Height } from '@atomica.co/types';
import { builder, hasLength, ZERO } from '@atomica.co/utils';
import React, { useEffect } from 'react';
import styled from 'styled-components';
import { SpaceReservationCard } from '../../../../models/account-model';
import ReservationCard from './ReservationCardV2';

interface P {
  base: BaseDto;
  user: User;
  /** @deprecated */
  cards: SpaceReservationCard[];
  accessControls: AccessControl[];
}

const ReservationCards: React.ForwardRefExoticComponent<P & React.RefAttributes<CardDeckRef>> = React.forwardRef<
  CardDeckRef,
  P
>((props, ref) => {
  const { base, user, cards, accessControls } = props;
  const unmountRef = useUnmountRef();
  const [cardDataList, setCardDataList] = useSafeState<CardData[]>(unmountRef, []);
  const [cardHeight, setCardHeight] = useSafeState<Height>(unmountRef, ZERO);

  const initialize = useSafeCallback((): void => {
    const cardDataList = cards.map((card, index) => {
      const node = (bind: () => ReactUseGestureEventHandlers, fronting: boolean): React.ReactNode => (
        <ReservationCard
          fronting={fronting}
          base={base}
          user={user}
          card={card}
          setCardHeight={setCardHeight}
          bind={bind}
        />
      );

      return builder<CardData>()
        .id(card.spaceReservationId)
        .visible(true)
        .propsIndex(index)
        .position(PositionEnum.CENTER)
        .node(node)
        .build();
    });

    const accessControlList = accessControls.map((control, index) => {
      const node = (bind: () => ReactUseGestureEventHandlers, fronting: boolean): React.ReactNode => (
        <ReservationCard
          fronting={fronting}
          base={base}
          user={user}
          accessControl={control}
          setCardHeight={setCardHeight}
          bind={bind}
        />
      );

      return builder<CardData>()
        .id(control.accessControlId)
        .visible(true)
        .propsIndex(index)
        .position(PositionEnum.CENTER)
        .node(node)
        .build();
    });

    const mergedCardDataList = [...cardDataList, ...accessControlList].map(
      (cardData, index) => ((cardData.propsIndex = index), cardData)
    );
    setCardDataList(mergedCardDataList);
  }, [cards, base, user, setCardDataList]);

  useEffect(() => {
    initialize();
  }, [initialize]);

  useEffect(() => {
    const deckRef = (ref as React.RefObject<CardDeckRef>).current;
    !!deckRef && deckRef.moveCard(PositionEnum.CENTER);
  }, [ref]);

  return (
    <Container>
      {hasLength(cardDataList) && (
        <YSwipableDeck
          ref={ref}
          cardDataList={cardDataList}
          cardHeight={cardHeight}
          swipeDirection={DirectionEnum.UP}
        />
      )}
    </Container>
  );
});

ReservationCards.displayName = 'ReservationCards';
export default ReservationCards;

const Container = styled.div`
  width: 100%;
  height: 100%;
`;
