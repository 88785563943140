import { themeV2 } from '@atomica.co/components';
import { Index, Text, Title, URL } from '@atomica.co/types';
import Typography from '@material-ui/core/Typography';
import React from 'react';
import Linkify from 'react-linkify';
import styled from 'styled-components';
import media from 'styled-media-query';

interface P {
  title: Title;
  text: Text;
}

const Notion: React.FC<P> = React.memo(props => {
  const { title, text } = props;

  return (
    <Container>
      <Content>
        <NotionFrame>
          <NotionTitle>{title}</NotionTitle>
          <Linkify
            componentDecorator={(decoratedHref: URL, decoratedText: Text, key: Index) => (
              <a href={decoratedHref} key={key} target='_blank' rel='noreferrer'>
                {decoratedText}
              </a>
            )}
          >
            <NotionText>{text}</NotionText>
          </Linkify>
        </NotionFrame>
      </Content>
    </Container>
  );
});

Notion.displayName = 'Notion';
export default Notion;

const Container = styled.div`
  width: 100%;
  height: auto;
`;

const Content = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  gap: ${themeV2.mixins.v2.spacing}px;
`;

const NotionFrame = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: ${themeV2.mixins.v2.spacing * 3}px;
  background: ${themeV2.mixins.v2.color.background.white};
  gap: ${themeV2.mixins.v2.spacing * 2}px;
  border-radius: 8px;

  ${media.lessThan('small')`
    gap: ${themeV2.mixins.v2.spacing}px;
    padding: ${themeV2.mixins.v2.spacing * 2}px;
  `}
`;

const NotionTitle = styled(Typography)`
  color: ${themeV2.mixins.v2.color.font.gray};
  ${themeV2.mixins.v2.typography.title.large};
  border-left: 4px solid ${themeV2.mixins.v2.color.border.gray};
  padding-left: ${themeV2.mixins.v2.spacing * 2}px;

  ${media.lessThan('small')`
    font-size: 14px;
    line-height: 18px;
    padding: ${themeV2.mixins.v2.spacing}px;
  `}
`;

const NotionText = styled(Typography)`
  ${themeV2.mixins.v2.typography.body.large};
  line-height: 26px;
  white-space: pre-wrap;

  ${media.lessThan('small')`
    ${themeV2.mixins.v2.typography.body.small};
    line-height: 18px;
  `}
`;
