import { MasterSearchOption } from '@atomica.co/components';
import {
  AuthorityDefCategory,
  BaseAuthority,
  BaseDto,
  PersonInCharge,
  User,
  UserDiv,
  UserInflowSource
} from '@atomica.co/irori';
import { Address, Code, Description, Email, Name, Phone, UserId } from '@atomica.co/types';
import { EMPTY, Prefecture, builder, hasLength, toBeginningOfDay, toEndOfDay } from '@atomica.co/utils';
import { format } from 'date-fns';
import { getBasePIC } from '../utils/user-util';
import { UserDto } from '../__generated/model';

export const toUserToSave = (
  prevUser: User | undefined,
  startDate: Date,
  endDate: Date | undefined,
  familyName: Name,
  firstName: Name,
  companyName: Name,
  dateOfBirthV2: Date,
  phone: Phone,
  email: Email | undefined,
  postalCode: Code,
  prefecture: Prefecture | undefined,
  address: Address,
  userDiv: UserDiv | undefined,
  userInflowSource: UserInflowSource | undefined,
  facePhotoURL: string,
  faceFeatureStr: string
): UserDto => {
  const building = builder<UserDto>()
    .startDate(toBeginningOfDay(startDate) as Date)
    .endDate(toEndOfDay(endDate) as Date)
    .familyName(familyName)
    .firstName(firstName)
    .companyName(companyName)
    .dateOfBirthV2(format(dateOfBirthV2, 'yyyy/MM/dd'))
    .phone(phone)
    .email(email || (prevUser ? prevUser.email : EMPTY))
    .photoURL((!!prevUser && prevUser.photoURL) || EMPTY)
    .faceRecognitionPhotoURL(facePhotoURL)
    .faceFeatureStr(faceFeatureStr)
    .postalCode(postalCode)
    .prefecture(prefecture)
    .address(address)
    .userDiv(userDiv)
    .userInflowSource(userInflowSource)
    .dateOfAgreement(new Date());

  if (prevUser) {
    building.userId(prevUser.userId);
  }

  return building.build();
};

export const toPersonInChargeToSave = (base: BaseDto, users: User[], authority: BaseAuthority): PersonInCharge[] => {
  return users.reduce((prev: PersonInCharge[], current: User): PersonInCharge[] => {
    const personInCharge = getBasePIC(current, base);
    if (!personInCharge) return prev;

    const picToSave = builder<PersonInCharge>()
      .personInChargeId(personInCharge.personInChargeId)
      .user(current)
      .authority(authority)
      .build();

    prev.push(picToSave);
    return prev;
  }, []);
};

export const setUserId = (user: User, userId: UserId): User => {
  user.userId = userId;
  return user;
};

export const toMasterSearchOptionFromUser = (plans: User[]): MasterSearchOption[] =>
  plans.map((opt: User) => ({
    label: opt.familyName ? `${opt.familyName} ${opt.firstName}` : opt.email,
    value: opt.userId
  }));

const pickupUserAuthName = (pics: PersonInCharge[] | undefined): Name => {
  if (!hasLength(pics)) return EMPTY;
  const userAuthPic = pics.find(
    (pic: PersonInCharge): boolean =>
      pic.authority?.authorityDef?.category?.categoryCode === AuthorityDefCategory.USER_AUTHORITY
  );
  if (!userAuthPic) return EMPTY;
  return userAuthPic.authority?.authorityName ?? EMPTY;
};

export const toMasterSearchOptionFromUserWithAuthAndCompany = (plans: User[]): MasterSearchOption[] =>
  plans.map((opt: User) => {
    const authName = pickupUserAuthName(opt.personInCharge);
    const companyName = (opt.companyName ?? EMPTY).trim();
    const supplementDesc = ((): Description => {
      if (authName && companyName) return ` ( ${authName} , ${companyName} )`;
      if (authName) return ` ( ${authName} )`;
      if (companyName) return ` ( ${companyName} )`;
      return EMPTY;
    })();
    const displayName = opt.familyName ? `${opt.familyName} ${opt.firstName}` : opt.email;
    return {
      label: `${displayName}${supplementDesc}`,
      value: opt.userId
    };
  });
