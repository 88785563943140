import {
  Component,
  HEADER_HEIGHT,
  Scrollable,
  themeV2,
  useSafeCallback,
  useSafeState,
  useUnmountRef
} from '@atomica.co/components';
import { BaseDto, LoginServiceType } from '@atomica.co/irori';
import { Message } from '@atomica.co/types';
import React, { useMemo } from 'react';
import styled from 'styled-components';
import atomica from './../../../assets/atomica.png';
import puzzles from './../../../assets/puzzles.png';
import firebase from './../../../firebase';
import ResetPassword, { ResetPasswordStatus } from './reset-password/ResetPassword';
import SignInOptionList from './sign-in-option-list/SignInOptionList';

export enum SignInStatus {
  SELECT_SIGN_IN_OPTION,
  RESET_PASSWORD
}

interface P {
  base: BaseDto;
  usableLoginServiceTypes: LoginServiceType[];
  message?: Message;
  onSignIn(res: firebase.auth.UserCredential): void;
}

const SignIn: React.FC<P> = React.memo(props => {
  const { base, usableLoginServiceTypes, message, onSignIn } = props;
  const unmountRef = useUnmountRef();
  const [signInIndex, setSignInIndex] = useSafeState<SignInStatus>(unmountRef, SignInStatus.SELECT_SIGN_IN_OPTION);
  const [resetPasswordIndex, setResetPasswordIndex] = useSafeState<ResetPasswordStatus>(
    unmountRef,
    ResetPasswordStatus.INPUT_EMAIL
  );

  const showResetPasswordScreen = useSafeCallback((): void => {
    setSignInIndex(SignInStatus.RESET_PASSWORD);
  }, [setSignInIndex]);

  const showSignInScreen = useSafeCallback((): void => {
    setSignInIndex(SignInStatus.SELECT_SIGN_IN_OPTION);
  }, [setSignInIndex]);

  const children = useMemo<React.ReactElement | undefined>(() => {
    switch (signInIndex) {
      case SignInStatus.SELECT_SIGN_IN_OPTION:
        return (
          <SignInOptionList
            base={base}
            usableLoginServiceTypes={usableLoginServiceTypes}
            message={message}
            onSignIn={onSignIn}
            resetPassword={showResetPasswordScreen}
          />
        );

      case SignInStatus.RESET_PASSWORD:
        return (
          <ResetPassword
            base={base}
            index={resetPasswordIndex}
            setResetPasswordIndex={setResetPasswordIndex}
            setSignInIndex={setSignInIndex}
            goBack={showSignInScreen}
          />
        );
    }
  }, [
    signInIndex,
    base,
    usableLoginServiceTypes,
    message,
    onSignIn,
    showResetPasswordScreen,
    resetPasswordIndex,
    setResetPasswordIndex,
    setSignInIndex,
    showSignInScreen
  ]);

  return (
    <Component className='sign-in'>
      <Container>
        <Content>
          <Scrollable>
            <ComponentWrapper>
              <LogoWrapper>
                <Logo
                  src={(resetPasswordIndex === ResetPasswordStatus.EMAIL_SENT ? puzzles : base.topImageURL) || atomica}
                />
              </LogoWrapper>
              {children}
            </ComponentWrapper>
          </Scrollable>
        </Content>
      </Container>
    </Component>
  );
});

SignIn.displayName = 'SignIn';
export default SignIn;

const Container = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  justify-content: center;
`;

const Content = styled.div`
  width: 100%;
  max-width: 428px;
  min-width: 320px;
  height: calc(100vh - ${HEADER_HEIGHT}px);

  @supports (height: 1dvh) {
    height: calc(100dvh - ${HEADER_HEIGHT}px);
  }
`;

const ComponentWrapper = styled.div`
  width: 100%;
  height: auto;
  margin-bottom: ${themeV2.mixins.v2.spacing * 6}px;
  padding: ${themeV2.mixins.v2.spacing * 2}px;
`;

const LogoWrapper = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: ${themeV2.mixins.v2.spacing * 2}px;
`;

const Logo = styled.img`
  width: 100%;
  height: auto;
  min-height: 160px;
  object-fit: contain;
`;
