import { TdV2, themeV2, TrV2, useSafeCallback } from '@atomica.co/components';
import { ContractPlanUtils, ContractV2, SpaceReservation } from '@atomica.co/irori';
import { Hour, Index, Price, Time } from '@atomica.co/types';
import {
  calcTimeDiff,
  isGreaterThan,
  isGreaterThanZero,
  toFormattedDateTimeDurationStr,
  toHour,
  ZERO
} from '@atomica.co/utils';
import { Typography } from '@material-ui/core';
import React, { useCallback, useMemo } from 'react';
import styled from 'styled-components';
import { SPACE_RESERVATION_TYPE_LABEL } from '../../../texts/space-text';
import { calcUsagePeriod } from '../../../utils/space-reservation-util';

// FIXME
const calcDiffHours = (startAt: Date, endAt: Date): Hour => toHour(calcTimeDiff(new Date(startAt), new Date(endAt)));

interface P {
  freeTier: Hour;
  currIndex: Index;
  currReservation: SpaceReservation;
  reservations: SpaceReservation[];
  contract?: ContractV2;
}

const SpaceUsageRow: React.FC<P> = React.memo(props => {
  const { freeTier, currIndex, currReservation, reservations, contract } = props;

  const usageTime = useMemo<Time>(
    () => toFormattedDateTimeDurationStr(currReservation.startAt!, currReservation.endAt!, 'M/d HH:mm', '-'),
    [currReservation]
  );

  const usageHours = useMemo<Hour>(() => {
    const { space, startAt, endAt } = currReservation;
    if (!space) return ZERO;
    return calcUsagePeriod(space.reservationType, startAt!, endAt!);
  }, [currReservation]);

  const remainingFreeTier = useMemo<Hour>(() => {
    return reservations
      .filter((_, index) => index <= currIndex)
      .reduce((prev, curr) => {
        if (curr.space?.baseResourceCategory?.isContractPlanFreeTierTarget) {
          return prev - calcDiffHours(curr.startAt!, curr.endAt!);
        }
        return prev;
      }, freeTier);
  }, [reservations, currIndex, freeTier]);

  const isFreeTierPlan = useCallback((space: SpaceReservation) => {
    return space.space?.baseResourceCategory?.isContractPlanFreeTierTarget ?? false;
  }, []);

  const payableHours = useMemo<Hour>(() => {
    if (!isFreeTierPlan(currReservation)) return usageHours;
    if (isGreaterThanZero(remainingFreeTier)) return ZERO;
    return isGreaterThan(usageHours, Math.abs(remainingFreeTier)) ? Math.abs(remainingFreeTier) : usageHours;
  }, [isFreeTierPlan, currReservation, usageHours, remainingFreeTier]);

  const freeHours = useMemo<Hour>(() => usageHours - payableHours, [usageHours, payableHours]);

  const priceToPay = useMemo<Price>(() => payableHours * currReservation.pricePerHour, [payableHours, currReservation]);

  const spaceName = useCallback(
    (space: SpaceReservation) => {
      const name = space.space!.spaceName;
      if (currReservation.space?.baseResourceCategory?.isContractPlanDiscountRateTarget) {
        const discount = contract?.contractPlan?.discountRate ?? 0;
        return discount !== 0 ? name.concat(`(${discount}%割引)`) : name;
      }
      return name;
    },
    [
      contract?.contractPlan?.discountRate,
      currReservation.space?.baseResourceCategory?.isContractPlanDiscountRateTarget
    ]
  );

  const pricePerHour = useSafeCallback(
    (space: SpaceReservation) => {
      const price = space.pricePerHour;
      if (currReservation.space?.baseResourceCategory?.isContractPlanDiscountRateTarget) {
        return price * ContractPlanUtils.normalizeDiscountRate(contract?.contractPlan?.discountRate);
      }
      return price;
    },
    [
      contract?.contractPlan?.discountRate,
      currReservation.space?.baseResourceCategory?.isContractPlanDiscountRateTarget
    ]
  );
  const paymentMount = useMemo<Price>(() => {
    const isContractPlanDiscountRateTarget =
      currReservation.space?.baseResourceCategory?.isContractPlanDiscountRateTarget ?? false;
    const discountRate = ContractPlanUtils.normalizeDiscountRate(contract?.contractPlan?.discountRate);
    return isContractPlanDiscountRateTarget ? priceToPay * discountRate : priceToPay;
  }, [
    priceToPay,
    currReservation.space?.baseResourceCategory?.isContractPlanDiscountRateTarget,
    contract?.contractPlan?.discountRate
  ]);

  return (
    <TrV2>
      <TdV2>{usageTime}</TdV2>
      <TdV2>{spaceName(currReservation)}</TdV2>
      <TdV2 align='right'>{pricePerHour(currReservation).toLocaleString()}</TdV2>
      <TdV2>
        {payableHours}
        {SPACE_RESERVATION_TYPE_LABEL[currReservation.space!.reservationType]}
        {isGreaterThanZero(freeHours) && isFreeTierPlan(currReservation) && (
          <DescriptionLabel>
            {usageHours}時間利用（うち
            {freeHours}
            時間無料）
          </DescriptionLabel>
        )}
      </TdV2>
      <TdV2 align='right'>{paymentMount.toLocaleString()}</TdV2>
    </TrV2>
  );
});

SpaceUsageRow.displayName = 'SpaceUsageRow';
export default SpaceUsageRow;

const DescriptionLabel = styled(Typography)`
  ${themeV2.mixins.v2.typography.body.small};
  color: ${themeV2.mixins.v2.color.font.gray};
  padding: ${themeV2.mixins.v2.spacing / 4}px 0;
`;
