import {
  BaseDto,
  CreateQrCodeForAllSpacesResponse,
  QrCodeData,
  SpaceCategory,
  SpaceManagement,
  SpaceReservation,
  User
} from '@atomica.co/irori';
import { Code, URL } from '@atomica.co/types';
import { EMPTY, builder, hasLength, isDefAndNotNull, uuid } from '@atomica.co/utils';
import { SpaceReservationCard } from '../models/account-model';
import { ENTRANCE_LABEL } from '../texts/space-text';
import { getBasePIC } from '../utils/user-util';

export const constructCard = (
  qrcodesForEachSpaceReservation: QrCodeData[],
  reservation: SpaceReservation
): SpaceReservationCard | undefined => {
  return convertToCardForEachSpaceReservation(reservation, qrcodesForEachSpaceReservation);
};

/** @deprecated */
export const constructCards = (
  base: BaseDto,
  user: User,
  qrcodeForAllSpaces: CreateQrCodeForAllSpacesResponse | undefined,
  qrcodesForEachSpaceReservation: QrCodeData[],
  reservations: SpaceReservation[]
): SpaceReservationCard[] => {
  const cardsToDisplay: SpaceReservationCard[] = [];

  if (qrcodeForAllSpaces) {
    const card = convertToCardForAllSpaces(base, user, qrcodeForAllSpaces);
    !!card && cardsToDisplay.push(card);
  }

  if (hasLength(reservations)) {
    const cards = reservations.reduce((prev: SpaceReservationCard[], current: SpaceReservation) => {
      const card = convertToCardForEachSpaceReservation(current, qrcodesForEachSpaceReservation);
      !!card && prev.push(card);
      return prev;
    }, []);
    cardsToDisplay.push(...cards);
  }

  return cardsToDisplay;
};

const toBlobURL = (buffer: Buffer | undefined): URL => {
  if (!buffer) return EMPTY;
  const arrayBuffer = Object.values(buffer);
  const blob = new Blob([Buffer.from(arrayBuffer)], { type: 'image/png' });
  const urlCreator = window.URL || window.webkitURL;
  return urlCreator.createObjectURL(blob);
};

export const toQrCode = (spaceManagement: SpaceManagement, qrcode: Code | Buffer): Code => {
  switch (spaceManagement) {
    case SpaceManagement.BITKEY:
      return qrcode as Code;
    case SpaceManagement.DENSO:
      return toBlobURL(qrcode as Buffer);
    default:
      throw new Error(`${spaceManagement} is out of target.`);
  }
};

/** @deprecated */
const convertToCardForAllSpaces = (
  base: BaseDto,
  user: User,
  qrcodeForAllSpaces: CreateQrCodeForAllSpacesResponse
): SpaceReservationCard | undefined => {
  const { space, qrcode } = qrcodeForAllSpaces;

  if (!isDefAndNotNull(space?.spaceManagement)) return;

  const pic = getBasePIC(user, base);
  const authority = pic ? pic.authority : undefined;
  const photoURL = space?.photoURL ? space?.photoURL : !!authority && !!authority.photoURL ? authority.photoURL : EMPTY;
  const endAt = user.endDate || undefined;
  const spaceManagement = base.spaceManagement;

  return builder<SpaceReservationCard>()
    .spaceReservationId(uuid())
    .spaceId(space ? space.spaceId : EMPTY)
    .spaceCode(space ? space.spaceCode : EMPTY)
    .spaceName(space ? space.spaceName : ENTRANCE_LABEL)
    .spacePhotoUrl(photoURL)
    .category(SpaceCategory.ENTRANCE)
    .reservationName(EMPTY)
    .reservationEndAt(endAt as Date)
    .reservationRemarks(EMPTY)
    .qrCode(spaceManagement && qrcode ? toQrCode(spaceManagement, qrcode) : EMPTY)
    .spaceManagement(spaceManagement as SpaceManagement)
    .build();
};

// const convertToCardForContractedSpaces = (
//   user: User,
//   base: BaseDto,
//   qrcodeForContractedSpaces: Code | Buffer | undefined
// ): SpaceReservationCard => {
//   const pic = getBasePIC(user, base);
//   const authority = !!pic ? pic.authority : undefined;
//   const endAt = user.endDate || undefined;

//   return builder<SpaceReservationCard>()
//     .spaceReservationId(uuid())
//     .spaceCode(EMPTY)
//     .spaceName(!!authority ? authority.authorityName : EMPTY)
//     .spacePhotoUrl(base.photoURL || EMPTY)
//     .reservationName(EMPTY)
//     .reservationEndAt(endAt!)
//     .reservationRemarks(EMPTY)
//     .qrCode(toBlobURL(qrcodeForContractedSpaces))
//     .spaceManagement(SpaceManagement.DENSO)
//     .build();
// };

/** @deprecated */
const convertToCardForEachSpaceReservation = (
  reservation: SpaceReservation,
  qrcodesForEachSpaceReservation: QrCodeData[]
): SpaceReservationCard | undefined => {
  const spaceManagement = reservation.space?.spaceManagement;
  if (!spaceManagement) return;

  const qrCode = qrcodesForEachSpaceReservation.find(qrcode => {
    return qrcode.spaceReservationId === reservation.spaceReservationId;
  })?.buffer;

  return builder<SpaceReservationCard>()
    .spaceReservationId(reservation.spaceReservationId)
    .spaceId(reservation.space!.spaceId)
    .spaceCode(reservation.space!.spaceCode)
    .spaceName(reservation.space!.spaceName)
    .spacePhotoUrl(reservation.space?.photoURL || EMPTY)
    .category(SpaceCategory.CONFERENCE)
    .reservationName(reservation.reservationName)
    .reservationStartAt(reservation.startAt)
    .reservationEndAt(reservation.endAt)
    .reservationCheckeoutAt(reservation.checkoutAt)
    .reservationRemarks(reservation.remarks)
    .qrCode(qrCode ? toQrCode(spaceManagement, qrCode) : EMPTY)
    .spaceManagement(spaceManagement)
    .permittedEntryMinutes(reservation.space?.permittedEntryMinutes)
    .build();
};
