import { HeaderV2, LeftPanelV2, MenuItem, ParentMenu, Screen, useSafeCallback } from '@atomica.co/components';
import {
  BaseDto,
  BaseFunctionToggleCode,
  BaseMenu,
  isBaseFunctionToggleEnabled,
  MenuType,
  User
} from '@atomica.co/irori';
import { Option, URL } from '@atomica.co/types';
import { builder, hasLength, partialBuilder } from '@atomica.co/utils';
import CssBaseline from '@material-ui/core/CssBaseline';
import React, { useMemo } from 'react';
import styled from 'styled-components';
import logo from '../../assets/logo/logo_knot_place.png';
import { HEADER_HEIGHT } from '../../constants/common-const';
import { toMenuPath } from '../../converters/base-menu-converter';
import { toIcon } from '../../converters/icon-converter';
import { MenuDefCodeEnum } from '../../enums/base-menu-enum';
import { IconCodeEnum } from '../../enums/icon-enum';
import { Labels } from '../../models/common-model';
import usePath from '../../redux/hooks/usePath';
import { Path } from '../../router/Routes';
import ConsoleSwitcher from './ConsoleSwitcher';

export const DRAWER_WIDTH = 280;

enum HeaderActionEnum {
  SIGNOUT = 'signout'
}

const HEADER_ACTION_LABELS: Labels = { [HeaderActionEnum.SIGNOUT]: 'ログアウトする' };

const constructMenus = (
  menus: BaseMenu[],
  currentPath: Path | undefined,
  onClickDefault: (path: Path) => void,
  onClickForExternalUrl: (url: URL) => void
): ParentMenu[] => {
  return menus.map(menu => {
    const menuPath = toMenuPath(menu.menuDef!.menuDefCode);
    const externalUrl = menu.externalUrl;

    const mainItem = builder<MenuItem>()
      .label(menu.menuLabel || menu.menuDef!.menuDefName)
      .isSelected(menuPath === currentPath)
      .onClick(externalUrl ? () => onClickForExternalUrl(externalUrl) : () => onClickDefault(menuPath))
      .build();
    const subItems = menu.baseSubMenus
      ? menu.baseSubMenus.map(sub => {
          const subMenuPath = toMenuPath(sub.menuDef!.menuDefCode);
          return builder<MenuItem>()
            .label(sub.menuLabel || sub.menuDef!.menuDefName)
            .isSelected(subMenuPath === currentPath)
            .onClick(() => onClickDefault(subMenuPath))
            .build();
        })
      : [];
    const parentMenu = builder<ParentMenu>().icon(toIcon(menu.menuDef!.iconCode)).mainItem(mainItem);

    return hasLength(subItems) ? parentMenu.subItems(subItems).build() : parentMenu.build();
  });
};

const constructMasterMenuBase = (currentPath: Path | undefined, onClick: (path: Path) => void) => {
  const path = Path.SHOT_MASTER;
  return builder<ParentMenu>()
    .icon(toIcon(IconCodeEnum.MASTER))
    .mainItem(
      builder<MenuItem>()
        .label('マスタ設定')
        .isSelected(path === currentPath)
        .onClick(() => onClick(path))
        .build()
    )
    .subItems([])
    .build();
};
interface P {
  isDrawerOpen?: boolean;
  base: BaseDto;
  user: User;
}

const ConsoleScreen: React.FC<P> = React.memo((props: P) => {
  const { isDrawerOpen = false, base, user } = props;
  const { path, openBasePath } = usePath();

  const handleNavigationMenuClick = useSafeCallback(
    (path: Path): void => {
      openBasePath(path);
    },
    [openBasePath]
  );
  const handleNavigationMenuClickForExternalUrl = useSafeCallback((externalUrl: URL): void => {
    window.open(externalUrl, '_blank');
  }, []);

  const master = useMemo<ParentMenu[]>(() => {
    const subItems: MenuItem[] = [];
    if (base.menus?.some(menu => menu.menuDef?.menuDefCode === MenuDefCodeEnum.CONSUMER_SHOT)) {
      const menuPath = Path.SHOT_MASTER;
      subItems.push(
        builder<MenuItem>()
          .label('入館受付')
          .isSelected(menuPath === path)
          .onClick(() => handleNavigationMenuClick(menuPath))
          .build()
      );
    }

    if (isBaseFunctionToggleEnabled(base, BaseFunctionToggleCode.FUNCTION_USE_RESOURCE_MASTER)) {
      const menuPath = Path.RESOURCE_MASTER;
      subItems.push(
        builder<MenuItem>()
          .label('リソース')
          .isSelected(menuPath === path)
          .onClick(() => handleNavigationMenuClick(menuPath))
          .build()
      );
    }

    const masterMenuBase = constructMasterMenuBase(path, handleNavigationMenuClick);
    return [partialBuilder(masterMenuBase, { subItems: masterMenuBase.subItems?.concat(subItems) })];
  }, [base, path, handleNavigationMenuClick]);

  const displayMenus = useMemo<ParentMenu[]>(() => {
    const menus = constructMenus(
      base.menus?.filter(menu => menu.menuType === MenuType.CONSOLE) ?? [],
      path,
      handleNavigationMenuClick,
      handleNavigationMenuClickForExternalUrl
    );
    return hasLength(master[0].subItems) ? menus.concat(master) : menus;
  }, [base, master, path, handleNavigationMenuClick, handleNavigationMenuClickForExternalUrl]);

  const handlePathChanged = useSafeCallback(
    (path: Path) => {
      openBasePath(path);
    },
    [openBasePath]
  );

  const handleIconMenuClicked = useSafeCallback(
    (option: Option): void => {
      switch (option) {
        case HeaderActionEnum.SIGNOUT:
          openBasePath(Path.SIGN_OUT);
          break;
        default:
          throw new Error(`${option} is out of target.`);
      }
    },
    [openBasePath]
  );

  return (
    <Screen className='console-screen'>
      <Container>
        <CssBaseline />

        <HeaderV2
          logo={base.logoURL || logo}
          icon={user.photoURL}
          labels={HEADER_ACTION_LABELS}
          options={Object.values(HeaderActionEnum)}
          onClickMenuItem={handleIconMenuClicked}
        />
        <Content>
          {isDrawerOpen && (
            <LeftPanelWrapper data-testid='console-screen-left-panel-wrapper'>
              <LeftPanelV2 headerPhotoURL={base.photoURL} headerName={base.baseName} menus={displayMenus} />
            </LeftPanelWrapper>
          )}

          {!!base && (
            <ConsoleSwitcherWrapper>
              <ConsoleSwitcher isDrawerOpen path={path} base={base} user={user} onChange={handlePathChanged} />
            </ConsoleSwitcherWrapper>
          )}
        </Content>
      </Container>
    </Screen>
  );
});

ConsoleScreen.displayName = 'ConsoleScreen';
export default ConsoleScreen;

const Container = styled.div`
  width: 100%;
  min-height: 100vh;
  display: flex;

  @supports (height: 1dvh) {
    min-height: 100dvh;
  }
`;

const Content = styled.div`
  position: fixed;
  top: ${HEADER_HEIGHT}px;
  width: 100%;
  display: flex;
`;

const LeftPanelWrapper = styled.div`
  height: calc(100vh - ${HEADER_HEIGHT}px);
  position: relative;

  @supports (height: 1dvh) {
    height: calc(100dvh - ${HEADER_HEIGHT}px);
  }
`;

const ConsoleSwitcherWrapper = styled.div`
  flex: 1;
  max-width: 100%;
  height: calc(100vh - ${HEADER_HEIGHT}px);

  @supports (height: 1dvh) {
    height: calc(100dvh - ${HEADER_HEIGHT}px);
  }
`;
