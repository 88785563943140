import { ButtonV2, themeV2, useSafeCallback } from '@atomica.co/components';
import { CATCH_FRONTEND_ERROR, CatchFrontendErrorRequest, CatchFrontendErrorResponse } from '@atomica.co/irori';
import { EMPTY, builder } from '@atomica.co/utils';
import React, { useEffect } from 'react';
import { FallbackProps } from 'react-error-boundary';
import styled from 'styled-components';
import Screen from '../screen/Screen';
import mojaco from './../../assets/mojaco/mojaco_sorry.png';
import CommonRequest from '../../requests/common-request';

const handleButtonClicked = (): void => {
  window.history.back();
};

const UnknownError: React.FC<FallbackProps> = React.memo(props => {
  const { error } = props;

  const throwError = useSafeCallback(async (): Promise<void> => {
    const stack = error.stack ? error.stack : EMPTY;
    const request = builder<CatchFrontendErrorRequest>()
      .stack('\nエラー発生箇所: ' + window.location.href + '\n\nstack: ' + stack + '\n\nmessage: ' + error.message)
      .build();
    await CommonRequest.call<CatchFrontendErrorRequest, CatchFrontendErrorResponse>(CATCH_FRONTEND_ERROR, request);
  }, [error]);

  useEffect(() => {
    throwError();
  }, [throwError]);

  return (
    <Screen className='unknown-error'>
      <MessagesWrapper>
        <Message>エラーが発生しました…！</Message>
        <Message>しばらくしてから</Message>
        <Message>もう一度お試しください。</Message>
      </MessagesWrapper>

      <Mojaco src={mojaco} />

      <ButtonWrapper>
        <ButtonV2 type='secondary' label='戻る' onClick={handleButtonClicked} />
      </ButtonWrapper>
    </Screen>
  );
});

UnknownError.displayName = 'UnknownError';
export default UnknownError;

const MessagesWrapper = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  flex-flow: column;
  justify-content: center;
  padding: ${themeV2.mixins.v2.spacing * 3}px ${themeV2.mixins.v2.spacing}px;
`;

const Message = styled.div`
  ${themeV2.mixins.v2.typography.label.large};
  color: ${themeV2.mixins.v2.color.font.black};
  text-align: center;
  user-select: none;
`;

const Mojaco = styled.img`
  width: 100%;
  height: auto;
  object-fit: cover;
  user-select: none;
  padding: ${themeV2.mixins.v2.spacing}px;
`;

const ButtonWrapper = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  justify-content: center;
  padding: ${themeV2.mixins.v2.spacing * 3}px ${themeV2.mixins.v2.spacing}px;
`;
