import {
  CircularLoader,
  ColgroupV2,
  Component,
  TableV2,
  TbodyV2,
  TdV2,
  TheadV2,
  themeV2,
  ThV2,
  TrV2,
  useSafeCallback,
  useSafeState,
  useUnmountRef
} from '@atomica.co/components';
import {
  BaseDto,
  ContractLogSortColumn,
  ContractLogV2,
  ContractV2,
  FETCH_CONTRACT_LOGS_V2_FOR_ADMIN,
  FetchContractLogsV2ForAdminRequest,
  FetchContractLogsV2ForAdminResponse,
  Sort,
  toFullName
} from '@atomica.co/irori';
import { Index, Label } from '@atomica.co/types';
import { builder, hasLength } from '@atomica.co/utils';
import { CSSProperties } from '@material-ui/core/styles/withStyles';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import { format } from 'date-fns';
import React, { useEffect } from 'react';
import styled from 'styled-components';
import useCommonRequest from '../../../redux/hooks/useCommonRequest';
import { NO_DATA_TEXT } from '../../../texts/common-text';
import { updateSortCol } from '../../../utils/contract-v2-util';

interface TableColumn {
  label: Label;
  sortCol?: ContractLogSortColumn;
}

const TABLE_COLUMNS: TableColumn[] = [
  { label: '更新日時', sortCol: ContractLogSortColumn.CREATED_AT },
  { label: '更新内容' },
  { label: '更新者' }
];

interface P {
  base: BaseDto;
  contract: ContractV2 | undefined;
}

const ContractLogs: React.FC<P> = React.memo(props => {
  const { base, contract } = props;
  const unmountRef = useUnmountRef();
  const { commonRequest } = useCommonRequest();

  const [contractLogs, setContractLogs] = useSafeState<ContractLogV2[]>(unmountRef, []);
  const [sortKey, setContractLogSortKey] = useSafeState<ContractLogSortColumn>(
    unmountRef,
    ContractLogSortColumn.CREATED_AT
  );
  const [sort, setSort] = useSafeState<Sort>(unmountRef, Sort.ASC);

  const [isLoaderShown, setIsLoaderShown] = useSafeState<boolean>(unmountRef, true);

  const handleClickContractLogSortCol = useSafeCallback(
    (sortCol: ContractLogSortColumn | undefined): void => {
      updateSortCol(sortCol, setContractLogSortKey, setSort);
    },
    [setContractLogSortKey, setSort]
  );

  const initContractLogs = useSafeCallback(async (): Promise<void> => {
    const request = builder<FetchContractLogsV2ForAdminRequest>()
      .baseId(base.baseId)
      .contractId(contract!.contractId)
      .sortCol(sortKey ? sortKey : ContractLogSortColumn.CREATED_AT)
      .sort(sort)
      .build();
    const response = await commonRequest<FetchContractLogsV2ForAdminRequest, FetchContractLogsV2ForAdminResponse>(
      FETCH_CONTRACT_LOGS_V2_FOR_ADMIN,
      request
    );
    setContractLogs(response.contractLogs);
    setIsLoaderShown(false);
  }, [base.baseId, contract, setIsLoaderShown, setContractLogs, sortKey, sort]);

  useEffect(() => {
    initContractLogs();
  }, [initContractLogs]);

  return (
    <Component className='contract-logs' style={styleForComponent}>
      <Container>
        <TableWrapper>
          <TableV2 shape='circle'>
            <ColgroupV2 width={256} />
            <ColgroupV2 />
            <ColgroupV2 width={160} />
            <TheadV2>
              <TrV2>
                {TABLE_COLUMNS.map((column: TableColumn, idx: Index) => (
                  <ThV2 key={idx} onClick={() => handleClickContractLogSortCol(column.sortCol)}>
                    {column.sortCol ? (
                      <SortColumn>
                        {column.label}
                        {column.sortCol === sortKey &&
                          (sort === Sort.ASC ? <CustomArrowDownwardIcon /> : <CustomArrowUpwardIcon />)}
                      </SortColumn>
                    ) : (
                      column.label
                    )}
                  </ThV2>
                ))}
              </TrV2>
            </TheadV2>
            <TbodyV2>
              {!isLoaderShown &&
                hasLength(contractLogs) &&
                contractLogs.map((row, index) => (
                  <TrV2 key={`tr${index}`}>
                    <TdV2>{format(row.createdAt!, 'yyyy/MM/dd HH:mm:ss')}</TdV2>
                    <TdV2>{row.operation}</TdV2>
                    <TdV2>{toFullName(row.createdUser)}</TdV2>
                  </TrV2>
                ))}
            </TbodyV2>
          </TableV2>

          {!isLoaderShown && !hasLength(contractLogs) && <NoSearchResult>{NO_DATA_TEXT}</NoSearchResult>}

          {isLoaderShown && (
            <LoaderWrapper>
              <CircularLoader />
            </LoaderWrapper>
          )}
        </TableWrapper>
      </Container>
    </Component>
  );
});

ContractLogs.displayName = 'ContractLogs';
export default ContractLogs;

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding-bottom: ${themeV2.mixins.v2.spacing * 10}px;
`;

const TableWrapper = styled.div`
  min-height: 92px;
  display: flex;
  flex-direction: column;
  border-radius: 6px;
  background: ${themeV2.mixins.v2.color.background.white};
  margin: ${themeV2.mixins.v2.spacing * 2}px 0;
`;

const SortColumn = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  cursor: pointer;
  gap: 8px;
`;
const CustomArrowDownwardIcon = styled(ArrowDownwardIcon)`
  ${themeV2.mixins.v2.typography.body.large};
`;
const CustomArrowUpwardIcon = styled(ArrowUpwardIcon)`
  ${themeV2.mixins.v2.typography.body.large};
`;
const LoaderWrapper = styled.div`
  flex: 1;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;
const NoSearchResult = styled.div`
  width: 100%;
  height: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
  ${themeV2.mixins.v2.typography.body.medium};
  color: ${themeV2.mixins.v2.color.font.gray};
  padding: ${themeV2.mixins.v2.spacing * 3}px;
`;

const styleForComponent: CSSProperties = {
  width: '100%',
  height: '100%'
};
