import { LabelV2, themeV2, useSafeState, useUnmountRef } from '@atomica.co/components';
import { ReservationPaymentMethod, SpaceReservation, SpaceReservationStatus } from '@atomica.co/irori';
import { Label } from '@atomica.co/types';
import { EMPTY } from '@atomica.co/utils';
import React, { useEffect } from 'react';
import styled from 'styled-components';
import { RESERVATION_PAYMENT_METHOD_LABELS } from '../../../../texts/payment-method-text';
import { RESERVATION_PAYMENT_STATUS_LABELS } from '../../../../texts/reservation-payment-status-text';

interface P {
  reservation?: SpaceReservation;
}

// HACK: 本来はいらない分岐処理(ステータスの意向が完全に終わっていないため)
const getPaymentStatusLabel = (reservation: SpaceReservation): string => {
  if (reservation.paymentMethod === ReservationPaymentMethod.CREDIT_CARD || !reservation.paymentMethod) {
    switch (reservation.status) {
      case SpaceReservationStatus.CONFIRMED:
        return '支払い済み';
      case SpaceReservationStatus.CANCELED:
        return 'キャンセル済み';
      default:
        return '未払い';
    }
  } else {
    return RESERVATION_PAYMENT_STATUS_LABELS[reservation.paymentStatus!];
  }
};

const AccountReservationDetailPaymentMethod: React.FC<P> = React.memo(props => {
  const { reservation } = props;
  const unmountRef = useUnmountRef();
  const [paymentMethod, setPaymentMethod] = useSafeState<Label>(unmountRef, EMPTY);
  const [paymentStatus, setPaymentStatus] = useSafeState<Label>(unmountRef, EMPTY);

  useEffect(() => {
    if (reservation && reservation.paymentMethod) {
      // HACK: 本来はいらない分岐、 temp Spaceが完全廃止できないための措置
      if (!RESERVATION_PAYMENT_METHOD_LABELS[reservation.paymentMethod]) {
        setPaymentMethod('クレジットカード払い');
      } else {
        setPaymentMethod(RESERVATION_PAYMENT_METHOD_LABELS[reservation.paymentMethod]);
      }
    }
    if (reservation) {
      setPaymentStatus(getPaymentStatusLabel(reservation));
    }
  }, [reservation, setPaymentMethod, setPaymentStatus]);
  return (
    <>
      <CardRow>
        <LabelWrapper>
          <LargeLabel text={'支払い'} />
        </LabelWrapper>
        <Text>
          {paymentMethod}
          <br />
          {`(${paymentStatus})`}
        </Text>
      </CardRow>
      <Border />
    </>
  );
});

AccountReservationDetailPaymentMethod.displayName = 'AccountReservationDetailPaymentMethod';
export default AccountReservationDetailPaymentMethod;

const CardRow = styled.div<{ align?: 'start' | 'center' }>`
  display: flex;
  align-items: ${({ align = 'center' }) => align};
`;

const LabelWrapper = styled.div`
  min-width: 120px;
  margin-right: ${themeV2.mixins.v2.spacing}px;
`;

const LargeLabel = styled(LabelV2).attrs(() => ({ fontSize: 'large' }))``;

const Text = styled.div`
  ${themeV2.mixins.v2.typography.body.large};
  white-space: pre-wrap;
`;

const Border = styled.div`
  border-top: 1px dashed ${themeV2.mixins.v2.color.border.gray};
  margin: ${themeV2.mixins.v2.spacing * 2}px 0;
`;
