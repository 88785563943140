import { MultiSearchBoxV2, themeV2, useSafeCallback, useSafeState, useUnmountRef } from '@atomica.co/components';
import {
  BaseDto,
  SEARCH_WISH_TAGS_FOR_ADMIN,
  SearchWishTagsForAdminRequest,
  SearchWishTagsForAdminResponse,
  WishTag
} from '@atomica.co/irori';
import { Name, Word } from '@atomica.co/types';
import { builder, hasLength, uuid } from '@atomica.co/utils';
import React, { useMemo } from 'react';
import styled from 'styled-components';
import { toWishTag } from '../../converters/wish-tag-converter';
import useCommonRequest from '../../redux/hooks/useCommonRequest';

const LIMIT = 10;
const OFFSET = 0;

interface P {
  base: BaseDto;
  editable?: boolean;
  addNewTags?: boolean;
  wishTags: WishTag[];
  setWishTags: React.Dispatch<React.SetStateAction<WishTag[]>>;
}

const WishTags: React.FC<P> = React.memo(props => {
  const { base, editable = false, addNewTags = false, wishTags, setWishTags } = props;
  const { commonRequest } = useCommonRequest();

  const unmountRef = useUnmountRef();
  const [searchedWishTags, setSearchedWishTags] = useSafeState<WishTag[]>(unmountRef, []);

  const tagsToSave = useMemo<Name[]>(() => wishTags.map(tag => tag.name), [wishTags]);
  const searchedTags = useMemo<Name[]>(() => searchedWishTags.map(tag => tag.name), [searchedWishTags]);

  const handleTagChanged = useSafeCallback(
    async (word: Word): Promise<void> => {
      const request = builder<SearchWishTagsForAdminRequest>()
        .baseId(base.baseId)
        .limit(LIMIT)
        .offset(OFFSET)
        .wishWord(word)
        .build();
      // await getBsWish().searchWishTagsForAdmin(base.baseId, request);
      const response = await commonRequest<SearchWishTagsForAdminRequest, SearchWishTagsForAdminResponse>(
        SEARCH_WISH_TAGS_FOR_ADMIN,
        request
      );
      const isSaved = response.wishTags.some(tag => tag.name === word);
      setSearchedWishTags(
        !isSaved && addNewTags
          ? [...response.wishTags, toWishTag(word)]
          : hasLength(response.wishTags)
            ? response.wishTags
            : []
      );
    },
    [addNewTags, base, commonRequest, setSearchedWishTags]
  );

  const handleTagSelected = useSafeCallback(
    (words: Word[]): void => {
      setSearchedWishTags(searchedTags => {
        setWishTags(prevTags => {
          return words.map(word => {
            const tag = [...searchedTags, ...prevTags].find(tag => tag.name === word);

            return builder<WishTag>()
              .wishTagId(tag ? tag.wishTagId : uuid())
              .name(word)
              .build();
          });
        });
        return [];
      });
    },
    [setSearchedWishTags, setWishTags]
  );

  return (
    <Frame data-testid='wish-tags'>
      <MultiSearchBoxV2
        isEditable={editable}
        words={tagsToSave}
        options={searchedTags}
        onChange={handleTagChanged}
        onSelect={handleTagSelected}
      />
    </Frame>
  );
});

WishTags.displayName = 'WishTags';
export default WishTags;

const Frame = styled.div`
  border: 1px solid ${themeV2.mixins.v2.color.border.gray};
  padding: ${themeV2.mixins.v2.spacing}px;
  border-radius: 8px;
  background: ${themeV2.mixins.v2.color.background.white};
  cursor: pointer;
`;
