import { ColgroupV2, TableV2, TbodyV2, TdV2, TheadV2, themeV2, ThV2, TrV2 } from '@atomica.co/components';
import { TaxDiv } from '@atomica.co/irori';
import { Index, Price, Title } from '@atomica.co/types';
import { isLessThanZero } from '@atomica.co/utils';
import { Typography } from '@material-ui/core';
import React, { useMemo } from 'react';
import styled from 'styled-components';
import {
  BillingSummaryDetail,
  BillingSummaryHeader,
  SubtotalPriceByTaxDiv
} from '../../../converters/billing-v2-converter';
import { getTaxLabel } from '../../../utils/tax-util';

interface P {
  title: Title;
  summary: BillingSummaryHeader;
}

const BillingSummary: React.FC<P> = React.memo(props => {
  const { title, summary } = props;
  const details = useMemo<BillingSummaryDetail[]>(() => summary.details, [summary]);

  const taxIncludedTotalPrice = useMemo((): Price => {
    return summary.taxIncludedTotalPrice;
  }, [summary]);

  const taxExcludedSubtotalPrices = useMemo((): SubtotalPriceByTaxDiv[] => {
    return summary.subtotalPricesByTaxDiv.filter(subtotal => subtotal.taxDiv === TaxDiv.EXCLUDED);
  }, [summary]);

  const taxIncludedSubtotalPrices = useMemo((): SubtotalPriceByTaxDiv[] => {
    return summary.subtotalPricesByTaxDiv.filter(subtotal => subtotal.taxDiv === TaxDiv.INCLUDED);
  }, [summary]);

  const taxExemptSubtotalPrices = useMemo((): SubtotalPriceByTaxDiv[] => {
    return summary.subtotalPricesByTaxDiv.filter(subtotal => subtotal.taxDiv === TaxDiv.EXEMPT);
  }, [summary]);

  return (
    <Container>
      <TitleText>{title}</TitleText>

      <TotalAmount>
        <TotalAmountLabel>請求金額合計</TotalAmountLabel>
        <TotalAmountValue>{taxIncludedTotalPrice.toLocaleString()}</TotalAmountValue>
      </TotalAmount>

      <AmountDetails>
        <TableV2 showBorder={false} shape='circle-top'>
          <ColgroupV2 />
          <ColgroupV2 width={120} />
          <TheadV2>
            <TrV2>
              <ThV2 horizonPadding={8}>品目</ThV2>
              <ThV2 horizonPadding={8} align='right'>
                金額（税抜）
              </ThV2>
            </TrV2>
          </TheadV2>

          <TbodyV2>
            {details.map((detail: BillingSummaryDetail, idx: Index) => (
              <TrV2 key={`detail-tr${idx}`}>
                <TdV2 horizonPadding={8}>
                  <ItemName>{detail.itemName}</ItemName>
                  <Breakdown>{`単価: ¥${detail.unitPrice.toLocaleString()}・${getTaxLabel(
                    detail.taxDiv,
                    detail.taxRate
                  )}`}</Breakdown>
                </TdV2>

                <TdV2 align='right' horizonPadding={8}>
                  <SubtotalPrice isLessThanZero={isLessThanZero(detail.unitPrice)}>
                    {detail.taxDiv === TaxDiv.EXCLUDED
                      ? detail.taxExcludedSubtotalPrice!.toLocaleString()
                      : detail.taxIncludedSubtotalPrice!.toLocaleString()}
                  </SubtotalPrice>
                </TdV2>
              </TrV2>
            ))}
          </TbodyV2>
        </TableV2>

        {taxExcludedSubtotalPrices.map((subtotal: SubtotalPriceByTaxDiv, idx: Index) => (
          <SubtotalByTaxRow key={`subtotal${idx}`}>
            <SubtotalByTax>
              <TotalAmountLabel>{`${getTaxLabel(subtotal.taxDiv, subtotal.taxRate)}対象`}</TotalAmountLabel>
              <TotalAmountValue>{subtotal.taxExcludedPrice.toLocaleString()}</TotalAmountValue>
            </SubtotalByTax>
          </SubtotalByTaxRow>
        ))}

        {taxIncludedSubtotalPrices.map((subtotal: SubtotalPriceByTaxDiv, idx: Index) => (
          <SubtotalByTaxRow key={`subtotal-row${idx}`}>
            <SubtotalByTax key={`excluded-subtotal${idx}`}>
              <TotalAmountLabel>{`${getTaxLabel(subtotal.taxDiv, subtotal.taxRate)}対象`}</TotalAmountLabel>
              <TotalAmountValue>{subtotal.taxIncludedPrice?.toLocaleString()}</TotalAmountValue>
            </SubtotalByTax>

            {subtotal.taxDiv === TaxDiv.INCLUDED && (
              <SubtotalByTax key={`included-subtotal${idx}`}>
                <SubtotalBrakdownByTax>（内消費税等</SubtotalBrakdownByTax>
                <SubtotalBrakdownByTax>
                  {`¥${(subtotal.taxIncludedPrice - subtotal.taxExcludedPrice).toLocaleString()}）`}
                </SubtotalBrakdownByTax>
              </SubtotalByTax>
            )}
          </SubtotalByTaxRow>
        ))}

        {taxExemptSubtotalPrices.map((subtotal: SubtotalPriceByTaxDiv, idx: Index) => (
          <SubtotalByTax key={`exempt-subtotal${idx}`}>
            <TotalAmountLabel>{`${getTaxLabel(subtotal.taxDiv, subtotal.taxRate)}対象`}</TotalAmountLabel>
            <TotalAmountValue>{subtotal.taxIncludedPrice!.toLocaleString()}</TotalAmountValue>
          </SubtotalByTax>
        ))}

        {taxExcludedSubtotalPrices.map((subtotal: SubtotalPriceByTaxDiv, idx: Index) => (
          <SubtotalByTaxRow key={`subtotal${idx}`}>
            <SubtotalByTax>
              <TotalAmountLabel>{`消費税 ${subtotal.taxRate}%`}</TotalAmountLabel>
              <TotalAmountValue>
                {(subtotal.taxIncludedPrice - subtotal.taxExcludedPrice).toLocaleString()}
              </TotalAmountValue>
            </SubtotalByTax>
          </SubtotalByTaxRow>
        ))}

        <Separater />

        <SubtotalByTaxRow>
          <SubtotalByTax>
            <TotalAmountLabel>請求金額合計</TotalAmountLabel>
            <TotalAmountValue>{taxIncludedTotalPrice.toLocaleString()}</TotalAmountValue>
          </SubtotalByTax>
        </SubtotalByTaxRow>
      </AmountDetails>
    </Container>
  );
});

BillingSummary.displayName = 'BillingSummary';
export default BillingSummary;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${themeV2.mixins.v2.spacing * 2}px;
`;

const TitleText = styled(Typography)`
  ${themeV2.mixins.v2.typography.title.xLarge};
  color: ${themeV2.mixins.v2.color.font.black};
  width: 100%;
`;

const TotalAmount = styled.div`
  display: flex;
  justify-content: space-between;
  border-radius: 6px;
  background-color: ${themeV2.mixins.v2.color.background.lightGray};
  padding: ${themeV2.mixins.v2.spacing * 2}px ${themeV2.mixins.v2.spacing * 1.5}px;
`;

const TotalAmountLabel = styled.div`
  ${themeV2.mixins.v2.typography.title.medium};
  color: ${themeV2.mixins.v2.color.font.black};
`;

const TotalAmountValue = styled.div`
  ${themeV2.mixins.v2.typography.title.medium};
  color: ${themeV2.mixins.v2.color.font.black};
  &:before {
    content: '¥';
  }
`;

const AmountDetails = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${themeV2.mixins.v2.spacing * 2}px;
`;

const ItemName = styled.div`
  ${themeV2.mixins.v2.typography.body.medium};
`;

const Breakdown = styled.div`
  ${themeV2.mixins.v2.typography.body.small};
`;

const SubtotalPrice = styled.div<{ isLessThanZero: boolean }>`
  ${({ isLessThanZero }) => isLessThanZero && `color: ${themeV2.mixins.v2.color.font.pink};`}
  &:before {
    content: '¥';
  }
`;

const SubtotalByTaxRow = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${themeV2.mixins.v2.spacing}px;
`;

const SubtotalByTax = styled.div`
  display: flex;
  justify-content: space-between;
  ${themeV2.mixins.v2.typography.body.large};
`;

const SubtotalBrakdownByTax = styled.div`
  ${themeV2.mixins.v2.typography.body.small};
  color: ${themeV2.mixins.v2.color.font.gray};
`;

const Separater = styled.div`
  height: 1px;
  border-bottom: 1px solid ${themeV2.mixins.v2.color.border.gray};
  width: 100%;
`;
