import {
  MOBILE_MAX_WIDTH,
  MOBILE_MIN_WIDTH,
  theme,
  useSafeCallback,
  useSafeState,
  useUnmountRef
} from '@atomica.co/components';
import {
  BaseDto,
  CONTRACT_ID,
  FETCH_CONTRACT,
  FetchContractRequest,
  FetchContractResponse,
  SAVE_CONTRACT_MEMBERS,
  SaveContractMembersRequest,
  SaveContractMembersResponse,
  User
} from '@atomica.co/irori';
import { builder } from '@atomica.co/utils';
import { Typography } from '@material-ui/core';
import React, { useEffect } from 'react';
import styled from 'styled-components';
import Screen from '../../components/screen/Screen';
import useCommonRequest from '../../redux/hooks/useCommonRequest';
import usePath from '../../redux/hooks/usePath';
import mojaco from './../../assets/mojaco/mojaco_thanks.png';

interface P {
  base: BaseDto;
  user: User;
}

const RegisterContractMemberScreen: React.FC<P> = React.memo(props => {
  const { base, user } = props;
  const { params } = usePath();
  const unmountRef = useUnmountRef();
  const [loaded, setLoaded] = useSafeState<boolean>(unmountRef, false);
  const { commonRequest } = useCommonRequest();

  const initialize = useSafeCallback(async (): Promise<void> => {
    const contractId = params[CONTRACT_ID];
    const request = builder<FetchContractRequest>().contractId(contractId).build();
    const response = await commonRequest<FetchContractRequest, FetchContractResponse>(FETCH_CONTRACT, request);
    const membersRequest = builder<SaveContractMembersRequest>()
      .contract(response.contract!)
      .userIds([user.userId])
      .build();
    await commonRequest<SaveContractMembersRequest, SaveContractMembersResponse>(SAVE_CONTRACT_MEMBERS, membersRequest);

    setLoaded(true);
  }, [base, commonRequest, params, setLoaded, user]);

  useEffect(() => {
    initialize();
  }, [initialize]);

  return (
    <Screen loading={!loaded} className='register-contract-member-screen'>
      <Container>
        <Content>
          <MessageWrapper>
            <StyledMessage>登録が完了しました！</StyledMessage>
          </MessageWrapper>

          <Mojaco src={mojaco} />
        </Content>
      </Container>
    </Screen>
  );
});

RegisterContractMemberScreen.displayName = 'RegisterContractMemberScreen';
export default RegisterContractMemberScreen;

const Container = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;

  @supports (height: 1dvh) {
    height: 100dvh;
  }
`;

const Content = styled.div`
  width: 100vw;
  min-width: ${MOBILE_MIN_WIDTH}px;
  max-width: ${MOBILE_MAX_WIDTH}px;
  height: 100%;
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
`;

const MessageWrapper = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  flex-flow: column;
  justify-content: center;
  padding: ${theme.mixins.spacing * 3}px ${theme.mixins.spacing}px;
`;

const StyledMessage = styled(Typography)`
  font-size: ${theme.mixins.typography.fontSize.twenty}px;
  font-weight: ${theme.mixins.typography.fontWeight.sevenHundreds};
  font-family: ${theme.mixins.typography.fontFamily};
  text-align: center;
  user-select: none;
`;

const Mojaco = styled.img`
  width: 100%;
  height: auto;
  object-fit: cover;
  user-select: none;
  padding: ${theme.mixins.spacing}px;
`;
