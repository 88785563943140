import { useSafeCallback, useSafeState, useUnmountRef } from '@atomica.co/components';
import {
  BaseId,
  DELETE_QUESTIONNAIRE_FOR_ADMIN,
  DeleteQuestionnaireForAdminRequest,
  FETCH_QUESTIONNAIRE,
  FETCH_QUESTIONNAIRE_FOR_ADMIN,
  FetchQuestionnaireForAdminRequest,
  FetchQuestionnaireForAdminResponse,
  FetchQuestionnaireRequest,
  FetchQuestionnaireResponse,
  Questionnaire,
  QuestionnaireAnswer,
  QuestionnaireAnswerDetail,
  QuestionnaireId,
  QuestionnaireItemId,
  QuestionnaireItemType,
  SAVE_QUESTIONNAIRE_ANSWER,
  SaveQuestionnaireAnswerRequest,
  SaveQuestionnaireAnswerResponse
} from '@atomica.co/irori';
import { UserId } from '@atomica.co/types';
import { builder, hasLength, isEmpty, uuid } from '@atomica.co/utils';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import CommonRequest from '../../requests/common-request';
import { partialBuilder } from '../../utils/common-util';
import { toQuestionnaireAction } from '../actions/questionnaire-action';
import useCommonRequest from './useCommonRequest';
import useUser from './useUser';

export interface CachedQuestionnaireInfo {
  cachedAnswer: QuestionnaireAnswer;
  invalidItemIds: QuestionnaireItemId[];
}

interface CachedQuestionnaireInfoForSelector {
  cachedQuestionnaireInfo: CachedQuestionnaireInfo;
}

export const getInitialCachedQuestionnaireInfo = (): CachedQuestionnaireInfo => {
  return {
    cachedAnswer: builder<QuestionnaireAnswer>().questionnaireAnswerId(uuid()).questionnaireAnswerDetails([]).build(),
    invalidItemIds: []
  };
};

interface P {
  isLoading: boolean;
  invalidItemIds: QuestionnaireItemId[];
  cachedAnswer: QuestionnaireAnswer;
  saveCachedAnswer: (answer: QuestionnaireAnswer) => void;
  clearCachedAnswer: () => void;
  fetchQuestionnaire: (questionnaireId: QuestionnaireId, userId?: UserId) => Promise<Questionnaire | undefined>;
  fetchQuestionnaireForAdmin: (
    questionnaireId: QuestionnaireId,
    baseId: BaseId,
    userId?: UserId
  ) => Promise<Questionnaire | undefined>;

  saveAnswer: () => Promise<void>;
  validateAnswer: () => boolean;
  deleteQuestionnaire: (questionnaireId: QuestionnaireId, baseId: BaseId) => Promise<void>;
}

const isValidAnswer = (type: QuestionnaireItemType, detail: QuestionnaireAnswerDetail | undefined): boolean => {
  switch (type) {
    case QuestionnaireItemType.RADIO:
    case QuestionnaireItemType.CHECKBOX:
      return !!detail;
    case QuestionnaireItemType.TEXT:
      return !!detail && !isEmpty(detail.textAnswer);
  }
};

function useQuestionnaire(): P {
  const { user } = useUser();
  const { commonRequest } = useCommonRequest();

  const cachedAnswer = useSelector(
    (state: CachedQuestionnaireInfoForSelector) => state.cachedQuestionnaireInfo.cachedAnswer,
    shallowEqual
  );
  const invalidItemIds = useSelector(
    (state: CachedQuestionnaireInfoForSelector) => state.cachedQuestionnaireInfo.invalidItemIds,
    shallowEqual
  );
  const dispatch = useDispatch();

  const unmountRef = useUnmountRef();
  const [isLoading, setIsLoading] = useSafeState<boolean>(unmountRef, false);

  const saveCachedAnswer = useSafeCallback(
    (cachedAnswer: QuestionnaireAnswer): void => {
      dispatch(toQuestionnaireAction({ cachedAnswer, invalidItemIds }));
    },
    [dispatch, invalidItemIds]
  );

  const clearCachedAnswer = useSafeCallback((): void => {
    dispatch(toQuestionnaireAction(getInitialCachedQuestionnaireInfo()));
  }, [dispatch]);

  const fetchQuestionnaireForAdmin = useSafeCallback(
    async (questionnaireId: QuestionnaireId, baseId: BaseId, userId?: UserId): Promise<Questionnaire | undefined> => {
      setIsLoading(true);

      const baseRequest = builder<FetchQuestionnaireForAdminRequest>()
        .baseId(baseId)
        .questionnaireId(questionnaireId)
        .build();
      const request = userId ? partialBuilder(baseRequest, { userId }) : baseRequest;
      // await getBsQuestionnaire().fetchQuestionnaireForAdmin(baseId, questionnaireId, request);
      const { questionnaire } = await commonRequest<
        FetchQuestionnaireForAdminRequest,
        FetchQuestionnaireForAdminResponse
      >(FETCH_QUESTIONNAIRE_FOR_ADMIN, request);
      setIsLoading(false);
      return questionnaire;
    },
    [commonRequest, setIsLoading]
  );

  const fetchQuestionnaire = useSafeCallback(
    async (questionnaireId: QuestionnaireId, userId?: UserId): Promise<Questionnaire | undefined> => {
      setIsLoading(true);

      const baseRequest = builder<FetchQuestionnaireRequest>().questionnaireId(questionnaireId).build();
      const request = userId ? partialBuilder(baseRequest, { userId }) : baseRequest;
      const { questionnaire } = await commonRequest<FetchQuestionnaireRequest, FetchQuestionnaireResponse>(
        FETCH_QUESTIONNAIRE,
        request
      );
      if (questionnaire) setIsLoading(false);
      return questionnaire;
    },
    [commonRequest, setIsLoading]
  );

  const validateAnswer = useSafeCallback((): boolean => {
    const invalidItemIds: QuestionnaireItemId[] = [];
    const questionnaireItems = cachedAnswer.questionnaire?.questionnaireItems;
    if (!questionnaireItems) return false;
    for (const item of questionnaireItems) {
      if (!item.isAnswerRequired) continue;
      const { questionnaireAnswerDetails } = cachedAnswer;
      const detail = questionnaireAnswerDetails?.find(
        detail => detail.questionnaireItem?.questionnaireItemId === item.questionnaireItemId
      );
      if (isValidAnswer(item.type, detail)) continue;
      invalidItemIds.push(item.questionnaireItemId);
    }
    dispatch(toQuestionnaireAction({ cachedAnswer, invalidItemIds: invalidItemIds }));
    return !hasLength(invalidItemIds);
  }, [cachedAnswer, dispatch]);

  const saveAnswer = useSafeCallback(async (): Promise<void> => {
    setIsLoading(true);
    const answerToSave = partialBuilder(cachedAnswer, { answeredUser: user });
    const request = builder<SaveQuestionnaireAnswerRequest>().questionnaireAnswer(answerToSave).build();
    const response = await CommonRequest.call<SaveQuestionnaireAnswerRequest, SaveQuestionnaireAnswerResponse>(
      SAVE_QUESTIONNAIRE_ANSWER,
      request
    );
    if (response) setIsLoading(false);
  }, [cachedAnswer, setIsLoading, user]);

  const deleteQuestionnaire = useSafeCallback(
    async (questionnaireId: QuestionnaireId, baseId: BaseId): Promise<void> => {
      setIsLoading(true);
      const request = builder<DeleteQuestionnaireForAdminRequest>()
        .baseId(baseId)
        .questionnaireId(questionnaireId)
        .build();
      const response = await commonRequest<DeleteQuestionnaireForAdminRequest, DeleteQuestionnaireForAdminRequest>(
        DELETE_QUESTIONNAIRE_FOR_ADMIN,
        request
      );
      if (response) setIsLoading(false);
    },
    [commonRequest, setIsLoading]
  );

  return {
    isLoading,
    invalidItemIds,
    cachedAnswer,
    saveCachedAnswer,
    clearCachedAnswer,
    fetchQuestionnaire,
    fetchQuestionnaireForAdmin,
    saveAnswer,
    validateAnswer,
    deleteQuestionnaire
  };
}

export default useQuestionnaire;
