import { MultiSearchBoxV2, themeV2, useSafeCallback } from '@atomica.co/components';
import { BaseDto, User, UserType, WithBaseId, toFullName } from '@atomica.co/irori';
import { Name, Word } from '@atomica.co/types';
import { ZERO, builder } from '@atomica.co/utils';
import React, { useMemo } from 'react';
import styled from 'styled-components';
import useSearchedUsers from '../../redux/hooks/useSearchedUsers';
import { SearchUsersForWishForAdminParams } from '../../__generated/model';

const LIMIT = 10;
const OFFSET = 0;

interface P {
  editable?: boolean;
  base: BaseDto;
  toUser: User[];
  setToUser: React.Dispatch<React.SetStateAction<User[]>>;
}

const PersonInCharge: React.FC<P> = React.memo(props => {
  const { editable = false, base, toUser, setToUser } = props;

  const { searchedUsers, search, clear } = useSearchedUsers();
  const tagsToSave = useMemo<Name[]>(() => toUser.map(user => toFullName(user)), [toUser]);
  const searchedTags = useMemo<Name[]>(() => searchedUsers.map(user => toFullName(user)), [searchedUsers]);

  const handleTagChanged = useSafeCallback(
    async (word: Word): Promise<void> => {
      if (!word) {
        clear();
        return;
      }

      const request = builder<WithBaseId<SearchUsersForWishForAdminParams>>()
        .baseId(base.baseId)
        .limit(LIMIT)
        .offset(OFFSET)
        .userWord(word)
        .userTypes([UserType.ADMIN])
        .build();
      await search(request);
    },
    [base, clear, search]
  );

  const handleTagSelected = useSafeCallback(
    (words: Word[]): void => {
      setToUser(prevUsers => {
        const userNames = [...searchedUsers.map(user => toFullName(user)), ...prevUsers.map(user => toFullName(user))];
        const users = [...searchedUsers, ...prevUsers].filter(
          (user, index) => !userNames.includes(toFullName(user), index + 1)
        );
        return users.filter(opt => words.includes(toFullName(opt)));
      });
      clear();
    },
    [setToUser, clear, searchedUsers]
  );

  return (
    <Frame isEditable={editable}>
      <MultiSearchBoxV2
        isEditable={editable}
        words={tagsToSave}
        options={searchedTags}
        onChange={handleTagChanged}
        onSelect={handleTagSelected}
      />
    </Frame>
  );
});

PersonInCharge.displayName = ' PersonInCharge';
export default PersonInCharge;

const Frame = styled.div<{ isEditable: boolean }>`
  max-width: 309px;
  border: ${props => (props.isEditable ? `1px solid ${themeV2.mixins.v2.color.border.gray}` : 'none')};
  padding: ${props => (props.isEditable ? themeV2.mixins.v2.spacing * 2 : ZERO)}px;
  border-radius: 8px;
  background: ${themeV2.mixins.v2.color.background.white};
`;
