import {
  CircularLoader,
  SearchBoxV2,
  customMedia,
  themeV2,
  useSafeCallback,
  useSafeState,
  useUnmountRef
} from '@atomica.co/components';
import { BaseDto, Space, SpaceId, User } from '@atomica.co/irori';
import { Index, Name, Word } from '@atomica.co/types';
import { EMPTY, hasLength } from '@atomica.co/utils';
import React, { useEffect, useMemo } from 'react';
import styled from 'styled-components';
import media from 'styled-media-query';
import SpaceCard from '../card/SpaceCard';
import SpaceCardBody from '../card/SpaceCardBody';
import SpaceCardWithImage from '../card/SpaceCardWithImage';

interface P {
  base: BaseDto;
  user: User | undefined;
  categoryName?: Name;
  isInitialized: boolean;
  isSearching: boolean;
  spaces: Space[];
  selectedSpaceIds: SpaceId[];
  searchSpaces: (searchingWord: Word) => Promise<void>;
  handleSpaceClicked: (spacesId: SpaceId) => void;
}

const SpaceList: React.FC<P> = React.memo(props => {
  const {
    base,
    user,
    categoryName,
    isInitialized,
    isSearching,
    spaces = [],
    selectedSpaceIds,
    searchSpaces,
    handleSpaceClicked
  } = props;

  const unmountRef = useUnmountRef();
  const [loadedSpaceIdsOnImage, setLoadedSpaceIdsOnImage] = useSafeState<SpaceId[]>(unmountRef, []);
  const [failedLoadSpaceIdsOnImage, setFailedLoadSpaceIdsOnImage] = useSafeState<SpaceId[]>(unmountRef, []);
  const [searchingWord, setSearchingWord] = useSafeState<Word>(unmountRef, EMPTY);

  const isAllImagesLoaded = useMemo<boolean>(() => {
    if (!isInitialized) return false;
    if (!hasLength(spaces)) return true;
    const spaceIds = [...failedLoadSpaceIdsOnImage, ...loadedSpaceIdsOnImage];
    for (const space of spaces) {
      if (!spaceIds.includes(space.spaceId)) {
        return false;
      }
    }
    return true;
  }, [failedLoadSpaceIdsOnImage, isInitialized, loadedSpaceIdsOnImage, spaces]);

  const handleImageLoaded = useSafeCallback(
    (spaceId: SpaceId, isLoaded: boolean): void => {
      if (!isLoaded) {
        setFailedLoadSpaceIdsOnImage(spaceIds => {
          const newSpaceIds = [...spaceIds, spaceId];
          return newSpaceIds;
        });
        return;
      }
      setFailedLoadSpaceIdsOnImage(spaceIds => {
        const newFailedSpaceIds = spaceIds.splice(spaceIds.indexOf(spaceId));
        return newFailedSpaceIds;
      });
      setLoadedSpaceIdsOnImage(spaceIds => {
        const newLoadedSpaceIds = [...spaceIds, spaceId];
        return newLoadedSpaceIds;
      });
    },
    [setLoadedSpaceIdsOnImage, setFailedLoadSpaceIdsOnImage]
  );

  const handleSearch = useSafeCallback(
    async (word: Word): Promise<void> => {
      setSearchingWord(word);
      await searchSpaces(word);
    },
    [setSearchingWord, searchSpaces]
  );

  useEffect(() => {
    setLoadedSpaceIdsOnImage([]);
  }, [setLoadedSpaceIdsOnImage, spaces]);

  return (
    <Container>
      <ItemNameWrapper>
        <ItemName>予約対象の選択（1/3）</ItemName>

        <Description>空き状況を確認したい対象を選択してください（複数選択可）</Description>
        <SearchBoxV2 word={searchingWord} onChange={handleSearch} />
      </ItemNameWrapper>

      {(!isInitialized || isSearching) && (
        <LoaderWrapper>
          <CircularLoader />
        </LoaderWrapper>
      )}

      {!isSearching && (
        <SpacesWrapper>
          {hasLength(spaces) &&
            spaces.map((space: Space, idx: Index) => (
              <React.Fragment key={`space-card-${idx}`}>
                {(space.baseResourceCategory?.isImageShownOnUserReservingPage ?? true) ? (
                  <SpaceCardWithImage
                    isAllImagesLoaded={isAllImagesLoaded}
                    failedLoadSpaceIdsOnImage={failedLoadSpaceIdsOnImage}
                    space={space}
                    selectedSpaceIds={selectedSpaceIds}
                    handleSpaceClicked={handleSpaceClicked}
                    handleImageLoaded={handleImageLoaded}
                  >
                    <SpaceCardBody base={base} space={space} user={user} />
                  </SpaceCardWithImage>
                ) : (
                  <SpaceCard id={space.spaceId} selectedIds={selectedSpaceIds} handleClicked={handleSpaceClicked}>
                    <SpaceCardBody base={base} space={space} user={user} />
                  </SpaceCard>
                )}
              </React.Fragment>
            ))}
          {isInitialized && !hasLength(spaces) && (
            <NoResulceMessage>予約可能な{categoryName}が見つかりませんでした。</NoResulceMessage>
          )}
        </SpacesWrapper>
      )}
    </Container>
  );
});

SpaceList.displayName = 'SpaceList';
export default SpaceList;

const Container = styled.div`
  width: 100%;
  height: auto;
  background: ${themeV2.mixins.v2.color.background.white};
  border-radius: 12px;
  padding: ${themeV2.mixins.v2.spacing * 3}px;
  display: flex;
  flex-direction: column;
  gap: ${themeV2.mixins.v2.spacing * 2}px;

  ${customMedia.lessThan('small')`
    padding: ${themeV2.mixins.v2.spacing * 2}px;
  `}
`;

const ItemNameWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${themeV2.mixins.v2.spacing}px;
`;

const ItemName = styled.div`
  ${themeV2.mixins.v2.typography.title.large};

  ${customMedia.lessThan('small')`
    ${themeV2.mixins.v2.typography.title.medium};
  `}
  ${customMedia.lessThan('tiny')`
    ${themeV2.mixins.v2.typography.title.small};
  `}
`;

const Description = styled.div`
  ${themeV2.mixins.v2.typography.body.large};

  ${customMedia.lessThan('small')`
    ${themeV2.mixins.v2.typography.body.medium};
  `}
  ${customMedia.lessThan('tiny')`
    ${themeV2.mixins.v2.typography.body.small};
  `}
`;

const LoaderWrapper = styled.div`
  width: 100%;
  height: 120px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const SpacesWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(330px, 1fr));
  justify-items: center;
  gap: ${themeV2.mixins.v2.spacing * 2}px ${themeV2.mixins.v2.spacing}px;

  ${media.lessThan('small')`
    display: flex;
    flex-direction: column;
    gap: ${themeV2.mixins.v2.spacing * 2}px;
    flex-wrap: wrap;
    justify-content: space-between;
  `};
`;

const NoResulceMessage = styled.div`
  text-align: center;
  ${themeV2.mixins.v2.typography.body.medium};
  padding: ${themeV2.mixins.v2.spacing * 2}px;
`;
