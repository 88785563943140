import {
  ButtonV2,
  ColWidth,
  Component,
  Header,
  PageHeaderV2,
  PAGINATE_HEIGHT,
  SearchListV2,
  styleForFullExpansion,
  themeV2,
  useSafeCallback,
  useSafeState,
  useUnmountRef
} from '@atomica.co/components';
import {
  BaseDto,
  EventIdV2,
  EventV2,
  SEARCH_EVENTS_V2_FOR_ADMIN,
  SearchEventsV2ForAdminRequest,
  SearchEventsV2ForAdminResponse,
  User
} from '@atomica.co/irori';
import { Count, Description, Index, Name, Offset, Word } from '@atomica.co/types';
import { builder, embedIdInPath, EMPTY, ZERO } from '@atomica.co/utils';
import AddIcon from '@material-ui/icons/Add';
import React, { useEffect } from 'react';
import styled from 'styled-components';
import mojaco from '../../assets/mojaco/mojaco_break.png';
import { SearchOption } from '../../enums/event-v2-enum';
import useCommonRequest from '../../redux/hooks/useCommonRequest';
import usePath from '../../redux/hooks/usePath';
import { Path, PATH_IDS } from '../../router/Routes';

const LIMIT = 50;

const COLUMN_WIDTH: ColWidth = { photo: 120, name: 300, description: 'auto' };

const HEADER: Header = { photo: { label: EMPTY }, name: { label: 'イベント名' }, description: { label: '説明' } };

interface EventRow {
  id: EventIdV2;
  photo: JSX.Element;
  name: Name;
  description: Description;
}

interface P {
  base: BaseDto;
  user: User;
  onChange(path: Path): void;
}

const EventV2ListScreen: React.FC<P> = React.memo(props => {
  const { base, onChange } = props;
  const { commonRequest } = useCommonRequest();
  const { openPathInNewTab } = usePath();
  const unmountRef = useUnmountRef();
  const [searchingWord, setSearchingWord] = useSafeState<Word>(unmountRef, EMPTY);
  const [rows, setRows] = useSafeState<EventRow[]>(unmountRef, []);
  const [offset, setOffset] = useSafeState<Offset>(unmountRef, SearchOption.OFFSET);
  const [totalCount, setTotalCount] = useSafeState<Count>(unmountRef, ZERO);
  const [isLoaderShown, setIsLoaderShown] = useSafeState<boolean>(unmountRef, false);
  const [isPhotoExisting, setIsPhotoExisting] = useSafeState<boolean>(unmountRef, true);

  const photoComponent = useSafeCallback((event: EventV2): JSX.Element => {
    return (
      <ImageWrapper>
        <Image
          src={isPhotoExisting && event.photoURL ? event.photoURL : mojaco}
          onError={() => setIsPhotoExisting(false)}
        />
      </ImageWrapper>
    );
  }, []);

  const initialize = useSafeCallback(async (): Promise<void> => {
    setIsLoaderShown(true);
    const request = builder<SearchEventsV2ForAdminRequest>()
      .baseId(base.baseId)
      .limit(LIMIT)
      .offset(offset)
      .word(searchingWord)
      .build();
    const { eventsV2, totalCount } = await commonRequest<SearchEventsV2ForAdminRequest, SearchEventsV2ForAdminResponse>(
      SEARCH_EVENTS_V2_FOR_ADMIN,
      request
    );
    const values = eventsV2.map(event =>
      builder<EventRow>()
        .id(event.eventId)
        .photo(photoComponent(event))
        .name(event.name)
        .description(event.description)
        .build()
    );

    setRows(values);
    setTotalCount(totalCount);
    setIsLoaderShown(false);
  }, [base, searchingWord, offset, setTotalCount, setRows, setIsLoaderShown, photoComponent]);

  useEffect(() => {
    initialize();
  }, [initialize]);

  const handleSearchingWordChanged = useSafeCallback(
    (word: Word): void => {
      setSearchingWord(word);
      setOffset(ZERO);
    },
    [setSearchingWord, setOffset]
  );

  const openEventV2DetailsScreen = useSafeCallback(
    (index: Index): void => {
      openPathInNewTab(embedIdInPath(Path.EVENT_V2_DETAILS, PATH_IDS, [base.baseCode, rows[index].id]));
    },
    [rows, openPathInNewTab, base]
  );

  const openRegisterEventV2Screen = useSafeCallback((): void => {
    onChange(embedIdInPath(Path.REGISTER_EVENT_V2, PATH_IDS, [base.baseCode]));
  }, [onChange, base]);

  return (
    <Component style={styleForFullExpansion} className='event-list-screen'>
      <Container>
        <Content>
          <PageHeaderV2
            title='イベント'
            rightComponent={
              <ButtonV2
                size='medium'
                type='primary'
                startIcon={<AddIcon />}
                label='新規作成'
                onClick={openRegisterEventV2Screen}
              />
            }
          />

          <SearchListV2
            name='イベント'
            colWidth={COLUMN_WIDTH}
            header={HEADER}
            rows={rows}
            offset={offset}
            limit={LIMIT}
            totalCount={totalCount}
            placeholder='イベント名・説明で検索'
            searchingWord={searchingWord}
            isLoaderShown={isLoaderShown}
            onChange={handleSearchingWordChanged}
            onClickRow={openEventV2DetailsScreen}
            setOffset={setOffset}
          />
        </Content>
      </Container>
    </Component>
  );
});

EventV2ListScreen.displayName = 'EventV2ListScreen';
export default EventV2ListScreen;

const Container = styled.div`
  width: 100%;
  height: calc(100% - ${PAGINATE_HEIGHT}px);
  display: flex;
  padding: ${themeV2.mixins.v2.spacing * 2}px ${themeV2.mixins.v2.spacing * 3}px;
`;

const Content = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: ${themeV2.mixins.v2.spacing * 2}px;
`;

const ImageWrapper = styled.div`
  width: inherit;
  height: inherit;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Image = styled.img`
  width: auto;
  height: auto;
  max-width: 100%;
  max-height: 100%;
`;
