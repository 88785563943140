import {
  SpaceCategory,
  SpacePublishOption,
  SpaceReservationMembersForSearching,
  SpaceReservationStatus,
  SpaceReservationStatusForSearching,
  SpaceReservationType
} from '@atomica.co/irori';
import { Label } from '@atomica.co/types';
import { SpaceReservationActionEnum } from '../enums/space-enum';

export const ENTRANCE_LABEL = 'エントランス';

export const SPACE_RESERVATION_STATUS_LABEL: Record<SpaceReservationStatus, Label> = {
  [SpaceReservationStatus.CONFIRMED]: '確定済み',
  [SpaceReservationStatus.PROVISIONAL]: '仮予約',
  [SpaceReservationStatus.CANCELED]: 'キャンセル',
  [SpaceReservationStatus.REJECTED]: '管理者キャンセル',
  [SpaceReservationStatus.PAYMENT_IN_PROGRESS]: '支払い中',
  [SpaceReservationStatus.ADDITIONAL_PAYMENT_IN_PROGRESS]: '支払い中',
  [SpaceReservationStatus.HOLD_FOR_CHANGE]: '日時変更中',
  [SpaceReservationStatus.BLOCKED_GOOGLE_CALENDER]: 'カレンダーブロック',
  [SpaceReservationStatus.REMOVED_GOOGLE_CALENDAR]: 'カレンダー削除'
};

export const SPACE_RESERVATION_STATUS_FOR_SEARCHING_LABEL: Record<SpaceReservationStatusForSearching, Label> = {
  [SpaceReservationStatusForSearching.ALL]: 'すべてのステータス',
  [SpaceReservationStatus.CONFIRMED]: '確定済み',
  [SpaceReservationStatus.PROVISIONAL]: '仮予約',
  [SpaceReservationStatusForSearching.PROVISIONAL_OR_CONFIRMED]: '仮予約・確定済み',
  [SpaceReservationStatus.CANCELED]: 'キャンセル',
  [SpaceReservationStatus.REJECTED]: '管理者キャンセル'
};

export const SPACE_RESERVATION_ACTION_LABEL: Record<SpaceReservationActionEnum, Label> = {
  [SpaceReservationActionEnum.CONFIRM]: '一括で予約を確定',
  [SpaceReservationActionEnum.REJECT]: '一括で予約を却下'
};

export const SPACE_RESERVATION_TYPE_LABEL: Record<SpaceReservationType, Label> = {
  [SpaceReservationType.TIME]: '時間',
  [SpaceReservationType.DATE]: '日'
};

export const SPACE_RESERVATION_MEMBER_FOR_SEARCHING_LABEL: Record<SpaceReservationMembersForSearching, Label> = {
  [SpaceReservationMembersForSearching.ALL]: 'すべてのユーザー',
  [SpaceReservationMembersForSearching.MONTHLY_MEMBER]: '月額会員',
  [SpaceReservationMembersForSearching.DROP_IN]: 'ドロップインユーザー'
};

export const SPACE_PUBLISH_OPTION_LABEL = {
  [SpacePublishOption.OPEN]: '全体に公開',
  [SpacePublishOption.CONTRACT_LIMITED]: '契約者限定で公開'
} as const satisfies Record<Exclude<SpacePublishOption, typeof SpacePublishOption.AUTHORITY_LIMITED>, Label>;

export const SPACE_PUBLISH_OPTION_LABEL_FOR_LIST = {
  all: 'すべての公開範囲',
  [SpacePublishOption.OPEN]: '全体',
  [SpacePublishOption.CONTRACT_LIMITED]: '契約者限定'
} as const satisfies Record<Exclude<SpacePublishOption, typeof SpacePublishOption.AUTHORITY_LIMITED> | 'all', Label>;

export const SPACE_IS_INVISIBLE_LABEL = {
  '0': '表示する',
  '1': '表示しない'
} as const;

export const SPACE_CATEGORY_LABEL = {
  [SpaceCategory.CONFERENCE]: '会議室',
  [SpaceCategory.DEVICE]: '機器',
  [SpaceCategory.ENTRANCE]: 'エントランス'
} as const satisfies Record<SpaceCategory, Label>;
