import { Code } from '@atomica.co/types';
import { AccountTab } from '../enums/account-enum';
import { Path } from '../router/Routes';

export const isAuthGuarded = (path: Path | undefined): boolean => {
  switch (path) {
    case Path.SIGN_UP:
    case Path.SIGN_IN:
    case Path.LIFF:
    case Path.JOIN:
    case Path.REGISTER_ACCOUNT:
    case Path.REGISTER_ACCOUNT_V2:
    case Path.SIGN_OUT:
    case Path.REDIRECT:
    case Path.ACCOUNT_EVENT_DEATIL:
    case Path.ACCOUNT_EVENT_PARTICIPANTS:
    case Path.RESERVE_SPACE_NO_GUARDED:
    case Path.ACCOUNT_EVENTS_NO_GUARDED:
    case Path.COMPLETED_REGISTER_CREDIT_CARD:
      return false;

    case Path.ACCOUNT_HOME:
    case Path.ACCOUNT_SHOT:
    case Path.ACCOUNT_SHARE:
    case Path.RESERVE_SPACE:
    case Path.RESERVE_SPACE_CONFIRM:
    case Path.RESERVE_SPACE_EDIT:
    case Path.RESERVE_SPACE_EXTENSION:
    case Path.RESERVE_SPACE_AUTO_CONFIRM:
    case Path.RESERVE_SPACE_COMPLETE:
    case Path.ACCOUNT_EVENTS:
    case Path.ACCOUNT_RESERVATION_DETAIL:
    case Path.ACCOUNT_PROFILE:
    case Path.ACCOUNT_PROFILE_V2:
    case Path.ACCOUNT_MY_SCHEDULES:
    case Path.ACCOUNT_EVENT_ENTRY_FORM:
    case Path.ACCOUNT_POINT_USAGE_LOGS:
    case Path.REGISTER_CONTRACT_MEMBER:
    case Path.ACCOUNT_WISH_LIST:
    case Path.ACCOUNT_SETTING:
    case Path.SHOT_RECEPTION:
    case Path.RECORD_ACCESS:
    case Path.TIMELINE:
    case Path.VISITORS:
    case Path.USER_SEARCH_V2:
    case Path.USER_SEARCH:
    case Path.USER_OVERVIEW:
    case Path.USER_ROOM_ENTRY_EXIT_HISTORY:
    case Path.USER_WISH:
    case Path.USER_DIALOGUE_LOG:
    case Path.USER_EVENT_LOG:
    case Path.USER_SPACE_USAGE_LOG:
    case Path.USER_CHECK_IN_HISTORY:
    case Path.USER_POINT_USAGE_LOG:
    case Path.USER_BULK_UPDATE:
    case Path.CONTRACT_LIST:
    case Path.CONTRACT_DETAILS:
    case Path.REGISTER_CONTRACT:
    case Path.CONTRACT_LIST_V2:
    case Path.CONTRACT_DETAILS_V2_DETAIL:
    case Path.CONTRACT_DETAILS_V2_BILLING:
    case Path.CONTRACT_DETAILS_V2_MEMBER:
    case Path.CONTRACT_DETAILS_V2_LOGS:
    case Path.CONTRACT_MEMBER_REGISTERED_V2:
    case Path.REGISTER_CONTRACT_V2:
    case Path.EDIT_CONTRACT_V2:
    case Path.BILLING_LIST_V2:
    case Path.BILLING_DETAILS_V2_DETAIL:
    case Path.BILLING_DETAILS_V2_SPACE_USAGES:
    case Path.BILLING_DETAILS_V2_ACCESSES:
    case Path.BILLING_DETAILS_V2_LOGS:
    case Path.REGISTER_BILLING_V2:
    case Path.EDIT_BILLING_V2:
    case Path.EVENT_V2_LIST:
    case Path.REGISTER_EVENT_V2:
    case Path.EDIT_EVENT_V2:
    case Path.EVENT_V2_DETAILS:
    case Path.REGISTER_EVENT_SCHEDULE_V2:
    case Path.EDIT_EVENT_SCHEDULE_V2:
    case Path.EVENT_SCHEDULE_V2_DETAILS:
    case Path.EDIT_EVENT_V2_DETAILS:
    case Path.EVENT_SCHEDULE_PARTICIPANTS_V2:
    case Path.EVENT_SCHEDULE_GROUPS_V2:
    case Path.QUESTIONNAIRE_LIST:
    case Path.REGISTER_QUESTIONNAIRE:
    case Path.EDIT_QUESTIONNAIRE:
    case Path.QUESTIONNAIRE_DETAILS:
    case Path.QUESTIONNAIRE_ANSWER:
    case Path.RESERVATION_LIST:
    case Path.RESERVATION_DETAIL:
    case Path.ENTRY_FORM_MESSAGES:
    case Path.REMIND_MESSAGES:
    case Path.DIARY:
    case Path.EXPORT:
    case Path.REGISTERED:
    case Path.WISH_LIST:
    case Path.REGISTER_WISH:
    case Path.WISH_DETAILS:
    case Path.EDIT_WISH:
    case Path.SHOT_MASTER:
    case Path.RESOURCE_MASTER:
    case Path.EDIT_RESOURCE_MASTER:
    case Path.REGISTER_ACCOUNT_WISH:
    case Path.EDIT_ACCOUNT_WISH:
    case Path.MEMBERS:
    case Path.MEMBER_PROFILE:
    case Path.RESEND_REGISTER_CREDIT_CARD:
    case Path.DROP_IN:
    case Path.DROP_IN_CONFIRM:
      return true;

    default:
      return false;
  }
};

export const toAccountTab = (path: Path, isShareMode: boolean): AccountTab => {
  switch (path) {
    case Path.ACCOUNT_HOME:
      return isShareMode ? AccountTab.SHARE : AccountTab.HOME;

    case Path.ACCOUNT_SHOT:
      return AccountTab.SHOT;

    case Path.ACCOUNT_EVENTS:
      return AccountTab.EVENTS;

    case Path.RESERVE_SPACE:
      return AccountTab.RESERVE;

    default:
      throw new Error(`${path} is out of target`);
  }
};

export const fromAccountTab = (tabCode: Code): Path => {
  switch (tabCode) {
    case AccountTab.HOME:
      return Path.ACCOUNT_HOME;

    case AccountTab.SHOT:
      return Path.ACCOUNT_SHOT;

    case AccountTab.EVENTS:
      return Path.ACCOUNT_EVENTS;

    case AccountTab.RESERVE:
      return Path.RESERVE_SPACE;

    case AccountTab.MY_PAGE:
      return Path.ACCOUNT_PROFILE_V2;

    case AccountTab.MEMBERS:
      return Path.MEMBERS;

    default:
      throw new Error(`${tabCode} is out of target`);
  }
};
