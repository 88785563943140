import {
  ButtonV2,
  DialogV2,
  InputWithLabelV2,
  ScreenLoaderV2,
  themeV2,
  useSafeCallback,
  useSafeState,
  useUnmountRef
} from '@atomica.co/components';
import {
  BaseId,
  EventScheduleGroupV2,
  EventScheduleV2,
  SAVE_EVENT_SCHEDULE_GROUPS_V2_FOR_ADMIN,
  SaveEventScheduleGroupsV2ForAdminRequest,
  SaveEventScheduleGroupsV2ForAdminResponse,
  SEARCH_EVENT_SCHEDULE_GROUPS_V2_FOR_ADMIN,
  SearchEventScheduleGroupsV2ForAdminRequest,
  SearchEventScheduleGroupsV2ForAdminResponse
} from '@atomica.co/irori';
import { Name } from '@atomica.co/types';
import { builder, EMPTY, isEmpty, uuid } from '@atomica.co/utils';
import React, { useEffect, useMemo } from 'react';
import styled from 'styled-components';
import { toEventScheduleGroupV2 } from '../../../converters/event-schedule-group-v2-converter';
import useCommonRequest from '../../../redux/hooks/useCommonRequest';

type Mode = 'register' | 'edit';

interface P {
  baseId: BaseId;
  isOpen: boolean;
  eventScheduleV2: EventScheduleV2;
  eventScheduleGroupV2?: EventScheduleGroupV2;
  mode: Mode;
  onChange?: (groupName: Name) => void;
  onClose(isUpdated: boolean): void;
}

const RegisterGroupModal: React.FC<P> = React.memo(props => {
  const { baseId, isOpen, eventScheduleV2, eventScheduleGroupV2, mode, onChange, onClose } = props;

  const unmountRef = useUnmountRef();
  const { commonRequest } = useCommonRequest();
  const [eventScheduleGroups, setEventScheduleGroups] = useSafeState<EventScheduleGroupV2[]>(unmountRef, []);
  const [groupName, setGroupName] = useSafeState<Name>(unmountRef, EMPTY);
  const [isScreenLoaderShown, setIsScreenLoaderShown] = useSafeState<boolean>(unmountRef, false);

  const isDuplicated = useMemo<boolean>(
    () =>
      eventScheduleGroups.some(
        group => group.name === groupName && group.eventScheduleGroupId !== eventScheduleGroupV2?.eventScheduleGroupId
      ),
    [eventScheduleGroups, eventScheduleGroupV2, groupName]
  );
  const isEdit = useMemo<boolean>(() => mode === 'edit', [mode]);

  const searchEventScheduleGroups = useSafeCallback(async (): Promise<void> => {
    const request = builder<SearchEventScheduleGroupsV2ForAdminRequest>()
      .baseId(baseId)
      .eventScheduleIdV2(eventScheduleV2.eventScheduleId)
      .word(EMPTY)
      .build();
    const response = await commonRequest<
      SearchEventScheduleGroupsV2ForAdminRequest,
      SearchEventScheduleGroupsV2ForAdminResponse
    >(SEARCH_EVENT_SCHEDULE_GROUPS_V2_FOR_ADMIN, request);
    setEventScheduleGroups(response.eventScheduleGroupsV2);
  }, [baseId, commonRequest, eventScheduleV2, setEventScheduleGroups]);

  const saveEventScheduleGroup = useSafeCallback(async (): Promise<void> => {
    if (isDuplicated) return;
    setIsScreenLoaderShown(true);

    const groupToSave = toEventScheduleGroupV2(
      isEdit ? eventScheduleGroupV2!.eventScheduleGroupId : uuid(),
      groupName,
      eventScheduleV2
    );
    const request = builder<SaveEventScheduleGroupsV2ForAdminRequest>()
      .baseId(baseId)
      .eventScheduleGroupsV2([groupToSave])
      .build();
    await commonRequest<SaveEventScheduleGroupsV2ForAdminRequest, SaveEventScheduleGroupsV2ForAdminResponse>(
      SAVE_EVENT_SCHEDULE_GROUPS_V2_FOR_ADMIN,
      request
    );

    if (onChange) onChange(groupName);
    setGroupName(EMPTY);
    onClose(false);
    setIsScreenLoaderShown(false);
  }, [
    groupName,
    isDuplicated,
    isEdit,
    eventScheduleV2,
    eventScheduleGroupV2,
    commonRequest,
    onClose,
    onChange,
    setGroupName,
    setIsScreenLoaderShown
  ]);

  const handleModalClosed = useSafeCallback((): void => {
    setGroupName(EMPTY);
    onClose(false);
  }, [onClose, setGroupName]);

  useEffect(() => {
    setGroupName(eventScheduleGroupV2?.name || EMPTY);
    if (isOpen) searchEventScheduleGroups();
  }, [eventScheduleGroupV2, isOpen, searchEventScheduleGroups, setGroupName]);

  const footerButtons = useMemo<React.ReactNode[]>(() => {
    return [
      <ButtonV2 key='cancelButton' type='default' label='キャンセル' onClick={handleModalClosed} />,
      <ButtonV2
        key='saveButton'
        width={105}
        disabled={isDuplicated || isEmpty(groupName)}
        type='primary'
        label={isEdit ? '保存' : '追加'}
        onClick={saveEventScheduleGroup}
      />
    ];
  }, [groupName, handleModalClosed, isDuplicated, isEdit, saveEventScheduleGroup]);

  return (
    <DialogV2
      isOpen={isOpen}
      headerLabel={isEdit ? 'グループ名を編集' : 'グループを追加'}
      buttonsOnTheRight={footerButtons}
      onClose={() => onClose(false)}
      width={480}
    >
      <FormWrapper>
        <InputWithLabelV2 required text='グループ名' value={groupName} onChange={setGroupName} />
        {isDuplicated && <ErrorMsg>グループ名が重複しています</ErrorMsg>}
      </FormWrapper>
      <ScreenLoaderV2 loading={isScreenLoaderShown} />
    </DialogV2>
  );
});

RegisterGroupModal.displayName = 'RegisterGroupModal';
export default RegisterGroupModal;

const FormWrapper = styled.div`
  padding: ${themeV2.mixins.v2.spacing * 2}px ${themeV2.mixins.v2.spacing * 3}px;
  background-color: #faf9f7;
  display: flex;
  flex-direction: column;
  gap: ${themeV2.mixins.v2.spacing}px;
`;
const ErrorMsg = styled.p`
  ${themeV2.mixins.v2.typography.body.medium};
  color: ${themeV2.mixins.v2.color.font.red};
`;
