import { BaseDto, ShotCategory, ShotQuantityInputType, ShotSubcategory } from '@atomica.co/irori';
import { Amount, Option } from '@atomica.co/types';
import { ZERO, isArray } from '@atomica.co/utils';
import { Base } from '../constants/base-const';
import { Labels } from '../models/common-model';

export const toShotCategoryOptions = (categories: (ShotCategory | ShotSubcategory)[] | undefined): Option[] => {
  if (!isArray(categories)) return [];
  return categories.filter(category => !!category.item?.itemCode).map(category => category.item!.itemCode!);
};

export const toShotCategoryLabels = (categories: (ShotCategory | ShotSubcategory)[] | undefined): Labels => {
  if (!isArray(categories)) return {};
  return categories
    .filter(category => !!category.item?.itemCode)
    .reduce((prev, current) => {
      prev[current.item!.itemCode!] = current.item!.itemName;
      return prev;
    }, {});
};

export const isQuantityFixed = (category: ShotCategory | ShotSubcategory | undefined): boolean => {
  return !!category && category.quantityInputType === ShotQuantityInputType.FIXED;
};

export const isQuantityManual = (category: ShotCategory | ShotSubcategory | undefined): boolean => {
  return !!category && category.quantityInputType === ShotQuantityInputType.MANUAL;
};

export const toUsageType = (category: ShotCategory | undefined): ShotCategory | undefined => {
  return !!category && !!category.item && !!category.item.itemCode ? category : undefined;
};

export const toUsageOption = (
  category: ShotCategory | undefined,
  subcategory: ShotSubcategory | undefined
): ShotSubcategory | undefined => {
  return isQuantityFixed(category) && !!subcategory && !!subcategory.item ? subcategory : undefined;
};

export const toConferenceUsageType = (
  category: ShotCategory | undefined,
  subcategory: ShotSubcategory | undefined
): ShotSubcategory | undefined => {
  return isQuantityManual(category) && !!subcategory && !!subcategory.item ? subcategory : undefined;
};

export const TAX_EXCLUDED_ONE_DAY_PRICE_LABEL = '1DAY利用料金(税抜)';

export const TAX_INCLUDED_ONE_DAY_PRICE_LABEL = '1DAY利用料金(税込)';

export const getOneDayDiscountPrice = (base: BaseDto | undefined): Amount => {
  if (!base) return ZERO;

  switch (base.baseCode) {
    case Base.MIYAZAKI:
    case Base.KITAKYUSHU:
      return 500;

    case Base.MINATOMIRAI:
      return 1500;

    default:
      return ZERO;
  }
};
