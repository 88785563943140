import { useSafeCallback, useSafeState, useUnmountRef } from '@atomica.co/components';
import { BaseDto, BaseFunctionToggleCode, User, isBaseFunctionToggleEnabled } from '@atomica.co/irori';
import { EMAIL, EQUAL, QUESTION_MARK } from '@atomica.co/utils';
import React, { useEffect } from 'react';
import useBase from '../redux/hooks/useBase';
import useCachedURL from '../redux/hooks/useCachedURL';
import usePath from '../redux/hooks/usePath';
import useUser from '../redux/hooks/useUser';
import { Path } from '../router/Routes';
import { screenContext, useScreenContext } from '../redux/hooks/useScreenContext';

export interface AuthGuardChildProps {
  base: BaseDto | undefined;
  user: User | undefined;
}

const AuthGuard = (Component: React.FC<AuthGuardChildProps>): React.FC =>
  React.memo(function main() {
    const unmountRef = useUnmountRef();
    const { loaded, user } = useUser();
    const { base } = useBase();
    const { path: currentPath } = usePath();
    const { saveCurrentURL } = useCachedURL();
    const { openBasePath, queryParams } = usePath();
    const [ready, setReady] = useSafeState<boolean>(unmountRef, false);
    const context = useScreenContext();

    const initialize = useSafeCallback((): void => {
      if (!base) return;
      const path = isBaseFunctionToggleEnabled(base, BaseFunctionToggleCode.FUNCTION_USE_WORKHUB_PASS)
        ? Path.REGISTER_ACCOUNT_V2
        : Path.REGISTER_ACCOUNT;

      if (!user) {
        saveCurrentURL();

        if (currentPath === Path.ACCOUNT_EVENTS) {
          openBasePath(Path.ACCOUNT_EVENTS_NO_GUARDED);
          return;
        }
        if (currentPath === Path.RESERVE_SPACE) {
          openBasePath(Path.RESERVE_SPACE_NO_GUARDED);
          return;
        }

        const email = queryParams[EMAIL];
        const pathToOpen = email ? (`${path}${QUESTION_MARK}${EMAIL}${EQUAL}${email}` as Path) : Path.SIGN_IN;

        openBasePath(pathToOpen);
        return;
      }

      if (!user.isActivated) {
        saveCurrentURL();
        openBasePath(path);
        return;
      }

      setReady(true);
    }, [base, currentPath, queryParams, user, saveCurrentURL, openBasePath, setReady]);

    useEffect(() => {
      if (!loaded || ready) return;
      context.setUserAndPropagate(user);
      initialize();
    }, [context, initialize, loaded, ready, user]);

    return (
      <screenContext.Provider value={context}>
        {ready ? <Component base={base} user={context.user} /> : <></>}
      </screenContext.Provider>
    );
  });

export default AuthGuard;
