import { LabelV2, RadioButton, themeV3, useSafeState, useUnmountRef } from '@atomica.co/components';
import { FetchSpacePaymentMethodPayment, PaymentMethod, ReservationPaymentMethod } from '@atomica.co/irori';
import { Count, Label } from '@atomica.co/types';
import { EMPTY, FIRST_INDEX, hasLength } from '@atomica.co/utils';
import React, { useEffect } from 'react';
import styled from 'styled-components';
import { RESERVATION_PAYMENT_METHOD_LABELS } from '../../../texts/payment-method-text';

const isCurrentTimeBetween = (startAt: string, endAt: string, selectedStartDate: Date): boolean => {
  const toJSTTime = (usageStartDate: Date) => (utcDateString: string) => {
    const [hours, minutes, seconds] = utcDateString.split(':').map(Number);
    const utcTime = new Date(
      Date.UTC(
        usageStartDate.getFullYear(),
        usageStartDate.getMonth(),
        usageStartDate.getDate(),
        hours,
        minutes,
        seconds
      )
    );
    return new Date(utcTime.getTime());
  };
  const convertToJST = toJSTTime(selectedStartDate);

  const jstStartAt = convertToJST(startAt);
  const jstEndAt = convertToJST(endAt);

  if (jstEndAt < jstStartAt) {
    return selectedStartDate >= jstStartAt || selectedStartDate <= jstEndAt;
  } else {
    return selectedStartDate >= jstStartAt && selectedStartDate <= jstEndAt;
  }
};

const getCurrentDayOfWeek = (selectedStartDate: Date): Count => {
  return selectedStartDate.getDay();
};

const isCashAvailable = (paymentMethods: FetchSpacePaymentMethodPayment[], selectedStartDate: Date): boolean => {
  const usageStartDate = getCurrentDayOfWeek(selectedStartDate);

  return paymentMethods.some(method => {
    if (method.methodName === PaymentMethod.CASH && hasLength(method.paymentAvailabilities)) {
      return method.paymentAvailabilities.some(availability => {
        return (
          availability.dayOfWeek === usageStartDate &&
          isCurrentTimeBetween(availability.startAt, availability.endAt, selectedStartDate)
        );
      });
    }
    return false;
  });
};

const isCreditCardAvailable = (paymentMethods: FetchSpacePaymentMethodPayment[], selectedStartDate: Date): boolean => {
  const usageStartDate = getCurrentDayOfWeek(selectedStartDate);

  return paymentMethods.some(method => {
    if (method.methodName === PaymentMethod.CREDIT_CARD && hasLength(method.paymentAvailabilities)) {
      return method.paymentAvailabilities.some(availability => {
        return (
          availability.dayOfWeek === usageStartDate &&
          isCurrentTimeBetween(availability.startAt, availability.endAt, selectedStartDate)
        );
      });
    }
    return false;
  });
};

interface P {
  setReservationPaymentMethod: (option: ReservationPaymentMethod) => void;
  reservationPaymentMethod?: ReservationPaymentMethod;
  spacePaymentMethodWithAvailabilities?: FetchSpacePaymentMethodPayment[];
  selectedStartDate?: Date;
}

const SpaceReservationPaymentMethod: React.FC<P> = React.memo(props => {
  const {
    reservationPaymentMethod,
    setReservationPaymentMethod,
    spacePaymentMethodWithAvailabilities,
    selectedStartDate
  } = props;

  const unmountRef = useUnmountRef();

  const [paymentMethodLabel, setPaymentMethodLabel] = useSafeState<Label>(unmountRef, EMPTY);
  const [options, setOptions] = useSafeState<ReservationPaymentMethod[]>(unmountRef, []);

  // 初期表示に関するuse-effect
  useEffect(() => {
    // TODO: もし初期値が設定されている場合は、そちらをゆん優先して利用させる。
    if (!hasLength(spacePaymentMethodWithAvailabilities) || !selectedStartDate) {
      setPaymentMethodLabel(RESERVATION_PAYMENT_METHOD_LABELS.cash);
      setReservationPaymentMethod(ReservationPaymentMethod.CASH);
      return;
    }
    // 各種支払い情報の有無を確認
    const availableOptions: ReservationPaymentMethod[] = [];

    if (
      isCashAvailable(spacePaymentMethodWithAvailabilities, selectedStartDate) &&
      isCreditCardAvailable(spacePaymentMethodWithAvailabilities, selectedStartDate)
    ) {
      availableOptions.push(ReservationPaymentMethod.CASH);
      availableOptions.push(ReservationPaymentMethod.CREDIT_CARD);
    }
    if (
      isCashAvailable(spacePaymentMethodWithAvailabilities, selectedStartDate) &&
      !isCreditCardAvailable(spacePaymentMethodWithAvailabilities, selectedStartDate)
    ) {
      availableOptions.push(ReservationPaymentMethod.CASH);
      setPaymentMethodLabel(RESERVATION_PAYMENT_METHOD_LABELS.cash);
      setReservationPaymentMethod(ReservationPaymentMethod.CASH);
    }

    if (
      !isCashAvailable(spacePaymentMethodWithAvailabilities, selectedStartDate) &&
      isCreditCardAvailable(spacePaymentMethodWithAvailabilities, selectedStartDate)
    ) {
      availableOptions.push(ReservationPaymentMethod.CREDIT_CARD);
      setPaymentMethodLabel(RESERVATION_PAYMENT_METHOD_LABELS.credit_card);
      setReservationPaymentMethod(ReservationPaymentMethod.CREDIT_CARD);
    }

    setOptions(availableOptions);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setPaymentMethodLabel, setReservationPaymentMethod, spacePaymentMethodWithAvailabilities, selectedStartDate]);

  // オプションに基づいて支払い方法を設定するuse-effect
  useEffect(() => {
    if (!hasLength(options)) {
      setPaymentMethodLabel(RESERVATION_PAYMENT_METHOD_LABELS.cash);
      setReservationPaymentMethod(ReservationPaymentMethod.CASH);
      return;
    }
    if (options.length === 1) {
      setPaymentMethodLabel(RESERVATION_PAYMENT_METHOD_LABELS[options[FIRST_INDEX]]);
      setReservationPaymentMethod(options[FIRST_INDEX]);
      return;
    }
  }, [options, setPaymentMethodLabel, setReservationPaymentMethod]);

  const isRadio = options.length > 1;

  return (
    <>
      <LabelV2 text='支払い方法' />
      {isRadio ? (
        <RadioButton
          options={options}
          labels={RESERVATION_PAYMENT_METHOD_LABELS}
          value={reservationPaymentMethod}
          onChange={setReservationPaymentMethod}
        />
      ) : (
        <PaymentMethodLabel>{paymentMethodLabel}</PaymentMethodLabel>
      )}
    </>
  );
});

SpaceReservationPaymentMethod.displayName = 'SpaceReservationPaymentMethod';
export default SpaceReservationPaymentMethod;

const PaymentMethodLabel = styled.div`
  color: ${themeV3.mixins.v3.color.container.neutral.dark};
  font-family: 'Noto Sans JP';
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  letter-spacing: 0.25px;
`;
