import { customMedia, themeV2 } from '@atomica.co/components';
// https://github.com/fullcalendar/fullcalendar-vue/issues/152#issue-918047023
// https://github.com/fullcalendar/fullcalendar/issues/6371#issuecomment-1060708159
// @fullcalendar/react/dist/vdomをfullcalendar系importの最上位に置くs
import '@fullcalendar/react/dist/vdom';
import bootstrapPlugin from '@fullcalendar/bootstrap';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin, { DateClickArg } from '@fullcalendar/interaction';
import FullCalendar from '@fullcalendar/react';
import React from 'react';
import styled from 'styled-components';
import '../../../styles/bootstrap-journal.min.css';

interface P {
  handleDateClicked: (date: DateClickArg) => void;
}

const SelectDate: React.FC<P> = React.memo(props => {
  const { handleDateClicked } = props;

  return (
    <Container>
      <Content>
        <ItemNameWrapper>
          <ItemName>日付の選択（2/3）</ItemName>
          <Description>空き状況を確認したい日付を選択してください。</Description>
        </ItemNameWrapper>

        <CalendarWrapper>
          <FullCalendar
            locale='ja'
            headerToolbar={{
              left: 'prev next',
              center: 'title',
              right: 'today'
            }}
            buttonText={{
              prev: '<',
              next: '>',
              today: '今日'
            }}
            timeZone='local'
            initialView='dayGridMonth'
            themeSystem='bootstrap'
            contentHeight='auto'
            validRange={{
              start: new Date()
            }}
            slotMinTime={{ day: 20 }}
            plugins={[bootstrapPlugin, dayGridPlugin, interactionPlugin]}
            dateClick={handleDateClicked}
          />
        </CalendarWrapper>
      </Content>
    </Container>
  );
});

SelectDate.displayName = 'SelectDate';
export default SelectDate;

const Container = styled.div`
  width: 100%;
  height: auto;
  background: ${themeV2.mixins.v2.color.background.white};
  border-radius: 8px;
`;

const Content = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  gap: ${themeV2.mixins.v2.spacing}px;
  padding: ${themeV2.mixins.v2.spacing * 3}px;

  ${customMedia.lessThan('small')`
  padding: ${themeV2.mixins.v2.spacing * 2}px;
  `}
`;

const ItemNameWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${themeV2.mixins.v2.spacing}px;
`;

const ItemName = styled.div`
  ${themeV2.mixins.v2.typography.title.large};

  ${customMedia.lessThan('small')`
    ${themeV2.mixins.v2.typography.title.medium};
  `}
  ${customMedia.lessThan('tiny')`
    ${themeV2.mixins.v2.typography.title.small};
  `}
`;

const Description = styled.div`
  ${themeV2.mixins.v2.typography.body.large};

  ${customMedia.lessThan('small')`
    ${themeV2.mixins.v2.typography.body.medium};
  `}
  ${customMedia.lessThan('tiny')`
    ${themeV2.mixins.v2.typography.body.small};
  `}
`;

const CalendarWrapper = styled.div`
  padding: ${themeV2.mixins.v2.spacing * 3}px;
  border: 1px solid ${themeV2.mixins.v2.color.border.gray};
  border-top: 4px solid ${themeV2.mixins.v2.color.background.yellow};

  .fc-daygrid-day-events {
    min-height: 5em !important;
  }

  ${customMedia.lessThan('small')`
    padding: ${themeV2.mixins.v2.spacing}px;
    .btn {
      padding: 4px 8px;
      font-size: 20px;
    }
    .fc-toolbar-title {
      font-size: 20px;
    }
    .fc-daygrid-body-natural .fc-daygrid-day-events {
      margin-bottom: 0;
    }
    .fc-daygrid-day-events {
      min-height: 1em !important;
    }
  `}
`;
