import { ButtonV2, CircularLoader, customMedia, themeV2 } from '@atomica.co/components';
import { SpaceId, SpacePlan } from '@atomica.co/irori';
import { Index, Message, Name } from '@atomica.co/types';
import { Language, formatTime, hasLength } from '@atomica.co/utils';
import React, { useMemo } from 'react';
import styled from 'styled-components';
import media from 'styled-media-query';
import usePath from '../../../redux/hooks/usePath';
import { Path } from '../../../router/Routes';
import ItemService from '../../../services/item-service';
import SpaceCard from '../card/SpaceCard';

interface P {
  isSearching: boolean;
  categoryName: Name;
  spacePlans: SpacePlan[] | undefined;
  selectedSpacePlanId: SpaceId;
  handleSpacePlanClicked: (spacePlanId: SpaceId) => void;
}

const SelectSpacePlan = React.memo(
  React.forwardRef<HTMLDivElement, P>((props, ref) => {
    const { isSearching, categoryName, spacePlans, selectedSpacePlanId, handleSpacePlanClicked } = props;

    const { openBasePath } = usePath();

    const message = useMemo<Message>(() => {
      if (!spacePlans) return '利用条件を入力して検索してください。';
      return hasLength(spacePlans)
        ? `利用する${categoryName}のタイプを選択して、予約に進んでください。`
        : `予約可能な${categoryName}がありませんでした。条件を変更して検索してください。`;
    }, [categoryName, spacePlans]);

    return (
      <Container ref={ref}>
        <Title>{categoryName}のタイプを選択（2/2）</Title>
        {isSearching && (
          <CircularLoaderWrapper>
            <CircularLoader />
          </CircularLoaderWrapper>
        )}
        {!isSearching && (
          <React.Fragment>
            <MessageWrapper>{message}</MessageWrapper>
            {hasLength(spacePlans) && (
              <SpacesWrapper>
                {spacePlans.map((plan: SpacePlan, idx: Index) => (
                  <SpaceCard
                    key={`space-card-${idx}`}
                    id={plan.spacePlanId}
                    selectedIds={[selectedSpacePlanId]}
                    handleClicked={handleSpacePlanClicked}
                  >
                    <CardBody>
                      <Title>{plan.planName}</Title>
                      <Price>{`料金: ￥${ItemService.calcTaxIncludedPrice(plan.item).toLocaleString()} / 日`}</Price>
                      <Text>
                        <div>
                          チェックイン: {formatTime(plan.checkIn!, Language.JAPANESE)}
                          <br />
                          チェックアウト: {formatTime(plan.checkOut!, Language.JAPANESE)}
                        </div>
                      </Text>
                    </CardBody>
                  </SpaceCard>
                ))}
              </SpacesWrapper>
            )}
          </React.Fragment>
        )}
        <ButtonV2
          isFullWidth
          disabled={(spacePlans && !hasLength(spacePlans)) || !selectedSpacePlanId}
          size='large'
          type='primary'
          label='予約へ進む'
          onClick={() => openBasePath(Path.RESERVE_SPACE_AUTO_CONFIRM)}
        />
      </Container>
    );
  })
);

SelectSpacePlan.displayName = 'SelectSpacePlan';
export default SelectSpacePlan;

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: ${themeV2.mixins.v2.spacing * 2}px;
  padding: ${themeV2.mixins.v2.spacing * 3}px;
  background: ${themeV2.mixins.v2.color.background.white};
  border-radius: 12px;
  scroll-margin-top: ${themeV2.mixins.v2.spacing * 2}px;

  ${customMedia.lessThan('small')`
    padding: ${themeV2.mixins.v2.spacing * 2}px;
  `}
`;

const Title = styled.div`
  ${themeV2.mixins.v2.typography.title.large};
`;

const MessageWrapper = styled.div`
  ${themeV2.mixins.v2.typography.body.large};
`;

const CircularLoaderWrapper = styled.div`
  width: 100%;
  height: 120px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const SpacesWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(330px, 1fr));
  justify-items: center;
  gap: ${themeV2.mixins.v2.spacing * 2}px ${themeV2.mixins.v2.spacing}px;

  ${media.lessThan('small')`
    display: flex;
    flex-direction: column;
    gap: ${themeV2.mixins.v2.spacing * 2}px;
    flex-wrap: wrap;
    justify-content: space-between;
  `};
`;

const CardBody = styled.div`
  width: 100%;
  color: ${themeV2.mixins.v2.color.font.black};
  display: flex;
  flex-direction: column;
  gap: ${themeV2.mixins.v2.spacing}px;
`;

const Price = styled.div`
  ${themeV2.mixins.v2.typography.label.large};
`;

const Text = styled.div`
  ${themeV2.mixins.v2.typography.body.medium};
`;
