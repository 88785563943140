import {
  Component,
  HEADER_HEIGHT,
  Scrollable,
  themeV2,
  useSafeCallback,
  useSafeState,
  useUnmountRef
} from '@atomica.co/components';
import { BaseDto, LoginServiceType } from '@atomica.co/irori';
import { Email, Message } from '@atomica.co/types';
import { EMPTY } from '@atomica.co/utils';
import React, { useMemo } from 'react';
import styled from 'styled-components';
import { SignUpInSendEmailStatus } from '../../../enums/account-enum';
import EmailSent from '../email-sent/EmailSent';
import atomica from './../../../assets/atomica.gif';
import puzzles from './../../../assets/puzzles.png';
import SignUpOptionList from './sign-up-option-list/SignUpOptionList';

export enum SignUpStatus {
  SELECT_SIGN_UP_OPTION,
  CONFIRMATION_EMAIL_SENT
}

interface P {
  base: BaseDto;
  usableLoginServiceTypes: LoginServiceType[];
  message?: Message;
}

const SignUp: React.FC<P> = React.memo(props => {
  const { base, usableLoginServiceTypes, message } = props;
  const unmountRef = useUnmountRef();
  const [index, setIndex] = useSafeState<SignUpStatus>(unmountRef, SignUpStatus.SELECT_SIGN_UP_OPTION);
  const [email, setEmail] = useSafeState<Email>(unmountRef, EMPTY);

  const showEmailSentMessage = useSafeCallback(
    (email: Email): void => {
      setEmail(email);
      setIndex(SignUpStatus.CONFIRMATION_EMAIL_SENT);
    },
    [setEmail, setIndex]
  );

  const children = useMemo<React.ReactElement | undefined>(() => {
    switch (index) {
      case SignUpStatus.SELECT_SIGN_UP_OPTION:
        return (
          <SignUpOptionList
            base={base}
            usableLoginServiceTypes={usableLoginServiceTypes}
            message={message}
            onSendEmail={showEmailSentMessage}
          />
        );

      case SignUpStatus.CONFIRMATION_EMAIL_SENT:
        return <EmailSent email={email} status={SignUpInSendEmailStatus.SIGN_UP} />;
    }
  }, [index, base, usableLoginServiceTypes, message, showEmailSentMessage, email]);

  return (
    <Component className='sign-up'>
      <Container>
        <Content>
          <Scrollable>
            <ComponentWrapper>
              <LogoWrapper>
                <Logo src={(index === SignUpStatus.CONFIRMATION_EMAIL_SENT ? puzzles : base.topImageURL) || atomica} />
              </LogoWrapper>
              {children}
            </ComponentWrapper>
          </Scrollable>
        </Content>
      </Container>
    </Component>
  );
});

SignUp.displayName = 'SignUp';
export default SignUp;

const Container = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  justify-content: center;
`;

const Content = styled.div`
  width: 100%;
  max-width: 428px;
  min-width: 320px;
  height: calc(100vh - ${HEADER_HEIGHT}px);

  @supports (height: 1dvh) {
    height: calc(100dvh - ${HEADER_HEIGHT}px);
  }
`;

const ComponentWrapper = styled.div`
  width: 100%;
  height: auto;
  margin-bottom: ${themeV2.mixins.v2.spacing * 6}px;
  padding: ${themeV2.mixins.v2.spacing * 2}px;
`;

const LogoWrapper = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: ${themeV2.mixins.v2.spacing * 2}px;
`;

const Logo = styled.img`
  width: 100%;
  height: auto;
  min-height: 160px;
  object-fit: contain;
`;
