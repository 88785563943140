import { CircularLoader, Pagenate, themeV2 } from '@atomica.co/components';
import { Count, Index, Limit, Offset, Text, Title, URL } from '@atomica.co/types';
import { ListItem, ListItemIcon, ListItemSecondaryAction, ListSubheader, List as MuiList } from '@material-ui/core';
import React from 'react';
import styled from 'styled-components';
import mojaco from './../../assets/mojaco/mojaco_break.png';

interface PaginateProps {
  offset: Offset;
  limit: Limit;
  totalCount: Count;
  setOffset: (offset: Offset) => void;
}

export interface ListRow {
  icon?: URL;
  text: Text;
  subText?: Text;
  secondaryAction?: React.ReactElement;
}

interface P {
  loaded?: boolean;
  subTitle?: Title;
  rows: ListRow[];
  paginateProps?: PaginateProps;
}

const ListV2: React.FC<P> = React.memo(props => {
  const { loaded = true, subTitle, rows, paginateProps } = props;

  return (
    <StyledList
      isBorderShown={!!subTitle}
      subheader={subTitle ? <StyledListSubheader>{subTitle}</StyledListSubheader> : undefined}
    >
      {loaded &&
        rows.map((row: ListRow, index: Index) => (
          <ListItem style={{ minHeight: 56 }} key={`access-${index}`} divider={rows && rows.length - 1 > index}>
            {row.icon && (
              <ListItemIcon>
                <Icon src={mojaco} />
              </ListItemIcon>
            )}
            <TextWrapper>
              <PrimaryText>{row.text}</PrimaryText>
              {row.subText && <SecondaryText>{row.subText}</SecondaryText>}
            </TextWrapper>
            {row.secondaryAction && (
              <StyledListItemSecondaryAction>{row.secondaryAction}</StyledListItemSecondaryAction>
            )}
          </ListItem>
        ))}
      {!loaded && (
        <LoaderArea>
          <CircularLoader />
        </LoaderArea>
      )}
      {paginateProps && (
        <Pagenate
          offset={paginateProps.offset}
          limit={paginateProps.limit}
          count={paginateProps.totalCount}
          onClickBack={() => paginateProps.setOffset(paginateProps.offset - 1)}
          onClickForward={() => paginateProps.setOffset(paginateProps.offset + 1)}
        />
      )}
    </StyledList>
  );
});

ListV2.displayName = 'ListV2';
export default ListV2;

const StyledList = styled(MuiList)<{ isBorderShown: boolean }>`
  width: 100%;
  background: ${themeV2.mixins.v2.color.background.white};
  ${({ isBorderShown }) => isBorderShown && `border: 1px solid ${themeV2.mixins.v2.color.background.gray};`}
  border-radius: 12px;
  margin-top: ${themeV2.mixins.v2.spacing}px;
  padding: 0;
`;

const StyledListSubheader = styled(ListSubheader)`
  ${themeV2.mixins.v2.typography.label.small};
  color: ${themeV2.mixins.v2.color.font.gray};
  background: ${themeV2.mixins.v2.color.background.offWhite};
  border-bottom: 1px solid ${themeV2.mixins.v2.color.border.gray};
  border-radius: 12px 12px 0 0;
`;

const Icon = styled.img`
  width: 40px;
  height: 40px;
  border-radius: 20px;
  border: 2px solid ${themeV2.mixins.v2.color.border.gray};
`;

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
`;

const PrimaryText = styled.div`
  ${themeV2.mixins.v2.typography.body.large};
  color: ${themeV2.mixins.v2.color.font.black};
`;

const SecondaryText = styled.div`
  ${themeV2.mixins.v2.typography.body.medium};
  color: ${themeV2.mixins.v2.color.font.gray};
`;

const LoaderArea = styled.div`
  height: 56px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const StyledListItemSecondaryAction = styled(ListItemSecondaryAction)`
  ${themeV2.mixins.v2.typography.body.large};
  font-family: 'Noto Sans JP' !important;
`;
