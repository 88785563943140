import { Message, Name } from '@atomica.co/types';
import {
  isAlphabetOrNumberOrSymbol,
  isEmail,
  isEmpty,
  isKatakana,
  isNegativeNumber,
  isPositiveNumber,
  isPost,
  isZero,
  isPhoneNumber
} from '@atomica.co/utils';
import { ValidateTypeEnum } from '../enums/validate-enum';

export type ConfigValidateColumn = {
  columnName: Name;
  itemName: Name;
  validateType: ValidateTypeEnum;
};

export interface ValidateMessage {
  emptyErrorMessage: Message[];
  invalidErrorMessage: Message[];
}

const validateEmpty = <T>(dto: T, config: ConfigValidateColumn, emptyErrorMessage: Message[]): boolean => {
  return isEmpty(dto[config.columnName]) && !emptyErrorMessage.includes(config.columnName);
};

export const validate = <T>(dto: T, configs: ConfigValidateColumn[]): ValidateMessage => {
  const emptyErrorMessage: Message[] = [];
  const invalidErrorMessage: Message[] = [];
  for (const config of configs) {
    const numericDto = Number(dto[config.columnName]);

    switch (config.validateType) {
      //空欄
      case ValidateTypeEnum.EMPTY: {
        if (validateEmpty(dto, config, emptyErrorMessage)) emptyErrorMessage.push(config.itemName);
        break;
      }

      //非ゼロ数
      case ValidateTypeEnum.NON_ZERO_NUMBER: {
        if (validateEmpty(dto, config, emptyErrorMessage)) {
          emptyErrorMessage.push(config.itemName);
        } else if (
          !isPositiveNumber(numericDto) &&
          !isNegativeNumber(numericDto) &&
          !invalidErrorMessage.includes(config.columnName)
        ) {
          invalidErrorMessage.push(config.itemName);
        }
        break;
      }

      //正数
      case ValidateTypeEnum.POSITIVE_NUMBER: {
        if (validateEmpty(dto, config, emptyErrorMessage)) {
          emptyErrorMessage.push(config.itemName);
        } else if (!isPositiveNumber(numericDto) && !invalidErrorMessage.includes(config.columnName)) {
          invalidErrorMessage.push(config.itemName);
        }
        break;
      }

      //正数またはゼロ
      case ValidateTypeEnum.POSITIVE_NUMBER_OR_ZERO: {
        if (validateEmpty(dto, config, emptyErrorMessage)) {
          emptyErrorMessage.push(config.itemName);
        } else if (
          !isPositiveNumber(numericDto) &&
          !isZero(parseInt(numericDto)) &&
          !invalidErrorMessage.includes(config.columnName)
        ) {
          invalidErrorMessage.push(config.itemName);
        }
        break;
      }

      //負数
      case ValidateTypeEnum.NEGATIVE_NUMBER: {
        if (validateEmpty(dto, config, emptyErrorMessage)) {
          emptyErrorMessage.push(config.itemName);
        } else if (!isNegativeNumber(numericDto) && !invalidErrorMessage.includes(config.columnName)) {
          invalidErrorMessage.push(config.itemName);
        }
        break;
      }

      //郵便番号
      case ValidateTypeEnum.POST: {
        if (validateEmpty(dto, config, emptyErrorMessage)) {
          emptyErrorMessage.push(config.itemName);
        } else if (!isPost(dto[config.columnName]) && !invalidErrorMessage.includes(config.columnName)) {
          invalidErrorMessage.push(config.itemName);
        }
        break;
      }

      //メールアドレス
      case ValidateTypeEnum.EMAIL: {
        if (validateEmpty(dto, config, emptyErrorMessage)) {
          emptyErrorMessage.push(config.itemName);
        } else if (!isEmail(dto[config.columnName]) && !invalidErrorMessage.includes(config.columnName)) {
          invalidErrorMessage.push(config.itemName);
        }
        break;
      }

      // 電話番号
      case ValidateTypeEnum.PHONE_NUMBER: {
        if (validateEmpty(dto, config, emptyErrorMessage)) {
          emptyErrorMessage.push(config.itemName);
        } else if (!isPhoneNumber(dto[config.columnName]) && !invalidErrorMessage.includes(config.columnName)) {
          invalidErrorMessage.push(config.itemName);
        }
        break;
      }

      // カタカナ
      case ValidateTypeEnum.KATAKANA: {
        if (validateEmpty(dto, config, emptyErrorMessage)) {
          emptyErrorMessage.push(config.itemName);
        } else if (!isKatakana(dto[config.columnName]) && !invalidErrorMessage.includes(config.columnName)) {
          invalidErrorMessage.push(config.itemName);
        }
        break;
      }

      // 半角英数字記号
      case ValidateTypeEnum.ALPHABET_NUMBER_SYMBOL: {
        if (validateEmpty(dto, config, emptyErrorMessage)) {
          emptyErrorMessage.push(config.itemName);
        } else if (
          !isAlphabetOrNumberOrSymbol(dto[config.columnName]) &&
          !invalidErrorMessage.includes(config.columnName)
        ) {
          invalidErrorMessage.push(config.itemName);
        }
        break;
      }

      //undefined
      case ValidateTypeEnum.UNDEFINED:
        if (!dto[config.columnName]) emptyErrorMessage.push(config.itemName);
        break;

      default: {
        const _: never = config.validateType;
        console.log(_);
      }
    }
  }
  return { emptyErrorMessage, invalidErrorMessage };
};
