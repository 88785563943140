import { themeV2, useSafeState, useUnmountRef } from '@atomica.co/components';
import { MessageTemplate } from '@atomica.co/irori';
import { Index } from '@atomica.co/types';
import { isTextOverflowing } from '@atomica.co/utils';
import { Button, FormControlLabel, Radio } from '@material-ui/core';
import React, { useEffect, useRef } from 'react';
import styled from 'styled-components';

interface P {
  template: MessageTemplate;
  index: Index;
  isSelected: boolean;
}

const SelectTemplateCard: React.FC<P> = React.memo(props => {
  const { template, index, isSelected } = props;

  const unmountRef = useUnmountRef();
  const [isLongTemplateContent, setIsLongTemplateContent] = useSafeState<boolean>(unmountRef, false);
  const [isAllContentShown, setIsAllContentShown] = useSafeState<boolean>(unmountRef, false);

  const contentRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    setIsLongTemplateContent(isTextOverflowing(contentRef.current));
  }, [setIsLongTemplateContent]);

  return (
    <Container isSelected={isSelected}>
      <StyledFormControlLabel
        value={index}
        control={<StyledRadio disableFocusRipple disableTouchRipple disableRipple />}
        label={template.name}
      />
      <Content ref={contentRef} open={isAllContentShown}>
        {template.content}
      </Content>
      {isLongTemplateContent && (
        <DisplayButton onClick={() => setIsAllContentShown(!isAllContentShown)}>
          {isAllContentShown ? '一部のみ表示' : 'すべて表示'}
        </DisplayButton>
      )}
    </Container>
  );
});

SelectTemplateCard.displayName = 'SelectTemplateCard';
export default SelectTemplateCard;

const Container = styled.label<{ isSelected: boolean }>`
  display: flex;
  flex-direction: column;
  gap: ${themeV2.mixins.v2.spacing}px;
  background: ${themeV2.mixins.v2.color.background.offWhite};
  border: 2px solid
    ${({ isSelected }) =>
      isSelected ? themeV2.mixins.v2.color.border.pink : themeV2.mixins.v2.color.background.offWhite};
  border-radius: 8px;
  padding: ${themeV2.mixins.v2.spacing * 2}px;
  margin: 0;

  transition: background 0.5s;
  cursor: pointer;
  &:hover {
    background: ${themeV2.mixins.v2.color.background.lightGray};
  }
`;

const StyledFormControlLabel = styled(FormControlLabel)`
  margin: 0;
  gap: ${themeV2.mixins.v2.spacing * 1.5}px;
  span {
    padding: 0;
  }
  .MuiTypography-root-101 {
    ${themeV2.mixins.v2.typography.label.large};
    color: ${themeV2.mixins.v2.color.font.black};
  }
`;

const StyledRadio = styled(Radio)`
  color: ${themeV2.mixins.v2.color.background.gray};
`;

const Content = styled.div<{ open: boolean }>`
  margin-left: ${themeV2.mixins.v2.spacing * 4.5}px;
  white-space: pre-wrap;
  ${({ open }) => !open && `display: -webkit-box;-webkit-box-orient: vertical;-webkit-line-clamp: 3;overflow: hidden;`};
`;

const DisplayButton = styled(Button)`
  width: max-content;
  margin-left: ${themeV2.mixins.v2.spacing * 4.5}px;
  padding: 0;
  color: ${themeV2.mixins.v2.color.background.pink};
  justify-content: start;
`;
