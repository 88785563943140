import { BillingV2, TaxDiv, UnitV2 } from '@atomica.co/irori';
import { Name } from '@atomica.co/types';
import { EMPTY } from '@atomica.co/utils';
import { BillingDetail, DetailForBillingRegisterScreen } from '../converters/billing-v2-converter';
import { ValidateTypeEnum } from '../enums/validate-enum';
import { ConfigValidateColumn, ValidateMessage, validate } from './validate-util';

export const findUnit = (units: UnitV2[], detail: BillingDetail): UnitV2 | undefined => {
  return units.find(unit => unit.unitId === detail.unit.unitId);
};

export const findUnitName = (units: UnitV2[], detail: BillingDetail): Name => {
  const unit = findUnit(units, detail);
  return unit ? unit.unitName : EMPTY;
};

export const existsBillingDetailWithTaxDiv = (details: BillingDetail[], taxDiv: TaxDiv): boolean => {
  return details.some(detail => detail.taxDiv === taxDiv);
};

const configValidateBilling: ConfigValidateColumn[] = [
  { columnName: 'billingNo', itemName: '契約番号', validateType: ValidateTypeEnum.EMPTY },
  //{ columnName: 'billingName', itemName: '件名', validateType: ValidateTypeEnum.EMPTY },
  { columnName: 'contractorName', itemName: '取引先', validateType: ValidateTypeEnum.EMPTY },
  { columnName: 'billingorDate', itemName: '請求日', validateType: ValidateTypeEnum.EMPTY },
  { columnName: 'paymentDueDate', itemName: '支払い期日', validateType: ValidateTypeEnum.EMPTY }
];

const configValidateBillingDetail: ConfigValidateColumn[] = [
  { columnName: 'itemName', itemName: '項目名', validateType: ValidateTypeEnum.EMPTY },
  { columnName: 'taxDiv', itemName: '課税', validateType: ValidateTypeEnum.UNDEFINED },
  { columnName: 'quantity', itemName: '数量', validateType: ValidateTypeEnum.POSITIVE_NUMBER_OR_ZERO },
  { columnName: 'unitPrice', itemName: '単価', validateType: ValidateTypeEnum.EMPTY }
];

export const billingValidater = (billing: BillingV2): ValidateMessage => {
  return validate<BillingV2>(billing, configValidateBilling);
};

export const billingDetailValidater = (billingDetails: DetailForBillingRegisterScreen[]): ValidateMessage => {
  let emptyError: string[] = [];
  let invalidError: string[] = [];

  billingDetails.forEach(detail => {
    const { emptyErrorMessage, invalidErrorMessage } = validate<DetailForBillingRegisterScreen>(
      detail,
      configValidateBillingDetail
    );
    emptyError = emptyErrorMessage;
    invalidError = invalidErrorMessage;
    return detail;
  });

  return {
    emptyErrorMessage: emptyError.filter((str, index) => emptyError.indexOf(str) === index),
    invalidErrorMessage: invalidError.filter((str, index) => invalidError.indexOf(str) === index)
  };
};
