import { ButtonV2, CardWithLabelV2, CircularLoader, Image, LabelV2, themeV2, useMobile } from '@atomica.co/components';
import { BaseDto, FetchProfile } from '@atomica.co/irori';
import { Gap } from '@atomica.co/types';
import { EMPTY } from '@atomica.co/utils';
import { Divider, Typography } from '@material-ui/core';
import React, { memo } from 'react';
import styled from 'styled-components';
import InterestTags from '../../../../components/tags/InterestTags';
import SkillTags from '../../../../components/tags/SkillTags';
import mojaco from './../../../../assets/mojaco/mojaco_break.png';

interface Props {
  base: BaseDto;
  profile: FetchProfile | undefined;
  onClickEditButton?: () => void;
  initialized: boolean;
}

const AccountProfileCardViewV3: React.FC<Props> = memo(props => {
  const { base, profile, onClickEditButton, initialized } = props;
  const isMobile = useMobile();

  return (
    <Card data-testid='account-profile-card-view-v3'>
      <SectionTitle data-testid='account-profile-title'>
        <TitleWrapper>
          <Title>プロフィール</Title>
          {initialized && onClickEditButton && <ButtonV2 onClick={onClickEditButton} label='編集' type='primary' />}
        </TitleWrapper>
        <StyledDivider variant='fullWidth' flexItem />
      </SectionTitle>

      {!initialized && (
        <LoaderWrapper>
          <CircularLoader />
        </LoaderWrapper>
      )}
      {initialized && (
        <ContentWrapper>
          <ImageWrapper>
            <StyledImage src={profile?.photoURL || mojaco} alt={'user phoro'} width={120} height={120} />
          </ImageWrapper>

          <InformationWrapper isMobile={isMobile}>
            <InformationTopWrapper isMobile={isMobile}>
              <BaseLabel>{base.baseName ?? EMPTY}</BaseLabel>
            </InformationTopWrapper>

            <InformationRowWrapper isMobile={isMobile}>
              <NameWrapper>
                {profile?.familyName || EMPTY} {profile?.firstName || EMPTY}
              </NameWrapper>
            </InformationRowWrapper>
            {profile && (
              <>
                <InformationRowWrapper isMobile={isMobile}>
                  <CardWithLabelV2 label={'自己紹介'} text={profile.profile?.selfIntroduction ?? EMPTY} />
                </InformationRowWrapper>

                <InformationRowWrapper isMobile={isMobile}>
                  <CardWithLabelV2 label={'略歴'} text={profile.profile?.biography ?? EMPTY} />
                </InformationRowWrapper>

                <InformationRowWrapper isMobile={isMobile}>
                  <LabelWithContent>
                    <LabelV2 text='趣味・好きなこと' />
                    <InterestTags
                      editable={false}
                      addNewTags={false}
                      base={base}
                      interestTag={profile?.interestTags ?? []}
                    />
                  </LabelWithContent>
                </InformationRowWrapper>

                <InformationRowWrapper isMobile={isMobile}>
                  <LabelWithContent>
                    <LabelV2 text='得意なこと' />
                    <SkillTags editable={false} addNewTags={false} base={base} skillTags={profile?.skillTags ?? []} />
                  </LabelWithContent>
                </InformationRowWrapper>
              </>
            )}
          </InformationWrapper>
        </ContentWrapper>
      )}
    </Card>
  );
});
AccountProfileCardViewV3.displayName = 'AccountProfileCardViewV3';
export default AccountProfileCardViewV3;

const Card = styled.div`
  display: flex;
  width: 100%;
  padding: ${themeV2.mixins.v2.spacing * 2}px;
  flex-direction: column;
  align-items: flex-start;
  gap: ${themeV2.mixins.v2.spacing * 2}px;

  background: ${themeV2.mixins.v2.color.background.white};
  border-radius: ${themeV2.mixins.v2.spacing}px;
`;

const SectionTitle = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: flex-start;
  gap: ${themeV2.mixins.v2.spacing}px;
  flex-shrink: 0;
`;

const TitleWrapper = styled.div`
  display: flex;
  width: 100%;
  height: ${themeV2.mixins.v2.spacing * 5}px;
  align-items: center;
  gap: ${themeV2.mixins.v2.spacing * 3}px;
  justify-content: space-between;
`;

const NameWrapper = styled.div`
  ${themeV2.mixins.v2.typography.headLine.small};
  width: 100%;
  overflow-wrap: break-word;
  text-align: center;
`;

const ContentWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
  flex-direction: column;
  width: 100%;
`;

const ImageWrapper = styled.div`
  width: 120px;
  height: 120px;
  text-align: center;
`;

const InformationWrapper = styled.div<{ isMobile: boolean; gap?: Gap }>`
  display: flex;
  flex-direction: column;
  ${({ isMobile }) => (isMobile ? EMPTY : 'flex-grow: 1')};
  ${({ isMobile }) => (isMobile ? 'width:100%' : EMPTY)};
  gap: ${({ gap }) => gap || themeV2.mixins.v2.spacing * 2}px;
  width: 100%;
  ${({ isMobile }) => (isMobile ? EMPTY : `padding: 0 ${themeV2.mixins.v2.spacing * 4}px`)};
`;

const InformationRowWrapper = styled.div<{ isMobile: boolean }>`
  display: flex;
  align-items: flex-start;
  flex-direction: ${({ isMobile }) => (isMobile ? 'column' : 'row')};
  gap: ${themeV2.mixins.v2.spacing * 3}px;
  width: 100%;
`;

const InformationTopWrapper = styled.div<{ isMobile: boolean }>`
  display: flex;
  align-items: center;
  align-content: center;
  align-self: stretch;
  flex-wrap: wrap;
  flex-direction: ${({ isMobile }) => (isMobile ? 'column' : 'row')};
  gap: ${themeV2.mixins.v2.spacing * 3}px;
  justify-content: center;
`;

const Title = styled(Typography)`
  ${themeV2.mixins.v2.typography.title.large};
  color: ${themeV2.mixins.v2.color.font.black};
`;

const BaseLabel = styled(Typography)`
  ${themeV2.mixins.v2.typography.label.medium};
  color: ${themeV2.mixins.v2.color.font.gray};
`;

const StyledDivider = styled(Divider)`
  border: 1px solid ${themeV2.mixins.v2.color.border.gray};
`;

const StyledImage = styled(Image)`
  object-fit: cover;
  border: 1px solid ${themeV2.mixins.v2.color.border.gray};
  border-radius: 60px;
`;

const LabelWithContent = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 8px;
`;
const LoaderWrapper = styled.div`
  width: 100%;
  height: 120px;
  display: flex;
  justify-content: center;
  align-items: center;
`;
