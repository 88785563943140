import { IconButtonV2, themeV2 } from '@atomica.co/components';
import {
  BaseDto,
  BaseFunctionToggleCode,
  isBaseFunctionToggleEnabled,
  Space,
  SpaceReservationType,
  User
} from '@atomica.co/irori';
import { embedIdInPath, EMPTY, FIRST_INDEX } from '@atomica.co/utils';
import CreateOutlinedIcon from '@material-ui/icons/CreateOutlined';
import { addDays, format } from 'date-fns';
import React from 'react';
import styled from 'styled-components';
import { convertToAvailableTimes } from '../../../converters/space-converter';
import usePath from '../../../redux/hooks/usePath';
import { Path, PATH_IDS } from '../../../router/Routes';
import ItemService from '../../../services/item-service';
import { SPACE_RESERVATION_TYPE_LABEL } from '../../../texts/space-text';
import { isNotFoundIndex } from '../../../utils/common-util';
import { isAdmin } from '../../../utils/user-util';

interface P {
  base: BaseDto;
  space: Space;
  user: User | undefined;
}

const SpaceCardBody: React.FC<P> = React.memo(props => {
  const { base, space, user } = props;
  const reservationType = space.baseResourceCategory?.resourceCategoryDef?.reservationType ?? SpaceReservationType.TIME;

  const { openPath } = usePath();

  return (
    <Container data-testid='space-card-body'>
      <Content>
        <Title>{space.spaceName}</Title>
        <Price>
          {`料金: ￥${ItemService.calcTaxIncludedPrice(space.item).toLocaleString()} / ${
            SPACE_RESERVATION_TYPE_LABEL[reservationType]
          }`}
        </Price>
        <Text>
          {!!space.capacity && !isNotFoundIndex(space.capacity) && (
            <div>
              定員: {space.capacity}名{space.permitCapacityExceedance && '程度'}
            </div>
          )}
          {isBaseFunctionToggleEnabled(base, BaseFunctionToggleCode.FUNCTION_USE_SPACE_ORDER_PERIOD) && (
            <div>
              {`予約受付: 
                ${
                  isNotFoundIndex(space.reservationStartDayOffset)
                    ? '本日'
                    : format(addDays(new Date(), space.reservationStartDayOffset), 'yyyy/MM/dd')
                } ～ ${
                  isNotFoundIndex(space.reservationAdvanceBookingDays)
                    ? EMPTY
                    : format(addDays(new Date(), space.reservationAdvanceBookingDays), 'yyyy/MM/dd')
                }
                使用分`}
            </div>
          )}
          {convertToAvailableTimes(space.availabilities, base.timezone).map((row, idx) => (
            <div key={`${space.spaceId}_${idx}`}>
              {idx === FIRST_INDEX ? '利用可能時間：' : '       '}
              {row.weekday} {row.time}
            </div>
          ))}
        </Text>
      </Content>
      {isBaseFunctionToggleEnabled(base, BaseFunctionToggleCode.FUNCTION_USE_RESOURCE_MASTER) &&
        isAdmin(user, base.baseCode) && (
          <IconButtonV2
            icon={<CreateOutlinedIcon />}
            onClick={() => openPath(embedIdInPath(Path.EDIT_RESOURCE_MASTER, PATH_IDS, [base.baseCode, space.spaceId]))}
          />
        )}
    </Container>
  );
});

SpaceCardBody.displayName = 'SpaceCardBody';
export default SpaceCardBody;

const Container = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  gap: ${themeV2.mixins.v2.spacing}px;
`;

const Content = styled.div`
  width: 100%;
  color: ${themeV2.mixins.v2.color.font.black};
  display: flex;
  flex-direction: column;
  gap: ${themeV2.mixins.v2.spacing}px;
`;

const Title = styled.div`
  ${themeV2.mixins.v2.typography.title.large};
  line-height: 110%;
`;

const Price = styled.div`
  ${themeV2.mixins.v2.typography.label.large};
`;

const Text = styled.div`
  ${themeV2.mixins.v2.typography.body.medium};
`;
