import { ColgroupV2, Component, TableV2, TbodyV2, TdV2, TheadV2, themeV2, ThV2, TrV2 } from '@atomica.co/components';
import { AccessV2 } from '@atomica.co/irori';
import { Index } from '@atomica.co/types';
import { hasLength, noop } from '@atomica.co/utils';
import { CSSProperties } from '@material-ui/core/styles/withStyles';
import React, { useMemo } from 'react';
import styled from 'styled-components';
import { AccessForBillingDetail, toAccessesForBillingDetails } from '../../../converters/access-v2-converter';
import { NO_DATA_TEXT } from '../../../texts/common-text';

const TABLE_COLUMNS: TableColumn[] = [
  { label: '入室日時' },
  { label: '退室日時' },
  { label: '滞在時間' },
  { label: '利用者' },
  { label: 'エリア' }
];

interface TableColumn {
  label: string;
  align?: string;
}

interface P {
  accesses: AccessV2[];
}

const BillingAccesses: React.FC<P> = React.memo(props => {
  const { accesses } = props;

  const accessForBillingDetails: AccessForBillingDetail[] = useMemo(
    () => (accesses ? toAccessesForBillingDetails(accesses) : []),
    [accesses]
  );

  return (
    <Component className='billing-contract-usages' style={styleForComponent}>
      <Container>
        <TableWrapper>
          <TableV2 shape='circle'>
            <ColgroupV2 width={56} />
            <ColgroupV2 width={56} />
            <ColgroupV2 width={56} />
            <ColgroupV2 width={56} />
            <ColgroupV2 width={168} />
            <TheadV2>
              <TrV2>
                {TABLE_COLUMNS.map((column: TableColumn, idx: number) => (
                  <ThV2 key={idx} onClick={noop}>
                    <Label align={column.align ?? 'left'}>{column.label}</Label>
                  </ThV2>
                ))}
              </TrV2>
            </TheadV2>
            <TbodyV2>
              {hasLength(accessForBillingDetails) &&
                accessForBillingDetails.map((access: AccessForBillingDetail, index: Index) => (
                  <TrV2 key={`tr${index}`}>
                    <TdV2>{access.inDatetime || '記録なし'}</TdV2>
                    <TdV2>{access.outDatetime || '記録なし'}</TdV2>
                    <TdV2>{access.usageTime || ' - '}</TdV2>
                    <TdV2>{access.userName}</TdV2>
                    <TdV2>{access.spaceName}</TdV2>
                  </TrV2>
                ))}
            </TbodyV2>
          </TableV2>

          {!hasLength(accessForBillingDetails) && <NoSearchResult>{NO_DATA_TEXT}</NoSearchResult>}
        </TableWrapper>
      </Container>
    </Component>
  );
});

BillingAccesses.displayName = 'BillingAccesses';
export default BillingAccesses;

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding-bottom: ${themeV2.mixins.v2.spacing * 10}px;
`;

const TableWrapper = styled.div`
  min-height: 92px;
  display: flex;
  flex-direction: column;
  border-radius: 6px;
  background: ${themeV2.mixins.v2.color.background.white};
  margin: ${themeV2.mixins.v2.spacing * 2}px 0;
`;

const Label = styled.div<{ align: string }>`
  text-align: ${({ align }) => align};
`;

const NoSearchResult = styled.div`
  width: 100%;
  height: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
  ${themeV2.mixins.v2.typography.body.medium};
  color: ${themeV2.mixins.v2.color.font.gray};
  padding: ${themeV2.mixins.v2.spacing * 3}px;
`;

const styleForComponent: CSSProperties = {
  width: '100%',
  height: '100%'
};
