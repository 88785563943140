import { BaseDto, DOMAINS } from '@atomica.co/irori';
import { ClientId, Code, Id, URL as UrlPath } from '@atomica.co/types';
import { embedIdInPath } from '@atomica.co/utils';
import liff, { Liff } from '@line/liff';
import querystring from 'querystring';
import env from '../env/env';
import { Path, PATH_IDS } from '../router/Routes';
import { IRORI_CLIENT_ID, IRORI_CLIENT_SECRET, LINE_AUTHORIZE_PARAMS, LINE_AUTHORIZE_URL, toLiffId } from './config';

export const createRedirectUrlForLine = (baseCode: Code): UrlPath => {
  const lineCallbackUrl = `https://${DOMAINS[env]}${embedIdInPath(Path.SIGN_IN, PATH_IDS, [baseCode])}`;
  const urlObject = new URL(lineCallbackUrl);
  const redirectUrl = `${urlObject.protocol}//${urlObject.host}${urlObject.pathname}${urlObject.search}${urlObject.hash}`;
  return redirectUrl;
};

export const constructLineLoginURL = (baseCode: Code, clientId: ClientId | undefined): UrlPath =>
  LINE_AUTHORIZE_URL +
  `?${querystring.stringify({ ...LINE_AUTHORIZE_PARAMS, client_id: clientId || IRORI_CLIENT_ID })}` +
  '&redirect_uri=' +
  createRedirectUrlForLine(baseCode);

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const getLineTokenBody = (lineCode: Code, base: BaseDto): any => {
  const lineClientId = base.lineClientId || IRORI_CLIENT_ID;
  const lineClientSecret = base.lineClientSecret || IRORI_CLIENT_SECRET;

  return {
    grant_type: 'authorization_code',
    code: lineCode,
    redirect_uri: createRedirectUrlForLine(base.baseCode),
    client_id: lineClientId,
    client_secret: lineClientSecret
  };
};

export const isInLineClient = (): boolean => {
  return liff.isInClient();
};

export const initLiff = async (liffId?: Id): Promise<Liff | undefined> => {
  if (!isInLineClient()) return;
  await liff.init({ liffId: toLiffId(liffId) });
  if (!liff.isLoggedIn()) liff.login();
  return liff;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const getLineProfile = async (liffId?: Id): Promise<any> => {
  const liff = await initLiff(liffId);
  return liff ? await liff.getProfile() : undefined;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const getLineToken = async (liffId?: Id): Promise<any> => {
  const liff = await initLiff(liffId);
  return liff ? await liff.getDecodedIDToken() : undefined;
};
