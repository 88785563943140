import {
  Button,
  Component,
  Modal,
  RadioBox,
  Skeleton,
  theme,
  useSafeCallback,
  useSafeState,
  useUnmountRef
} from '@atomica.co/components';
import { BaseAuthority, User } from '@atomica.co/irori';
import { Index } from '@atomica.co/types';
import { MINUS_ONE } from '@atomica.co/utils';
import { Typography } from '@material-ui/core';
import { CSSProperties } from '@material-ui/core/styles/withStyles';
import { Group, VisibilityOutlined } from '@material-ui/icons';
import React, { useEffect } from 'react';
import styled from 'styled-components';
import mojaco from './../../../assets/mojaco/mojaco_bow.png';
import { convertToLabel, convertToOption } from './../../../converters/base-authority-converter';
interface P {
  isOpen: boolean;
  authority: BaseAuthority[];
  selectedUsers: User[];
  onSave(idx: Index): void;
  onClose(): void;
}

const UserAuthorityModal: React.FC<P> = React.memo(props => {
  const { isOpen, authority, selectedUsers, onSave, onClose } = props;
  const unmountRef = useUnmountRef();
  const [selectedIdx, setSelectedIdx] = useSafeState<number>(unmountRef, MINUS_ONE);

  const handleModalClosed = useSafeCallback((): void => {
    onClose();
    setSelectedIdx(MINUS_ONE);
  }, [onClose, setSelectedIdx]);

  const handleSaveClicked = useSafeCallback((): void => {
    setSelectedIdx(selectedIdx => {
      onSave(selectedIdx);
      return MINUS_ONE;
    });
  }, [onSave, setSelectedIdx]);

  useEffect(() => {
    return setSelectedIdx(MINUS_ONE);
  }, [setSelectedIdx]);

  return (
    <Component className='user-aythority-modal'>
      <Modal isOpen={isOpen} onClose={handleModalClosed}>
        <Container onClick={handleModalClosed}>
          <Frame onClick={e => e.stopPropagation()}>
            <TitleWrapper>
              <Title>ユーザ権限の変更</Title>
              <Notion>下記のユーザー権限を変更します</Notion>
            </TitleWrapper>

            <ItemWrapper>
              <ItemName>
                <IconGroup />
                <Label>選択されたユーザー</Label>
              </ItemName>

              <ItemList>
                {selectedUsers.map((user, idx) => (
                  <UserBadge key={`user${idx}`}>
                    <PhotoWrapper>
                      <Skeleton style={styleForPhoto} src={user.photoURL ? user.photoURL : mojaco} />
                    </PhotoWrapper>
                    <Name>{`${user.familyName} ${user.firstName}`}</Name>
                  </UserBadge>
                ))}
              </ItemList>
            </ItemWrapper>

            <ItemWrapper>
              <ItemName>
                <IconVisibility />
                <Label>付与する権限</Label>
              </ItemName>

              <RadioWrapper>
                <CustomRadio
                  options={convertToOption(authority)}
                  labels={convertToLabel(authority)}
                  value={selectedIdx}
                  onChange={setSelectedIdx}
                />
              </RadioWrapper>
            </ItemWrapper>

            <ButtonWrapper>
              <Button type='primary' shape='rect' onClick={handleSaveClicked}>
                <Label>変更する</Label>
              </Button>
            </ButtonWrapper>
          </Frame>
        </Container>
      </Modal>
    </Component>
  );
});

UserAuthorityModal.displayName = 'UserAuthorityModal'
export default UserAuthorityModal;

const Container = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  justify-content: center;
`;

const Frame = styled.div`
  width: 768px;
  height: auto;
  background: ${theme.mixins.background.lightGray};
  border-radius: 12px;
  padding: ${theme.mixins.spacing * 3}px ${theme.mixins.spacing * 4}px;
  display: flex;
  flex-direction: column;
  gap: ${theme.mixins.spacing * 2}px;
`;

const TitleWrapper = styled.div``;

const Title = styled(Typography)`
  font-size: ${theme.mixins.typography.fontSize.sixteen}px;
  font-family: ${theme.mixins.typography.fontFamily};
  font-weight: ${theme.mixins.typography.fontWeight.sevenHundreds};
`;

const Notion = styled(Typography)`
  color: ${theme.mixins.typography.fontColor.gray};
  font-size: ${theme.mixins.typography.fontSize.sixteen}px;
  font-family: ${theme.mixins.typography.fontFamily};
`;

const ItemWrapper = styled.div``;

const ItemName = styled.div`
  display: inline-flex;
  align-items: center;
  gap: ${theme.mixins.spacing}px;
`;

const ItemList = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: ${theme.mixins.spacing * 2}px;
`;

const IconGroup = styled(Group)`
  font-size: ${theme.mixins.typography.fontSize.twenty}px;
`;

const IconVisibility = styled(VisibilityOutlined)`
  font-size: ${theme.mixins.typography.fontSize.twenty}px;
`;

const Label = styled(Typography)`
  font-size: ${theme.mixins.typography.fontSize.sixteen}px;
  font-family: ${theme.mixins.typography.fontFamily};
  font-weight: ${theme.mixins.typography.fontWeight.sevenHundreds};
`;

const Name = styled(Typography)`
  font-size: ${theme.mixins.typography.fontSize.sixteen}px;
  font-family: ${theme.mixins.typography.fontFamily};
  font-weight: ${theme.mixins.typography.fontWeight.fourHundreds};
`;

const UserBadge = styled.div`
  display: flex;
  gap: ${theme.mixins.spacing / 2}px;
`;

const PhotoWrapper = styled.div`
  width: 28px;
  height: 28px;
`;

const RadioWrapper = styled.div`
  width: 100%;
  height: auto;
  margin: 4px 0px 0px -8px;
`;

const CustomRadio = styled(RadioBox)`
  background: ${theme.mixins.background.lightGray};
  * {
    padding: 0;
  }
`;

const ButtonWrapper = styled.div`
  margin-left: auto;
`;

const styleForPhoto: CSSProperties = {
  width: 28,
  height: 28,
  background: theme.mixins.background.lightGray,
  border: `2px solid ${theme.mixins.border.lightGray}`,
  borderRadius: '50%',
  objectFit: 'contain'
};
