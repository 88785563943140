import { Component, Scrollable, theme } from '@atomica.co/components';
import { BaseDto, BaseFunctionToggleCode, isBaseFunctionToggleEnabled, User } from '@atomica.co/irori';
import React, { useMemo } from 'react';
import styled from 'styled-components';
import { Path } from '../../router/Routes';
import AccountEventDetailScreen from '../account/account-events/AccountEventDetailScreen';
import AccountEventList from '../account/account-events/AccountEventList';
import AccountHome from '../account/account-home/AccountHome';
import AccountMySchedules from '../account/account-my-schedules/AccountMySchedules';
import AccountPointUsageLogs from '../account/account-point-usage-logs/AccountPointUsageLogs';
import AccountProfile from '../account/account-profile/AccountProfile';
import AccountProfileV2 from '../account/account-profile/AccountProfileV2';
import AccountProfileV3 from '../account/account-profile/AccountProfileV3';
import AccountReservationDetail from '../account/account-reservation-detail/AccountReservationDetail';
import AccountSettingScreen from '../account/account-setting/AccountSettingScreen';
import AccountShare from '../account/account-share/AccountShare';
import AccountShot from '../account/account-shot/AccountShot';
import AccountWishListScreen from '../account/account-wish/AccountWishListScreen';
import RegisterAccountWish from '../account/account-wish/RegisterAccountWish';
import CompleteRegisterCreditCardScreen from '../contract-v2/credit-card/CompleteRegisterCreditCardScreen';
import ResendRegisterCreditCardScreen from '../contract-v2/credit-card/ResendRegisterCreditCardScreen';
import DropInConfirmScreen from '../drop-in/DropInConfirmScreen';
import DropInEntryScreen from '../drop-in/DropInEntryScreen';
import MemberListScreen from '../member/MemberListScreen';
import MemberProfileScreen from '../member/MemberProfileScreen';
import QuestionnaireAnswerScreen from '../questionnaire/QuestionnaireAnswersScreen';
import AutomaticSpaceReservationConfirmScreen from '../space/AutomaticSpaceReservationConfirmScreen';
import CompleteSpaceReservationScreen from '../space/CompleteSpaceReservationScreen';
import SpaceReservationConfirmScreen from '../space/SpaceReservationConfirmScreen';
import SpaceReservationScreen from '../space/SpaceReservationScreen';
import StepVerificationScreen from '../verification/StepVerificationScreen';

interface P {
  path: Path;
  base: BaseDto;
  user: User | undefined;
}

const ConsumerSwitcher: React.FC<P> = React.memo(props => {
  const { base, path, user } = props;

  const screen = useMemo<React.ReactElement | undefined>(() => {
    switch (path) {
      case Path.ACCOUNT_HOME:
        return <AccountHome base={base} user={user!} />;

      case Path.ACCOUNT_SHOT:
        return <AccountShot base={base} user={user!} />;

      case Path.ACCOUNT_SHARE:
        return <AccountShare base={base} user={user!} />;

      case Path.ACCOUNT_EVENTS:
      case Path.ACCOUNT_EVENTS_NO_GUARDED:
        return <AccountEventList base={base} user={user!} />;

      case Path.RESERVE_SPACE:
      case Path.RESERVE_SPACE_NO_GUARDED:
        return <SpaceReservationScreen base={base} user={user!} />;

      case Path.RESERVE_SPACE_CONFIRM:
      case Path.RESERVE_SPACE_EXTENSION:
      case Path.RESERVE_SPACE_EDIT:
        return <SpaceReservationConfirmScreen base={base} user={user!} />;

      case Path.RESERVE_SPACE_AUTO_CONFIRM:
        return <AutomaticSpaceReservationConfirmScreen base={base} user={user} />;

      case Path.RESERVE_SPACE_COMPLETE:
        return <CompleteSpaceReservationScreen />;

      case Path.DROP_IN:
        return <DropInEntryScreen base={base} />;

      case Path.DROP_IN_CONFIRM:
        return <DropInConfirmScreen base={base} />;

      case Path.ACCOUNT_MY_SCHEDULES:
        return <AccountMySchedules base={base} user={user!} />;

      case Path.ACCOUNT_PROFILE:
        return <AccountProfile base={base} user={user!} />;

      case Path.ACCOUNT_PROFILE_V2: {
        if (isBaseFunctionToggleEnabled(base, BaseFunctionToggleCode.FUNCTION_USE_PROFILE_V3)) {
          return <AccountProfileV3 base={base} user={user!} />;
        }

        return <AccountProfileV2 base={base} user={user!} />;
      }

      case Path.ACCOUNT_POINT_USAGE_LOGS:
        return <AccountPointUsageLogs base={base} user={user!} />;

      case Path.ACCOUNT_RESERVATION_DETAIL:
        return <AccountReservationDetail base={base} user={user!} />;

      case Path.ACCOUNT_EVENT_DEATIL:
      case Path.ACCOUNT_EVENT_ENTRY_FORM:
      case Path.ACCOUNT_EVENT_PARTICIPANTS:
        return <AccountEventDetailScreen base={base} user={user!} path={path} />;

      case Path.QUESTIONNAIRE_ANSWER:
        return <QuestionnaireAnswerScreen user={user!} />;

      case Path.COMPLETED_REGISTER_CREDIT_CARD:
        return <CompleteRegisterCreditCardScreen />;

      case Path.RESEND_REGISTER_CREDIT_CARD:
        return <ResendRegisterCreditCardScreen />;

      case Path.REGISTER_ACCOUNT_WISH:
      case Path.EDIT_ACCOUNT_WISH:
        return <RegisterAccountWish base={base} user={user!} />;

      case Path.ACCOUNT_WISH_LIST:
        return <AccountWishListScreen base={base} user={user!} />;

      case Path.MEMBERS:
        return <MemberListScreen base={base} />;

      case Path.MEMBER_PROFILE:
        return <MemberProfileScreen base={base} user={user!} />;

      case Path.ACCOUNT_SETTING:
        return <AccountSettingScreen base={base} user={user!} />;

      case Path.STEP_VERIFICATION:
        return <StepVerificationScreen base={base} user={user} />;
    }
  }, [path, base, user]);

  return (
    <Component style={{ width: '100%', height: '100%' }} className='consumer-switcher'>
      <Container>
        <Scrollable>{screen}</Scrollable>
      </Container>
    </Component>
  );
});

ConsumerSwitcher.displayName = 'ConsumerSwitcher';
export default ConsumerSwitcher;

const Container = styled.main`
  width: 100%;
  height: 100%;
  transition: ${theme.transitions.create('margin', {
    easing: theme.transitions.easing.easeOut,
    duration: theme.transitions.duration.enteringScreen
  })};
  margin-left: 0px;
`;
