import {
  ButtonV2,
  ColWidth,
  DialogV2,
  Header,
  Pagenate,
  ScreenLoaderV2,
  SearchField,
  TableV3,
  themeV2,
  useSafeCallback,
  useSafeState,
  useUnmountRef
} from '@atomica.co/components';
import {
  BaseDto,
  EventRegularParticipantV2,
  EventV2,
  SAVE_EVENT_REGULAR_PARTICIPANTS_V2_FOR_ADMIN,
  SEARCH_USERS_FOR_ADMIN,
  SaveEventRegularParticipantsV2ForAdminRequest,
  SaveEventRegularParticipantsV2ForAdminResponse,
  SearchUsersForAdminRequest,
  SearchUsersForAdminResponse,
  User,
  toFullName
} from '@atomica.co/irori';
import { Count, Email, Id, Name, Offset, UserId, Word } from '@atomica.co/types';
import { EMPTY, ONE, ZERO, builder, hasLength, uuid } from '@atomica.co/utils';
import React, { useEffect, useMemo } from 'react';
import styled from 'styled-components';
import DefaultUserIcon from '../../../assets/default_user_icon.png';
import { SearchOption } from '../../../enums/event-v2-enum';
import useCommonRequest from '../../../redux/hooks/useCommonRequest';

export interface UserRow {
  id: Id;
  icon: JSX.Element;
  name: Name;
  email: Email;
}

interface P {
  base: BaseDto;
  event: EventV2;
  isOpen: boolean;
  onClose(isUpdated: boolean): void;
}

const LIMIT = 50;

const COLUMN_WIDTH: ColWidth = { icon: 56, name: 'auto', email: 'auto' };
const HEADER: Header = {
  icon: { label: EMPTY },
  name: { label: '氏名' },
  email: { label: 'メールアドレス' }
};

const iconComponent = (user: User): JSX.Element => {
  return (
    <IconWrapper>
      <Icon src={user.photoURL || DefaultUserIcon} />
    </IconWrapper>
  );
};

const RegisterRegularParticipantModal: React.FC<P> = React.memo(props => {
  const { base, event, isOpen, onClose } = props;
  const unmountRef = useUnmountRef();
  const { commonRequest } = useCommonRequest();

  const [isSaving, setIsSaving] = useSafeState<boolean>(unmountRef, false);
  const [isLoaderShown, setIsLoaderShown] = useSafeState<boolean>(unmountRef, false);
  const [searchingWord, setSearchingWord] = useSafeState<Word>(unmountRef, EMPTY);
  const [offset, setOffset] = useSafeState<Offset>(unmountRef, SearchOption.OFFSET);
  const [totalCount, setTotalCount] = useSafeState<Count>(unmountRef, ZERO);
  const [rows, setRows] = useSafeState<UserRow[]>(unmountRef, []);
  const [selectedUserIds, setSelectedUserIds] = useSafeState<UserId[]>(unmountRef, []);
  const [users, setUsers] = useSafeState<User[]>(unmountRef, []);

  const searchUsers = useSafeCallback(
    async (searchingWord?: Word): Promise<void> => {
      setIsLoaderShown(true);

      const request = builder<SearchUsersForAdminRequest>()
        .baseId(base.baseId)
        .offset(offset * LIMIT)
        .limit(LIMIT)
        .word(searchingWord || EMPTY)
        .eventId(event.eventId)
        .build();
      const { users, totalCount } = await commonRequest<SearchUsersForAdminRequest, SearchUsersForAdminResponse>(
        SEARCH_USERS_FOR_ADMIN,
        request
      );
      const rows = users.map(user =>
        builder<UserRow>().id(user.userId).icon(iconComponent(user)).name(toFullName(user)).email(user.email).build()
      );

      setUsers(users);
      setRows(rows);
      setTotalCount(totalCount);
      setIsLoaderShown(false);
    },
    [base, commonRequest, offset, event, setUsers, setTotalCount, setRows, setIsLoaderShown]
  );

  useEffect(() => {
    searchUsers();
  }, [searchUsers]);

  const handleSaveClicked = useSafeCallback(async (): Promise<void> => {
    setIsSaving(true);

    const participants = selectedUserIds.map(userId =>
      builder<EventRegularParticipantV2>()
        .eventRegularParticipantId(uuid())
        .user(users.find(user => user.userId === userId)!)
        .eventV2(event)
        .build()
    );

    const request = builder<SaveEventRegularParticipantsV2ForAdminRequest>()
      .baseId(base.baseId)
      .eventRegularParticipantsV2(participants)
      .build();

    await commonRequest<SaveEventRegularParticipantsV2ForAdminRequest, SaveEventRegularParticipantsV2ForAdminResponse>(
      SAVE_EVENT_REGULAR_PARTICIPANTS_V2_FOR_ADMIN,
      request
    );

    onClose(true);
    setSelectedUserIds([]);
    setIsSaving(false);
  }, [base, event, selectedUserIds, users, commonRequest, onClose, setSelectedUserIds, setIsSaving]);

  const handleModalClosed = useSafeCallback((): void => {
    setSearchingWord(EMPTY);
    setOffset(ZERO);
    onClose(false);
  }, [setSearchingWord, setOffset, onClose]);

  const footerButtons = useMemo<React.ReactNode[]>(() => {
    return [
      <ButtonV2 key='cancelButton' label='キャンセル' disabled={isSaving} onClick={handleModalClosed} />,
      <ButtonV2
        disabled={!hasLength(selectedUserIds) || isSaving}
        key='createButton'
        width={105}
        type='primary'
        label={`追加${hasLength(selectedUserIds) ? `(${selectedUserIds.length})` : EMPTY}`}
        onClick={handleSaveClicked}
      />
    ];
  }, [selectedUserIds, handleSaveClicked, handleModalClosed, isSaving]);

  return (
    <DialogV2
      isOpen={isOpen}
      headerLabel='定期参加者を登録'
      buttonsOnTheRight={footerButtons}
      onClose={() => onClose(false)}
      width={800}
    >
      <SearchFieldWrapper>
        <SearchField
          disableUnderline
          placeholder='Enter で検索'
          value={searchingWord}
          onChange={setSearchingWord}
          onEnter={searchUsers}
        />
      </SearchFieldWrapper>
      <TableWrapper>
        <TableV3
          hiddenHeaderCheckbox
          colWidth={COLUMN_WIDTH}
          header={HEADER}
          rows={rows}
          isLoaderShown={isLoaderShown}
          selectedIds={selectedUserIds}
          setSelectedIds={setSelectedUserIds}
        />
      </TableWrapper>
      {hasLength(rows) && (
        <Pagenate
          offset={offset}
          limit={LIMIT}
          count={totalCount}
          onClickBack={() => setOffset(offset - ONE)}
          onClickForward={() => setOffset(offset + ONE)}
        />
      )}
      <ScreenLoaderV2 loading={isSaving} />
    </DialogV2>
  );
});

RegisterRegularParticipantModal.displayName = 'RegisterRegularParticipantModal';
export default RegisterRegularParticipantModal;

const SearchFieldWrapper = styled.div`
  padding-left: ${themeV2.mixins.v2.spacing * 2.5}px;
  border-bottom: 1px solid ${themeV2.mixins.v2.color.border.gray};
`;

const TableWrapper = styled.div`
  height: auto;
  max-height: 500px;
  border-radius: 0 0 ${themeV2.mixins.v2.spacing}px ${themeV2.mixins.v2.spacing}px;
  overflow-y: scroll;
  position: relative;
  padding-bottom: 0;
  ${themeV2.mixins.v2.scrollbarInvisible};
`;

const IconWrapper = styled.div`
  display: flex;
  justify-content: center;
`;

const Icon = styled.img`
  width: 40px;
  height: 40px;
  border-radius: 20px;
`;
