import { Pagenate, SelectBoxV2, themeV2 } from '@atomica.co/components';
import { DateStr, Id, Index, Label, Time } from '@atomica.co/types';
import { hasLength } from '@atomica.co/utils';
import { format, subMonths } from 'date-fns';
import React, { Dispatch, SetStateAction, useMemo, useState } from 'react';
import styled from 'styled-components';

const ITEMS_PER_PAGE = 10;

export interface RoomEntryExitHistoryRow {
  id: { entryId: Id; exitId: Id };
  entryDate: DateStr;
  exitDate: DateStr;
  stayingTime: Time;
  area: Label;
  control: React.ReactNode;
}

interface P {
  rows: RoomEntryExitHistoryRow[];
  isLoaderShown: boolean;
  usagePeriod: DateStr;
  setUsagePeriod: Dispatch<SetStateAction<DateStr>>;
  headerRightComponent?: React.ReactNode;
  onClickRow?: (index: Index) => void;
}

const UserRoomEntryExitHistoryTable: React.FC<P> = props => {
  const { rows, isLoaderShown, usagePeriod, setUsagePeriod, headerRightComponent, onClickRow } = props;

  const [currentPage, setCurrentPage] = useState(0);

  const showPaginate = useMemo<boolean>(() => !isLoaderShown && hasLength(rows), [isLoaderShown, rows]);

  const totalPages = useMemo(() => Math.ceil(rows.length / ITEMS_PER_PAGE), [rows.length]);
  const paginatedRows = useMemo(() => {
    const start = currentPage * ITEMS_PER_PAGE;
    return rows.slice(start, start + ITEMS_PER_PAGE);
  }, [rows, currentPage]);

  const ROOM_ENTRY_EXIT_HISTORY_LABELS = useMemo(() => {
    const currentDate = new Date();
    return Array.from({ length: 12 }, (_, i) => {
      const date = subMonths(currentDate, i);
      const key = format(date, 'yyyy-M');
      return { [key]: format(date, 'yyyy年M月利用分') };
    }).reduce((acc, curr) => ({ ...acc, ...curr }), {});
  }, []);

  const ROOM_ENTRY_EXIT_HISTORY_OPTIONS = useMemo(() => {
    return Object.keys(ROOM_ENTRY_EXIT_HISTORY_LABELS);
  }, [ROOM_ENTRY_EXIT_HISTORY_LABELS]);

  return (
    <Container>
      <ListHeader>
        <ListTitle>入退室履歴</ListTitle>
        <InputWrapper>{headerRightComponent}</InputWrapper>
      </ListHeader>
      <TableWrapper showPaginate={showPaginate}>
        <ConditionRow>
          <SelectBoxV2
            labels={ROOM_ENTRY_EXIT_HISTORY_LABELS}
            options={ROOM_ENTRY_EXIT_HISTORY_OPTIONS}
            value={usagePeriod}
            onChange={setUsagePeriod}
          />
        </ConditionRow>
        <Table>
          <thead>
            <Tr>
              <DummyTh />
              <Th>入室日時</Th>
              <Th>退室日時</Th>
              <Th>滞在時間</Th>
              <Th>エリア</Th>
              <Th>操作</Th>
            </Tr>
          </thead>
          <tbody>
            {paginatedRows.map((row, index) => (
              <Tr key={`${row.id.entryId}-${row.id.exitId}`} onClick={() => onClickRow?.(index)}>
                <DummyTd />
                <Td data-testid='entry-date'>{row.entryDate}</Td>
                <Td data-testid='exit-date'>{row.exitDate}</Td>
                <Td data-testid='staying-time'>{row.stayingTime}</Td>
                <Td data-testid='area'>{row.area}</Td>
                <Td data-testid='control'>{row.control}</Td>
              </Tr>
            ))}
          </tbody>
        </Table>
        {isLoaderShown && <LoaderWrapper>Loading...</LoaderWrapper>}
        {!isLoaderShown && paginatedRows.length === 0 && <NoDataWrapper>データがありません</NoDataWrapper>}
      </TableWrapper>
      {showPaginate && (
        <Footer>
          <PaginateWrapper>
            <Pagenate
              offset={currentPage}
              limit={ITEMS_PER_PAGE}
              count={rows.length}
              onClickBack={() => setCurrentPage(page => Math.max(0, page - 1))}
              onClickForward={() => setCurrentPage(page => Math.min(totalPages - 1, page + 1))}
            />
          </PaginateWrapper>
        </Footer>
      )}
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  height: auto;
  max-height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  border-radius: ${themeV2.mixins.v2.spacing}px;
  background: ${themeV2.mixins.v2.color.background.white};
  padding: 0;
  cursor: default;
`;

const ListHeader = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: ${themeV2.mixins.v2.spacing * 3}px;
  box-sizing: border-box;
  border-radius: 8px 8px 0px 0px;
  border-bottom: 1px solid var(--border-gray, #c9c9c9);
`;

const ListTitle = styled.div`
  width: 100%;
  height: auto;
  line-height: ${themeV2.mixins.v2.spacing * 6}px;
  vertical-align: middle;
  ${themeV2.mixins.v2.typography.title.xLarge};
`;

const ConditionRow = styled.div`
  padding: ${themeV2.mixins.v2.spacing}px ${themeV2.mixins.v2.spacing * 3}px;
  display: flex;
  gap: ${themeV2.mixins.v2.spacing}px;
  align-items: center;
  background-color: ${themeV2.mixins.v2.color.background.lightGray};
`;

const InputWrapper = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: ${themeV2.mixins.v2.spacing}px ${themeV2.mixins.v2.spacing * 2}px ${themeV2.mixins.v2.spacing}px;
  gap: 10px;
`;

const TableWrapper = styled.div<{ showPaginate: boolean }>`
  height: auto;
  max-height: 100%;
  border-radius: 0 0 ${themeV2.mixins.v2.spacing}px ${themeV2.mixins.v2.spacing}px;
  overflow-y: auto;
  ${themeV2.mixins.v2.scrollbarInvisible};
  position: relative;
  padding-bottom: ${({ showPaginate }) => (showPaginate ? '48px' : '0')};
`;

const Table = styled.table`
  width: 100%;
  border-collapse: separate;
  border-spacing: 0;
`;

const DummyTh = styled.td`
  width: ${themeV2.mixins.v2.spacing * 3}px;
  border-bottom: 1px solid ${themeV2.mixins.v2.color.border.gray};
  background-color: #faf9f7;
`;

const DummyTd = styled.td`
  width: ${themeV2.mixins.v2.spacing * 3}px;
  border-bottom: 1px solid ${themeV2.mixins.v2.color.border.gray};
`;

const Tr = styled.tr`
  &:hover {
    background-color: ${themeV2.mixins.v2.color.background.lightGray};
  }
`;

const Th = styled.th`
  ${themeV2.mixins.v2.typography.label.medium};
  text-align: left;
  padding: ${themeV2.mixins.v2.spacing}px;
  border-bottom: 1px solid ${themeV2.mixins.v2.color.border.gray};
  background-color: #faf9f7;
`;

const Td = styled.td`
  ${themeV2.mixins.v2.typography.body.medium};
  padding: ${themeV2.mixins.v2.spacing}px;
  border-bottom: 1px solid ${themeV2.mixins.v2.color.border.gray};
`;

const LoaderWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 200px;
`;

const NoDataWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 200px;
  ${themeV2.mixins.v2.typography.body.medium};
  color: ${themeV2.mixins.v2.color.font.gray};
`;

const Footer = styled.div`
  position: relative;
`;

const PaginateWrapper = styled.div`
  position: absolute;
  width: 100%;
  bottom: 0;
`;

export default UserRoomEntryExitHistoryTable;
