import { MultiSearchBoxV2, themeV2, useSafeCallback, useSafeState, useUnmountRef } from '@atomica.co/components';
import {
  BaseDto,
  InterestTagPlain,
  SEARCH_INTEREST_TAGS,
  SearchInterestTagsRequest,
  SearchInterestTagsResponse
} from '@atomica.co/irori';
import { Id, Name, Word } from '@atomica.co/types';
import { ZERO, builder, hasLength, uuid } from '@atomica.co/utils';
import React, { useEffect, useMemo } from 'react';
import styled from 'styled-components';
import useCommonRequest from '../../redux/hooks/useCommonRequest';

const LIMIT = 10;

type _InterestTag = {
  interestTagId?: Id;
  interestTagName: Name;
};

interface P {
  base: BaseDto;
  editable?: boolean;
  addNewTags?: boolean;
  interestTag: _InterestTag[];
  setInterestTags?: React.Dispatch<React.SetStateAction<_InterestTag[]>>;
}

const InterestTags: React.FC<P> = React.memo(props => {
  const { base, editable = false, addNewTags = false, interestTag, setInterestTags } = props;
  const unmountRef = useUnmountRef();
  const { commonRequest } = useCommonRequest();

  const [allInterestTags, setAllInterestTags] = useSafeState<_InterestTag[]>(unmountRef, []);
  const [searchedInterestTags, setSearchedInterestTags] = useSafeState<_InterestTag[]>(unmountRef, []);

  const tagsToSave = useMemo<Name[]>(() => interestTag.map(tag => tag.interestTagName), [interestTag]);
  const searchedTags = useMemo<Name[]>(
    () => searchedInterestTags.map(tag => tag.interestTagName),
    [searchedInterestTags]
  );
  const showTags = useMemo<boolean>(() => editable || (!editable && hasLength(tagsToSave)), [editable, tagsToSave]);

  const handleTagChanged = useSafeCallback(
    async (word: Word): Promise<void> => {
      if (!word) {
        setSearchedInterestTags(allInterestTags.slice(0, LIMIT - 1));
        return;
      }

      const upperWord = word.toUpperCase();
      const isSaved = allInterestTags.some(tag => tag.interestTagName.toUpperCase() === upperWord);
      const filteredTags = allInterestTags
        .filter(tag => tag.interestTagName.toUpperCase().indexOf(upperWord) >= 0)
        .slice(0, LIMIT - 1);
      if (!isSaved && addNewTags) filteredTags.push({ interestTagName: word });
      setSearchedInterestTags(filteredTags);
    },
    [base, setSearchedInterestTags, allInterestTags, addNewTags]
  );

  const handleTagSelected = useSafeCallback(
    (words: Word[]): void => {
      setSearchedInterestTags(searchedTags => {
        setInterestTags?.(prevTags => {
          return words.map(word => {
            const tag = [...searchedTags, ...prevTags].find(tag => tag.interestTagName === word);

            return builder<InterestTagPlain>()
              .interestTagId(tag?.interestTagId ? tag.interestTagId : uuid())
              .interestTagName(word)
              .build();
          });
        });
        return [];
      });
    },
    [setSearchedInterestTags, setInterestTags]
  );

  const initialize = useSafeCallback(async (): Promise<void> => {
    const request = builder<SearchInterestTagsRequest>().baseId(base.baseId).build();
    const response = await commonRequest<SearchInterestTagsRequest, SearchInterestTagsResponse>(
      SEARCH_INTEREST_TAGS,
      request
    );
    setAllInterestTags(response.interestTags ?? []);
  }, [setAllInterestTags]);

  useEffect(() => {
    initialize();
  }, [initialize]);

  return (
    <Frame isEditable={editable} data-testid='interest-tags' hasChild={showTags}>
      {showTags && (
        <MultiSearchBoxV2
          isEditable={editable}
          words={tagsToSave}
          options={searchedTags}
          onChange={handleTagChanged}
          onSelect={handleTagSelected}
        />
      )}
    </Frame>
  );
});

InterestTags.displayName = 'InterestTags';
export default InterestTags;

const Frame = styled.div<{ isEditable: boolean; hasChild: boolean }>`
  width: 100%;
  min-height: 60px;
  border: ${({ isEditable }) => (isEditable ? `1px solid ${themeV2.mixins.v2.color.border.gray}` : 'none')};
  padding: ${({ isEditable }) => (isEditable ? themeV2.mixins.v2.spacing : ZERO)}px;
  border-radius: 8px;
  background: ${({ hasChild }) =>
    hasChild ? themeV2.mixins.v2.color.background.white : themeV2.mixins.v2.color.background.offWhite};
`;
