import { Category, Gender, Identification, UserType } from '@atomica.co/irori';
import { Label } from '@atomica.co/types';
import {
  USER_DETAIL_FEATURES,
  USER_DETAIL_ROOM_ENTRY_EXIT_HISTORY_FEATURES,
  UserAction,
  UserDetailFeature,
  UserDetailRoomEntryExitHistoryFeatures,
  UserInflowSource
} from '../enums/users-enum';
import { Labels } from '../models/common-model';

export const USER_TYPE_LABELS: Labels = {
  [UserType.ADMIN]: '管理者',
  [UserType.MEMBER]: '契約会員',
  [UserType.SHOT_USER]: '非契約会員',
  [UserType.WISH_USER]: 'WISHユーザー'
};

export const IDENTIFICATION_LABELS: Labels = {
  [Identification.CONFIRMED]: '確認済み',
  [Identification.UNCONFIRMED]: '未確認'
};

export const USER_ACTION_LABEL: Labels = {
  [UserAction.OPERATE_ALL]: '一括操作',
  [UserAction.DETAIL_ALL]: 'ユーザー情報の一括更新',
  [UserAction.UPDATE_USER_AUTHORITY]: 'ユーザー権限の一括更新',
  [UserAction.UPDATE_RANK]: '会員ランクの一括更新',
  [UserAction.UPDATE_RESOURCE_AUTHORITY]: 'リソース権限の一括更新'
  // [UserAction.DELETE_ALL]: '一括削除'
};

export const USER_DETAIL_FEATURE_LABELS_V2: Record<UserDetailFeature, Label> = {
  [USER_DETAIL_FEATURES.OVERVIEW]: '概要',
  [USER_DETAIL_FEATURES.ROOM_ENTRY_EXIT_HISTORY]: '入退室履歴',
  [USER_DETAIL_FEATURES.POINT_USAGE_LOG]: 'ポイント利用履歴'
};

export const USER_DETAIL_ROOM_ENTRY_EXIT_HISTORY_FEATURE_LABELS: Record<UserDetailRoomEntryExitHistoryFeatures, Label> =
  {
    [USER_DETAIL_ROOM_ENTRY_EXIT_HISTORY_FEATURES.REGISTER]: '登録',
    [USER_DETAIL_ROOM_ENTRY_EXIT_HISTORY_FEATURES.UPDATE]: '更新',
    [USER_DETAIL_ROOM_ENTRY_EXIT_HISTORY_FEATURES.DELETE]: '削除'
  };

/** @deprecated */
export const USER_CATEGORY_LABELS: Labels = {
  [Category.WORK]: '社会人',
  [Category.UNIVERSITY]: '大学生',
  [Category.HIGH_SCHOOL]: '高校生',
  [Category.OTHER]: 'その他'
};

/** @deprecated */
export const USER_INFLOW_SOURCE_LABELS: Labels = {
  [UserInflowSource.INTERNET]: 'インターネット検索',
  [UserInflowSource.TWITTER]: 'Twitter',
  [UserInflowSource.INSTAGRAM]: 'Instagram',
  [UserInflowSource.FACEBOOK]: 'Facebook',
  [UserInflowSource.REFERRAL]: '知人からの紹介',
  [UserInflowSource.TELEVISION]: 'テレビ',
  [UserInflowSource.RADIO]: 'ラジオ',
  [UserInflowSource.MAGAZIN]: '雑誌',
  [UserInflowSource.NEWSPAPER]: '新聞・チラシ',
  [UserInflowSource.OTHER]: 'その他'
};

export const USER_GENDER_LABELS: Labels = {
  [Gender.MALE]: '男性',
  [Gender.FEMALE]: '女性',
  [Gender.OTHER]: 'その他/無回答'
};
