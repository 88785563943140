import { Component, theme, useSafeCallback, useSafeState, useUnmountRef } from '@atomica.co/components';
import { BaseDto, UserEntity, toLatestUserPath } from '@atomica.co/irori';
import { UserId } from '@atomica.co/types';
import { embedIdInPath } from '@atomica.co/utils';
import { Typography } from '@material-ui/core';
import React, { useEffect } from 'react';
import styled from 'styled-components';
import { getBsUser } from '../../__generated/admin/bs-user/bs-user';
import MemberPolaroid from '../../components/cards/MemberPolaroid';
import ShotPolaroid from '../../components/cards/ShotPolaroid';
import { database } from '../../firebase';
import usePath from '../../redux/hooks/usePath';
import { PATH_IDS, Path } from '../../router/Routes';
import { getRotate } from '../../utils/common-util';

interface P {
  base: BaseDto;
}

const VisitorsScreen: React.FC<P> = React.memo(props => {
  const { base } = props;

  const { openPath } = usePath();

  const unmountRef = useUnmountRef();
  const [shotUsers, setShotUsers] = useSafeState<UserEntity[]>(unmountRef, []);
  const [members, setMembers] = useSafeState<UserEntity[]>(unmountRef, []);

  const loadTodayUsers = useSafeCallback(async (): Promise<void> => {
    const { data } = await getBsUser().fetchTodayUsersForAdmin(base.baseId);
    setShotUsers(data.shotUsers);
    setMembers(data.members);
  }, [base, setMembers, setShotUsers]);

  useEffect(() => {
    loadTodayUsers();
  }, [loadTodayUsers]);

  useEffect(() => {
    const ref = database.ref(toLatestUserPath(base.baseCode));
    ref.on('value', () => loadTodayUsers());
  }, [base, loadTodayUsers]);

  const handleCardClicked = useSafeCallback(
    (userId: UserId): void => {
      openPath(embedIdInPath(Path.USER_OVERVIEW, PATH_IDS, [base.baseCode, userId]));
    },
    [base, openPath]
  );

  return (
    <Component className='visitors-screen'>
      <Container>
        <SubTitle data-testid='page-header'>ショット利用者</SubTitle>
        <Title>Shot Users</Title>

        <PolaroidsWrapper>
          {shotUsers.map((user, index) => (
            <ShotPolaroid key={index} rotate={getRotate()} user={user} onClick={handleCardClicked} />
          ))}
        </PolaroidsWrapper>

        <SubTitle>会員利用者</SubTitle>
        <Title>Members</Title>

        <PolaroidsWrapper>
          {members.map((user, index) => (
            <MemberPolaroid key={index} rotate={getRotate()} user={user} onClick={handleCardClicked} />
          ))}
        </PolaroidsWrapper>
      </Container>
    </Component>
  );
});

VisitorsScreen.displayName = 'VisitorsScreen';
export default VisitorsScreen;

const Container = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  flex-flow: column;
  align-items: center;
`;

const SubTitle = styled(Typography)`
  color: ${theme.mixins.typography.fontColor.black};
  font-size: ${theme.mixins.typography.fontSize.twentyFour}px;
  font-family: ${theme.mixins.typography.fontFamily};
  font-weight: ${theme.mixins.typography.fontWeight.nineHundreds};
  margin-top: 24px;
`;

const Title = styled(Typography)`
  color: ${theme.mixins.typography.fontColor.black};
  font-size: 56px;
  font-family: ${theme.mixins.typography.fontFamily};
  font-weight: ${theme.mixins.typography.fontWeight.nineHundreds};
  line-height: 64px;
`;

const PolaroidsWrapper = styled.div`
  width: 100%;
  height: auto;
  min-height: 336px;
  display: flex;
  flex-flow: wrap;
  justify-content: space-around;
  padding: ${theme.mixins.spacing}px 0px ${theme.mixins.spacing * 3}px;
`;
