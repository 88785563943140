import {
  TabProperty,
  TabsV3,
  TransitionGroup,
  themeV2,
  useSafeCallback,
  useSafeState,
  useUnmountRef
} from '@atomica.co/components';
import { AUTHORITY_CODE, BaseDto, User } from '@atomica.co/irori';
import { Code, Index } from '@atomica.co/types';
import React, { useEffect, useMemo } from 'react';
import { RouteComponentProps } from 'react-router';
import styled from 'styled-components';
import Screen from '../../components/screen/Screen';
import { SignUpInTabEnum } from '../../enums/account-enum';
import useCachedInitialAuthorityCode from '../../redux/hooks/useCachedInitialAuthorityCode';
import useLogin from '../../redux/hooks/useLoginService';
import usePath from '../../redux/hooks/usePath';
import { Path } from '../../router/Routes';
import { SIGN_UP_IN_TAB_LABELS } from '../../texts/account-text';
import SignIn from './sign-in/SignIn';
import SignUp from './sign-up/SignUp';

interface P extends RouteComponentProps {
  base: BaseDto;
  user: User | undefined;
}

const tabProperties: TabProperty[] = [
  {
    label: SIGN_UP_IN_TAB_LABELS[SignUpInTabEnum.SIGN_IN],
    disabled: false
  },
  {
    label: SIGN_UP_IN_TAB_LABELS[SignUpInTabEnum.SIGN_UP],
    disabled: false
  }
];

const SignUpInScreen: React.FC<P> = React.memo(props => {
  const { base, user } = props;
  const { path, queryParams, openBasePath } = usePath();
  const { saveCachedInitialAuthorityCode } = useCachedInitialAuthorityCode();
  const unmountRef = useUnmountRef();
  const [initialized, setInitialized] = useSafeState<boolean>(unmountRef, false);
  const {
    usableLoginServiceTypes,
    loadLoginServices,
    handleSignInWithRedirectResult,
    succeededToSignIn,
    errorMessage
  } = useLogin({ base, user });

  const authorityCode = useMemo<Code | undefined>(() => queryParams[AUTHORITY_CODE], [queryParams]);
  const initialTabIdx = useMemo<SignUpInTabEnum>(() => {
    switch (path) {
      case Path.SIGN_UP:
        return SignUpInTabEnum.SIGN_UP;
      case Path.SIGN_IN:
      default:
        return SignUpInTabEnum.SIGN_IN;
    }
  }, [path]);

  const [selectedTabIdx, setSelectedTabIdx] = useSafeState<SignUpInTabEnum>(unmountRef, initialTabIdx);

  const initialize = useSafeCallback(async () => {
    await loadLoginServices();
  }, [loadLoginServices]);

  useEffect(() => {
    if (initialized) return;
    handleSignInWithRedirectResult().finally(() => initialize().finally(() => setInitialized(true)));
  }, [initialize, initialized, setInitialized, handleSignInWithRedirectResult]);

  useEffect(() => {
    setSelectedTabIdx(initialTabIdx);
  }, [setSelectedTabIdx, initialTabIdx]);

  useEffect(() => {
    if (authorityCode) saveCachedInitialAuthorityCode(authorityCode);
  }, [authorityCode, saveCachedInitialAuthorityCode]);

  const handleTabChanged = useSafeCallback(
    (selectedTabIdx: Index) => {
      switch (selectedTabIdx) {
        case SignUpInTabEnum.SIGN_UP:
          openBasePath(Path.SIGN_UP);
          break;
        case SignUpInTabEnum.SIGN_IN:
          openBasePath(Path.SIGN_IN);
          break;
      }
      setSelectedTabIdx(selectedTabIdx);
    },
    [openBasePath, setSelectedTabIdx]
  );

  return (
    <Screen loading={!initialized} className='sign-up-in-screen'>
      {base.isPublic && (
        <TabsWrapper>
          <TabsV3 tabs={tabProperties} variant='filled' selectedTabIdx={selectedTabIdx} onChange={handleTabChanged} />
        </TabsWrapper>
      )}

      <TransitionWrapper>
        <TransitionGroup
          index={selectedTabIdx}
          components={
            base.isPublic
              ? [
                  <SignIn
                    key='sign-in'
                    base={base}
                    usableLoginServiceTypes={usableLoginServiceTypes}
                    message={errorMessage}
                    onSignIn={succeededToSignIn}
                  />,
                  <SignUp
                    key='sign-up'
                    base={base}
                    usableLoginServiceTypes={usableLoginServiceTypes}
                    message={errorMessage}
                  />
                ]
              : [
                  <SignIn
                    key='sign-in'
                    base={base}
                    usableLoginServiceTypes={usableLoginServiceTypes}
                    message={errorMessage}
                    onSignIn={succeededToSignIn}
                  />
                ]
          }
        />
      </TransitionWrapper>
    </Screen>
  );
});

SignUpInScreen.displayName = 'SignUpInScreen';
export default SignUpInScreen;

const TabsWrapper = styled.div`
  width: 288px;
  display: flex;
  justify-content: center;
  padding: ${themeV2.mixins.v2.spacing * 1.5}px 0;
  margin: 0 auto;
`;

const TransitionWrapper = styled.div`
  margin-top: 0;
`;
