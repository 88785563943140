import { themeV2, themeV3 } from '@atomica.co/components';
import { Color, Label } from '@atomica.co/types';
import { Button } from '@material-ui/core';
import React from 'react';
import styled from 'styled-components';

type Type = 'primary' | 'default';

interface P {
  disabled?: boolean;
  type?: Type;
  icon: JSX.Element;
  label: Label;
  onClick: () => void;
}

const CapsuleButton: React.FC<P> = React.memo(props => {
  const { disabled, type = 'default', icon, label, onClick } = props;

  return (
    <StyledButton disableRipple disabled={disabled} buttontype={type} startIcon={icon} onClick={onClick}>
      {label}
    </StyledButton>
  );
});

CapsuleButton.displayName = 'CapsuleButton';
export default CapsuleButton;

const getColor = (type: Type): Color => {
  switch (type) {
    case 'primary':
      return themeV3.mixins.v3.color.object.white;
    case 'default':
      return themeV3.mixins.v3.color.object.gray;
  }
};

const getBackgroundColor = (type: Type): Color => {
  switch (type) {
    case 'primary':
      return themeV3.mixins.v3.color.container.primary.default;

    case 'default':
      return themeV3.mixins.v3.color.container.neutral.white;
  }
};

const getBackgroundColorOnHover = (type: Type): Color => {
  switch (type) {
    case 'primary':
      return themeV3.mixins.v3.color.container.primary.dark;

    case 'default':
      return themeV3.mixins.v3.color.container.neutral.row;
  }
};

const StyledButton = styled(Button)<{ buttontype: Type }>`
  && {
    ${themeV2.mixins.v2.typography.label.large};
    width: 288px;
    height: 48px;
    color: ${({ buttontype }) => getColor(buttontype)};
    background: ${({ buttontype }) => getBackgroundColor(buttontype)};
    color: ${({ buttontype }) => getColor(buttontype)};
    padding: ${themeV2.mixins.v2.spacing}px ${themeV2.mixins.v2.spacing * 3}px;
    justify-content: start;
    border: 1px solid ${themeV3.mixins.v3.color.border.gray};
    border-radius: 24px;
  }
  &&:hover {
    background: ${({ buttontype }) => getBackgroundColorOnHover(buttontype)};
  }
  &&:disabled {
    color: ${themeV3.mixins.v3.color.object.disabled};
    background-color: ${themeV3.mixins.v3.color.container.neutral.disabled};
  }
`;
