import {
  ColgroupV2,
  IconButtonV2,
  SelectBoxV2,
  TableV2,
  TbodyV2,
  TdV2,
  TextFieldV2,
  TheadV2,
  ThV2,
  TrV2
} from '@atomica.co/components';
import { ContractDetailId, UnitV2 } from '@atomica.co/irori';
import { Index, Option } from '@atomica.co/types';
import { EMPTY, hasLength, MINUS_ONE } from '@atomica.co/utils';
import { Clear, Visibility, VisibilityOff } from '@material-ui/icons';
import React, { useMemo } from 'react';
import styled from 'styled-components';
import { DetailForContractRegisterScreen } from '../../../../converters/contract-v2-converter';
import { DisplayTaxes, toTaxLabels, toTaxOptions } from '../../../../converters/tax-converter';
import { ContractDiscountInputKeyEnum } from '../../../../enums/contract-v2-enum';
import { Labels } from '../../../../models/common-model';
import { getTaxKey, reverseTaxKey } from '../../../../utils/tax-util';

interface P {
  deleteDiscountRow: (contractDetailId: ContractDetailId) => void;
  discountDetails: DetailForContractRegisterScreen[];
  handleDiscountInput: <T>(contractDetailId: ContractDetailId, key: ContractDiscountInputKeyEnum, val: T) => void;
  displayTaxes: DisplayTaxes | undefined;
  units: UnitV2[];
}

export const DiscountDetailsInput: React.FC<P> = React.memo(props => {
  const { deleteDiscountRow, discountDetails, handleDiscountInput, displayTaxes } = props;

  const taxOptions = useMemo<Option[]>(() => toTaxOptions(displayTaxes), [displayTaxes]);
  const taxLabels = useMemo<Labels>(() => toTaxLabels(displayTaxes), [displayTaxes]);

  return (
    <Container>
      {hasLength(discountDetails) && (
        <TableV2 shape='circle' showBorder={true}>
          <ColgroupV2 />
          <ColgroupV2 width={160} />
          <ColgroupV2 width={120} />
          <ColgroupV2 width={136} />
          <ColgroupV2 width={0} />
          <TheadV2>
            <TrV2>
              <ThV2 horizonPadding={8}>品目</ThV2>
              <ThV2 horizonPadding={8}>割引額</ThV2>
              <ThV2 horizonPadding={8}>課税</ThV2>
              <ThV2 horizonPadding={8}>取引期間（ヶ月）</ThV2>
              <ThV2 horizonPadding={8}>{EMPTY}</ThV2>
            </TrV2>
          </TheadV2>
          <TbodyV2>
            {discountDetails.map((discount: DetailForContractRegisterScreen, rowIdx: Index) => (
              <TrV2 key={`discount-tr${rowIdx}`} showOverlay={discount.disabled}>
                <TdV2 horizonPadding={8}>
                  <TextFieldV2
                    align='left'
                    value={discount.itemName}
                    onChange={value =>
                      handleDiscountInput(discount.contractDetailId, ContractDiscountInputKeyEnum.ITEM_NAME, value)
                    }
                    readonly={discount.disabled}
                  />
                </TdV2>
                <TdV2 horizonPadding={8}>
                  <TextFieldV2
                    align='right'
                    inputType='number'
                    value={typeof discount.unitPrice === 'number' ? Math.abs(discount.unitPrice) : discount.unitPrice}
                    onChange={value =>
                      handleDiscountInput(
                        discount.contractDetailId,
                        ContractDiscountInputKeyEnum.UNIT_PRICE,
                        (typeof value === 'number' ? value : Number(value)) * MINUS_ONE
                      )
                    }
                    disabled={discount.disabled}
                  />
                </TdV2>
                <TdV2 horizonPadding={8}>
                  <SelectBoxV2
                    labels={taxLabels}
                    options={taxOptions}
                    value={getTaxKey(discount.taxId, discount.taxDiv, discount.taxRate)}
                    onChange={key => {
                      const { taxId, taxDiv, taxRate } = reverseTaxKey(key);
                      if (!taxId || !taxDiv || taxRate == null) return;
                      handleDiscountInput(discount.contractDetailId, ContractDiscountInputKeyEnum.TAX_ID, taxId);
                      handleDiscountInput(discount.contractDetailId, ContractDiscountInputKeyEnum.TAX_DIV, taxDiv);
                      handleDiscountInput(discount.contractDetailId, ContractDiscountInputKeyEnum.TAX_RATE, taxRate);
                    }}
                    disabled={discount.disabled}
                    readonly={!discount.isDeletable}
                  />
                </TdV2>
                <TdV2 horizonPadding={8}>
                  <TextFieldV2
                    align='right'
                    inputType='number'
                    value={discount.recurrenceCount}
                    onChange={value =>
                      handleDiscountInput(
                        discount.contractDetailId,
                        ContractDiscountInputKeyEnum.RECURRENCE_COUNT,
                        value
                      )
                    }
                    disabled={discount.disabled}
                    readonly={!discount.isDeletable}
                  />
                </TdV2>
                <TdV2 horizonPadding={8}>
                  {!discount.isDeletable && (
                    <IconButtonV2
                      size='small'
                      icon={discount.disabled ? <VisibilityOff fontSize='small' /> : <Visibility fontSize='small' />}
                      onClick={() =>
                        handleDiscountInput(
                          discount.contractDetailId,
                          ContractDiscountInputKeyEnum.DISABLED,
                          !discount.disabled
                        )
                      }
                    />
                  )}

                  {discount.isDeletable && (
                    <IconButtonV2
                      size='small'
                      icon={<Clear fontSize='small' />}
                      onClick={() => deleteDiscountRow(discount.contractDetailId)}
                    />
                  )}
                </TdV2>
              </TrV2>
            ))}
          </TbodyV2>
        </TableV2>
      )}
    </Container>
  );
});

DiscountDetailsInput.displayName = 'DiscountDetailsInput';
const Container = styled.div``;
