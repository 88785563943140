import {
  ButtonV2,
  Icon,
  TextFieldV2,
  themeV2,
  useSafeCallback,
  useSafeState,
  useUnmountRef
} from '@atomica.co/components';
import { BaseDto, LoginServiceType } from '@atomica.co/irori';
import { Email, Message, Password } from '@atomica.co/types';
import { EMPTY, NOT_FOUND_INDEX } from '@atomica.co/utils';
import MailIcon from '@material-ui/icons/Mail';
import React, { useEffect } from 'react';
import styled from 'styled-components';
import google from '../../../../assets/icon/icon_google.png';
import line from '../../../../assets/icon/icon_line.png';
import sakura from '../../../../assets/icon/sakuraId.svg';
import CapsuleButton from '../../../../components/button/CapsuleButton';
import { PRIVACY_POLICY_FOR_KNOT_PLACE, TERMS_OF_USE_FOR_KNOT_PLACE } from '../../../../constants/account-const';
import { Process } from '../../../../enums/action-enum';
import firebase, { auth } from '../../../../firebase';
import useLogin from '../../../../redux/hooks/useLoginService';
import useProcess from '../../../../redux/hooks/useProcess';
import { ERROR_MESSAGES } from '../../../../texts/error-text';

interface P {
  base: BaseDto;
  message?: Message;
  usableLoginServiceTypes: LoginServiceType[];
  onSignIn(res: firebase.auth.UserCredential): void;
  resetPassword(): void;
}

const openTermOfUse = (): void => {
  window.open(TERMS_OF_USE_FOR_KNOT_PLACE, 'newtab');
};

const openPrivacyPolicy = (): void => {
  window.open(PRIVACY_POLICY_FOR_KNOT_PLACE, 'newtab');
};

const SignInOptionList: React.FC<P> = React.memo(props => {
  const { base, message, usableLoginServiceTypes, onSignIn, resetPassword } = props;

  //email
  const unmountRef = useUnmountRef();
  const { saveProcess } = useProcess();
  const [disabledSignInButton, setDisabledSignInButton] = useSafeState<boolean>(unmountRef, true);
  const [email, setEmail] = useSafeState<Email>(unmountRef, EMPTY);
  const [password, setPassword] = useSafeState<Password>(unmountRef, EMPTY);
  const [errorMessage, setErrorMessage] = useSafeState<Message>(unmountRef, EMPTY);
  const { openGoogleLoginPage, openLineLoginPage, openSakuraLoginPage } = useLogin({ base });

  useEffect(() => {
    setDisabledSignInButton(!email || !password);
  }, [setDisabledSignInButton, email, password]);

  useEffect(() => {
    if (message) setErrorMessage(message);
  }, [setErrorMessage, message]);

  const constructErrorMessage = useSafeCallback(
    (message: Message): void => {
      if (!message) return;

      Object.entries(ERROR_MESSAGES).forEach(([key, value]) => {
        if (message.toString().indexOf(key) === NOT_FOUND_INDEX) return;
        setErrorMessage(value);
      });
    },
    [setErrorMessage]
  );

  const signInWithEmailAndPassword = useSafeCallback((): void => {
    setDisabledSignInButton(true);
    saveProcess(Process.PROCESSING);

    auth
      .signInWithEmailAndPassword(email, password)
      .then(onSignIn)
      .catch(err => {
        setDisabledSignInButton(false);
        saveProcess(Process.INITIAL);
        constructErrorMessage(err.code);
      });
  }, [setDisabledSignInButton, saveProcess, email, password, onSignIn, constructErrorMessage]);

  return (
    <Container>
      <LoginServiceSection>
        {usableLoginServiceTypes.map(
          (serviceType, idx) =>
            (serviceType === LoginServiceType.MAIL && (
              <MailOption key={idx}>
                <StyledTextField inputMode='email' height={56} placeholder='Email' value={email} onChange={setEmail} />
                <PasswordWrapper>
                  <StyledTextField
                    inputType='password'
                    errorMessage={errorMessage}
                    height={56}
                    placeholder='パスワード'
                    value={password}
                    onChange={setPassword}
                    onEnter={signInWithEmailAndPassword}
                  />
                  <ButtonV2 label='パスワードを忘れた場合' onClick={resetPassword} />
                </PasswordWrapper>
                <CapsuleButton
                  type='primary'
                  disabled={disabledSignInButton}
                  icon={<MailIcon fontSize='small' />}
                  label='メールアドレスでログイン'
                  onClick={signInWithEmailAndPassword}
                />
              </MailOption>
            )) ||
            (serviceType === LoginServiceType.SAKURA && (
              <CapsuleButton
                key={idx}
                icon={<Icon size='xsmall' src={sakura} />}
                label='さくらIDでログイン'
                onClick={openSakuraLoginPage}
              />
            )) ||
            (serviceType === LoginServiceType.GOOGLE && (
              <CapsuleButton
                key={idx}
                icon={<Icon size='xsmall' src={google} />}
                label='Googleでログイン'
                onClick={openGoogleLoginPage}
              />
            )) ||
            (serviceType === LoginServiceType.LINE && (
              <CapsuleButton
                key={idx}
                icon={<Icon size='xsmall' src={line} />}
                label='LINEでログイン'
                onClick={openLineLoginPage}
              />
            ))
        )}
      </LoginServiceSection>
      <MessageSection>
        <MessageText>
          ログインには
          <Link onClick={openTermOfUse}>ご利用規約</Link>
          および
          <Link onClick={openPrivacyPolicy}>プライバシーポリシー</Link>
          への同意が必要です。ご利用いただいた場合には、ご同意いただいたものとして取り扱わせていただきます。
        </MessageText>
      </MessageSection>
    </Container>
  );
});

SignInOptionList.displayName = 'SignInOptionList';
export default SignInOptionList;

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: ${themeV2.mixins.v2.spacing * 2}px;
`;

const StyledTextField = styled(TextFieldV2)`
  && {
    border: none !important;
    border-radius: 16px !important;
  }
`;

const MessageSection = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  justify-content: center;
  padding: ${themeV2.mixins.v2.spacing * 2}px ${themeV2.mixins.v2.spacing * 4}px;
`;

const MessageText = styled.div`
  ${themeV2.mixins.v2.typography.body.small};
  width: 100%;
  height: auto;
  color: ${themeV2.mixins.v2.color.font.gray};
`;

const Link = styled.div`
  ${themeV2.mixins.v2.typography.body.small};
  display: inline;
  color: ${themeV2.mixins.v2.color.font.pink};
  cursor: pointer;
`;

const LoginServiceSection = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: ${themeV2.mixins.v2.spacing}px;
`;
const MailOption = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: ${themeV2.mixins.v2.spacing * 2}px;
`;
const PasswordWrapper = styled.div`
  width: 100%;
  text-align: right;
`;
