import { useSafeCallback, useSafeState, useUnmountRef } from '@atomica.co/components';
import { DateStr, Id, Time } from '@atomica.co/types';
import { EMPTY } from '@atomica.co/utils';
import { format, parse } from 'date-fns';
import { z } from 'zod';

/**入退室に関するValidation処理 */
const entryExitSchema = z
  .object({
    entryDate: z.string().regex(/^\d{4}-\d{2}-\d{2}$/, '正しい日付形式で入力してください。'),
    entryTime: z.string().regex(/^([01]\d|2[0-3]):([0-5]\d)$/, '正しい時刻形式で入力してください。'),
    exitDate: z.string().regex(/^\d{4}-\d{2}-\d{2}$/, '正しい日付形式で入力してください。'),
    exitTime: z.string().regex(/^([01]\d|2[0-3]):([0-5]\d)$/, '正しい時刻形式で入力してください。')
  })
  .refine(
    data => {
      const entryDateTime = new Date(`${data.entryDate}T${data.entryTime}`);
      const exitDateTime = new Date(`${data.exitDate}T${data.exitTime}`);
      return exitDateTime >= entryDateTime;
    },
    {
      message: '入室時間より後の日時を入力してください。',
      path: ['exitDate', 'exitTime']
    }
  )
  .refine(
    data => {
      return data.entryDate === data.exitDate;
    },
    {
      message: '入室時間と同じ日付を入力してください。',
      path: ['exitDate', 'exitTime']
    }
  );

export interface EntryExitErrors {
  entryDate?: string[] | undefined;
  exitDate?: string[] | undefined;
  entryTime?: string[] | undefined;
  exitTime?: string[] | undefined;
}
export const useEntryExitValidation = (data: EntryExitData) => {
  const validationResult = entryExitSchema.safeParse(data);
  if (!validationResult.success) {
    return {
      isValid: false,
      errors: validationResult.error.flatten().fieldErrors
    };
  }
  return {
    isValid: true,
    errors: {}
  };
};

/** 入退室情報型定義 */
export interface EntryExitData {
  entryId: Id;
  exitId: Id;
  entryDate: DateStr;
  entryTime: Time;
  exitDate: DateStr;
  exitTime: Time;
}

export interface EntryExitChangeHandlers {
  onEntryIdChange: React.Dispatch<React.SetStateAction<Id>>;
  onExitIdChange: React.Dispatch<React.SetStateAction<Id>>;
  onEntryDateChange: React.Dispatch<React.SetStateAction<DateStr>>;
  onExitDateChange: React.Dispatch<React.SetStateAction<DateStr>>;
  onEntryTimeChange: React.Dispatch<React.SetStateAction<Time>>;
  onExitTimeChange: React.Dispatch<React.SetStateAction<Time>>;
}

export interface EntryExitHook {
  data: EntryExitData;
  changeHandlers: EntryExitChangeHandlers;
  validate: () => { isValid: boolean; errors: EntryExitErrors };
  resetAllFields: () => void;
  setDateTimeFields: (type: 'entry' | 'exit', date: Date) => void;
  getDateTime: (key: 'entry' | 'exit') => Date;
}

/** 入退室の情報を管理するHooks */
export const useEntryExit = (): EntryExitHook => {
  const unmountRef = useUnmountRef();

  const [entryId, setEntryId] = useSafeState<Id>(unmountRef, EMPTY);
  const [exitId, setExitId] = useSafeState<Id>(unmountRef, EMPTY);
  const [entryDate, setEntryDate] = useSafeState<DateStr>(unmountRef, EMPTY);
  const [exitDate, setExitDate] = useSafeState<DateStr>(unmountRef, EMPTY);
  const [entryTime, setEntryTime] = useSafeState<Time>(unmountRef, EMPTY);
  const [exitTime, setExitTime] = useSafeState<Time>(unmountRef, EMPTY);

  const validate = useSafeCallback(() => {
    const data = { entryDate, entryTime, exitDate, exitTime };
    const result = entryExitSchema.safeParse(data);

    if (result.success) {
      return { isValid: true, errors: {} };
    } else {
      return {
        isValid: false,
        errors: result.error.flatten().fieldErrors as EntryExitErrors
      };
    }
  }, [entryDate, entryTime, exitDate, exitTime]);

  const resetAllFields = useSafeCallback(() => {
    setEntryId(EMPTY);
    setExitId(EMPTY);
    setEntryDate(EMPTY);
    setExitDate(EMPTY);
    setEntryTime(EMPTY);
    setExitTime(EMPTY);
  }, []);

  const setDateTimeFields = useSafeCallback((type: 'entry' | 'exit', date: Date) => {
    const dateStr = format(date, 'yyyy-MM-dd');
    const timeStr = format(date, 'HH:mm');
    if (type === 'entry') {
      setEntryDate(dateStr);
      setEntryTime(timeStr);
    } else {
      setExitDate(dateStr);
      setExitTime(timeStr);
    }
  }, []);

  const getDateTime = useSafeCallback(
    (key: 'entry' | 'exit'): Date => {
      const date = key === 'entry' ? entryDate : exitDate;
      const time = key === 'entry' ? entryTime : exitTime;
      return parse(`${date} ${time}`, 'yyyy-MM-dd HH:mm', new Date());
    },
    [entryDate, entryTime, exitDate, exitTime]
  );

  return {
    data: { entryId, exitId, entryDate, entryTime, exitDate, exitTime },
    changeHandlers: {
      onEntryIdChange: setEntryId,
      onExitIdChange: setExitId,
      onEntryDateChange: setEntryDate,
      onEntryTimeChange: setEntryTime,
      onExitDateChange: setExitDate,
      onExitTimeChange: setExitTime
    },
    validate,
    resetAllFields,
    setDateTimeFields,
    getDateTime
  };
};
