import { MultiSearchBoxV2, themeV2, useSafeCallback, useSafeState, useUnmountRef } from '@atomica.co/components';
import {
  BaseDto,
  SEARCH_SKILL_TAGS,
  SearchSkillTagsRequest,
  SearchSkillTagsResponse,
  SkillTag
} from '@atomica.co/irori';
import { Id, Name, Word } from '@atomica.co/types';
import { ZERO, builder, hasLength, uuid } from '@atomica.co/utils';
import React, { useEffect, useMemo } from 'react';
import styled from 'styled-components';
import useCommonRequest from '../../redux/hooks/useCommonRequest';

const LIMIT = 10;

interface _SkillTag {
  skillTagId?: Id;
  name: Name;
}
interface P {
  base: BaseDto;
  editable?: boolean;
  addNewTags?: boolean;
  skillTags: (SkillTag | _SkillTag)[];
  setSkillTags?: React.Dispatch<React.SetStateAction<SkillTag[]>> | React.Dispatch<React.SetStateAction<_SkillTag[]>>;
}

const SkillTags: React.FC<P> = React.memo(props => {
  const { base, editable = false, addNewTags = false, skillTags, setSkillTags } = props;
  const unmountRef = useUnmountRef();
  const { commonRequest } = useCommonRequest();

  const [searchedSkillTags, setSearchedSkillTags] = useSafeState<_SkillTag[]>(unmountRef, []);
  const [allSkillTags, setAllSkillTags] = useSafeState<_SkillTag[]>(unmountRef, []);

  const tagsToSave = useMemo<Name[]>(() => skillTags.map(tag => tag.name), [skillTags]);
  const searchedTags = useMemo<Name[]>(() => searchedSkillTags.map(tag => tag.name), [searchedSkillTags]);
  const showTags = useMemo<boolean>(() => editable || (!editable && hasLength(tagsToSave)), [editable, tagsToSave]);

  const handleTagChanged = useSafeCallback(
    async (word: Word): Promise<void> => {
      if (!word) {
        setSearchedSkillTags(allSkillTags.slice(0, LIMIT - 1));
        return;
      }

      const upperWord = word.toUpperCase();
      const isSaved = allSkillTags.some(tag => tag.name.toUpperCase() === upperWord);
      const filteredTags = allSkillTags
        .filter(tag => tag.name.toUpperCase().indexOf(upperWord) >= 0)
        .slice(0, LIMIT - 1);
      if (!isSaved && addNewTags) filteredTags.push({ name: word });
      setSearchedSkillTags(filteredTags);
    },
    [base, setSearchedSkillTags, allSkillTags, addNewTags]
  );

  const handleTagSelected = useSafeCallback(
    (words: Word[]): void => {
      setSearchedSkillTags(searchedTags => {
        setSkillTags?.(prevTags => {
          return words.map(word => {
            const tag: SkillTag | _SkillTag = [...searchedTags, ...prevTags].find(tag => tag.name === word);

            return builder<SkillTag>()
              .skillTagId(tag?.skillTagId ? tag.skillTagId : uuid())
              .name(word)
              .build();
          });
        });
        return [];
      });
    },
    [setSearchedSkillTags, setSkillTags]
  );

  const initialize = useSafeCallback(async (): Promise<void> => {
    const request = builder<SearchSkillTagsRequest>().baseId(base.baseId).build();
    const response = await commonRequest<SearchSkillTagsRequest, SearchSkillTagsResponse>(SEARCH_SKILL_TAGS, request);
    setAllSkillTags(response.skillTags ?? []);
  }, [setAllSkillTags]);

  useEffect(() => {
    initialize();
  }, [initialize]);

  return (
    <Frame isEditable={editable} data-testid='skill-tags' hasChild={showTags}>
      {showTags && (
        <MultiSearchBoxV2
          isEditable={editable}
          words={tagsToSave}
          options={searchedTags}
          onChange={handleTagChanged}
          onSelect={handleTagSelected}
        />
      )}
    </Frame>
  );
});

SkillTags.displayName = 'SkillTags';
export default SkillTags;

const Frame = styled.div<{ isEditable: boolean; hasChild: boolean }>`
  width: 100%;
  min-height: 60px;
  border: ${({ isEditable }) => (isEditable ? `1px solid ${themeV2.mixins.v2.color.border.gray}` : 'none')};
  padding: ${({ isEditable }) => (isEditable ? themeV2.mixins.v2.spacing : ZERO)}px;
  border-radius: 8px;
  background: ${({ hasChild }) =>
    hasChild ? themeV2.mixins.v2.color.background.white : themeV2.mixins.v2.color.background.offWhite};
`;
