import { MultiCheckBoxOption, MultiCheckBoxOptionV2 } from '@atomica.co/components';
import {
  AuthorityDefCategory,
  AuthorityDefCodeEnum,
  BaseDto,
  BaseIdentification,
  PersonInCharge,
  User,
  UserDiv,
  UserEntity,
  UserFamilyMember,
  UserFamilyMemberMapping,
  UserInflowSource,
  UserStudentDiv,
  UserUsagePurpose
} from '@atomica.co/irori';
import { City, Code, Name, Option, ProviderId, Word } from '@atomica.co/types';
import { EMPTY, builder, hasLength, isArray, uuid } from '@atomica.co/utils';
import firebase from 'firebase/compat';
import { PROVIDER_ID_SAKURA_OIDC } from '../constants/auth-const';
import { BaseDef } from '../constants/base-const';
import { Labels } from '../models/common-model';

export const toProviderId = (fbUser: firebase.User): ProviderId => {
  if (fbUser.displayName === ProviderId.LINE) return ProviderId.LINE;
  if (fbUser.providerData?.[0]?.providerId === PROVIDER_ID_SAKURA_OIDC) return ProviderId.SAKURA;

  return ProviderId.GOOGLE;
};

export const toFullName = (user: UserEntity | User): Name => (user ? `${user.familyName} ${user.firstName}` : EMPTY);

export const isPIC = (user: User, baseCode: Code): boolean => {
  if (!user || !isArray(user.personInCharge)) return false;
  return user.personInCharge.some(pic => {
    const authority = pic.authority;
    const base = authority?.base;
    return base?.baseCode === BaseDef.ALL || base?.baseCode === baseCode;
  });
};

export const isAdmin = (user: User | undefined, baseCode: Code): boolean => {
  if (!user || !isArray(user.personInCharge)) return false;
  return user.personInCharge.some(pic => {
    const authority = pic.authority;
    const base = authority?.base;
    return (
      (base?.baseCode === BaseDef.ALL || base?.baseCode === baseCode) &&
      (authority?.authorityDef?.authorityDefCode === AuthorityDefCodeEnum.ADMIN ||
        authority?.authorityDef?.authorityDefCode === AuthorityDefCodeEnum.COMMUNITY_MANAGER)
    );
  });
};

export const getBasePIC = (user: User, base: BaseDto): PersonInCharge | undefined => {
  if (!isArray(user.personInCharge)) return;
  return user.personInCharge.find(pic => {
    const authority = pic.authority;
    return authority?.base?.baseId === base.baseId;
  });
};

export const getBasePICV2 = (user: User, base: BaseDto, categoryCode?: AuthorityDefCategory): PersonInCharge[] => {
  if (!isArray(user.personInCharge)) return [];
  const personInCharges = user.personInCharge.filter(pic => pic.authority?.base?.baseId === base.baseId);
  if (!categoryCode) return personInCharges;
  return personInCharges.filter(pic => pic.authority?.authorityDef?.category?.categoryCode === categoryCode);
};

export const getBaseIdentification = (user: User, base: BaseDto): BaseIdentification | undefined => {
  if (!hasLength(user.userIdentifications)) return;
  return user.userIdentifications!.find(
    identification => identification.baseIdentification?.base?.baseId === base.baseId
  )?.baseIdentification;
};

export const toCityLabels = (cities: City[]): Labels => {
  return cities.reduce((prev, current) => {
    prev[current] = current;
    return prev;
  }, {});
};

export const toUserDivOptions = (userDivs: UserDiv[]): Option[] => {
  return userDivs.map(div => div.userDivId);
};

export const toUserDivLabels = (userDivs: UserDiv[]): Labels => {
  return userDivs.reduce((prev, current) => {
    prev[current.userDivId] = current.userDivName || current.userDivDef?.userDivDefName;
    return prev;
  }, {});
};

export const toUserStudentDivOptions = (userStudentDivs: UserStudentDiv[]): Option[] => {
  return userStudentDivs.map(div => div.userStudentDivId);
};

export const toUserStudentDivLabels = (userStudentDivs: UserStudentDiv[]): Labels => {
  return userStudentDivs.reduce((prev, current) => {
    prev[current.userStudentDivId] = current.userStudentDivName || current.userStudentDivDef?.userStudentDivDefName;
    return prev;
  }, {});
};

export const toInflowSourceOptions = (inflowSources: UserInflowSource[]): Option[] => {
  return inflowSources.map(src => src.userInflowSourceId);
};

export const toInflowSourceLabels = (inflowSources: UserInflowSource[]): Labels => {
  return inflowSources.reduce((prev, current) => {
    prev[current.userInflowSourceId] =
      current.userInflowSourceName || current.userInflowSourceDef?.userInflowSourceDefName;
    return prev;
  }, {});
};

export const toUsagePurposeOptions = (usagePurposes: UserUsagePurpose[]): Option[] => {
  return usagePurposes.map(src => src.userUsagePurposeId);
};

export const toUsagePurposeLabels = (usagePurposes: UserUsagePurpose[]): Labels => {
  return usagePurposes.reduce((prev, current) => {
    prev[current.userUsagePurposeId] =
      current.userUsagePurposeName || current.userUsagePurposeDef?.userUsagePurposeDefName;
    return prev;
  }, {});
};

export const toFamilyMemberOptions = (
  srcOptions: UserFamilyMember[],
  checkedOptions: UserFamilyMember[]
): MultiCheckBoxOptionV2[] => {
  return srcOptions
    .sort((a, b) => a.order - b.order)
    .map(src => {
      const isChecked = checkedOptions.some(checked => {
        return checked.userFamilyMemberId === src.userFamilyMemberId;
      });

      return builder<MultiCheckBoxOption>()
        .id(src.userFamilyMemberId)
        .label(src.userFamilyMemberName)
        .checked(isChecked)
        .build();
    });
};

export const toFamilyMemberMappings = (
  members: UserFamilyMember[],
  options: MultiCheckBoxOptionV2[],
  other?: Word
): UserFamilyMemberMapping[] => {
  return options
    .filter(option => option.checked)
    .map(option => {
      const member = members.find(member => member.userFamilyMemberId === option.id)!;
      const mapBuilder = builder<UserFamilyMemberMapping>().userFamilyMemberMappingId(uuid()).userFamilyMember(member);
      if (other && member.userFamilyMemberDef?.hasOther) {
        mapBuilder.other(other as Word);
      }
      return mapBuilder.build();
    });
};

export const toFamilyMembers = (mappings?: UserFamilyMemberMapping[]): UserFamilyMember[] => {
  if (!hasLength(mappings)) return [];
  return mappings!.map(mapping => mapping.userFamilyMember!);
};
