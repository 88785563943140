import {
  BackButtonV2,
  Component,
  styleForFullExpansion,
  themeV2,
  useSafeCallback,
  useSafeState,
  useUnmountRef
} from '@atomica.co/components';
import { BaseDto, User } from '@atomica.co/irori';
import { USER_ID } from '@atomica.co/types';
import { EMPTY, embedIdInPath } from '@atomica.co/utils';
import React, { useEffect, useMemo } from 'react';
import styled from 'styled-components';
import { MOBILE_MIN_WIDTH } from '../../constants/common-const';
import usePath from '../../redux/hooks/usePath';
import useProfile from '../../redux/hooks/useProfile';
import useUserWish from '../../redux/hooks/useUserWish';
import { Path, PATH_IDS } from '../../router/Routes';
import AccountProfileCardViewV3 from '../account/account-profile/user-information-v3/AccountProfileCardViewV3';
import AccountWishCardViewV3 from '../account/account-profile/user-information-v3/AccountWishCardViewV3';

interface P {
  base: BaseDto;
  user: User;
}

const MemberProfileScreen: React.FC<P> = React.memo(props => {
  const { base, user } = props;

  const { params, openBasePath,openPath  } = usePath();
  const { profile, loadProfile } = useProfile();
  const { userWishes, loadUserWish } = useUserWish();

  const unmountRef = useUnmountRef();
  const [isInitialized, setIsInitialized] = useSafeState<boolean>(unmountRef, false);

  const initialize = useSafeCallback(async (): Promise<void> => {
    if (isInitialized) return;
    const [result] = await Promise.all([
      loadProfile(params[USER_ID], base.baseId),
      loadUserWish(params[USER_ID], base.baseId)
    ]);
    if (!result) {
      openBasePath(Path.ACCOUNT_HOME);
      return;
    }
    setIsInitialized(true);
  }, [base.baseId, isInitialized, loadProfile, loadUserWish, openBasePath, params, setIsInitialized]);

  const goBack = useSafeCallback(() => {
    openPath(embedIdInPath(Path.MEMBERS, PATH_IDS, [base.baseCode]));
  }, [base.baseCode,openPath])

  const isSelf = useMemo(()=> {
    return params[USER_ID] === user.userId
  },[params, user])

  useEffect(() => {
    initialize();
  }, [initialize]);

  return (
    <Component style={styleForFullExpansion} className='member-profile-screen' loading={!isInitialized}>
      <Container data-testid='member-profile-screen'>
        <BackButtonV2 label='戻る' onClick={goBack} />
        <Content>
          <Name>
            {profile?.familyName || EMPTY} {profile?.firstName || EMPTY}
          </Name>
          <AccountProfileCardViewV3 base={base} profile={profile} initialized={isInitialized} />
          <AccountWishCardViewV3 base={base} userWishes={userWishes} initialized={isInitialized} isSelf={isSelf} userId={params[USER_ID]} />
        </Content>
      </Container>
    </Component>
  );
});
MemberProfileScreen.displayName = 'MemberProfileScreen';
export default MemberProfileScreen;

const Container = styled.div`
  width: 100%;
  max-width: 640px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Content = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: ${themeV2.mixins.v2.spacing * 3}px;
  min-width: ${MOBILE_MIN_WIDTH}px;
  padding: 0px ${themeV2.mixins.v2.spacing * 2}px ${themeV2.mixins.v2.spacing * 2}px ${themeV2.mixins.v2.spacing * 2}px;
`;

const Name = styled.span`
  ${themeV2.mixins.v2.typography.headLine.medium};
  display: flex;
  align-items: flex-end;
  gap: ${themeV2.mixins.v2.spacing}px;
  &::after {
    content: 'さん';
    white-space: nowrap;
    ${themeV2.mixins.v2.typography.headLine.small};
  }
`;
