import {
  AnimatedModal,
  Button,
  CommentBox,
  Component,
  MultiInputBox,
  theme,
  useSafeCallback,
  useSafeState,
  useUnmountRef
} from '@atomica.co/components';
import { Email } from '@atomica.co/types';
import { Typography } from '@material-ui/core';
import React from 'react';
import styled from 'styled-components';
import mojaco_greeting from './../../../assets/mojaco/mojaco_greeting.png';

const MODAL_HEIGHT = 472;

interface P {
  isOpen: boolean;
  onClick(emails: Email[]): void;
  onClose(): void;
}

const ContractModal: React.FC<P> = React.memo(props => {
  const { isOpen, onClick, onClose } = props;
  const unmountRef = useUnmountRef();
  const [emails, setEmails] = useSafeState<Email[]>(unmountRef, []);

  const handleButtonClicked = useSafeCallback((): void => {
    setEmails(emails => {
      onClick(emails);
      return [];
    });
  }, [setEmails, onClick]);

  return (
    <Component className='contract-modal'>
      <AnimatedModal isOpen={isOpen} height={(window.innerHeight - MODAL_HEIGHT) / 2 + MODAL_HEIGHT} onClose={onClose}>
        <Container onClick={onClose}>
          <Frame onClick={e => e.stopPropagation()}>
            <MojacoWrapper>
              <CommentBox animation photoURL={mojaco_greeting}>
                <MojacoGreeting>招待するメンバーのメールアドレスを入力してね！</MojacoGreeting>
              </CommentBox>
            </MojacoWrapper>

            <InputWrapper>
              <MultiInputBox placeholder='送信先' emails={emails} onChange={setEmails} />
            </InputWrapper>

            <MultiButtonsWrapper>
              <ButtonWrapper>
                <Button type='primary' onClick={handleButtonClicked}>
                  <Label>送信</Label>
                </Button>
              </ButtonWrapper>
            </MultiButtonsWrapper>
          </Frame>
        </Container>
      </AnimatedModal>
    </Component>
  );
});

ContractModal.displayName = 'ContractModal'
export default ContractModal;

const Container = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  justify-content: center;
`;

const Frame = styled.div`
  width: 768px;
  height: auto;
  background: ${theme.mixins.background.lightGray};
  border-radius: 16px;
`;

const MojacoWrapper = styled.div`
  padding: ${theme.mixins.spacing * 4}px ${theme.mixins.spacing * 2}px ${theme.mixins.spacing * 2}px;
`;

const MojacoGreeting = styled(Typography)`
  width: calc(100% - ${theme.mixins.spacing * 3}px);
  height: auto;
  color: ${theme.mixins.typography.fontColor.gray};
  font-size: ${theme.mixins.typography.fontSize.sixteen}px;
  font-weight: ${theme.mixins.typography.fontWeight.sevenHundreds};
  font-family: ${theme.mixins.typography.fontFamily};
  margin: ${theme.mixins.spacing}px;
  ${theme.mixins.underline};
`;

const InputWrapper = styled.div`
  width: 100%;
  height: auto;
  padding: ${theme.mixins.spacing * 2}px;
`;

const MultiButtonsWrapper = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: ${theme.mixins.spacing * 2}px ${theme.mixins.spacing * 2}px ${theme.mixins.spacing * 4}px;
`;

const ButtonWrapper = styled.div`
  padding: 0px ${theme.mixins.spacing / 2}px;
`;

const Label = styled(Typography)`
  width: 120px;
  height: auto;
  color: ${theme.mixins.typography.fontColor.white};
  font-size: ${theme.mixins.typography.fontSize.sixteen}px;
  font-family: ${theme.mixins.typography.fontFamily};
  font-weight: ${theme.mixins.typography.fontWeight.sevenHundreds};
`;
