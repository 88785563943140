import { StepNavigationV2, themeV2, useSafeCallback, useSafeState, useUnmountRef } from '@atomica.co/components';
import { BaseDto, User } from '@atomica.co/irori';
import { Email, Message } from '@atomica.co/types';
import { EMPTY, isEmail } from '@atomica.co/utils';
import React from 'react';
import styled from 'styled-components';
import { MOBILE_MAX_WIDTH } from '../../constants/common-const';
import InputEmail from './content/InputEmail';

interface P {
  base: BaseDto;
  user?: User;
}

const ERROR_MESSAGES = {
  EMAIL: {
    INVALID: 'メールアドレスが不正です',
    REQUIRED: 'メールアドレスを入力してください'
  }
} as const;

const STEPS: Record<string, number> = {
  SEND: 1,
  VERIFY: 2,
  INPUT: 3,
  COMPLETE: 4
} as const;

type StepKey = keyof typeof STEPS;

const STEP_LABELS: Record<StepKey, string> = {
  SEND: 'メール',
  VERIFY: '認証',
  INPUT: '登録',
  COMPLETE: '完了'
} as const;

const validateEmail = (email: string): Message | undefined => {
  if (!email) return ERROR_MESSAGES.EMAIL.REQUIRED;
  if (!isEmail(email)) return ERROR_MESSAGES.EMAIL.INVALID;
  return undefined;
};

type ErrorType = keyof typeof ERROR_MESSAGES;
type ErrorState = Partial<Record<ErrorType, Message>>;

const StepVerificationScreen: React.FC<P> = React.memo(props => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars, no-unused-vars
  const _ = props;

  const unmountRef = useUnmountRef();

  const [email, setEmail] = useSafeState<Email>(unmountRef, EMPTY);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars, no-unused-vars
  const [currentStep, setCurrentStep] = useSafeState<StepKey>(unmountRef, 'SEND');

  const [errors, setErrors] = useSafeState<ErrorState>(unmountRef);

  const validEmail = useSafeCallback((): void => {
    const errorMessage = validateEmail(email);
    setErrors(prev => ({
      ...prev,
      EMAIL: errorMessage
    }));
  }, [email, setErrors]);

  const sendVerification = useSafeCallback(() => {
    setErrors(undefined);
    alert('認証コード送信！！');
    // HACK: ここでばBackendをCallする
  }, []);

  return (
    <Container>
      <Content>
        {/* <PageHeaderV2 title='アカウント登録' titleSize='small' /> */}
        <StepNavigationV2 texts={Object.values(STEP_LABELS)} currentStepIndex={STEPS[currentStep]} />
        {/* TODO: 以降の実装は別コンポーネントに切り出す */}
        <InputEmail
          email={email}
          errorMessage={errors?.EMAIL}
          onBlur={validEmail}
          onChange={setEmail}
          sendVerification={sendVerification}
        />
      </Content>
    </Container>
  );
});

StepVerificationScreen.displayName = 'StepVerificationScreen';
export default StepVerificationScreen;

const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: ${MOBILE_MAX_WIDTH}px;
  align-items: center;
  width: 100%;
  gap: ${themeV2.mixins.v2.spacing * 3}px;
  padding: ${themeV2.mixins.v2.spacing}px ${themeV2.mixins.v2.spacing * 2}px;
  margin-top: ${themeV2.mixins.v2.spacing * 2}px;
`;
