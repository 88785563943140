import { BackButtonV2, themeV2, useSafeCallback, useSafeState, useUnmountRef } from '@atomica.co/components';
import {
  BaseDto,
  CONTRACT_ID,
  ContractStatusV2,
  FETCH_CONTRACT_V2,
  FetchContractV2Request,
  FetchContractV2Response,
  User
} from '@atomica.co/irori';
import { Message } from '@atomica.co/types';
import { EMAIL, EMPTY, builder } from '@atomica.co/utils';
import React, { useEffect } from 'react';
import styled from 'styled-components';
import Screen from '../../components/screen/Screen';
import { MOBILE_MAX_WIDTH, MOBILE_MIN_WIDTH } from '../../constants/common-const';
import useCommonRequest from '../../redux/hooks/useCommonRequest';
import usePath from '../../redux/hooks/usePath';
import { Path } from '../../router/Routes';
import { isValidContract } from '../../utils/contract-v2-util';
import mojacoSorry from './../../assets/mojaco/mojaco_sorry.png';
import mojacoThanks from './../../assets/mojaco/mojaco_thanks.png';

interface P {
  base: BaseDto;
  user: User;
}

const RegisterContractMemberScreen: React.FC<P> = React.memo(props => {
  const { user } = props;
  const { openBasePath, params, queryParams } = usePath();
  const { commonRequest } = useCommonRequest();

  const unmountRef = useUnmountRef();
  const [loaded, setLoaded] = useSafeState<boolean>(unmountRef, false);
  const [message, setMessage] = useSafeState<Message>(unmountRef, EMPTY);
  const [isError, setIsError] = useSafeState<boolean>(unmountRef, false);

  const initialize = useSafeCallback(async (): Promise<void> => {
    const email = queryParams[EMAIL];

    if (user.email !== email) {
      setMessage(
        `契約に招待したメールアドレスとログインアカウントのメールアドレスが異なっています。管理者までお問い合わせください。\n\n招待メールアドレス: ${email}\n\nログインメールアドレス: ${user.email}`
      );
      setIsError(true);
      setLoaded(true);
      return;
    }

    const contractId = params[CONTRACT_ID];
    const contractRequest = builder<FetchContractV2Request>().contractId(contractId).build();
    const { contract } = await commonRequest<FetchContractV2Request, FetchContractV2Response>(
      FETCH_CONTRACT_V2,
      contractRequest
    );

    if (!isValidContract(contract, new Date())) {
      setMessage('このリンクは無効です');
      setIsError(true);
      setLoaded(true);
      return;
    }

    const { contractStatus, contractUsers } = contract;
    const currentContractUser = contractUsers?.find(
      contractUser =>
        (contractUser.user && contractUser.user.userId === user.userId) || contractUser.invitationEmail === user.email
    );

    if (contractStatus !== ContractStatusV2.CONFIRMED || !currentContractUser) {
      setMessage('このリンクは無効です');
      setIsError(true);
      setLoaded(true);
      return;
    }

    setMessage(`下記のアドレスで登録が完了しました！\n\n${email}`);
    setLoaded(true);
  }, [commonRequest, params, queryParams, setIsError, setLoaded, setMessage, user]);

  useEffect(() => {
    initialize();
  }, [initialize]);

  const openAccountHomeScreen = useSafeCallback((): void => {
    openBasePath(Path.ACCOUNT_HOME);
  }, [openBasePath]);

  return (
    <Screen loading={!loaded} className='register-contract-member-screen'>
      <Container>
        <Content>
          {!isError && <BackButtonV2 label='ホーム' onClick={openAccountHomeScreen} />}

          <MessageWrapper>
            <StyledMessage>{message}</StyledMessage>

            <Mojaco src={isError ? mojacoSorry : mojacoThanks} />
          </MessageWrapper>
        </Content>
      </Container>
    </Screen>
  );
});

RegisterContractMemberScreen.displayName = 'RegisterContractMemberScreen';
export default RegisterContractMemberScreen;

const Container = styled.div`
  width: 100%;
  height: 100vh;

  @supports (height: 1dvh) {
    height: 100dvh;
  }
`;

const Content = styled.div`
  width: 100%;
  height: 100%;
  min-width: ${MOBILE_MIN_WIDTH}px;
  max-width: ${MOBILE_MAX_WIDTH}px;
  margin: 0 auto;
  display: flex;
  flex-flow: column;
  align-items: flex-start;
  justify-content: center;
  padding: ${themeV2.mixins.v2.spacing * 3}px ${themeV2.mixins.v2.spacing * 2}px;
  gap: ${themeV2.mixins.v2.spacing * 3}px;
`;

const MessageWrapper = styled.div`
  width: 100%;
  height: auto;
  flex: 1;
  display: flex;
  flex-flow: column;
  justify-content: center;
  gap: ${themeV2.mixins.v2.spacing * 3}px;
`;

const StyledMessage = styled.div`
  ${themeV2.mixins.v2.typography.body.large};
  text-align: center;
  white-space: pre-wrap;
  user-select: none;
  padding: ${themeV2.mixins.v2.spacing / 2}px ${themeV2.mixins.v2.spacing * 2}px;
`;

const Mojaco = styled.img`
  width: 100%;
  height: auto;
  object-fit: cover;
  user-select: none;
  padding: ${themeV2.mixins.v2.spacing}px;
`;
