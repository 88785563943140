import {
  ColWidth,
  Component,
  Header,
  PageHeaderV2,
  PAGINATE_HEIGHT,
  PullDownOption,
  PullDownV2,
  SearchListV2,
  themeV2,
  useSafeCallback,
  useSafeState,
  useUnmountRef
} from '@atomica.co/components';
import {
  BASE_CODE,
  BaseDto,
  BaseResourceCategoryId,
  ResourceCategoryCode,
  ResourceForSearchResources,
  SEARCH_RESOURCES_FOR_ADMIN,
  SearchResourcesForAdminRequest,
  SearchResourcesForAdminResponse,
  SpaceId,
  SpacePublishOption
} from '@atomica.co/irori';
import { Count, Index, Label, Name, PriceStr } from '@atomica.co/types';
import { builder, embedIdInPath } from '@atomica.co/utils';
import React, { useEffect, useMemo } from 'react';
import styled from 'styled-components';
import useCommonRequest from '../../../redux/hooks/useCommonRequest';
import usePath from '../../../redux/hooks/usePath';
import { Path } from '../../../router/Routes';
import { styleForComponent } from '../../../styles/components';
import { SPACE_PUBLISH_OPTION_LABEL_FOR_LIST } from '../../../texts/space-text';

const COLUMN_WIDTH: ColWidth<ResourceRow> = {
  resourceName: 'auto',
  resourceCategoryLabel: 224,
  publish: 168,
  isInvisibla: 168,
  unitPrice: 168
};

const HEADER: Header<ResourceRow> = {
  resourceName: { label: 'リソース名称' },
  resourceCategoryLabel: { label: 'リソースカテゴリ' },
  publish: { label: '公開範囲' },
  isInvisibla: { label: '予約画面表示' },
  unitPrice: { label: '料金' }
};

export interface ResourceRow {
  id: SpaceId;
  resourceName: Name;
  resourceCategoryLabel: Label;
  publish: '全体' | '契約者限定';
  isInvisibla: '表示する' | '表示しない';
  unitPrice: PriceStr;
}

interface P {
  base: BaseDto;
}

const publishPullDownOptions = Object.entries(SPACE_PUBLISH_OPTION_LABEL_FOR_LIST).map(([key, value]) =>
  builder<PullDownOption>().id(key).label(value).build()
);

const ResourceMasterListScreen: React.FC<P> = React.memo(props => {
  const { base } = props;

  const { openPath } = usePath();
  const { commonRequest } = useCommonRequest();

  const unmountRef = useUnmountRef();
  const [isLoaderShown, setIsLoaderShown] = useSafeState<boolean>(unmountRef, false);
  const [resources, setResources] = useSafeState<ResourceForSearchResources[]>(unmountRef, []);
  const [totalCount, setTotalCount] = useSafeState<Count>(unmountRef, 0);

  const [selectedBaseResourceCategoryId, setSelectedBaseResourceCategoryId] = useSafeState<BaseResourceCategoryId>(
    unmountRef,
    'all'
  );
  const [selectedPublishOption, setSelectedPublishOption] = useSafeState<SpacePublishOption | 'all'>(unmountRef, 'all');

  const baseResourceCategoryPullDownOptions = useMemo<PullDownOption[]>(() => {
    return [
      builder<PullDownOption>().id('all').label('すべてのカテゴリ').build(),
      ...(base.baseResourceCategories
        ?.filter(category => category.resourceCategoryDef?.categoryCode !== ResourceCategoryCode.ENTRANCE)
        .map(category =>
          builder<PullDownOption>().id(category.baseResourceCategoryId).label(category.categoryLabel).build()
        ) ?? [])
    ];
  }, [base.baseResourceCategories]);

  const rows = useMemo<ResourceRow[]>(() => {
    return resources.map(resource =>
      builder<ResourceRow>()
        .id(resource.spaceId)
        .resourceName(resource.spaceName)
        .resourceCategoryLabel(resource.categoryLabel)
        .publish(resource.publish)
        .isInvisibla(resource.isInvisible)
        .unitPrice(`¥${resource.unitPrice.toLocaleString()}/${resource.reservationType}`)
        .build()
    );
  }, [resources]);

  const openRegisterResourceMasterScreen = useSafeCallback(
    (index: Index) => {
      openPath(
        embedIdInPath(Path.EDIT_RESOURCE_MASTER, [BASE_CODE, 'resourceId'], [base.baseCode, resources[index].spaceId])
      );
    },
    [base.baseCode, openPath, resources]
  );

  const searchResources = useSafeCallback(async (): Promise<void> => {
    setIsLoaderShown(true);
    const preBuild = builder<SearchResourcesForAdminRequest>().baseId(base.baseId);

    if (selectedBaseResourceCategoryId !== 'all') {
      preBuild.baseResourceCategoryId(selectedBaseResourceCategoryId);
    }

    if (selectedPublishOption !== 'all') {
      preBuild.publish(selectedPublishOption);
    }

    const request = preBuild.build();

    const response = await commonRequest<SearchResourcesForAdminRequest, SearchResourcesForAdminResponse>(
      SEARCH_RESOURCES_FOR_ADMIN,
      request
    );

    // const response = await getResource().searchResourcesForAdmin(request);

    setResources(response.resources);
    setTotalCount(response.totalCount);
    setIsLoaderShown(false);
  }, [
    base.baseId,
    commonRequest,
    selectedBaseResourceCategoryId,
    selectedPublishOption,
    setIsLoaderShown,
    setResources,
    setTotalCount
  ]);

  const initialize = useSafeCallback(async (): Promise<void> => {
    await searchResources();
  }, [searchResources]);

  useEffect(() => {
    initialize();
  }, [initialize]);

  return (
    <Component className='resource-master-list-screen' style={styleForComponent}>
      <Container data-testid='resource-master-list-screen'>
        <Content>
          <PageHeaderV2 title='マスタ設定 リソース' />
          <SearchListV2
            title={`${totalCount}件のリソース`}
            name='リソース'
            colWidth={COLUMN_WIDTH}
            header={HEADER}
            rows={rows}
            isLoaderShown={isLoaderShown}
            headerBottomComponent={
              <FilterComponents>
                <PullDownWrapper data-testid='category-pull-down'>
                  <PullDownV2
                    id={selectedBaseResourceCategoryId}
                    options={baseResourceCategoryPullDownOptions}
                    onClick={setSelectedBaseResourceCategoryId}
                  />
                </PullDownWrapper>
                <PullDownWrapper data-testid='publish-pull-down'>
                  <PullDownV2
                    id={selectedPublishOption}
                    options={publishPullDownOptions}
                    onClick={setSelectedPublishOption}
                  />
                </PullDownWrapper>
              </FilterComponents>
            }
            onClickRow={index => openRegisterResourceMasterScreen(index)}
          />
        </Content>
      </Container>
    </Component>
  );
});

ResourceMasterListScreen.displayName = 'ResourceMasterListScreen';
export default ResourceMasterListScreen;

const Container = styled.div`
  width: 100%;
  height: calc(100% - ${PAGINATE_HEIGHT}px);
  display: flex;
  padding: ${themeV2.mixins.v2.spacing * 2}px ${themeV2.mixins.v2.spacing * 3}px;
`;

const Content = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: ${themeV2.mixins.v2.spacing * 2}px;
`;

const FilterComponents = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  gap: ${themeV2.mixins.v2.spacing * 2}px;
  padding: ${themeV2.mixins.v2.spacing}px ${themeV2.mixins.v2.spacing * 3}px;
`;

const PullDownWrapper = styled.div`
  width: 200px;
`;
