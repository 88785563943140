import {
  ButtonV2,
  LabelV2,
  PullDownOption,
  PullDownV2,
  TabProperty,
  TabsV3,
  themeV2,
  useSafeCallback,
  useSafeState,
  useUnmountRef
} from '@atomica.co/components';
import { BaseDto, ReplacingReservedWordsForEventMessage, ReservedWordsForEventMessageEnum } from '@atomica.co/irori';
import { Index, Label, Name, Text } from '@atomica.co/types';
import { EMPTY, builder, replaceReservedWords } from '@atomica.co/utils';
import { ContentState, Editor, EditorState, Modifier } from 'draft-js';
import 'draft-js/dist/Draft.css';
import React, { useEffect, useMemo } from 'react';
import styled from 'styled-components';
import { EditorTabEnum } from '../../enums/common-enum';
import SelectTemplateModal from '../../screens/event-v2/modal/SelectTemplateModal';
import { EDITER_TYPE_LABELS } from '../../texts/common-text';

interface P {
  base: BaseDto;
  config: ReplacingReservedWordsForEventMessage;
  label: Label;
  defaultValue?: Text;
  value: Text;
  onChange: (value: Text) => void;
}

const tagNames = Object.values(ReservedWordsForEventMessageEnum);

const pullDownOptions = tagNames.map(tag => builder<PullDownOption>().id(tag).label(tag).build());

const tabProperties: TabProperty[] = [
  {
    label: EDITER_TYPE_LABELS[EditorTabEnum.EDIT],
    disabled: false
  },
  {
    label: EDITER_TYPE_LABELS[EditorTabEnum.PREVIEW],
    disabled: false
  }
];

const InputWithPreview: React.FC<P> = React.memo(props => {
  const { base, config, label, defaultValue, value, onChange } = props;
  const unmountRef = useUnmountRef();
  const [editorState, setEditorState] = useSafeState(unmountRef, () => EditorState.createEmpty());
  const [selectedTabIdx, setSelectedTabIdx] = useSafeState<Index>(unmountRef, EditorTabEnum.EDIT);
  const [selectedTag, setSelectedTag] = useSafeState<Name>(unmountRef, EMPTY);
  const [isModalOpen, setIsModalOpen] = useSafeState<boolean>(unmountRef, false);

  const isPreview = useMemo<boolean>(() => selectedTabIdx === EditorTabEnum.PREVIEW, [selectedTabIdx]);

  const handleEditorChanged = useSafeCallback(
    (editorState: EditorState): void => {
      setEditorState(editorState);
      onChange(editorState.getCurrentContent().getPlainText());
    },
    [setEditorState, onChange]
  );

  const handleTemplateSelected = useSafeCallback(
    (text: Text): void => {
      const newContentState = ContentState.createFromText(text);
      setEditorState(EditorState.push(editorState, newContentState, 'insert-characters'));
      onChange(text);
    },
    [editorState, onChange]
  );

  const handlePullDownChanged = useSafeCallback(
    (text: Text): void => {
      setSelectedTag(text);
      const contentState = Modifier.insertText(
        editorState.getCurrentContent(),
        editorState.getSelection(),
        `{{ ${text} }}`
      );
      const newEditorState = EditorState.push(editorState, contentState, 'insert-characters');
      setEditorState(newEditorState);
      onChange(newEditorState.getCurrentContent().getPlainText());
    },
    [config, editorState, onChange, setEditorState, setSelectedTag]
  );

  const getNewEditorState = useSafeCallback(
    (selectedTabIdx: EditorTabEnum): EditorState => {
      const currentContent = editorState.getCurrentContent();
      const text = currentContent.getPlainText();

      const replacedText = replaceReservedWords(text, config);

      switch (selectedTabIdx) {
        case EditorTabEnum.EDIT: {
          const contentState = ContentState.createFromText(value);
          return EditorState.push(editorState, contentState, 'insert-characters');
        }
        case EditorTabEnum.PREVIEW: {
          const contentStateWithReplacement = ContentState.createFromText(replacedText);
          return EditorState.push(editorState, contentStateWithReplacement, 'insert-characters');
        }
        default:
          return editorState;
      }
    },
    [config, editorState, selectedTabIdx, value]
  );

  const handleTabClicked = useSafeCallback(
    (nextSelectedTabIdx: Index): void => {
      if (nextSelectedTabIdx === selectedTabIdx) return;
      setSelectedTabIdx(nextSelectedTabIdx);
      const newEditorState = getNewEditorState(nextSelectedTabIdx);
      setEditorState(newEditorState);
    },
    [getNewEditorState, selectedTabIdx, setEditorState, setSelectedTabIdx]
  );

  useEffect(() => {
    if (!defaultValue) return;
    const initialContentState = ContentState.createFromText(defaultValue);
    setEditorState(editorState => {
      return EditorState.push(editorState, initialContentState, 'insert-characters');
    });
  }, [setEditorState, defaultValue]);

  return (
    <Container>
      <HeaderWrapper>
        <LabelV2 text={label} />
        <TabWrapper>
          <TabsV3
            tabs={tabProperties}
            variant='outline'
            selectedTabIdx={selectedTabIdx}
            onChange={handleTabClicked}
            align='center'
            height='small'
          />
        </TabWrapper>
      </HeaderWrapper>
      <EditorContent>
        <EditorHeader>
          <PullDownWrapper>
            <PullDownV2
              nullable
              placeholder='変数を選択'
              id={selectedTag}
              options={pullDownOptions}
              onClick={handlePullDownChanged}
            />
          </PullDownWrapper>
          <ButtonV2 label='テンプレートを選択' onClick={() => setIsModalOpen(true)} />
        </EditorHeader>
        <EditorWrapper isPreview={isPreview}>
          <Editor readOnly={isPreview} editorState={editorState} onChange={handleEditorChanged} />
        </EditorWrapper>
      </EditorContent>
      <SelectTemplateModal
        base={base}
        isOpen={isModalOpen}
        onChange={handleTemplateSelected}
        onClose={() => setIsModalOpen(false)}
      />
    </Container>
  );
});

InputWithPreview.displayName = 'InputWithPreview';
export default InputWithPreview;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${themeV2.mixins.v2.spacing}px;
`;

const HeaderWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  div:first-child {
    min-width: 97px;
  }
`;

const TabWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: end;
`;

const EditorContent = styled.div`
  border: 1px solid ${themeV2.mixins.v2.color.border.gray};
  border-radius: 8px;
`;

const EditorHeader = styled(HeaderWrapper)`
  height: 53px;
  background: ${themeV2.mixins.v2.color.background.lightGray};
  padding: ${themeV2.mixins.v2.spacing * 1.5}px ${themeV2.mixins.v2.spacing}px;
  border-radius: 8px 8px 0 0;
`;

const PullDownWrapper = styled.div`
  width: 232px;
`;

const EditorWrapper = styled.div<{ isPreview: boolean }>`
  min-height: 120px;
  background: ${({ isPreview }) =>
    isPreview ? themeV2.mixins.v2.color.background.offWhite : themeV2.mixins.v2.color.background.white};
  padding: ${themeV2.mixins.v2.spacing * 2}px ${themeV2.mixins.v2.spacing * 1.5}px;
  border-radius: 0 0 8px 8px;
  cursor: text;
`;
