import {
  ButtonV2,
  CardWithLabelV2,
  DialogV2,
  InputWithLabelV2,
  ScreenLoaderV2,
  themeV2,
  useSafeCallback,
  useSafeState,
  useUnmountRef
} from '@atomica.co/components';
import {
  BaseDto,
  IntroducingOther,
  SAVE_INTRODUCING_OTHER_FOR_ADMIN,
  SaveIntroducingOtherForAdminRequest,
  SaveIntroducingOtherForAdminResponse,
  User,
  toFullName
} from '@atomica.co/irori';
import { Message } from '@atomica.co/types';
import { EMPTY, builder, isEmpty, uuid } from '@atomica.co/utils';
import React, { useEffect, useMemo } from 'react';
import styled from 'styled-components';
import mojaco from '../../../assets/mojaco/mojaco_break.png';
import useCommonRequest from '../../../redux/hooks/useCommonRequest';

interface P {
  isOpen: boolean;
  base: BaseDto;
  selectedUser: User;
  fromUser: User;
  isEditable: boolean;
  prevIntroducingOther?: IntroducingOther | null;
  onSave(newIntro: IntroducingOther): void;
  onClose(): void;
}

const RegisterIntroModal: React.FC<P> = React.memo(props => {
  const { commonRequest } = useCommonRequest();
  const { isOpen, base, selectedUser, fromUser, prevIntroducingOther, onSave, onClose, isEditable } = props;
  const unmountRef = useUnmountRef();
  const [saving, setSaving] = useSafeState<boolean>(unmountRef, false);
  const [message, setMessage] = useSafeState<Message>(unmountRef, EMPTY);

  const initialize = useSafeCallback((): void => {
    setMessage(prevIntroducingOther ? prevIntroducingOther.message : EMPTY);
  }, [setMessage, prevIntroducingOther]);

  useEffect(() => {
    if (!isOpen) return;
    initialize();
  }, [isOpen, initialize]);

  const saveIntroductionOther = useSafeCallback(async (): Promise<void> => {
    setSaving(true);

    const introToSave = builder<IntroducingOther>()
      .introducingOtherId(prevIntroducingOther ? prevIntroducingOther.introducingOtherId : uuid())
      .message(message)
      .fromUser(fromUser)
      .toUser(selectedUser)
      .base(base)
      .build();

    const request = builder<SaveIntroducingOtherForAdminRequest>()
      .baseId(base.baseId)
      .introducingOther(introToSave)
      .build();
    await commonRequest<SaveIntroducingOtherForAdminRequest, SaveIntroducingOtherForAdminResponse>(
      SAVE_INTRODUCING_OTHER_FOR_ADMIN,
      request
    );

    initialize();
    setSaving(false);
    onSave(introToSave);
  }, [base, commonRequest, fromUser, initialize, message, onSave, prevIntroducingOther, selectedUser, setSaving]);

  const footerButtons = useMemo<JSX.Element[]>(() => {
    const buttons: JSX.Element[] = [];
    buttons.push(<ButtonV2 disabled={saving} key='cancel' label='キャンセル' onClick={onClose} />);
    if (isEditable)
      buttons.push(
        <ButtonV2
          disabled={saving || isEmpty(message)}
          key='save'
          type='primary'
          label='保存'
          onClick={saveIntroductionOther}
        />
      );
    return buttons;
  }, [isEditable, message, saving, onClose, saveIntroductionOther]);

  return (
    <DialogV2
      height={650}
      width={1120}
      isOpen={isOpen}
      headerLabel={`${toFullName(selectedUser)}の他己紹介`}
      buttonsOnTheRight={footerButtons}
      onClose={onClose}
      className='register-intro-modal'
    >
      <Content>
        {/* 対話ログ機能が実装されるまでの暫定コメントアウト対応
        <PanelWrapper>
          <InformationPanel isBold status='default' title='入力の際のご注意'>
            入力された内容はSlackに投稿されるため、個人情報（氏名、住所、電話番号など）は入力しないようお願いいたします。
          </InformationPanel>
        </PanelWrapper> */}

        <CardWithLabelV2
          cardStyles={{ background: themeV2.mixins.v2.color.background.white }}
          label='ユーザー'
          src={selectedUser.photoURL || mojaco}
          text={toFullName(selectedUser)}
          subText={`${selectedUser.companyName && `${selectedUser.companyName}のメンバー`}${
            selectedUser.companyName && selectedUser.email && '・'
          }${selectedUser.email}`}
        />
        <CardWithLabelV2
          cardStyles={{ background: themeV2.mixins.v2.color.background.white }}
          label='紹介者'
          src={fromUser.photoURL || mojaco}
          text={toFullName(fromUser)}
          subText={base.baseName}
        />
        {isEditable && <InputWithLabelV2 multiline text='他己紹介文' value={message} onChange={setMessage} />}
        {!isEditable && <CardWithLabelV2 label='他己紹介文' text={message} />}
      </Content>
      <ScreenLoaderV2 loading={saving} />
    </DialogV2>
  );
});

RegisterIntroModal.displayName = 'RegisterIntroModal';
export default RegisterIntroModal;

// 対話ログ機能が実装されるまでの暫定コメントアウト対応
// const PanelWrapper = styled.div`
//   border: 0.5px solid ${themeV2.mixins.v2.color.border.gray};
//   border-radius: 8px;
// `;

const Content = styled.div`
  padding: ${themeV2.mixins.v2.spacing * 3}px;
  display: flex;
  gap: ${themeV2.mixins.v2.spacing * 3}px;
  background-color: ${themeV2.mixins.v2.color.background.offWhite};
  flex-direction: column;
`;
