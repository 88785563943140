import {
  ButtonV2,
  DialogV2,
  ScreenLoaderV2,
  useSafeCallback,
  useSafeState,
  useUnmountRef
} from '@atomica.co/components';
import {
  BaseDto,
  LogLevel,
  RECORD_LOGS,
  RecordLogsRequest,
  RecordLogsResponse,
  SAVE_USER_WISH_TAGS_FOR_ADMIN,
  SAVE_WISH_FOR_ADMIN,
  SaveUserWishTagsForAdminRequest,
  SaveUserWishTagsForAdminResponse,
  SaveWishForAdminRequest,
  SaveWishForAdminResponse,
  User,
  UserWishTag,
  Wish,
  WishScope,
  WishStatus,
  WishTag,
  WishType
} from '@atomica.co/irori';
import { Text } from '@atomica.co/types';
import { EMPTY, builder, hasLength, isEmpty, noop, stringify, uuid } from '@atomica.co/utils';
import React, { useEffect, useMemo } from 'react';
import styled from 'styled-components';
import { toWish } from '../../../converters/wish-converter';
import { useSnackbarV2 } from '../../../provider/SnackbarProviderV2';
import useCommonRequest from '../../../redux/hooks/useCommonRequest';
import { FAILED_TO_REGISTER_WISH } from '../../../texts/wish-text';
import WishInput from '../../wish/input/WishInput';

interface P {
  isOpen: boolean;
  base: BaseDto;
  selectedUser: User;
  signInUser: User;
  prevWish?: Wish;
  onSave?(savedWish: Wish): void;
  onClose(): void;
}

const RegisterWishModal: React.FC<P> = React.memo(props => {
  const { isOpen, base, selectedUser, signInUser, prevWish, onSave = noop, onClose } = props;
  const { commonRequest } = useCommonRequest();
  const { openSnackbar } = useSnackbarV2();

  const unmountRef = useUnmountRef();
  const [saving, setSaving] = useSafeState<boolean>(unmountRef, false);
  const [type, setType] = useSafeState<WishType>(unmountRef, WishType.FAVOR);
  const [status, setStatus] = useSafeState<WishStatus>(unmountRef, WishStatus.TODO);
  const [scope, setScope] = useSafeState<WishScope>(unmountRef, WishScope.PUBLIC);
  const [content, setContent] = useSafeState<Text>(unmountRef, EMPTY);
  const [remarks, setRemarks] = useSafeState<Text>(unmountRef, EMPTY);
  const [linkSlack] = useSafeState<boolean>(unmountRef, true);
  const [toUser, setToUser] = useSafeState<User | null>(unmountRef, signInUser);
  const [wishTagsToSave, setWishTagsToSave] = useSafeState<WishTag[]>(unmountRef, []);

  const isDisabledSaveButton = useMemo<boolean>(
    () => !toUser || isEmpty(content) || isEmpty(remarks),
    [toUser, content, remarks]
  );

  const initialize = useSafeCallback(() => {
    const wishTags =
      !!prevWish && hasLength(prevWish.userWishTags)
        ? prevWish.userWishTags!.map(tag => tag.wishTag!).filter(tag => tag)
        : [];

    setType(prevWish ? prevWish.type : WishType.FAVOR);
    setStatus(prevWish ? prevWish.status : WishStatus.TODO);
    setScope(prevWish ? prevWish.scope : WishScope.PUBLIC);
    setContent(prevWish ? prevWish.content : EMPTY);
    setRemarks(prevWish ? prevWish.remarks : EMPTY);
    setToUser(prevWish ? prevWish.toUser! : signInUser);
    setWishTagsToSave(wishTags);
  }, [prevWish, setType, setStatus, setScope, setContent, setRemarks, setToUser, signInUser, setWishTagsToSave]);

  useEffect(() => {
    initialize();
  }, [initialize]);

  const saveNewWish = useSafeCallback(async (): Promise<void> => {
    if (!selectedUser || !toUser) return;

    setSaving(true);
    const wishId = prevWish ? prevWish.wishId : uuid();
    const wishToSave = toWish(wishId, type, status, linkSlack, scope, content, remarks, base, selectedUser, toUser);

    const userWishTagToSave = wishTagsToSave.map(wishTag => {
      return builder<UserWishTag>().userWishTagId(uuid()).wish(wishToSave).user(selectedUser).wishTag(wishTag).build();
    });
    const wishRequest = builder<SaveWishForAdminRequest>().baseId(base.baseId).wish(wishToSave).build();
    const userWishTagsRequest = builder<SaveUserWishTagsForAdminRequest>()
      .baseId(base.baseId)
      .userWishTags(userWishTagToSave)
      .userWishTagsToDelete(prevWish?.userWishTags ?? [])
      .build();

    try {
      await commonRequest<SaveWishForAdminRequest, SaveWishForAdminResponse>(SAVE_WISH_FOR_ADMIN, wishRequest);
      await commonRequest<SaveUserWishTagsForAdminRequest, SaveUserWishTagsForAdminResponse>(
        SAVE_USER_WISH_TAGS_FOR_ADMIN,
        userWishTagsRequest
      );

      initialize();
      onSave(Object.assign(wishToSave, { userWishTags: userWishTagToSave }));
    } catch (e) {
      openSnackbar(FAILED_TO_REGISTER_WISH, 'error', 5000);

      commonRequest<RecordLogsRequest, RecordLogsResponse>(
        RECORD_LOGS,
        builder<RecordLogsRequest>()
          .level(LogLevel.ERROR)
          .jsonToRecord(stringify(e as Error))
          .build()
      );
    }

    setSaving(false);
  }, [
    commonRequest,
    prevWish,
    type,
    status,
    scope,
    content,
    remarks,
    linkSlack,
    base,
    selectedUser,
    toUser,
    wishTagsToSave,
    initialize,
    setSaving,
    onSave,
    openSnackbar
  ]);

  return (
    <DialogV2
      height={650}
      width={1120}
      isOpen={isOpen}
      headerLabel={prevWish ? prevWish.content : 'Wishを新規作成(β版)'}
      buttonsOnTheRight={[
        <ButtonV2 disabled={saving} key='cancel' label='キャンセル' onClick={onClose} />,
        <ButtonV2
          disabled={saving || isDisabledSaveButton}
          key='save'
          type='primary'
          label={prevWish ? '保存' : '作成'}
          onClick={saveNewWish}
        />
      ]}
      onClose={onClose}
    >
      <WishInputWrapper>
        <WishInput
          base={base}
          initWish={prevWish}
          fromUser={selectedUser}
          toUser={toUser}
          content={content}
          remarks={remarks}
          wishTags={wishTagsToSave}
          status={status}
          scope={scope}
          type={type}
          setToUser={setToUser}
          setContent={setContent}
          setRemarks={setRemarks}
          setWishTags={setWishTagsToSave}
          setStatus={setStatus}
          setScope={setScope}
          setType={setType}
        />
      </WishInputWrapper>
      <ScreenLoaderV2 loading={saving} />
    </DialogV2>
  );
});

RegisterWishModal.displayName = 'RegisterWishModal';
export default RegisterWishModal;

const WishInputWrapper = styled.div`
  padding: 24px;
`;
