import {
  BackButtonV2,
  ButtonV2,
  Component,
  PageHeaderV2,
  TabProperty,
  TabsV3,
  themeV2,
  useSafeCallback,
  useSafeState,
  useUnmountRef
} from '@atomica.co/components';
import { BaseDto, DOMAINS, HTTP, HTTPS, QUESTIONNAIRE_ID, Questionnaire, QuestionnaireId } from '@atomica.co/irori';
import { Index, URL } from '@atomica.co/types';
import { EnvDef, copyText, embedIdInPath } from '@atomica.co/utils';
import React, { useEffect, useMemo, useRef } from 'react';
import styled from 'styled-components';
import DefaultModal from '../../components/modal/DefaultModal';
import { QuestionnaireTabEnum } from '../../enums/questionnaire-enum';
import env from '../../env/env';
import { useSnackbarV2 } from '../../provider/SnackbarProviderV2';
import usePath from '../../redux/hooks/usePath';
import useQuestionnaire from '../../redux/hooks/useQuestionnaire';
import { PATH_IDS, Path } from '../../router/Routes';
import { ImageService } from '../../services/image-service';
import { QUESTIONNAIRE_TAB_LABEL } from '../../texts/questionnaire-text';
import QuestionnaireAnswers from './questionnaire-answers/QuestionnaireAnswers';
import QuestionnaireDetail from './questionnaire-detail/QuestionnaireDetail';

interface P {
  base: BaseDto;
}

const tabProperties = Object.values(QUESTIONNAIRE_TAB_LABEL).map<TabProperty>(label => ({
  label,
  disabled: false
}));

const QuestionnaireDetailScreen: React.FC<P> = React.memo(props => {
  const { base } = props;

  const { params, queryParams, openBasePath, openPath } = usePath();
  const { isLoading, fetchQuestionnaireForAdmin, deleteQuestionnaire, clearCachedAnswer } = useQuestionnaire();
  const { openSnackbar } = useSnackbarV2();

  const unmountRef = useUnmountRef();
  const [questionnaire, setQuestionnaire] = useSafeState<Questionnaire>(unmountRef);
  const [thumbnail, setThumbnail] = useSafeState<File>(unmountRef);
  const [isModalOpen, setIsModalOpen] = useSafeState<boolean>(unmountRef, false);
  const [selectedTabIdx, setSelectedTabIdx] = useSafeState<Index>(unmountRef, QuestionnaireTabEnum.QUESTIONNAIRE);

  const isInitialized = useRef<boolean>(false);

  const questionnaireId = useMemo<QuestionnaireId>(() => params[QUESTIONNAIRE_ID], [params]);

  const getQuestionnaireThumbnail = useSafeCallback(
    async (thumbnailURL: URL): Promise<void> => {
      const downloadFile = await ImageService.urlToFile(thumbnailURL);
      downloadFile && setThumbnail(downloadFile);
    },
    [setThumbnail]
  );

  const initialize = useSafeCallback(async (): Promise<void> => {
    const questionnaire = await fetchQuestionnaireForAdmin(questionnaireId, base.baseId);
    if (!questionnaire) return;
    const { thumbnailURL } = questionnaire;

    setQuestionnaire(questionnaire);
    if (thumbnailURL) getQuestionnaireThumbnail(thumbnailURL);
  }, [base, fetchQuestionnaireForAdmin, getQuestionnaireThumbnail, queryParams, questionnaireId, setQuestionnaire]);

  useEffect(() => {
    initialize();
  }, [initialize]);

  const handleCopyButtonClicked = useSafeCallback(async (): Promise<void> => {
    await copyText(
      `${env === EnvDef.LOCAL || env === EnvDef.TEST ? HTTP : HTTPS}://${DOMAINS[env]}${embedIdInPath(
        Path.QUESTIONNAIRE_ANSWER,
        PATH_IDS,
        [base.baseCode, questionnaireId]
      )}`
    );
    openSnackbar('回答用のURLをコピーしました', 'success', 3000);
  }, [base, openSnackbar, questionnaireId]);

  const openEditQuestionnaireScreen = useSafeCallback((): void => {
    openPath(embedIdInPath(Path.EDIT_QUESTIONNAIRE, PATH_IDS, [base.baseCode, questionnaireId]));
  }, [base, openPath, questionnaireId]);

  const openQuestionnaireListScreen = useSafeCallback((): void => {
    openBasePath(Path.QUESTIONNAIRE_LIST);
  }, [openBasePath]);

  const handleModalButtonClicked = useSafeCallback(async (): Promise<void> => {
    await deleteQuestionnaire(questionnaireId, base.baseId);
    openQuestionnaireListScreen();
  }, [base, deleteQuestionnaire, openQuestionnaireListScreen, questionnaireId]);

  useEffect(() => {
    if (isInitialized.current) return;
    clearCachedAnswer();
    isInitialized.current = true;
  }, [clearCachedAnswer]);

  return (
    <Component loading={isLoading} style={{ width: '100%', height: '100%' }} className='questionnaire-detail-screen'>
      {questionnaire && (
        <Container>
          <Content>
            <BackButtonV2 label='戻る' onClick={openQuestionnaireListScreen} />
            <PageHeaderV2
              title={questionnaire.name}
              useEllipsisForOverflow
              rightComponent={
                <ButtonWrapper>
                  <ButtonV2 type='primary' label='回答用のURLをコピー' onClick={handleCopyButtonClicked} />
                  <ButtonV2 type='tertiary' label='編集' onClick={openEditQuestionnaireScreen} />
                </ButtonWrapper>
              }
            />
            <TabsV3 tabs={tabProperties} selectedTabIdx={selectedTabIdx} onChange={setSelectedTabIdx} />
            {selectedTabIdx === QuestionnaireTabEnum.QUESTIONNAIRE && (
              <QuestionnaireDetail questionnaire={questionnaire} thumbnail={thumbnail} />
            )}
            {selectedTabIdx === QuestionnaireTabEnum.ANSWER && (
              <QuestionnaireAnswers base={base} questionnaire={questionnaire} />
            )}
          </Content>
          <DefaultModal
            isOpen={isModalOpen}
            headerLabel='アンケートの削除'
            leftButtonProps={{ disabled: isLoading }}
            rightButtonProps={{ disabled: isLoading, label: '削除', onClick: handleModalButtonClicked }}
            onClose={() => setIsModalOpen(false)}
            width={409}
          >
            <div>アンケートを削除します。この操作は取り消すことができません。</div>
            <br />
            <div> 削除してよろしいですか？</div>
          </DefaultModal>
        </Container>
      )}
    </Component>
  );
});

QuestionnaireDetailScreen.displayName = 'QuestionnaireDetailScreen';
export default QuestionnaireDetailScreen;

const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  padding: ${themeV2.mixins.v2.spacing * 3}px;
`;

const Content = styled.div`
  width: 100%;
  max-width: 1120px;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: ${themeV2.mixins.v2.spacing * 2}px;
`;

const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: ${themeV2.mixins.v2.spacing * 2}px;
`;
