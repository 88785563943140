import { ButtonV2, themeV2, useSafeCallback, useSafeState, useUnmountRef } from '@atomica.co/components';
import {
  BaseDto,
  CsvOperationType,
  Questionnaire,
  SEARCH_CSV_DATA_FOR_ADMIN,
  SearchCsvDataForAdminRequest,
  SearchCsvDataForAdminResponse,
  SearchQuestionnaireAnswerCsvRequestPayload
} from '@atomica.co/irori';
import { builder } from '@atomica.co/utils';
import GetAppRoundedIcon from '@material-ui/icons/GetAppRounded';
import React, { useRef } from 'react';
import { Data, Headers } from 'react-csv/lib/core';
import styled from 'styled-components';
import useCommonRequest from '../../../redux/hooks/useCommonRequest';
import { CSVLink } from 'react-csv';
import type Link from 'react-csv/components/Link';

type SearchCsvDataForAdminRequestOfQuestionnaireAnswer = SearchCsvDataForAdminRequest<
  typeof CsvOperationType.QUESTIONNAIRE_ANSWER
>;
type SearchCsvDataForAdminResponseOfQuestionnaireAnswer = SearchCsvDataForAdminResponse<
  typeof CsvOperationType.QUESTIONNAIRE_ANSWER
>;

interface P {
  base: BaseDto;
  questionnaire: Questionnaire;
}

const QuestionnaireAnswers: React.FC<P> = React.memo(props => {
  const { base, questionnaire } = props;

  const { commonRequest } = useCommonRequest();

  const unmountRef = useUnmountRef();
  const [headers, setHeaders] = useSafeState<Headers>(unmountRef, []);
  const [data, setData] = useSafeState<Data>(unmountRef, []);

  const csvInstance = useRef<Link & HTMLAnchorElement & { link: HTMLAnchorElement }>(null);

  const exportCSV = useSafeCallback(async (): Promise<void> => {
    if (!csvInstance.current) return;
    const requestPayload = builder<SearchQuestionnaireAnswerCsvRequestPayload>()
      .questionnaireId(questionnaire.questionnaireId)
      .build();
    const request = builder<SearchCsvDataForAdminRequestOfQuestionnaireAnswer>()
      .baseId(base.baseId)
      .operationType(CsvOperationType.QUESTIONNAIRE_ANSWER)
      .payload(requestPayload)
      .build();
    const { payload } = await commonRequest<
      SearchCsvDataForAdminRequestOfQuestionnaireAnswer,
      SearchCsvDataForAdminResponseOfQuestionnaireAnswer
    >(SEARCH_CSV_DATA_FOR_ADMIN, request);

    const { headers, records } = payload;

    setHeaders(headers);
    setData(records);

    csvInstance.current.link.click();
  }, [base, commonRequest, questionnaire, setData, setHeaders]);

  return (
    <Container>
      <Content>
        <Title>回答</Title>
        <Text>回答は以下からCSV形式でダウンロードできます。</Text>
        <ButtonV2
          type='secondary'
          startIcon={<GetAppRoundedIcon />}
          label='CSVファイルをダウンロード'
          onClick={exportCSV}
        />
        <StyledCSVLink ref={csvInstance} headers={headers} data={data} filename={`${questionnaire.name}.csv`} />
      </Content>
    </Container>
  );
});

QuestionnaireAnswers.displayName = 'QuestionnaireAnswers';
export default QuestionnaireAnswers;

const Container = styled.div``;

const Content = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: ${themeV2.mixins.v2.spacing * 2}px;
  padding: ${themeV2.mixins.v2.spacing * 3}px;
  border-radius: 8px;
  background: ${themeV2.mixins.v2.color.background.white};
`;

const Title = styled.div`
  ${themeV2.mixins.v2.typography.title.xLarge};
  color: ${themeV2.mixins.v2.color.font.black};
  border-bottom: 1px solid ${themeV2.mixins.v2.color.border.gray};
  padding-bottom: ${themeV2.mixins.v2.spacing}px;
`;

const Text = styled.div`
  ${themeV2.mixins.v2.typography.body.large};
  color: ${themeV2.mixins.v2.color.font.black};
`;

const StyledCSVLink = styled(CSVLink)`
  display: none;
`;
