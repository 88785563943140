import { Icon, TextFieldV2, themeV2, useSafeCallback, useSafeState, useUnmountRef } from '@atomica.co/components';
import {
  AUTHORITY_CODE,
  BASE_CODE,
  BaseDto,
  BaseFunctionToggleCode,
  DOMAINS,
  ErrorCode,
  isBaseFunctionToggleEnabled,
  LoginServiceType
} from '@atomica.co/irori';
import { Email, Message } from '@atomica.co/types';
import {
  BCC_EMAIL,
  EMAIL,
  embedIdInPath,
  EMPTY,
  EQUAL,
  FIRST_INDEX,
  hasLength,
  isEmail,
  NOT_FOUND_INDEX,
  QUESTION_MARK
} from '@atomica.co/utils';
import MailIcon from '@material-ui/icons/Mail';
import React, { useEffect } from 'react';
import styled from 'styled-components';
import google from '../../../../assets/icon/icon_google.png';
import line from '../../../../assets/icon/icon_line.png';
import sakura from '../../../../assets/icon/sakuraId.svg';
import CapsuleButton from '../../../../components/button/CapsuleButton';
import { CACHED_URL } from '../../../../constants/url-const';
import env from '../../../../env/env';
import firebase, { auth } from '../../../../firebase';
import useCachedInitialAuthorityCode from '../../../../redux/hooks/useCachedInitialAuthorityCode';
import useCachedURL from '../../../../redux/hooks/useCachedURL';
import useLogin from '../../../../redux/hooks/useLoginService';
import usePath from '../../../../redux/hooks/usePath';
import { Path } from '../../../../router/Routes';
import { ERROR_MESSAGES } from '../../../../texts/error-text';

export enum SignUpEnum {
  INPUT_EMAIL,
  EMAIL_SENT
}

interface P {
  base: BaseDto;
  message?: Message;
  usableLoginServiceTypes: LoginServiceType[];
  onSendEmail(email: Email): void;
}

const SignUpOptionList: React.FC<P> = React.memo(props => {
  const { base, message, usableLoginServiceTypes, onSendEmail } = props;

  const { openBasePath } = usePath();
  const { cachedURL } = useCachedURL();
  const { cachedInitialAuthorityCode } = useCachedInitialAuthorityCode();
  const unmountRef = useUnmountRef();
  const [disabledSendButton, setDisabledSendButton] = useSafeState<boolean>(unmountRef, true);
  const [email, setEmail] = useSafeState<Email>(unmountRef, EMPTY);
  const [errorMessage, setErrorMessage] = useSafeState<Message>(unmountRef, EMPTY);
  const { openGoogleLoginPage, openLineLoginPage, openSakuraLoginPage } = useLogin({ base });

  useEffect(() => {
    setDisabledSendButton(!email);
  }, [setDisabledSendButton, email]);

  useEffect(() => {
    if (message) setErrorMessage(message);
  }, [setErrorMessage, message]);

  const constructErrorMessage = useSafeCallback(
    (code: ErrorCode): void => {
      if (!code) return;

      Object.entries(ERROR_MESSAGES)
        .filter(message => code.toString().indexOf(message[FIRST_INDEX]) !== NOT_FOUND_INDEX)
        .forEach(message => setErrorMessage(message[1]));
    },
    [setErrorMessage]
  );

  const createUserWithEmail = useSafeCallback(async (): Promise<void> => {
    if (!email || !isEmail(email)) {
      constructErrorMessage(ErrorCode.INVALID_EMAIL);
      return;
    }

    setDisabledSendButton(true);

    const methods = await auth.fetchSignInMethodsForEmail(email);
    if (methods && hasLength(methods)) {
      constructErrorMessage(ErrorCode.EMAIL_ALREADY_IN_USE);
      return;
    }

    const redirectUrl = `https://${DOMAINS[env]}${embedIdInPath(
      Path.REGISTER_ACCOUNT,
      [BASE_CODE],
      [base.baseCode]
    )}?${EMAIL}=${email}&${CACHED_URL}=${cachedURL}${
      cachedInitialAuthorityCode ? `&${AUTHORITY_CODE}=${cachedInitialAuthorityCode}` : EMPTY
    }`;

    const settings: firebase.auth.ActionCodeSettings = {
      handleCodeInApp: true,
      url: redirectUrl
    };

    if (isBaseFunctionToggleEnabled(base, BaseFunctionToggleCode.FUNCTION_USE_WORKHUB_PASS)) {
      const path = `${Path.REGISTER_ACCOUNT_V2}${QUESTION_MARK}${EMAIL}${EQUAL}${email}` as Path;
      openBasePath(path);
    } else {
      auth
        .sendSignInLinkToEmail(email, settings)
        .then(() => {
          auth.sendSignInLinkToEmail(BCC_EMAIL, settings);
          onSendEmail(email);
        })
        .catch(err => {
          setDisabledSendButton(false);
          constructErrorMessage(err.code);
        });
    }
  }, [
    base,
    cachedInitialAuthorityCode,
    cachedURL,
    constructErrorMessage,
    email,
    onSendEmail,
    openBasePath,
    setDisabledSendButton
  ]);

  return (
    <Container>
      <Box>
        {base.topMessage ||
          `アトミカは\nクリエイティブなコミュニティスペース\n高セキュリティのプライベートルーム\n洗練された会議室など\n多様な"働く形"に対応できる\nコワーキングスペースです`}
      </Box>
      <LoginServiceSection>
        {usableLoginServiceTypes.map(
          (serviceType, idx) =>
            (serviceType === LoginServiceType.MAIL && (
              <MailOption key={idx}>
                <StyledTextField
                  inputMode='email'
                  errorMessage={errorMessage}
                  height={56}
                  placeholder='Email'
                  value={email}
                  onChange={setEmail}
                  onEnter={createUserWithEmail}
                />
                <CapsuleButton
                  type='primary'
                  disabled={disabledSendButton}
                  icon={<MailIcon fontSize='small' />}
                  label='メールアドレスで登録'
                  onClick={createUserWithEmail}
                />
              </MailOption>
            )) ||
            (serviceType === LoginServiceType.SAKURA && (
              <CapsuleButton
                key={idx}
                icon={<Icon size='xsmall' src={sakura} />}
                label='さくらIDで登録'
                onClick={openSakuraLoginPage}
              />
            )) ||
            (serviceType === LoginServiceType.GOOGLE && (
              <CapsuleButton
                key={idx}
                icon={<Icon size='xsmall' src={google} />}
                label='Googleで登録'
                onClick={openGoogleLoginPage}
              />
            )) ||
            (serviceType === LoginServiceType.LINE && (
              <CapsuleButton
                key={idx}
                icon={<Icon size='xsmall' src={line} />}
                label='LINEで登録'
                onClick={openLineLoginPage}
              />
            ))
        )}
      </LoginServiceSection>
    </Container>
  );
});

SignUpOptionList.displayName = 'SignUpOptionList:';
export default SignUpOptionList;

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: ${themeV2.mixins.v2.spacing * 2}px;
`;

const Box = styled.div`
  ${themeV2.mixins.v2.typography.label.large};
  width: 100%;
  height: auto;
  background: ${themeV2.mixins.v2.color.background.white};
  border-radius: 16px;
  padding: ${themeV2.mixins.v2.spacing}px ${themeV2.mixins.v2.spacing * 2}px;
  text-align: center;
  white-space: pre-line;
`;

const StyledTextField = styled(TextFieldV2)`
  && {
    border: none !important;
    border-radius: 16px !important;
  }
`;

const LoginServiceSection = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: ${themeV2.mixins.v2.spacing}px;
`;

const MailOption = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: ${themeV2.mixins.v2.spacing * 2}px;
`;
