import {
  EVENT_ID_V2,
  EVENT_SCHEDULE_ID_V2,
  EVENT_SCHEDULE_PARTICIPANT_ID,
  EventHoldingMethod,
  EventInflowSource,
  ParticipatingMembershipTypeEnum,
  RecurrenceTarget
} from '@atomica.co/irori';
import { DateUnitEnum } from '@atomica.co/utils';
import {
  EVENT_CREATED_AT,
  EVENT_DESCRIPTION,
  EVENT_NAME,
  EVENT_PHOTO_URL,
  EVENT_SCHEDULE_CREATED_AT,
  EVENT_SCHEDULE_DEADLINE_AT,
  EVENT_SCHEDULE_DESCRIPTION,
  EVENT_SCHEDULE_END_AT,
  EVENT_SCHEDULE_NAME,
  EVENT_SCHEDULE_START_AT,
  EVENT_SCHEDULE_UPDATED_AT,
  EVENT_UPDATED_AT,
  GROUP_NAME,
  PARTICIPANT_ADDRESS,
  PARTICIPANT_COMPANY_NAME,
  PARTICIPANT_CREATED_AT,
  PARTICIPANT_DATE_OF_BIRTH,
  PARTICIPANT_EMAIL,
  PARTICIPANT_NAME,
  PARTICIPANT_PHONE,
  PARTICIPANT_PHOTO_URL,
  PARTICIPANT_POSTAL_CODE,
  PARTICIPANT_UPDATED_AT
} from '../constants/event-v2-const';
import { AccountMySchedulesTabEnum } from '../enums/account-enum';
import {
  AccountEventTabEnum,
  EventDetailTabEnum,
  EventScheduleDeadlineEnum,
  EventScheduleParticipantPathEnum,
  EventSchedulePathEnum,
  EventScheduleTabEnum
} from '../enums/event-v2-enum';
import { CSVTemplate, Labels } from '../models/common-model';

export const EVENT_INFLOW_SOURCE_LABELS: Labels = {
  [EventInflowSource.CONTINUE]: 'これまでに参加したことがある',
  [EventInflowSource.STAFF]: '店頭（スタッフからの声掛け）',
  [EventInflowSource.FRIEND]: '知り合いからの紹介',
  [EventInflowSource.INSTAGRAM]: 'Instagram',
  [EventInflowSource.TWITTER]: 'Twitter',
  [EventInflowSource.FACEBOOK]: 'Facebook',
  [EventInflowSource.LINE]: 'LINE',
  [EventInflowSource.FLYER]: 'チラシ',
  [EventInflowSource.OTHER]: 'その他'
};

export const EVENT_SCHEDULE_TAB_LABELS: Labels = {
  [EventSchedulePathEnum.SUMMARY]: '概要',
  [EventSchedulePathEnum.PARTICIPANTS]: '参加者',
  // [EventSchedulePathEnum.ANALYSIS]: 'イベント分析',
  [EventSchedulePathEnum.ENTRY_FORM]: '申し込みフォーム',
  [EventSchedulePathEnum.REMIND]: 'リマインド'
};

export const EVENT_SCHEDULE_PARTICIPANT_TAB_LABELS: Labels = {
  [EventScheduleParticipantPathEnum.PARTICIPANTS]: '参加者別',
  [EventScheduleParticipantPathEnum.GROUPS]: 'グループ別'
};

export const EVENT_HOLDING_METHOD_LABELS: Labels = {
  [EventHoldingMethod.OFFLINE]: 'オフライン',
  [EventHoldingMethod.ONLINE]: 'オンライン',
  [EventHoldingMethod.UNDECIDED]: '未定'
};
export const EVENT_DETAIL_TAB_LABELS: Labels = {
  [EventDetailTabEnum.SCHEDULES]: 'スケジュール',
  [EventDetailTabEnum.PARTICIPANTS]: '定期参加者'
};

export const EVENT_SCHEDULE_LIST_TAB_LABELS: Labels = {
  [EventScheduleTabEnum.SCHEDULED]: '未開催',
  [EventScheduleTabEnum.HELD]: '開催済み'
};

export const EVENT_SCHEDULE_DEADLINE_LABELS: Labels = {
  [EventScheduleDeadlineEnum.UNTIL_END_TIME]: '終了日時まで',
  [EventScheduleDeadlineEnum.ASSIGN_DATE]: '日時指定'
};

export const EVENT_MEMBERSHIP_TYPE_LABELS: Labels = {
  [ParticipatingMembershipTypeEnum.ALL]: 'すべての会員種別',
  [ParticipatingMembershipTypeEnum.LIMITED]: '会員種別を指定'
};

export const ACCOUNT_MY_SCHEDULES_TAB_LABELS: Labels = {
  [AccountMySchedulesTabEnum.ATTENDING]: '今後の予定',
  [AccountMySchedulesTabEnum.ATTENDED]: '過去の予定'
};

export const ACCOUNT_EVENT_TAB_LABELS: Labels = {
  [AccountEventTabEnum.SCHEDULED]: '開催予定',
  [AccountEventTabEnum.ATTENDING]: '参加予定',
  [AccountEventTabEnum.ATTENDED]: '参加済み'
};

export const DATE_UNIT_LABELS: Labels = {
  [DateUnitEnum.DAYS_AGO]: '日前',
  [DateUnitEnum.WEEKS_AGO]: '週間前',
  [DateUnitEnum.MONTHS_AGO]: 'ヶ月前'
};

export const EVENT_SCHEDULE_RECURRENCE_TARGET_LABELS: Labels = {
  [RecurrenceTarget.THIS_ONE]: 'このスケジュール',
  [RecurrenceTarget.AFTER_THIS]: 'これ以降のすべてのスケジュール'
};

export const EVENT_HEADERS: CSVTemplate[] = [
  {
    key: EVENT_ID_V2,
    label: 'イベントID'
  },
  {
    key: EVENT_NAME,
    label: 'イベント名'
  },
  {
    key: EVENT_DESCRIPTION,
    label: 'イベントの説明'
  },
  {
    key: EVENT_PHOTO_URL,
    label: 'イベント画像のURL'
  },
  {
    key: EVENT_CREATED_AT,
    label: 'イベント作成日'
  },
  {
    key: EVENT_UPDATED_AT,
    label: 'イベント更新日'
  },
  {
    key: EVENT_SCHEDULE_ID_V2,
    label: 'スケジュールID'
  },
  {
    key: EVENT_SCHEDULE_NAME,
    label: 'スケジュール名'
  },
  {
    key: EVENT_SCHEDULE_DESCRIPTION,
    label: 'スケジュールの説明'
  },
  {
    key: EVENT_SCHEDULE_START_AT,
    label: '開始日時'
  },
  {
    key: EVENT_SCHEDULE_END_AT,
    label: '終了日時'
  },
  {
    key: EVENT_SCHEDULE_DEADLINE_AT,
    label: '締切日時'
  },
  {
    key: EVENT_SCHEDULE_CREATED_AT,
    label: 'スケジュール作成日'
  },
  {
    key: EVENT_SCHEDULE_UPDATED_AT,
    label: 'スケジュール更新日'
  },
  {
    key: EVENT_SCHEDULE_PARTICIPANT_ID,
    label: '参加者ID'
  },
  {
    key: PARTICIPANT_NAME,
    label: '氏名'
  },
  { key: PARTICIPANT_DATE_OF_BIRTH, label: '誕生日' },
  {
    key: PARTICIPANT_PHONE,
    label: '電話番号'
  },
  {
    key: PARTICIPANT_EMAIL,
    label: 'メールアドレス'
  },
  {
    key: PARTICIPANT_COMPANY_NAME,
    label: '会社名'
  },
  {
    key: PARTICIPANT_POSTAL_CODE,
    label: '郵便番号'
  },
  {
    key: PARTICIPANT_ADDRESS,
    label: '住所'
  },
  {
    key: PARTICIPANT_PHOTO_URL,
    label: '参加者アイコンのURL'
  },
  {
    key: GROUP_NAME,
    label: '所属グループ'
  },
  {
    key: PARTICIPANT_CREATED_AT,
    label: '参加者作成日'
  },
  {
    key: PARTICIPANT_UPDATED_AT,
    label: '参加者更新日'
  }
];
