import { BillingDateColumn, BillingIdV2, BillingStatus, HonorificType, PaymentMethod } from '@atomica.co/irori';
import { Name, Text } from '@atomica.co/types';
import { CREATED_AT, EMPTY, UPDATED_AT, isDefAndNotNull } from '@atomica.co/utils';
import {
  BANK_ACCOUNT,
  BILLING_ADDRESS,
  BILLING_DATE,
  BILLING_DETAIL_ID,
  BILLING_DETAIL_ITEM_NAME,
  BILLING_DETAIL_PRICE,
  BILLING_DETAIL_QUANTITY,
  BILLING_DETAIL_TAX,
  BILLING_DETAIL_UNIT_NAME,
  BILLING_DETAIL_UNIT_PRICE,
  BILLING_ID,
  BILLING_NAME,
  BILLING_NO,
  BILLING_POST,
  CONTRACTOR_INFO,
  CONTRACTOR_NAME,
  CUTOFF_DATE,
  PAYABLE_ACCOUNT,
  PAYMENT_COUNT,
  PAYMENT_DUE_DATE,
  PAYMENT_METHOD,
  REMARKS,
  STRIPE_INVOICE_ID,
  TAX_EXCLUDED_TOTAL_PRICE,
  TAX_INCLUDED_TOTAL_PRICE
} from '../constants/billing-v2-const';
import { BillingAction } from '../enums/billing-v2-enum';
import { CSVTemplate, Labels } from '../models/common-model';

export const CONFIRM_BILLING = '請求金額を確定しました';

export const UNDO_CONFIRED_BILLING = '請求金額の確定を取り消しました';

export const PAYMENT_EXEC_SUCCESS_BILLING = '決済処理の実行に成功しました';

export const PAYMENT_FAIL_BILLING = '決済処理の実行に失敗しました';

export const BILLING_DUPLICATED = (billingId: BillingIdV2): Text => `請求(ID: ${billingId})を複製しました`;

export const BILLING_CONFIRMED = (billingId: BillingIdV2): Text => `請求(ID: ${billingId})を確定しました`;

export const BILLING_UNCONFIRMED = (billingId: BillingIdV2): Text => `請求(ID: ${billingId})の確定を取り消しました`;

export const BILLING_PAYED = (billingId: BillingIdV2): Text => `請求(ID: ${billingId})の決済処理を実行に成功しました`;

export const BILLING_FAILED = (billingId: BillingIdV2): Text => `請求(ID: ${billingId})の決済処理を実行に失敗しました`;

export const BILLING_CREATED = '新規作成しました';

export const BILLING_DELETED = (billingId: BillingIdV2): Text => `請求(ID: ${billingId})を削除しました`;

export const BILLING_MODIFIED_DEFAULT = (columnName: Name, itemName?: Name): Text =>
  `${itemName ? `${itemName}の` : EMPTY}${columnName}を変更しました。`;

export const BILLING_MODIFIED_DETAIL = (
  columnName: Name,
  prev: string | number | boolean,
  curr: string | number | boolean,
  itemName?: Name
): Text =>
  `${itemName ? `${itemName}の` : EMPTY}${columnName}を${
    isDefAndNotNull(prev) ? `${prev}から` : EMPTY
  }${curr}に変更しました。`;

export const BILLING_DETAIL_ADDED = (columnName: Name): Text => `${columnName}を追加しました。`;

export const BILLING_DETAIL_DELETED = (columnName: Name): Text => `${columnName}を削除しました。`;

export const BILLING_DETAIL_MODIFIED_DEFAULT = (columnName: Name): Text => `${columnName}を追加しました。`;

export const BILLING_DETAIL_MODIFIED_DETAIL = (
  columnName: Name,
  prev: string | number | boolean,
  curr: string | number | boolean
): Text => `${columnName}を${prev != null && `${prev}から`}${curr}に変更しました。`;

export const BILLING_HEADERS: CSVTemplate[] = [
  {
    key: BILLING_ID,
    label: '請求ID'
  },
  {
    key: BILLING_NO,
    label: '請求番号'
  },
  {
    key: BILLING_NAME,
    label: '請求名'
  },
  {
    key: CUTOFF_DATE,
    label: '請求締め日'
  },
  {
    key: BILLING_DATE,
    label: '請求日'
  },
  {
    key: PAYMENT_DUE_DATE,
    label: '支払い期日'
  },
  {
    key: CONTRACTOR_NAME,
    label: '取引先'
  },
  {
    key: CONTRACTOR_INFO,
    label: '取引先情報'
  },
  {
    key: PAYMENT_METHOD,
    label: '支払い方法'
  },
  {
    key: PAYABLE_ACCOUNT,
    label: '振込先'
  },
  {
    key: PAYMENT_COUNT,
    label: '支払い回数'
  },
  {
    key: BILLING_POST,
    label: '請求先郵便番号'
  },
  {
    key: BILLING_ADDRESS,
    label: '請求先住所'
  },
  {
    key: BANK_ACCOUNT,
    label: '口座情報'
  },
  {
    key: REMARKS,
    label: '備考'
  },
  {
    key: STRIPE_INVOICE_ID,
    label: 'stripe請求ID'
  },
  {
    key: TAX_INCLUDED_TOTAL_PRICE,
    label: '合計金額（税込）'
  },
  {
    key: TAX_EXCLUDED_TOTAL_PRICE,
    label: '合計金額（税抜）'
  },
  {
    key: BILLING_DETAIL_ID,
    label: '請求詳細ID'
  },
  {
    key: BILLING_DETAIL_ITEM_NAME,
    label: '品目名'
  },
  {
    key: BILLING_DETAIL_UNIT_PRICE,
    label: '単価'
  },
  {
    key: BILLING_DETAIL_QUANTITY,
    label: '数量'
  },
  {
    key: BILLING_DETAIL_UNIT_NAME,
    label: '単位'
  },
  {
    key: BILLING_DETAIL_TAX,
    label: '課税'
  },
  {
    key: BILLING_DETAIL_PRICE,
    label: '課税'
  },
  {
    key: CREATED_AT,
    label: '作成日'
  },
  {
    key: UPDATED_AT,
    label: '更新日'
  }
];

export const BILLING_DATE_LABEL: Labels = {
  [BillingDateColumn.CUTOFF_DATE]: '請求締め日',
  [BillingDateColumn.BILLING_DATE]: '請求日'
};

export const BILLING_STATUS_LABEL: Labels = {
  [BillingStatus.CONFIRMED]: '確定済み',
  [BillingStatus.UNCONFIRMED]: '未確定',
  [BillingStatus.PAYMENT_COMPLETED]: '決済成功',
  [BillingStatus.PAYMENT_ERROR]: '決済エラー'
};

export const BILLING_ACTION_LABEL: Labels = {
  [BillingAction.OPERATE_ALL]: '一括操作',
  [BillingAction.OPEN_SELECTED]: '請求詳細を確認する',
  [BillingAction.CONFIRM_AMOUNT]: '請求金額を確定する',
  [BillingAction.DOWNLOAD_CSV]: 'CSVダウンロード',
  // [BillingAction.DOWNLOAD_PDF]: 'PDFダウンロード',
  [BillingAction.DELETE_ALL]: '一括削除'
};

export const HONORIFIC_TYPE_LABEL: Labels = {
  [HonorificType.ONCHU]: '御中',
  [HonorificType.SAMA]: '様'
};

export const BILLING_PAYMENT_METHOD_LABEL: Labels = {
  [PaymentMethod.CREDIT_CARD]: 'クレジットカード',
  [PaymentMethod.BANK_TRANSFER]: '口座振替',
  [PaymentMethod.INVOICE]: '請求書払い',
  [PaymentMethod.CASH]: '現金'
};

export const BILLING_DETAIL_PAYMENT_METHOD_LABEL: Labels = {
  [PaymentMethod.CREDIT_CARD]: 'お支払方法',
  [PaymentMethod.BANK_TRANSFER]: '引き落とし口座',
  [PaymentMethod.INVOICE]: '振込先'
};
