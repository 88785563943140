import { BaseDto, ShotCategory, ShotQuantityInputType, ShotSubcategory } from '@atomica.co/irori';
import { Amount, Label } from '@atomica.co/types';
import { EMPTY, toTaxExcluded, toTaxIncluded, ZERO } from '@atomica.co/utils';
import { getTaxRate } from '../utils/item-util';
import {
  getOneDayDiscountPrice,
  TAX_EXCLUDED_ONE_DAY_PRICE_LABEL,
  TAX_INCLUDED_ONE_DAY_PRICE_LABEL
} from '../utils/shot-category-util';
import ItemService from './item-service';

export default class ShotPriceService {
  public static calculateTaxExcludedBasePrice = (
    category: ShotCategory | undefined,
    subcategory: ShotSubcategory | undefined
  ): Amount => {
    if (!category) return ZERO;

    switch (category.quantityInputType) {
      case ShotQuantityInputType.FIXED:
        return ItemService.calcTaxExcludedPrice(category.item);

      case ShotQuantityInputType.MANUAL:
        if (!subcategory) return ZERO;
        return ItemService.calcTaxExcludedPrice(subcategory.item);

      default:
        throw new Error(`${category.quantityInputType} is out of target.`);
    }
  };

  public static getTaxExcludedBasePriceLabel = (
    category: ShotCategory | undefined,
    subcategory: ShotSubcategory | undefined
  ): Label => {
    if (!category) return EMPTY;

    switch (category.quantityInputType) {
      case ShotQuantityInputType.FIXED:
        return ItemService.getTaxExcludedPriceLabel(category.item);

      case ShotQuantityInputType.MANUAL:
        if (!subcategory) return EMPTY;
        return ItemService.getTaxExcludedPriceLabel(subcategory.item);

      default:
        throw new Error(`${category.quantityInputType} is out of target.`);
    }
  };

  public static calculateTaxIncludedBasePrice = (
    category: ShotCategory | undefined,
    subcategory: ShotSubcategory | undefined
  ): Amount => {
    if (!category) return ZERO;

    switch (category.quantityInputType) {
      case ShotQuantityInputType.FIXED:
        return ItemService.calcTaxIncludedPrice(category.item);

      case ShotQuantityInputType.MANUAL:
        if (!subcategory) return ZERO;
        return ItemService.calcTaxIncludedPrice(subcategory.item);

      default:
        throw new Error(`${category.quantityInputType} is out of target.`);
    }
  };

  public static getTaxIncludedBasePriceLabel = (
    category: ShotCategory | undefined,
    subcategory: ShotSubcategory | undefined
  ): Label => {
    if (!category) return EMPTY;

    switch (category.quantityInputType) {
      case ShotQuantityInputType.FIXED:
        return ItemService.getTaxIncludedPriceLabel(category.item);

      case ShotQuantityInputType.MANUAL:
        if (!subcategory) return EMPTY;
        return ItemService.getTaxIncludedPriceLabel(subcategory.item);

      default:
        throw new Error(`${category.quantityInputType} is out of target.`);
    }
  };

  public static calculateTaxExcludedOptionPrice = (
    base: BaseDto,
    category: ShotCategory | undefined,
    subcategory: ShotSubcategory | undefined,
    conferenceOneDayUsage: boolean | undefined
  ): Amount => {
    if (!category) return ZERO;

    switch (category.quantityInputType) {
      case ShotQuantityInputType.FIXED:
        if (!subcategory) return ZERO;
        return toTaxExcluded(ItemService.calcTaxExcludedPrice(subcategory.item), 10); // FIXME

      case ShotQuantityInputType.MANUAL:
        return conferenceOneDayUsage === true ? getOneDayDiscountPrice(base) : ZERO;

      default:
        throw new Error(`${category.quantityInputType} is out of target.`);
    }
  };

  public static getTaxExcludedOptionPriceLabel = (
    base: BaseDto,
    category: ShotCategory | undefined,
    subcategory: ShotSubcategory | undefined,
    conferenceOneDayUsage: boolean | undefined
  ): Label => {
    if (!category) return EMPTY;

    switch (category.quantityInputType) {
      case ShotQuantityInputType.FIXED:
        if (!subcategory) return EMPTY;
        return ItemService.getTaxExcludedPriceLabel(subcategory.item);

      case ShotQuantityInputType.MANUAL:
        return conferenceOneDayUsage === true ? TAX_EXCLUDED_ONE_DAY_PRICE_LABEL : EMPTY;

      default:
        throw new Error(`${category.quantityInputType} is out of target.`);
    }
  };

  public static calculateTaxIncludedOptionPrice = (
    base: BaseDto,
    category: ShotCategory | undefined,
    subcategory: ShotSubcategory | undefined,
    conferenceOneDayUsage: boolean | undefined
  ): Amount => {
    if (!category) return ZERO;

    switch (category.quantityInputType) {
      case ShotQuantityInputType.FIXED:
        if (!subcategory) return ZERO;
        return ItemService.calcTaxIncludedPrice(subcategory.item);

      case ShotQuantityInputType.MANUAL:
        {
        const price = conferenceOneDayUsage === true ? getOneDayDiscountPrice(base) : ZERO;
        const taxRate = subcategory ? getTaxRate(subcategory.item) : ZERO;
        return toTaxIncluded(price, taxRate);
      }
      default:
        throw new Error(`${category.quantityInputType} is out of target.`);
    }
  };

  public static getTaxIncludedOptionPriceLabel = (
    base: BaseDto,
    category: ShotCategory | undefined,
    subcategory: ShotSubcategory | undefined,
    conferenceOneDayUsage: boolean | undefined
  ): Label => {
    if (!category) return EMPTY;

    switch (category.quantityInputType) {
      case ShotQuantityInputType.FIXED:
        if (!subcategory) return EMPTY;
        return ItemService.getTaxIncludedPriceLabel(subcategory.item);

      case ShotQuantityInputType.MANUAL:
        return conferenceOneDayUsage === true ? TAX_INCLUDED_ONE_DAY_PRICE_LABEL : EMPTY;

      default:
        throw new Error(`${category.quantityInputType} is out of target.`);
    }
  };
}
