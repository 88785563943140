import {
  ButtonV2,
  CircularLoader,
  Component,
  themeV2,
  useSafeCallback,
  useSafeState,
  useUnmountRef
} from '@atomica.co/components';
import { CSSProperties } from '@material-ui/core/styles/withStyles';
import React, { useEffect, useMemo } from 'react';
import styled from 'styled-components';
import {
  ContractV2,
  FETCH_CONTRACT_V2,
  FetchContractV2Request,
  FetchContractV2Response,
  PaymentMethod,
  RESEND_CREDIT_CARD_REGISTER_FORM,
  ResendCreditCardRegisterFormRequest,
  ResendCreditCardRegisterFormResponse
} from '@atomica.co/irori';
import usePath from '../../../redux/hooks/usePath';
import useCommonRequest from '../../../redux/hooks/useCommonRequest';

const SendResult = {
  Success: 'success',
  Failure: 'failure'
} as const;
type SendResult = (typeof SendResult)[keyof typeof SendResult];

const ResendRegisterCreditCardScreen: React.FC = React.memo(() => {
  const { queryParams } = usePath();
  const { commonRequest } = useCommonRequest();
  const unmountref = useUnmountRef();
  const [initialized, setInitialized] = useSafeState<boolean>(unmountref, false);
  const [isInvalid, setIsInvalid] = useSafeState<boolean>(unmountref, false);
  const [isSending, setIsSending] = useSafeState<boolean>(unmountref, false);
  const [sendResult, setSendResult] = useSafeState<SendResult | undefined>(unmountref);
  const [contract, setContract] = useSafeState<ContractV2 | undefined>(unmountref);
  const contractId = useMemo(() => queryParams['contractId'], [queryParams]);

  const fetchContract = useSafeCallback(async (): Promise<void> => {
    if (!contractId) return;
    const response = await commonRequest<FetchContractV2Request, FetchContractV2Response>(FETCH_CONTRACT_V2, {
      contractId
    });
    if (response.contract && response.contract.paymentMethod === PaymentMethod.CREDIT_CARD) {
      setContract(response.contract);
    } else {
      setIsInvalid(true);
    }
  }, [commonRequest, contractId, setContract, setIsInvalid]);

  const resendRegisterCreditCardForm = useSafeCallback(async (): Promise<void> => {
    if (!contractId) return;
    setIsSending(true);
    const response = await commonRequest<ResendCreditCardRegisterFormRequest, ResendCreditCardRegisterFormResponse>(
      RESEND_CREDIT_CARD_REGISTER_FORM,
      { contractId }
    );
    if (response.result) {
      setSendResult(SendResult.Success);
    } else {
      setSendResult(SendResult.Failure);
    }
    setIsSending(false);
  }, [commonRequest, contractId, setIsSending, setSendResult]);

  const initialize = useSafeCallback(async () => {
    await fetchContract();
    setInitialized(true);
  }, [fetchContract, setInitialized]);

  useEffect(() => {
    if (initialized) return;
    initialize();
  }, [initialize, initialized]);

  const InvalidContent = useSafeCallback(
    (): JSX.Element => (
      <Card>
        <TitleWrapper>
          <Title>不正なリクエストです</Title>
        </TitleWrapper>
      </Card>
    ),
    []
  );

  const ValidContent = useSafeCallback(
    (): JSX.Element => (
      <Card>
        <TitleWrapper>
          <Title>クレジットカード登録メールの再送信</Title>
        </TitleWrapper>
        <Content>
          登録されているメールアドレス <br />
          {contract?.contractorEmail}
        </Content>
        {isSending ? (
          <CircularLoader />
        ) : (
          <>
            {!sendResult && (
              <ButtonV2 size='medium' type='primary' label='再送信' onClick={resendRegisterCreditCardForm} />
            )}
            {sendResult === SendResult.Success && <Content>メールを再送信しました</Content>}
            {sendResult === SendResult.Failure && (
              <Content>
                メールの再送信に失敗しました
                <br />
                時間をあけて再度お試しいただくか
                <br />
                拠点スタッフにお問い合わせください
              </Content>
            )}
          </>
        )}
      </Card>
    ),
    [contract?.contractorEmail, isSending, resendRegisterCreditCardForm, sendResult]
  );

  return (
    <Component className='resend-contract-screen' style={styleForComponent} loading={!initialized}>
      <Container>{isInvalid ? <InvalidContent /> : <ValidContent />}</Container>
    </Component>
  );
});
ResendRegisterCreditCardScreen.displayName = 'ResendRegisterCreditCardScreen';
export default ResendRegisterCreditCardScreen;

const styleForComponent: CSSProperties = {
  height: '100%'
};

const Container = styled.div`
  display: flex;
  height: 100%;
  width: 100%;
  max-width: 428px;
  padding: 48px 16px 16px 16px;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  flex: 1 0 0;
  margin-inline: auto;
`;

const Card = styled.div`
  display: flex;
  height: 320px;
  flex-direction: column;
  align-items: center;
  gap: 32px;
`;

const TitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
`;

const Title = styled.div`
  color: ${themeV2.mixins.v2.color.font.black};
  ${themeV2.mixins.v2.typography.title.medium};
  text-align: center;
  white-space: pre-wrap;
`;

const Content = styled.div`
  color: ${themeV2.mixins.v2.color.font.black};
  ${themeV2.mixins.v2.typography.body.medium};
  text-align: center;
  white-space: pre-wrap;
`;
