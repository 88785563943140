import {
  ButtonV2,
  ColWidth,
  Header,
  IconButtonV2,
  LabelV2,
  SortableTable,
  TableV3,
  themeV2,
  themeV3
} from '@atomica.co/components';

import { BaseDto } from '@atomica.co/irori';
import { Index } from '@atomica.co/types';
import { hasLength } from '@atomica.co/utils';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import AddRoundedIcon from '@material-ui/icons/AddRounded';
import CloseRoundedIcon from '@material-ui/icons/CloseRounded';
import ExpandLessRoundedIcon from '@material-ui/icons/ExpandLessRounded';
import ExpandMoreRoundedIcon from '@material-ui/icons/ExpandMoreRounded';
import MenuRoundedIcon from '@material-ui/icons/MenuRounded';
import React from 'react';
import styled from 'styled-components';
import { MainOrSub, ShotCategoryRow, ShotSubCategoryRow } from '../ShotMasterScreen';

interface P {
  base: BaseDto;
  isDeletable: boolean;
  isExpanded: boolean;
  shotCategoryRow: ShotCategoryRow;
  shotSubcategoryRows: ShotSubCategoryRow[];
  getColumnWidth: (type: MainOrSub) => ColWidth<ShotCategoryRow>;
  getHeader: (type: MainOrSub) => Header<ShotCategoryRow>;
  onAdd: () => void;
  onDelete: () => void;
  onExpand: () => void;
  onSort: (oldIndex: Index, newIndex: Index) => void;
}

const ShotCategoryCard: React.FC<P> = React.memo(props => {
  const {
    isDeletable,
    isExpanded,
    shotCategoryRow,
    shotSubcategoryRows,
    getColumnWidth,
    getHeader,
    onAdd,
    onDelete,
    onExpand,
    onSort
  } = props;

  const { isDragging, setActivatorNodeRef, attributes, listeners, setNodeRef, transform, transition } = useSortable({
    id: shotCategoryRow.id,
    animateLayoutChanges: () => false
  });

  return (
    <Container
      style={{
        zIndex: isDragging ? 100 : 0,
        transform: CSS.Transform.toString(transform),
        transition
      }}
      ref={setNodeRef}
      className='shot-master-screen-card'
    >
      <TableWrapper data-testid='shot-master-screen-parent-table-wrapper'>
        <LabelWrapper>
          <LabelV2 text='選択肢' required />
          <IconButtonWrapper>
            {isDeletable && (
              <div
                data-testid='sort-button'
                id={shotCategoryRow.id}
                ref={setActivatorNodeRef}
                {...attributes}
                {...listeners}
              >
                <IconButtonV2
                  type='tertiary'
                  shape='rect'
                  size='small'
                  icon={<MenuRoundedIcon data-testid='sort-icon' />}
                  onClick={() => {}}
                />
              </div>
            )}
            {isDeletable && (
              <IconButtonV2
                type='secondary'
                shape='rect'
                size='small'
                icon={<CloseRoundedIcon data-testid='close-icon' />}
                onClick={onDelete}
              />
            )}
          </IconButtonWrapper>
        </LabelWrapper>
        <TableV3
          showBorder
          shape='circle'
          colWidth={getColumnWidth('main')}
          header={getHeader('main')}
          rows={[shotCategoryRow]}
        />
      </TableWrapper>
      {isExpanded && (
        <TableWrapper data-testid='shot-master-screen-child-table-wrapper'>
          {hasLength(shotSubcategoryRows) && (
            <>
              <LabelV2 text='追加の選択肢' required />
              <SortableTable
                onItemSet={(oldIndex, newIndex) => onSort(oldIndex, newIndex)}
                showBorder
                shape='circle'
                colWidth={getColumnWidth('sub')}
                header={getHeader('sub')}
                rows={shotSubcategoryRows}
              />
            </>
          )}
          <ButtonV2 startIcon={<AddRoundedIcon />} label='追加の選択肢を追加' onClick={onAdd} />
        </TableWrapper>
      )}
      {hasLength(shotSubcategoryRows) && (
        <ButtonV2
          isFullWidth
          label={isExpanded ? '追加の選択肢を畳む' : '追加の選択肢を表示する'}
          startIcon={isExpanded ? <ExpandLessRoundedIcon /> : <ExpandMoreRoundedIcon />}
          onClick={onExpand}
        />
      )}
    </Container>
  );
});

ShotCategoryCard.displayName = 'ShotCategoryCard';
export default ShotCategoryCard;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${themeV2.mixins.v2.spacing * 2}px;
  padding: ${themeV2.mixins.v2.spacing * 3}px;
  background: ${themeV3.mixins.v3.color.container.neutral.row};
  border-radius: 8px;
`;

const LabelWrapper = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
`;

const TableWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${themeV2.mixins.v2.spacing}px;
`;

const IconButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: ${themeV2.mixins.v2.spacing * 2}px;
`;
