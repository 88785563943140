import { customMedia, themeV2 } from '@atomica.co/components';
import { Space, SpaceId } from '@atomica.co/irori';
import CheckCircleRoundedIcon from '@material-ui/icons/CheckCircleRounded';
import { Skeleton } from '@material-ui/lab';
import React from 'react';
import styled from 'styled-components';

interface P {
  isAllImagesLoaded: boolean;
  space: Space;
  selectedSpaceIds: SpaceId[];
  failedLoadSpaceIdsOnImage: SpaceId[];
  handleSpaceClicked: (spacesId: SpaceId) => void;
  handleImageLoaded: (spaceId: SpaceId, isLoaded: boolean) => void;
  children: React.ReactNode;
}

const SpaceCardWithImage: React.FC<P> = React.memo(props => {
  const {
    isAllImagesLoaded,
    failedLoadSpaceIdsOnImage,
    space,
    selectedSpaceIds,
    handleSpaceClicked,
    handleImageLoaded,
    children
  } = props;

  return (
    <Container>
      {!isAllImagesLoaded && (
        <SkeletonCard>
          <ImageSkeleton variant='rect' />
          <CardContent>
            <CardBody>
              <Skeleton variant='rect' width='60%' height={24} />
              <Skeleton variant='rect' width='60%' height={24} />
              <Skeleton variant='rect' width='100%' height={12} />
              <Skeleton variant='rect' width='100%' height={12} />
            </CardBody>
          </CardContent>
        </SkeletonCard>
      )}
      <CustomCard isLoaded={isAllImagesLoaded} onClick={() => handleSpaceClicked(space.spaceId)}>
        <IconWrapper>
          <CustomCheckIcon fontSize='large' checked={selectedSpaceIds.includes(space.spaceId)} />
        </IconWrapper>
        <CardImage>
          {failedLoadSpaceIdsOnImage.includes(space.spaceId) && <ImageSkeleton variant='rect' />}
          {!failedLoadSpaceIdsOnImage.includes(space.spaceId) && (
            <Image
              src={space.photoURL}
              alt='Paella dish'
              onLoad={() => {
                handleImageLoaded(space.spaceId, true);
              }}
              onError={() => {
                handleImageLoaded(space.spaceId, false);
              }}
            />
          )}
        </CardImage>
        <CardContent>{children}</CardContent>
      </CustomCard>
    </Container>
  );
});

SpaceCardWithImage.displayName = 'SpaceCardWithImage';
export default SpaceCardWithImage;

const Container = styled.div`
  max-width: 330px;
  width: 100%;
  position: relative;
  color: ${themeV2.mixins.v2.color.font.white};
  border-radius: 16px;
  ${customMedia.lessThan('small')`
    max-width: 100%;
    width: 100%;
  `};
`;

const SkeletonCard = styled.div`
  width: 100%;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.2);
  border-radius: 16px;
`;

const CustomCard = styled.div<{ isLoaded: boolean }>`
  position: ${({ isLoaded }) => (isLoaded ? 'relative' : 'absolute')};
  visibility: ${({ isLoaded }) => (isLoaded ? 'visible' : 'hidden')};
  transform: scale(${({ isLoaded }) => (isLoaded ? 1 : 0)});
  opacity: ${({ isLoaded }) => (isLoaded ? 1 : 0)};
  transition: opacity 0.5s, visibility 0s, transform 0s;
  border-radius: 16px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.2);
  cursor: pointer;
`;

const IconWrapper = styled.div`
  position: absolute;
  top: ${themeV2.mixins.v2.spacing * 2}px;
  right: ${themeV2.mixins.v2.spacing * 2}px;
`;

const CardImage = styled.div`
  width: 100%;
  height: 210px;
`;

const ImageSkeleton = styled(Skeleton)`
  width: 100%;
  height: 210px;
  border-radius: 16px 16px 0 0;
`;

const Image = styled.img`
  width: 100%;
  max-height: 100%;
  height: auto;
  object-fit: cover;
  border-radius: 16px 16px 0 0;
`;

const CardContent = styled.div`
  display: flex;
  align-items: flex-start;
  padding: ${themeV2.mixins.v2.spacing * 2}px;
  gap: ${themeV2.mixins.v2.spacing * 1.5}px;
  min-height: 160px;
`;

const CardBody = styled.div`
  width: 100%;
  color: ${themeV2.mixins.v2.color.font.black};
  display: flex;
  flex-direction: column;
  gap: ${themeV2.mixins.v2.spacing}px;
`;

const CustomCheckIcon = styled(CheckCircleRoundedIcon)<{ checked: boolean }>`
  color: ${({ checked }) =>
    checked ? themeV2.mixins.v2.color.background.pink : themeV2.mixins.v2.color.background.gray};
  background: ${themeV2.mixins.v2.color.background.white};
  border-radius: 50%;
`;
