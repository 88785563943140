import {
  ButtonV2,
  DialogV2,
  ScreenLoaderV2,
  TextFieldV2,
  themeV2,
  useSafeCallback,
  useSafeState,
  useUnmountRef
} from '@atomica.co/components';
import { ContractUserV2, InvalidSendInvitationDataForAdmin, InvitationStatus } from '@atomica.co/irori';
import { Email, Message } from '@atomica.co/types';
import { BREAK, EMPTY, hasLength, isEmail } from '@atomica.co/utils';
import WarningRoundedIcon from '@material-ui/icons/WarningRounded';
import React, { useMemo } from 'react';
import styled from 'styled-components';

interface P {
  existingContractUsers: ContractUserV2[];
  invalidSendInvitationData: InvalidSendInvitationDataForAdmin[];
  isOpen: boolean;
  sendInvitation(emails: Email[]): Promise<void>;
  onClose(): void;
}

const createErrorArea = (message: Message, list: Email[]): React.ReactNode => (
  <ErrorArea key={message}>
    <ErrorMsg>
      <WarningRoundedIconWrapper>
        <WarningRoundedIcon fontSize='inherit' />
      </WarningRoundedIconWrapper>
      {message}
    </ErrorMsg>
    {list.map((email, idx) => (
      <ErrorEmail key={`list-${idx}`}>{email}</ErrorEmail>
    ))}
  </ErrorArea>
);

const InviteMemberModal: React.FC<P> = React.memo(props => {
  const { existingContractUsers, invalidSendInvitationData, isOpen, onClose, sendInvitation } = props;
  const unmountRef = useUnmountRef();
  const [emailText, setEmailText] = useSafeState<Email>(unmountRef, EMPTY);
  const [inviting, setInviting] = useSafeState<boolean>(unmountRef, false);
  const [duplicatedEmails, setDuplicatedEmails] = useSafeState<Email[]>(unmountRef, []);
  const [existingEmails, setExistingEmails] = useSafeState<Email[]>(unmountRef, []);
  const [invalidEmails, setInvalidEmails] = useSafeState<Email[]>(unmountRef, []);

  const isInviteButtonDisabled = useMemo<boolean>(
    () => !emailText || hasLength(duplicatedEmails.concat(existingEmails).concat(invalidEmails)),
    [duplicatedEmails, emailText, existingEmails, invalidEmails]
  );

  const isErrorForInvite = useMemo<boolean>(() => hasLength(invalidSendInvitationData), [invalidSendInvitationData]);

  const handleEmailChanged = useSafeCallback(
    (email: Email): void => {
      setEmailText(email);
      setDuplicatedEmails([]);
      setExistingEmails([]);
      setInvalidEmails([]);
    },
    [setDuplicatedEmails, setEmailText, setExistingEmails, setInvalidEmails]
  );

  const handleInvite = useSafeCallback(async (): Promise<void> => {
    setInviting(true);
    const emails = emailText.split(BREAK).filter(email => !!email);
    const duplicatedEmails: Email[] = [];
    const existingEmails: Email[] = [];
    const invalidEmails: Email[] = [];
    emails.forEach((email, index) => {
      if (!isEmail(email)) {
        invalidEmails.push(email);
      }
      if (emails.indexOf(email) !== index) {
        duplicatedEmails.push(email);
      }
      if (
        existingContractUsers
          .filter(user => user.invitationStatus !== InvitationStatus.DELETED)
          .some(user => user.invitationEmail === email)
      ) {
        existingEmails.push(email);
      }
    });

    if (hasLength(duplicatedEmails.concat(existingEmails).concat(invalidEmails))) {
      setDuplicatedEmails(duplicatedEmails);
      setExistingEmails(existingEmails);
      setInvalidEmails(invalidEmails);
      setInviting(false);
      return;
    }

    await sendInvitation(emails);
    setEmailText(EMPTY);
    setInviting(false);
  }, [
    emailText,
    existingContractUsers,
    sendInvitation,
    setDuplicatedEmails,
    setEmailText,
    setExistingEmails,
    setInvalidEmails,
    setInviting
  ]);

  const footerButtons = useMemo<React.ReactNode[]>(() => {
    const buttons: React.ReactNode[] = [];
    buttons.push(
      <ButtonV2
        key='cancelButton'
        size='medium'
        type='default'
        label={isErrorForInvite ? '閉じる' : 'キャンセル'}
        onClick={onClose}
      />
    );
    if (!isErrorForInvite) {
      buttons.push(
        <ButtonV2
          disabled={isInviteButtonDisabled}
          key='inviteButton'
          size='medium'
          type='primary'
          label='招待'
          onClick={handleInvite}
        />
      );
    }
    return buttons;
  }, [handleInvite, isErrorForInvite, isInviteButtonDisabled, onClose]);

  return (
    <DialogV2
      width={600}
      isOpen={isOpen}
      headerLabel={isErrorForInvite ? '招待に失敗したメールアドレスがあります' : 'メンバーを追加'}
      buttonsOnTheRight={footerButtons}
      onClose={onClose}
    >
      <FormWrapper>
        {hasLength(invalidSendInvitationData) && (
          <BodyWrapper>
            {invalidSendInvitationData.map(data => createErrorArea(data.errorMessage, data.invalidEmails))}
          </BodyWrapper>
        )}
        {!hasLength(invalidSendInvitationData) && (
          <BodyWrapper>
            <Description>
              招待したいメンバーのメールアドレスを入力してください。複数のメンバーをまとめて招待する場合は、メールアドレスを1行ごとに改行して入力してください。
            </Description>
            <TextFieldV2 multiline onChange={handleEmailChanged} placeholder='メールアドレスを入力' value={emailText} />
          </BodyWrapper>
        )}
        {hasLength(existingEmails) && createErrorArea('既に招待済みのメールアドレスが含まれています', existingEmails)}
        {hasLength(invalidEmails) && createErrorArea('メールアドレスが不正です', invalidEmails)}
        {hasLength(duplicatedEmails) && createErrorArea('メールアドレスが重複しています', duplicatedEmails)}
      </FormWrapper>
      <ScreenLoaderV2 loading={inviting} />
    </DialogV2>
  );
});

InviteMemberModal.displayName = 'InviteMemberModal';
export default InviteMemberModal;

const BodyWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: ${themeV2.mixins.v2.spacing * 2}px;
  margin-bottom: ${themeV2.mixins.v2.spacing}px;
`;
const Description = styled.div`
  ${themeV2.mixins.v2.typography.body.medium}
`;
const FormWrapper = styled.div`
  padding: ${themeV2.mixins.v2.spacing * 2}px ${themeV2.mixins.v2.spacing * 3}px;
  background-color: #faf9f7;
  display: flex;
  flex-direction: column;
  gap: ${themeV2.mixins.v2.spacing}px;
`;
const ErrorArea = styled.div`
  display: flex;
  flex-direction: column;
`;
const ErrorMsg = styled.div`
  display: flex;
  ${themeV2.mixins.v2.typography.body.small};
  font-weight: 700;
  gap: ${themeV2.mixins.v2.spacing / 2}px;
  color: ${themeV2.mixins.v2.color.font.red};
`;
const ErrorEmail = styled.div`
  ${themeV2.mixins.v2.typography.body.small};
  padding: 0 ${themeV2.mixins.v2.spacing * 2}px;
`;

const WarningRoundedIconWrapper = styled.div`
  font-size: 16px;
  height: 16px;
  line-height: 12px;
`;
