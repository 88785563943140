import { SearchBox, themeV2, useSafeCallback } from '@atomica.co/components';
import { EMPTY, noop } from '@atomica.co/utils';
import { Dialog } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Typography from '@material-ui/core/Typography';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import Search from '@material-ui/icons/Search';
import TreeItem from '@material-ui/lab/TreeItem';
import TreeView from '@material-ui/lab/TreeView';
import React from 'react';
import styled from 'styled-components';

interface P {
  isOpen: boolean;
  onClose(): void;
}

const WishScopeModal: React.FC<P> = React.memo(props => {
  const { isOpen, onClose } = props;

  const handleModalClosed = useSafeCallback((): void => {
    onClose();
  }, [onClose]);

  return (
    <Dialog open={isOpen} onClose={handleModalClosed} maxWidth='xs' fullWidth>
      <DialogHolder>
        <TopRow>
          <DropdownHeading>1件の拠点を選択中</DropdownHeading>
          <SearchHolder>
            <SearchBox placeholder='拠点名で検索' text={EMPTY} onChange={noop} />
            <Search color='inherit' style={{ position: 'absolute', left: 28 }} />
          </SearchHolder>
        </TopRow>
        <TreeView
          defaultCollapseIcon={<ExpandLess />}
          defaultExpandIcon={<ExpandMore />}
          multiSelect
          style={{ padding: 16, overflowY: 'scroll' }}
        >
          <TreeViewItem
            nodeId='1'
            label={<FormControlLabel label={<Typography>東北地方 l</Typography>} control={<Checkbox />} />}
          >
            <TreeViewItem
              nodeId='2'
              label={<FormControlLabel label={<Typography>岩手県</Typography>} control={<Checkbox />} />}
            />
            <TreeViewItem
              nodeId='3'
              label={<FormControlLabel label={<Typography>宮城県</Typography>} control={<Checkbox />} />}
            >
              <TreeViewItem
                nodeId='4'
                label={<FormControlLabel label={<Typography>仙台拠点</Typography>} control={<Checkbox />} />}
              >
                <TreeItem
                  nodeId='5'
                  label={<FormControlLabel label={<Typography>仙台拠点</Typography>} control={<Checkbox />} />}
                />
              </TreeViewItem>
            </TreeViewItem>
          </TreeViewItem>
          <TreeViewItem
            nodeId='6'
            label={<FormControlLabel label={<Typography>関東地方</Typography>} control={<Checkbox />} />}
          >
            <TreeViewItem
              nodeId='7'
              label={<FormControlLabel label={<Typography>仙台拠点</Typography>} control={<Checkbox />} />}
            />
            <TreeViewItem
              nodeId='8'
              label={<FormControlLabel label={<Typography>ATOMica</Typography>} control={<Checkbox />} />}
            >
              <TreeViewItem
                nodeId='9'
                label={<FormControlLabel label={<Typography>宮城県</Typography>} control={<Checkbox />} />}
              />
            </TreeViewItem>
          </TreeViewItem>
          <TreeViewItem
            nodeId='10'
            label={<FormControlLabel label={<Typography>中国地方</Typography>} control={<Checkbox />} />}
          >
            <TreeViewItem
              nodeId='11'
              label={<FormControlLabel label={<Typography>宮崎県</Typography>} control={<Checkbox />} />}
            />
          </TreeViewItem>
          <TreeViewItem
            nodeId='12'
            label={<FormControlLabel label={<Typography>九州地方</Typography>} control={<Checkbox />} />}
          >
            <TreeViewItem
              nodeId='13'
              label={<FormControlLabel label={<Typography>宮崎県</Typography>} control={<Checkbox />} />}
            />
          </TreeViewItem>
        </TreeView>
        <ButtonHolder>
          <ModalButton
            onClick={handleModalClosed}
            variant='text'
            style={{
              color: themeV2.mixins.v2.color.font.black
            }}
          >
            キャンセル
          </ModalButton>
          <ModalButton
            variant='contained'
            color='primary'
            disableElevation
            style={{
              color: themeV2.mixins.v2.color.font.white
            }}
          >
            選択 (1)
          </ModalButton>
        </ButtonHolder>
      </DialogHolder>
    </Dialog>
  );
});

WishScopeModal.displayName = 'WishScopeModal'
export default WishScopeModal;

const DropdownHeading = styled.p`
  ${themeV2.mixins.v2.typography.body.medium};
  margin: 0px;
  padding: 16px;
  color: ${themeV2.mixins.v2.color.font.black};
`;

const TreeViewItem = styled(TreeItem)`
  & label {
    margin-bottom: 0px;
  }
`;
const DialogHolder = styled.div`
  background-color: ${themeV2.mixins.v2.color.font.white};
  max-height: 470px;
  position: relative;
`;

const TopRow = styled.div`
  z-index: 999;
  top: 0px;
  position: sticky;
  background-color: ${themeV2.mixins.v2.color.font.white};
`;

const SearchHolder = styled.div`
  ${themeV2.mixins.v2.typography.body.medium};
  display: flex;
  align-items: center;
  position: relative;
  color: ${themeV2.mixins.v2.color.font.black};
  width: 100%;
  padding: 0px 16px;
`;

const ButtonHolder = styled.div`
  display: flex;
  gap: 16px;
  justify-content: flex-end;
  align-items: center;
  padding: 16px;
  position: sticky;
  bottom: 0px;
  z-index: 99;
  width: 100%;
  border-top: 1px solid ${themeV2.mixins.v2.color.font.lightGray};
  background-color: ${themeV2.mixins.v2.color.font.white};
`;

const ModalButton = styled(Button)`
  ${themeV2.mixins.v2.typography.title.small};
`;
