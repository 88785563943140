import { BaseDto, Shot, ShotCategory, ShotId, ShotSubcategory, User } from '@atomica.co/irori';
import { Amount, Code, Count, Name, NoStr, Option, PersonInCharge, Remarks } from '@atomica.co/types';
import { EMPTY, ZERO, parseFloat, parseInt } from '@atomica.co/utils';
import { UsageOption } from '../constants/base-const';
import { Hour } from '../enums/common-enum';
import { CoBaTakamatsuShotPaymentMethod, ShotPaymentMethod } from '../enums/shot-enum';
import { QueryParams } from '../models/path-model';
import ShotPriceService from '../services/shot-price-service';
import ShotQuantityService from '../services/shot-quantity-service';
import { hasUnitPrice } from '../utils/item-util';
import { isQuantityManual, toConferenceUsageType, toUsageOption, toUsageType } from '../utils/shot-category-util';
import { toFullName } from '../utils/user-util';

export const toShot = (
  shotId: ShotId,
  baseDto: BaseDto,
  user: User | undefined,
  category: ShotCategory | undefined,
  subcategory: ShotSubcategory | undefined,
  conferenceUsageHours?: Hour,
  conferenceNumberOfPeople?: Count,
  conferenceOneDayUsage?: boolean,
  conferenceOneDayNumberOfPeople?: Count,
  receiptName?: Name,
  cardNo?: NoStr,
  totalPrice?: Amount,
  paymentMethod?: ShotPaymentMethod,
  personInCharge?: PersonInCharge,
  remarks?: Remarks
): Shot => {
  const usageType = toUsageType(category);
  const usageOption = toUsageOption(category, subcategory);
  const conferenceUsageType = toConferenceUsageType(category, subcategory);
  const hasCost = !!subcategory && hasUnitPrice(subcategory.item);

  const taxExcludedBasePrice = ShotPriceService.calculateTaxExcludedBasePrice(category, subcategory);
  const taxIncludedBasePrice = ShotPriceService.calculateTaxIncludedBasePrice(category, subcategory);
  const baseQuantity = ShotQuantityService.calculateBaseQuantity(category, subcategory, conferenceUsageHours);

  const taxExcludedOptionPrice = ShotPriceService.calculateTaxExcludedOptionPrice(
    baseDto,
    category,
    subcategory,
    conferenceOneDayUsage
  );
  const taxIncludedOptionPrice = ShotPriceService.calculateTaxIncludedOptionPrice(
    baseDto,
    category,
    subcategory,
    conferenceOneDayUsage
  );
  const optionQuantity = ShotQuantityService.calculateOptionQuantity(
    category,
    subcategory,
    conferenceOneDayUsage,
    conferenceOneDayNumberOfPeople
  );

  const taxExcludedTotalPrice = taxExcludedBasePrice * baseQuantity + taxExcludedOptionPrice * optionQuantity;
  const taxIncludedTotalPrice = taxIncludedBasePrice * baseQuantity + taxIncludedOptionPrice * optionQuantity;

  return {
    shotId,
    usageType: usageType?.item?.itemCode,
    usageItem: usageType?.item,
    baseDto,
    user,
    usageOption: usageOption?.item?.itemCode ?? EMPTY,
    usageOptionItem: usageOption?.item,
    conferenceUsageType: conferenceUsageType?.item?.itemCode ?? EMPTY,
    conferenceUsageItem: conferenceUsageType?.item,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    conferenceUsageHours: isQuantityManual(category) && hasCost ? parseFloat(conferenceUsageHours as any) : ZERO,
    conferenceNumberOfPeople: isQuantityManual(category) && hasCost ? conferenceNumberOfPeople : ZERO,
    conferenceOneDayUsage,
    conferenceOneDayNumberOfPeople: conferenceOneDayUsage === true ? conferenceOneDayNumberOfPeople : ZERO,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    taxIncludedUnitPrice: parseInt(taxIncludedBasePrice as any),
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    taxExcludedUnitPrice: parseInt(taxExcludedBasePrice as any),
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    unitQuantity: parseFloat(baseQuantity as any),
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    taxIncludedOptionPrice: parseInt(taxIncludedOptionPrice as any),
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    taxExcludedOptionPrice: parseInt(taxExcludedOptionPrice as any),
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    optionQuantity: parseInt(optionQuantity as any),
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    taxIncludedTotalPrice: parseInt(taxIncludedTotalPrice as any),
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    taxExcludedTotalPrice: parseInt(taxExcludedTotalPrice as any),
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    totalPrice: totalPrice ? parseInt(totalPrice as any) : parseInt(taxIncludedTotalPrice as any),
    paymentMethod,
    receiptName,
    cardNo,
    personInCharge,
    remarks
  } as Shot;
};

export const fromQueryParams = (
  queryParams: QueryParams,
  category: ShotCategory | undefined,
  subcategory: ShotSubcategory | undefined,
  baseDto: BaseDto,
  staff: User,
  visitor: User
): Shot => {
  return {
    shotId: queryParams.shotId,
    usageType: queryParams.usageType,
    usageItem: category?.item,
    baseDto,
    user: visitor,
    usageOption: queryParams.usageOption ? queryParams.usageOption : (EMPTY as UsageOption),
    usageOptionItem: queryParams.usageOption ? subcategory?.item : undefined,
    conferenceUsageType: queryParams.conferenceUsageType ? queryParams.conferenceUsageType : EMPTY,
    conferenceUsageItem: queryParams.conferenceUsageType ? subcategory?.item : undefined,
    conferenceUsageHours: queryParams.conferenceUsageHours,
    conferenceNumberOfPeople: parseInt(queryParams.conferenceNumberOfPeople as string),
    conferenceOneDayUsage: queryParams.conferenceOneDayUsage === 'true',
    conferenceOneDayNumberOfPeople: parseInt(queryParams.conferenceOneDayNumberOfPeople as string),
    taxIncludedUnitPrice: parseInt(queryParams.taxIncludedUnitPrice as string),
    taxExcludedUnitPrice: parseInt(queryParams.taxExcludedUnitPrice as string),
    unitQuantity: parseFloat(queryParams.unitQuantity as string),
    taxIncludedOptionPrice: parseInt(queryParams.taxIncludedOptionPrice as string),
    taxExcludedOptionPrice: parseInt(queryParams.taxExcludedOptionPrice as string),
    optionQuantity: parseInt(queryParams.optionQuantity as string),
    taxIncludedTotalPrice: parseInt(queryParams.taxIncludedTotalPrice as string),
    taxExcludedTotalPrice: parseInt(queryParams.taxExcludedTotalPrice as string),
    totalPrice: parseInt(queryParams.totalPrice as string),
    paymentMethod: ShotPaymentMethod.CASH,
    receiptName: queryParams.receiptName ? decodeURIComponent(queryParams.receiptName) : EMPTY,
    cardNo: queryParams.cardNo ? queryParams.cardNo : EMPTY,
    personInCharge: toFullName(staff),
    remarks: queryParams.remarks ? queryParams.remarks : EMPTY
  } as Shot;
};

/** @deprecated */
export const toShotPaymentMethodOptions = (baseCode: Code): Option[] => {
  switch (baseCode) {
    case 'takamatsu':
      return Object.values(CoBaTakamatsuShotPaymentMethod);
    default:
      return Object.values(ShotPaymentMethod);
  }
};
