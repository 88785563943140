import { ItemV2, PriceUtils, TaxDiv } from '@atomica.co/irori';
import { Amount, Count, Label, Price, Rate } from '@atomica.co/types';
import { EMPTY, ZERO, toTaxExcluded, toTaxIncluded } from '@atomica.co/utils';
import { TAX_DIV_LABELS } from '../texts/tax-text';

const TAX_EXCLUDED_SUFFIX_LABEL = '料金(税抜)';

const TAX_INCLUDED_SUFFIX_LABEL = '料金(税込)';

interface DiscplayTax {
  label: Label;
  taxRate: Rate;
  taxDiv: TaxDiv;
}

export interface TotalPriceDetail {
  taxExcludedTotalPrice: Price;
  taxIncludedTotalPrice: Price;
  taxPrice: Price;
}

export interface TaxForScreen {
  [taxId: string]: DiscplayTax;
}

export default class ItemService {
  public static getTotalPricesAndTaxPrice = (item: ItemV2 | undefined, count: Count): TotalPriceDetail => {
    if (!item) return { taxExcludedTotalPrice: ZERO, taxIncludedTotalPrice: ZERO, taxPrice: ZERO };
    const taxExcludedTotalPrice = this.calcTaxExcludedPrice(item, count);
    const taxIncludedTotalPrice = this.calcTaxIncludedPrice(item, count);
    const taxPrice = taxIncludedTotalPrice - taxExcludedTotalPrice;
    return { taxExcludedTotalPrice, taxIncludedTotalPrice, taxPrice };
  };

  public static calcTaxExcludedPrice = (item: ItemV2 | undefined, count: Count = 1): Amount => {
    if (!item) return ZERO;
    const { unitPrice, taxDiv, tax } = item;
    const taxRate = tax?.taxRate ?? ZERO;
    const price = PriceUtils.applyAmountPrice(unitPrice, count);
    return taxDiv === TaxDiv.INCLUDED ? toTaxExcluded(price, taxRate) : price;
  };

  public static getTaxExcludedPriceLabel = (item: ItemV2 | undefined): Label => {
    if (!item || !item.unitPrice) return EMPTY;
    return item.itemName ? `${item.itemName}${TAX_EXCLUDED_SUFFIX_LABEL}` : EMPTY;
  };

  public static calcTaxIncludedPrice = (item: ItemV2 | undefined, count: Count = 1): Amount => {
    if (!item) return ZERO;
    const { unitPrice, taxDiv, tax } = item;
    const taxRate = tax?.taxRate ?? ZERO;
    const price = PriceUtils.applyAmountPrice(unitPrice, count);
    return taxDiv === TaxDiv.EXCLUDED ? toTaxIncluded(price, taxRate) : price;
  };

  public static getTaxIncludedPriceLabel = (item: ItemV2 | undefined): Label => {
    if (!item || !item.unitPrice) return EMPTY;
    return item.itemName ? `${item.itemName}${TAX_INCLUDED_SUFFIX_LABEL}` : EMPTY;
  };

  public static getTaxLabel = (item: ItemV2 | undefined): Label => {
    if (!item) return EMPTY;
    return item.taxDiv === TaxDiv.EXEMPT
      ? TAX_DIV_LABELS[item.taxDiv]
      : `${TAX_DIV_LABELS[item.taxDiv]} ${item.tax!.taxRate}%`;
  };
}
