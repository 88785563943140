import {
  BASE_CODE,
  BILLING_ID_V2,
  CONTRACT_ID_V2,
  EVENT_ID,
  EVENT_SCHEDULE_ID_V2,
  QUESTIONNAIRE_ID,
  SPACE_RESERVATION_ID,
  WISH_ID
} from '@atomica.co/irori';
import { Id, USER_ID } from '@atomica.co/types';
import { SPACE_RESERVATION_OPERATION } from '../constants/space-const';

export const PATH_IDS: Id[] = [
  BASE_CODE,
  USER_ID,
  EVENT_ID,
  EVENT_SCHEDULE_ID_V2,
  CONTRACT_ID_V2,
  BILLING_ID_V2,
  WISH_ID,
  SPACE_RESERVATION_ID,
  QUESTIONNAIRE_ID,
  SPACE_RESERVATION_OPERATION,
  'resourceId',
  'userWishId'
];

export enum Path {
  /** sign-up */
  SIGN_UP = '/base/:baseCode/signup',

  /** sign-in */
  SIGN_IN = '/base/:baseCode/signin',

  /** sign-out */
  SIGN_OUT = '/base/:baseCode/signout',

  /** join */
  JOIN = '/base/:baseCode/join',

  /** redirect */
  REDIRECT = '/redirect',

  /** liff */
  LIFF = '/liff',

  /** account */
  REGISTER_ACCOUNT = '/base/:baseCode/account/new',
  REGISTER_ACCOUNT_V2 = '/base/:baseCode/account-v2/new',
  REGISTER_ACCOUNT_WISH = '/base/:baseCode/account/wish/new',
  EDIT_ACCOUNT_WISH = '/base/:baseCode/account/wish/edit/:userWishId',
  ACCOUNT_HOME = '/base/:baseCode/account',
  ACCOUNT_SHOT = '/base/:baseCode/account/shot',
  ACCOUNT_SHARE = '/base/:baseCode/account/share',
  ACCOUNT_RESERVATION_DETAIL = '/base/:baseCode/account/reservation/:spaceReservationId/detail',
  ACCOUNT_PROFILE = '/base/:baseCode/account/profile',
  ACCOUNT_PROFILE_V2 = '/base/:baseCode/account/profile-v2',
  ACCOUNT_MY_SCHEDULES = '/base/:baseCode/account/my-schedules',
  ACCOUNT_EVENTS = '/base/:baseCode/account/events',
  ACCOUNT_EVENTS_NO_GUARDED = '/base/:baseCode/account/events/published',
  ACCOUNT_EVENT_DEATIL = '/base/:baseCode/account/event/:eventScheduleId/detail',
  ACCOUNT_EVENT_ENTRY_FORM = '/base/:baseCode/account/event/:eventScheduleId/entry-form',
  ACCOUNT_EVENT_PARTICIPANTS = '/base/:baseCode/account/event/:eventScheduleId/participants',
  ACCOUNT_POINT_USAGE_LOGS = '/base/:baseCode/account/point-usage-logs',
  ACCOUNT_WISH_LIST = '/base/:baseCode/account/:userId/wishes',
  ACCOUNT_SETTING = '/base/:baseCode/account/setting',

  /** verification */
  STEP_VERIFICATION = '/base/:baseCode/step-verifications',

  /** member */
  MEMBERS = '/base/:baseCode/members',
  MEMBER_PROFILE = '/base/:baseCode/member/:userId/profile',

  /** shot */
  SHOT_RECEPTION = '/base/:baseCode/shot/reception',

  /** space */
  RESERVE_SPACE = '/base/:baseCode/space/reserve',
  RESERVE_SPACE_CONFIRM = '/base/:baseCode/space/reserve/confirm',
  RESERVE_SPACE_EDIT = '/base/:baseCode/space/reserve/edit/:spaceReservationId',
  RESERVE_SPACE_EXTENSION = '/base/:baseCode/space/reserve/extension/:spaceReservationId',
  RESERVE_SPACE_NO_GUARDED = '/base/:baseCode/space/reserve/published',

  RESERVE_SPACE_AUTO_CONFIRM = '/base/:baseCode/space/reserve/auto/confirm',

  RESERVE_SPACE_COMPLETE = '/base/:baseCode/space/reserve/complete/:spaceReservationOperation',

  DROP_IN = '/base/:baseCode/drop-in',
  DROP_IN_CONFIRM = '/base/:baseCode/drop-in/confirm',

  /** contract */
  REGISTER_CONTRACT_MEMBER = '/base/:baseCode/contract/:contractId/new',
  REGISTERED = '/base/:baseCode/registered',

  /** access */
  RECORD_ACCESS = '/base/:baseCode/access/new',

  /** console */
  TIMELINE = '/base/:baseCode',
  VISITORS = '/base/:baseCode/current',
  USER_SEARCH = '/base/:baseCode/users/search',
  USER_BULK_UPDATE = '/base/:baseCode/user/:userId/detail',
  USER_OVERVIEW = '/base/:baseCode/user/:userId/overview',
  USER_ROOM_ENTRY_EXIT_HISTORY = '/base/:baseCode/user/:userId/room-entry-exit-history',
  USER_WISH = '/base/:baseCode/user/:userId/wish',
  USER_DIALOGUE_LOG = '/base/:baseCode/user/:userId/dialogue-log',
  USER_EVENT_LOG = '/base/:baseCode/user/:userId/event-log',
  USER_SPACE_USAGE_LOG = '/base/:baseCode/user/:userId/space-usage-log',
  USER_CHECK_IN_HISTORY = '/base/:baseCode/user/:userId/check-in-history',
  USER_POINT_USAGE_LOG = '/base/:baseCode/user/:userId/point-usage-log',
  REGISTER_CONTRACT = '/base/:baseCode/contract/new',
  CONTRACT_LIST = '/base/:baseCode/contracts',
  CONTRACT_DETAILS = '/base/:baseCode/contract/:contractId',
  REGISTER_CONTRACT_V2 = '/base/:baseCode/contract-v2/new',
  EDIT_CONTRACT_V2 = '/base/:baseCode/contract-v2/edit/:contractId',
  CONTRACT_LIST_V2 = '/base/:baseCode/contracts-v2',
  CONTRACT_DETAILS_V2_DETAIL = '/base/:baseCode/contract-v2/:contractId/detail',
  CONTRACT_DETAILS_V2_BILLING = '/base/:baseCode/contract-v2/:contractId/billing',
  CONTRACT_DETAILS_V2_MEMBER = '/base/:baseCode/contract-v2/:contractId/member',
  CONTRACT_DETAILS_V2_LOGS = '/base/:baseCode/contract-v2/:contractId/logs',
  CONTRACT_MEMBER_REGISTERED_V2 = '/base/:baseCode/contract-v2/:contractId/member/new',
  REGISTER_BILLING_V2 = '/base/:baseCode/billing-v2/new',
  EDIT_BILLING_V2 = '/base/:baseCode/billing-v2/edit/:billingId',
  BILLING_LIST_V2 = '/base/:baseCode/billings-v2',
  BILLING_DETAILS_V2_DETAIL = '/base/:baseCode/billing-v2/:billingId/detail',
  BILLING_DETAILS_V2_SPACE_USAGES = '/base/:baseCode/billing-v2/:billingId/space-usages',
  BILLING_DETAILS_V2_ACCESSES = '/base/:baseCode/billing-v2/:billingId/accesses',
  BILLING_DETAILS_V2_LOGS = '/base/:baseCode/billing-v2/:billingId/logs',
  EVENT_V2_LIST = '/base/:baseCode/events-v2',
  REGISTER_EVENT_V2 = '/base/:baseCode/event-v2/new',
  EDIT_EVENT_V2 = '/base/:baseCode/event-v2/edit/:eventId',
  EVENT_V2_DETAILS = '/base/:baseCode/events-v2/:eventId',
  EDIT_EVENT_V2_DETAILS = '/base/:baseCode/events-v2/edit/:eventId',
  REGISTER_EVENT_SCHEDULE_V2 = '/base/:baseCode/events-v2/:eventId/event-schedule-v2/new',
  EDIT_EVENT_SCHEDULE_V2 = '/base/:baseCode/events-v2/:eventId/event-schedule-v2/edit/:eventScheduleId',
  EVENT_SCHEDULE_V2_DETAILS = '/base/:baseCode/events-v2/:eventId/event-schedule-v2/:eventScheduleId/summary',
  EVENT_SCHEDULE_PARTICIPANTS_V2 = '/base/:baseCode/events-v2/:eventId/event-schedule-v2/:eventScheduleId/participants',
  EVENT_SCHEDULE_GROUPS_V2 = '/base/:baseCode/events-v2/:eventId/event-schedule-v2/:eventScheduleId/groups',
  ENTRY_FORM_MESSAGES = '/base/:baseCode/events-v2/:eventId/event-schedule-v2/:eventScheduleId/entry-form-messages',
  QUESTIONNAIRE_LIST = '/base/:baseCode/questionnaires',
  REGISTER_QUESTIONNAIRE = '/base/:baseCode/questionnaire/new',
  EDIT_QUESTIONNAIRE = '/base/:baseCode/questionnaire/edit/:questionnaireId',
  QUESTIONNAIRE_DETAILS = '/base/:baseCode/questionnaire/:questionnaireId/detail',
  QUESTIONNAIRE_ANSWER = '/base/:baseCode/questionnaire/:questionnaireId/answer',
  RESERVATION_LIST = '/base/:baseCode/reservations',
  RESERVATION_DETAIL = '/base/:baseCode/reservation/:spaceReservationId/detail',

  REMIND_MESSAGES = '/base/:baseCode/events-v2/:eventId/event-schedule-v2/:eventScheduleId/remind-messages',
  DIARY = '/base/:baseCode/diary',
  EXPORT = '/base/:baseCode/export',
  USER_SEARCH_V2 = '/base/:baseCode/users-v2/search',

  WISH_LIST = '/base/:baseCode/wishes',
  REGISTER_WISH = '/base/:baseCode/wishes/new',
  EDIT_WISH = '/base/:baseCode/wishes/edit/:wishId',
  WISH_DETAILS = '/base/:baseCode/wishes/:wishId/detail',

  COMPLETED_REGISTER_CREDIT_CARD = '/base/:baseCode/credit-card/complete',
  RESEND_REGISTER_CREDIT_CARD = '/base/:baseCode/credit-card/resend',

  /** master settings */
  SHOT_MASTER = '/base/:baseCode/master/shot',
  RESOURCE_MASTER = '/base/:baseCode/master/resource',
  EDIT_RESOURCE_MASTER = '/base/:baseCode/master/resource/edit/:resourceId'

  /** @deprecated */
  // SETTING = '/setting'
}
