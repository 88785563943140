import {
  CircularLoader,
  Component,
  PageHeaderV2,
  SearchBoxV2,
  themeV2,
  useMobile,
  useSafeCallback,
  useSafeState,
  useUnmountRef
} from '@atomica.co/components';
import {
  AuthorityDefCodeEnum,
  BaseDto,
  BaseFunctionToggleCode,
  isBaseFunctionToggleEnabled,
  SEARCH_USER_OVERVIEWS,
  SearchedUserOverview,
  SearchUserOverviewsRequest,
  SearchUserOverviewsResponse
} from '@atomica.co/irori';
import { Index, UserId, Word } from '@atomica.co/types';
import { builder, embedIdInPath, EMPTY } from '@atomica.co/utils';
import React, { useEffect } from 'react';
import styled from 'styled-components';
import useCommonRequest from '../../redux/hooks/useCommonRequest';
import usePath from '../../redux/hooks/usePath';
import { Path, PATH_IDS } from '../../router/Routes';
import mojaco from './../../assets/mojaco/mojaco_break.png';

interface P {
  base: BaseDto;
}

const renderMemberCard = (
  { userId, familyName, firstName, photoURL, roles }: SearchedUserOverview,
  index: Index,
  onClick: (userId: UserId) => void,
  isMobile: boolean
): JSX.Element => {
  return (
    <MemberCard data-testid='member-card' key={`card-${index}`} onClick={() => onClick(userId)} isMobile={isMobile}>
      <ImageWrapper>
        <Image src={photoURL || mojaco} />
      </ImageWrapper>
      <TextWrapper>
        {roles.some(e => e.code === AuthorityDefCodeEnum.COMMUNITY_MANAGER) && <Role>コミュニティマネージャー</Role>}
        <Name>
          {familyName} {firstName}
        </Name>
      </TextWrapper>
    </MemberCard>
  );
};

const MemberListScreen: React.FC<P> = React.memo(props => {
  const { base } = props;
  const unmountRef = useUnmountRef();
  const { openPath, openBasePath } = usePath();
  const { commonRequest } = useCommonRequest();
  const [isUseMembersEnabled, setIsUseMembersEnabled] = useSafeState<boolean>(unmountRef, false);
  const [isLoaderShown, setIsLoaderShown] = useSafeState<boolean>(unmountRef, true);
  const [members, setMembers] = useSafeState<SearchedUserOverview[]>(unmountRef, []);
  const [searchingWord, setSearchingWord] = useSafeState<Word>(unmountRef, EMPTY);

  const isMobile = useMobile();
  const searchUsers = useSafeCallback(async (): Promise<void> => {
    setIsLoaderShown(true);
    const request = builder<SearchUserOverviewsRequest>().baseId(base.baseId).word(searchingWord).build();
    const response = await commonRequest<SearchUserOverviewsRequest, SearchUserOverviewsResponse>(
      SEARCH_USER_OVERVIEWS,
      request
    );
    setMembers(response.userOverviews);
    setIsLoaderShown(false);
  }, [base.baseId, commonRequest, searchingWord, setIsLoaderShown, setMembers]);

  const openMemberProfileScreen = useSafeCallback(
    (userId: UserId) => {
      openPath(embedIdInPath(Path.MEMBER_PROFILE, PATH_IDS, [base.baseCode, userId]));
    },
    [base.baseCode, openPath]
  );

  useEffect(() => {
    searchUsers();
  }, [searchUsers]);

  useEffect(() => {
    if (isBaseFunctionToggleEnabled(base, BaseFunctionToggleCode.FUNCTION_USE_MEMBERS)) {
      setIsUseMembersEnabled(true);
      return;
    }
    openBasePath(Path.ACCOUNT_HOME);
  }, [base, openBasePath, setIsUseMembersEnabled]);

  return (
    <Component style={{ width: '100%', height: '100%' }} className='member-list-screen' loading={!isUseMembersEnabled}>
      <Container data-testid='member-list-screen'>
        <PageHeaderV2 title='メンバー' titleSize={isMobile ? 'small' : 'default'} />

        <SearchBoxV2 placeholder='名前で検索' word={searchingWord} onChange={setSearchingWord} />

        {isLoaderShown && (
          <CircularLoaderWrapper>
            <CircularLoader />
          </CircularLoaderWrapper>
        )}
        {!isLoaderShown && (
          <MemberCardWrapper data-testid='community-managers'>
            {members
              .filter(member => member.roles.some(e => e.code === AuthorityDefCodeEnum.COMMUNITY_MANAGER))
              .map((member, index) => renderMemberCard(member, index, openMemberProfileScreen, isMobile))}
          </MemberCardWrapper>
        )}
        {!isLoaderShown && (
          <MemberCardWrapper data-testid='visitors'>
            {members
              .filter(member => !member.roles.some(e => e.code === AuthorityDefCodeEnum.COMMUNITY_MANAGER))
              .map((member, index) => renderMemberCard(member, index, openMemberProfileScreen, isMobile))}
          </MemberCardWrapper>
        )}
      </Container>
    </Component>
  );
});
MemberListScreen.displayName = 'MemberListScreen';
export default MemberListScreen;

const Container = styled.div`
  width: 100%;
  max-width: 968px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  gap: ${themeV2.mixins.v2.spacing * 3}px;
  padding: ${themeV2.mixins.v2.spacing * 2}px ${themeV2.mixins.v2.spacing * 3}px;
`;

const MemberCardWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: flex-start;
  gap: 9px;
  align-self: stretch;
  flex-wrap: wrap;
`;

const CircularLoaderWrapper = styled.div`
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const MemberCard = styled.div<{ isMobile: boolean }>`
  display: flex;
  min-height: ${themeV2.mixins.v2.spacing * 26}px;
  width: ${({ isMobile }) => (isMobile ? 'calc(50% -  5px)' : `${themeV2.mixins.v2.spacing * 19}px`)};
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: ${themeV2.mixins.v2.spacing * 2}px;
  border-radius: 12px;
  background: ${themeV2.mixins.v2.color.background.white};
  padding: ${themeV2.mixins.v2.spacing * 2}px;

  &:hover {
    cursor: pointer;
  }
`;

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${themeV2.mixins.v2.spacing}px;
`;

const Name = styled.div`
  color: ${themeV2.mixins.v2.color.font.black};
  ${themeV2.mixins.v2.typography.label.large};
  overflow-wrap: break-word;
  word-break: break-all;
  text-align: center;
`;

const Role = styled.div`
  color: ${themeV2.mixins.v2.color.font.gray};
  ${themeV2.mixins.v2.typography.label.small};
  font-size: 12px;
  white-space: nowrap;
`;

const ImageWrapper = styled.div`
  width: 120px;
  height: 120px;
  text-align: center;
`;

const Image = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  border: 1px solid ${themeV2.mixins.v2.color.border.gray};
  border-radius: 60px;
`;
