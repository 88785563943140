import { ButtonV2, Component, themeV2, themeV3 } from '@atomica.co/components';
import { Email } from '@atomica.co/types';
import { HYPHEN } from '@atomica.co/utils';
import React from 'react';
import { RouteComponentProps } from 'react-router';
import styled from 'styled-components';

interface P extends RouteComponentProps {
  openHome: () => void;
  openSignOut: () => void;
  backToValidInvitation: () => void;
  invitationEmail?: Email;
  userEmail?: Email;
  isSigned: boolean;
}

const DifferntEmail: React.FC<P> = React.memo(props => {
  const {
    backToValidInvitation,
    openHome,
    openSignOut,
    invitationEmail = HYPHEN,
    userEmail = HYPHEN,
    isSigned
  } = props;

  return (
    <Component className='invalid-invitation'>
      <Card>
        <MessageSection>
          <MessageText>メールアドレスが一致しません</MessageText>
          <MessageSubText>
            別のアカウントを利用するか、招待メールアドレスに関してスタッフにお問い合わせください。
          </MessageSubText>
        </MessageSection>
        <EmailSection>
          招待メールアドレス
          <EmailText>{invitationEmail}</EmailText>
        </EmailSection>
        <EmailSection>
          あなたのメールアドレス
          <EmailText>{userEmail}</EmailText>
        </EmailSection>

        <LinkSection>
          {isSigned && (
            <>
              <ButtonV2 type='primary' isFullWidth label='ログアウト' onClick={openSignOut} />
              <ButtonV2 type='default' isFullWidth label='ホームに戻る' onClick={openHome} />
            </>
          )}
          {!isSigned && <ButtonV2 type='default' isFullWidth label='招待画面に戻る' onClick={backToValidInvitation} />}
        </LinkSection>
      </Card>
    </Component>
  );
});

DifferntEmail.displayName = 'DifferntEmail';
export default DifferntEmail;

const Card = styled.div`
  width: 100%;
  height: auto;
  background: ${themeV2.mixins.v2.color.background.white};
  border-radius: 16px;
  padding: ${themeV2.mixins.v2.spacing * 4}px ${themeV2.mixins.v2.spacing * 2}px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: ${themeV2.mixins.v2.spacing * 3}px;
`;

const MessageSection = styled.div`
  padding: 0 ${themeV2.mixins.v2.spacing * 3}px;
  text-align: center;
`;
const MessageText = styled.p`
  ${themeV2.mixins.v2.typography.label.large};
  white-space: pre-line;
`;
const MessageSubText = styled.p`
  ${themeV2.mixins.v2.typography.body.medium};
  white-space: pre-line;
`;
const EmailSection = styled.div`
  ${themeV2.mixins.v2.typography.body.small};
  width: 100%;
  text-align: center;
`;
const EmailText = styled.div`
  border-top: 1px solid ${themeV3.mixins.v3.color.border.gray};
  border-bottom: 1px solid ${themeV3.mixins.v3.color.border.gray};
  margin: ${themeV2.mixins.v2.spacing * 0.5}px ${themeV2.mixins.v2.spacing * 2}px;
  padding: ${themeV2.mixins.v2.spacing * 1.5}px;
  white-space: pre-wrap;
  word-break: break-all;
  color: ${themeV3.mixins.v3.color.object.error};
`;
const LinkSection = styled.div`
  width: 100%;
  padding: 0 ${themeV2.mixins.v2.spacing * 3}px;
  margin-top: ${themeV2.mixins.v2.spacing * 5}px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: ${themeV2.mixins.v2.spacing * 2}px;
  && {
    color: ${themeV2.mixins.v2.color.font.blue} !important;
  }
`;
