import { CheckBoxV3, Component, ErrorMessageV3, themeV3 } from '@atomica.co/components';
import { Message, Name } from '@atomica.co/types';
import { EMPTY_HALF_WIDTH } from '@atomica.co/utils';
import React from 'react';
import styled from 'styled-components';

export interface UserPolicyProps {
  ownerName: Name;
  checked: boolean;
  onChange: () => void;
  handleUseOfTermClicked?: () => void;
  handlePrivacyPolicyClicked?: () => void;
  errorMessage?: Message;
}

const cancelOveredLabelClicked = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
  e.preventDefault();
};

const UserPolicy: React.FC<UserPolicyProps> = React.memo(props => {
  const { ownerName, checked, onChange, handleUseOfTermClicked, handlePrivacyPolicyClicked, errorMessage } = props;
  const hasUseOfTerm = !!handleUseOfTermClicked;
  const hasPrivacyPolicy = !!handlePrivacyPolicyClicked;

  return (
    <Component style={{ maxWidth: '100%' }} className='user-policy'>
      <CheckBoxV3 status={checked ? 'checked' : 'unchecked'} onChange={onChange}>
        <Content>
          <span onClick={cancelOveredLabelClicked}>
            {ownerName}の{EMPTY_HALF_WIDTH}
          </span>
          {!!hasUseOfTerm && (
            <Link
              onClick={e => {
                e.stopPropagation();
                handleUseOfTermClicked();
              }}
            >
              ご利用規約
            </Link>
          )}
          {!!hasUseOfTerm && !!hasPrivacyPolicy && (
            <span onClick={cancelOveredLabelClicked}>
              {EMPTY_HALF_WIDTH}
              および
              {EMPTY_HALF_WIDTH}
            </span>
          )}
          {!!handlePrivacyPolicyClicked && (
            <Link
              onClick={e => {
                e.stopPropagation();
                handlePrivacyPolicyClicked();
              }}
            >
              プライバシーポリシー
            </Link>
          )}
          <span onClick={cancelOveredLabelClicked}>
            {EMPTY_HALF_WIDTH}
            に同意する
          </span>
        </Content>
      </CheckBoxV3>
      {errorMessage && (
        <Wrapper>
          <ErrorMessageV3 errorMessage={errorMessage} />
        </Wrapper>
      )}
    </Component>
  );
});

UserPolicy.displayName = 'UserPolicy';
export default UserPolicy;

const Content = styled.div`
  ${themeV3.mixins.v3.typography.label.large};
  color: ${themeV3.mixins.v3.color.object.gray};
  word-break: break-all;
  line-height: 150%;
`;
const Link = styled.div`
  display: inline;
  color: ${themeV3.mixins.v3.color.object.primary};
  cursor: pointer;
  text-decoration: underline;
  text-underline-offset: 2px;
`;
const Wrapper = styled.div`
  margin: ${themeV3.mixins.v3.spacing * 0.5}px 0 ${themeV3.mixins.v3.spacing * 1.5}px;
`;
