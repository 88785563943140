import { BillingDateColumn, BillingStatus, HonorificType, PaymentMethod } from '@atomica.co/irori';
import { BillingAction } from '../enums/billing-v2-enum';
import { Path } from '../router/Routes';

export const BILLING_NO_DIGITS = 5;

export const BILLING_DATE_OPTIONS = Object.values(BillingDateColumn);

export const BILLING_PAYMENT_OPTIONS = Object.values(PaymentMethod);

export const BILLING_STATUS_OPTIONS = Object.values(BillingStatus).filter(status => {
  const _: BillingStatus[] = [BillingStatus.PAYMENT_COMPLETED, BillingStatus.PAYMENT_ERROR, BillingStatus.DELETED];
  return !_.includes(status);
});
export const BILLING_STATUS_OPTIONS_USE_PAYMENT = Object.values(BillingStatus).filter(
  status => status !== BillingStatus.DELETED
);

export const BILLING_ACTION_OPTIONS = Object.values(BillingAction).filter(
  action => action !== BillingAction.OPERATE_ALL
);

export const HONORIFIC_TYPE_OPTIONS = Object.values(HonorificType);

export const BILLING_DETAIL_PATHS: Path[] = [
  Path.BILLING_DETAILS_V2_DETAIL,
  Path.BILLING_DETAILS_V2_SPACE_USAGES,
  Path.BILLING_DETAILS_V2_ACCESSES,
  Path.BILLING_DETAILS_V2_LOGS
];

/** billing header */
export const BILLING_ID = 'billingId';
export const BILLING_NO = 'billingNo';
export const BILLING_NAME = 'billingName';
export const CUTOFF_DATE = 'cutoffdate';
export const BILLING_DATE = 'billingDate';
export const PAYMENT_DUE_DATE = 'paymentDueDate';
export const CONTRACTOR_NAME = 'contractorName';
export const HONORIFIC_TYPE = 'honorificType';
export const CONTRACTOR_INFO = 'contractorInfo';
export const PAYMENT_METHOD = 'paymentMethod';
export const PAYABLE_ACCOUNT = 'payableAccount';
export const PAYMENT_COUNT = 'paymentCount';
export const BILLING_POST = 'billingPost';
export const BILLING_ADDRESS = 'billingAddress';
export const BANK_ACCOUNT = 'bankAccount';
export const REMARKS = 'remarks';
export const STRIPE_INVOICE_ID = 'stripeInvoiceId';
export const TAX_INCLUDED_TOTAL_PRICE = 'taxIncludedTotalPrice';
export const TAX_EXCLUDED_TOTAL_PRICE = 'taxExcludedTotalPrice';

/** billing details */
export const BILLING_DETAIL_ID = 'billingDetailId';
export const BILLING_DETAIL_ITEM_NAME = 'itemName';
export const BILLING_DETAIL_UNIT_PRICE = 'unitPrice';
export const BILLING_DETAIL_QUANTITY = 'unitQuantity';
export const BILLING_DETAIL_UNIT_NAME = 'unitName';
export const BILLING_DETAIL_TAX = 'tax';
export const BILLING_DETAIL_PRICE = 'price';
export const CREATED_AT = 'createdAt';
export const UPDATED_AT = 'updatedAt';
