import { InputWithLabelV2, LabelV2, themeV2, useMobile } from '@atomica.co/components';
import { BaseDto, InterestTagPlain, SkillTagPlain, User } from '@atomica.co/irori';
import { Description, Gap, Message } from '@atomica.co/types';
import { EMPTY } from '@atomica.co/utils';
import React, { memo } from 'react';
import styled from 'styled-components';
import InterestTags from '../../../../components/tags/InterestTags';
import SkillTags from '../../../../components/tags/SkillTags';
import mojaco from './../../../../assets/mojaco/mojaco_break.png';

interface InputErrorMessage {
  selfIntroduction?: Message;
  biography?: Message;
}

interface Props {
  base: BaseDto;
  user: User;
  selfIntroduction: Description;
  biography: Description;
  interestTagNames: Pick<InterestTagPlain, 'interestTagName'>[];
  skillTagNames: Pick<SkillTagPlain, 'name'>[];
  inputErrorMessage: InputErrorMessage;
  setSelfIntroduction: React.Dispatch<React.SetStateAction<Description>>;
  setBiography: React.Dispatch<React.SetStateAction<Description>>;
  setInterestTagNames: React.Dispatch<React.SetStateAction<Pick<InterestTagPlain, 'interestTagName'>[]>>;
  setSkillTagNames: React.Dispatch<React.SetStateAction<Pick<SkillTagPlain, 'name'>[]>>;
}

const AccountProfileCardEditV3: React.FC<Props> = memo(props => {
  const {
    base,
    user,
    selfIntroduction,
    setSelfIntroduction,
    biography,
    setBiography,
    interestTagNames,
    setInterestTagNames,
    skillTagNames,
    setSkillTagNames,
    inputErrorMessage
  } = props;
  const isMobile = useMobile();

  return (
    <Card>
      <ContentWrapper isMobile={isMobile}>
        <ImageWrapper>
          <Image src={user.photoURL || mojaco} />
        </ImageWrapper>

        <InformationWrapper isMobile={isMobile}>
          <>
            <InformationRowWrapper isMobile={isMobile} data-testid={'self-introduction'}>
              <InputWithLabelV2
                text={'自己紹介'}
                value={selfIntroduction}
                onChange={setSelfIntroduction}
                required
                errorMessage={inputErrorMessage.selfIntroduction ?? EMPTY}
                multiline
              />
            </InformationRowWrapper>

            <InformationRowWrapper isMobile={isMobile} data-testid={'biography'}>
              <InputWithLabelV2
                text={'略歴'}
                value={biography}
                onChange={setBiography}
                optional
                errorMessage={inputErrorMessage ? inputErrorMessage.biography : EMPTY}
                multiline
              />
            </InformationRowWrapper>

            <InformationRowWrapper isMobile={isMobile} data-testid={'interestTags'}>
              <LabelWithContent>
                <LabelV2 text='趣味・好きなこと' optional />
                <InterestTags
                  editable={true}
                  addNewTags={true}
                  base={base}
                  interestTag={interestTagNames}
                  setInterestTags={setInterestTagNames}
                />
              </LabelWithContent>
            </InformationRowWrapper>

            <InformationRowWrapper isMobile={isMobile} data-testid={'skillTags'}>
              <LabelWithContent>
                <LabelV2 text='得意なこと' optional />
                <SkillTags
                  editable={true}
                  addNewTags={true}
                  base={base}
                  skillTags={skillTagNames}
                  setSkillTags={setSkillTagNames}
                />
              </LabelWithContent>
            </InformationRowWrapper>
          </>
        </InformationWrapper>
      </ContentWrapper>
    </Card>
  );
});
AccountProfileCardEditV3.displayName = 'AccountProfileCardEditV3';
export default AccountProfileCardEditV3;

const Card = styled.div`
  display: flex;
  width: 100%;
  padding: ${themeV2.mixins.v2.spacing * 2}px ${themeV2.mixins.v2.spacing * 3}px ${themeV2.mixins.v2.spacing * 4}px
    ${themeV2.mixins.v2.spacing * 3}px;
  flex-direction: column;
  align-items: flex-start;
  gap: ${themeV2.mixins.v2.spacing * 2}px;

  background: ${themeV2.mixins.v2.color.background.white};
  border-radius: ${themeV2.mixins.v2.spacing}px;
  margin-bottom: ${themeV2.mixins.v2.spacing * 20}px;
`;

const ContentWrapper = styled.div<{ isMobile: boolean }>`
  display: flex;
  align-items: center;
  gap: 16px;
  flex-direction: column;
  width: 100%;
`;

const ImageWrapper = styled.div`
  width: 120px;
  height: 120px;
  text-align: center;
`;

const InformationWrapper = styled.div<{ isMobile: boolean; gap?: Gap }>`
  width: inherit;
  display: flex;
  flex-direction: column;
  ${({ isMobile }) => (isMobile ? EMPTY : 'flex-grow: 1')};
  ${({ isMobile }) => (isMobile ? 'width:100%' : EMPTY)};
  align-items: flex-start;
  gap: ${({ gap }) => gap || themeV2.mixins.v2.spacing * 2}px;
`;

const InformationRowWrapper = styled.div<{ isHalf?: boolean; isMobile: boolean }>`
  display: flex;
  align-items: flex-start;
  flex-direction: ${({ isMobile }) => (isMobile ? 'column' : 'row')};
  gap: ${themeV2.mixins.v2.spacing * 3}px;
  width: ${({ isHalf }) => (isHalf ? 'calc(50% - ' + Math.floor((themeV2.mixins.v2.spacing * 3) / 2) + 'px)' : '100%')};
`;

const Image = styled.img`
  width: 120px;
  height: 120px;
  object-fit: cover;
  border: 1px solid ${themeV2.mixins.v2.color.border.gray};
  border-radius: 60px;
`;

const LabelWithContent = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 8px;
`;
